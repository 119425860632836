<template>
  <div class="frame-container" :style="`width: ${width};`">
    <div class="cedula-parent">
      <div class="cedula">{{ label }}</div>
      <div class="div" v-if="required">*</div>
    </div>
    <div class="wrapper">
      <input
        :type="type" v-model="internalValue"
        @blur="blur"
        @focus="$emit('focus', $event)"
        @KeyDown="$emit('KeyDown', $event)"
        @Copy="$emit('Copy', $event)"
        ref="inputField"
        :required="required"
      >
      <i v-if="icon" class="icon-chevron" @click="$emit('click:append')" v-html="getIcon"></i>
    </div>
    <div>
      <span v-if="errorMessage" class="error--text">{{ errorMessage }}</span>
    </div>
  </div>
</template>
<script>
import tools from '@/api/tools.js'
import icons from '../../assets/redaIcons'
export default {
  name: 'CdInputText',
  components: {},
  props: {
    icon: String,
    label: String,
    value: String,
    required: {
      type: Boolean,
      default: false
    },
    width: [Number, String],
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      lazyValue: this.value,
      errorMessage: null
    }
  },
  computed: {
    getIcon () {
      return icons[this.icon]
    },
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', this.lazyValue)
      }
    }
  },
  watch: {
    value (val) {
      this.lazyValue = val
    }
  },
  methods: {
    blur (e) {
      const target = e.target
      if (!target.checkValidity()) {
        this.errorMessage = target.validationMessage
      } else {
        this.errorMessage = null
      }
      this.$emit('blur', e)
      this.$emit('change', e)
    },
    onFocus () {
      this.$refs.inputField.focus()
    },
    onInput (e) {
      // if (
      //   this.selectedIndex > -1 ||
      //   !e.target
      // ) return

      const target = e.target
      const value = target.value

      // If typing and menu is not currently active
      // if (target.value) this.activateMenu()

      // if (!this.multiple && value === '') this.deleteCurrentItem()

      // this.internalSearch = value
      this.setValue(value)
      // this.badInput = target.validity && target.validity.badInput
    },
    setValue (value) {
      if (!this.valueComparator(value, this.internalValue)) {
        this.internalValue = value
        this.$emit('change', value)
      }
    },
    valueComparator (a, b) {
      return tools.deepEqual(a, b)
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.cedula {
  position: relative;
  font-weight: 500;
}
.wrapper {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #000;
}
.div {
  position: relative;
  font-weight: 500;
  color: #f72585;
}
.cedula-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.div1 {
  flex: 1;
  position: relative;
}
input {
  width: 100%;
    flex: 1;
    position: relative;
    border: none;
    outline: none;
    background: transparent;
  padding: 6px 16px;
}
.frame-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.icon-chevron {
  width: 16px;
  position: relative;
  height: 16px;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}
</style>
