<template>
  <v-container>
    <Period></Period>
    <v-data-table
      :headers="headers"
      :items="vouchersReceivedItems"
      sort-by="date"
      :search="search"
      class="elevation-1"
      :loading="sendingVoucher"
      sort-desc
      dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Comprobantes Electrónicos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="mdi-magnify"
            class="mt-3 py-2 mr-2"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="500px">
            <NewReceipt
              :isPayment="true"
              :voucher="tmp.voucher"
              :amountOutstanding="tmp.amountOutstanding"
              receiptsFrom="01"
              @cancel="dialog = false"
            ></NewReceipt>
          </v-dialog>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-btn @click="clickUpload" color="secondary" class="mx-2"  :loading="uploadingVoucher"><v-icon left>mdi-upload</v-icon>Subir</v-btn>
                <input type="file" ref="xml" accept="application/xml" @change="handleFileUpload" hidden>
              </v-col>
            </v-row>
          </v-form>
          <v-btn @click="downloadExcel"><v-icon>mdi-file-excel</v-icon></v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.voucherTotal="{ item }">
        {{ item.totalVouchersCRC | currencyFormat(item.data.summary.currency) }}
      </template>
      <template v-slot:item.amountOutstanding="{ item }">
        {{ item.amountOutstanding * Number(item.data.summary.exchangeRate) | currencyFormat(item.data.summary.currency) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{moment(item.date).format('l')}}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex flex-row">
          <v-tooltip bottom v-for="(option, index) in items" :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-1"
                :color="option.color || 'info'"
                @click="option.text === 'Realizar pago' ? newPayVoucher(item) : option.callback(item)"
                :disabled="(option.text === 'Realizar pago' && Number(item.amountOutstanding) <= 0)"
              >
                {{ option.icon }}
              </v-icon>
            </template>
            <span>{{ option.text }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        {{vouchersType[item.data.documentType]}}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="(item.type === '02' || item.type === '03')">N/A</span>
        <span :class="[item.collected ? 'success--text' : 'error--text']" v-else>{{item.collected ? 'Pagada' : 'Por Pagar'}}</span>
      </template>
      <template v-slot:item.state="{ item }">
        {{status[item.treasuryMessage]}}
      </template>
      <template v-slot:item.datetime="{ item }">
        {{getDateString(item.date)}}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import store from '@/store/index.js'
import router from '@/router/index.js'
import tools from '@/api/tools.js'
import axios from 'axios'
import moment from 'moment'
import vex from 'vex-js'
import printer from '@/api/printer.js'
import Period from '@/components/Period.vue'
import NewReceipt from '@/components/NewReceipt.vue'
// import redaApp from '../api/redaApp'
// import vexDialog from 'vex-dialog'

// Main css
import 'vex-js/dist/css/vex.css'

// Themes (Import all themes you want to use here)
import 'vex-js/dist/css/vex-theme-default.css'
import 'vex-js/dist/css/vex-theme-os.css'

// Options
vex.defaultOptions.className = 'vex-theme-default'

// Register vex-dialog
// vex.registerPlugin(vexDialog)
// const smalltalk = require('smalltalk');

export default {
  name: 'Vouchers',
  components: { NewReceipt, Period },
  data: () => ({
    // showTable: false,
    search: null,
    moment,
    status: {
      1: 'Aceptado',
      2: 'Aceptación parcial',
      3: 'Rechazado',
      0: 'Enviado'
    },
    tmp: {
      voucher: null,
      amountOutstanding: null
    },
    vouchersType: {
      '01': 'Factura',
      '02': 'Nota de débito',
      '03': 'Nota de crédito',
      '04': 'Tiquete',
      10: 'Tiquete Régimen Simplificado'
    },
    dialog: false,
    uploadingVoucher: false,
    headersExcel: [
      { text: 'Fecha', value: 'date', type: 'date' },
      { text: 'Consecutivo', value: 'data.sequence' },
      {
        text: 'Tipo',
        value: 'data.documentType',
        enum: {
          '01': 'Factura',
          '02': 'Nota Débito',
          '03': 'Nota Crédito',
          '04': 'Tiquete',
          10: 'Tiquete Régimen Simplificado'
        }
      },
      { text: 'Emisor', value: 'data.issuer.name' },
      { text: 'I.V.A Colones', value: 'totalVouchersTaxCRC', type: 'number' },
      { text: 'Total Colones', value: 'totalVouchersCRC', type: 'number' },
      { text: 'Moneda', value: 'data.summary.currency' },
      { text: 'Estado', value: 'treasuryMessage' }
    ],
    itemsTable: [],
    items: [
      { text: 'Ver Comprobante', callback (item) { router.push('/voucher/' + item.key) }, icon: 'mdi-eye' },
      {
        text: 'Realizar pago',
        icon: 'mdi-cash-plus'
      },
      // { text: 'XML', callback (item) { store.dispatch('vouchers/downloadXmlFE', { key: item.key, xml: 'xml' }).catch(error => { store.dispatch('showError', error) }) }, icon: 'fa-file-code' },
      // { text: 'Respuesta Hacienda', callback (item) { if (item.treasuryMessage === '0') { store.dispatch('showInfo', 'El Comprobante no tiene respuesta de Hacienda todavía') } else { store.dispatch('vouchers/downloadXmlFE', { key: item.key, xml: 'mh' }) } }, icon: 'fa-clipboard-check' },
      { text: 'Descargar pdf', callback (item) { store.dispatch('vouchers/downloadXmlFE', { key: item.key, xml: 'pdf' }) }, icon: 'mdi-file-download' },
      /* {
        text: 'Nota de Crédito',
        callback (item) {
          store.commit('setSendingVoucher', true)
          store.dispatch('submitNDNC', { type: '03', action: '01', reason: 'Error en los montos', voucher: item })
            .then(() => {
              store.dispatch('showSuccess', 'El Comprobante ' + item.key + ' fue emitido correctamente')
            })
            .catch(error => {
              console.error(error)
              store.dispatch('showError', 'Error al emitir comprobante')
            })
            .finally(() => {
              store.commit('setSendingVoucher', false)
            })
        },
        icon: 'fa-file-alt'
      }, /* */
      /* {
        text: 'Nota de Débito',
        callback (item) {
          store.commit('setSendingVoucher', true)
          store.dispatch('submitNDNC', { type: '02', action: '01', reason: 'Error en los montos', voucher: item })
            .then(() => {
              store.dispatch('showSuccess', 'El Comprobante ' + item.key + ' fue emitido correctamente')
            })
            .catch(error => {
              console.error(error)
              store.dispatch('showError', 'Error al emitir comprobante')
            })
            .finally(() => {
              store.commit('setSendingVoucher', false)
            })
        },
        icon: 'fa-file'
      },/* */
      {
        text: 'Reenviar Correo',
        callback: (item) => {
          vex.dialog.prompt({
            message: 'Reenviar correo electrónico',
            placeholder: 'Correo electrónico',
            callback: function (value) {
              store.commit('setSendingVoucher', true)
              const payload = {
                dest: value,
                key: item.key
              }
              console.log(payload)
              axios.post('https://us-central1-facturacionelectronica-387a7.cloudfunctions.net/api/sendMail', payload)
                .then(() => {
                  store.commit('setSendingVoucher', false)
                // alert('Correo electrónico enviado');
                })
                .catch(error => {
                  console.log(error)
                  store.commit('setSendingVoucher', false)
                // alert('El correo electrónico no pudó ser enviado');
                })
            }
          })
        },
        icon: 'mdi-email-fast'
      },
      { text: 'Imprimir Comprobante', callback (item) { printer.printVoucher(item.data); console.log(item) }, icon: 'mdi-printer' }
    ],
    currentVoucher: null,
    renderPDFhtml: false,
    showHtml: false
  }),

  computed: {

    headers () {
      return [
        { text: 'Fecha de emisión', value: 'date' },
        {
          text: 'Consecutivo',
          sortable: false,
          value: 'data.sequence'
        },
        { text: 'Tipo de comprobante', value: 'type' },
        { text: 'Emisor', value: 'data.issuer.name' },
        { text: 'Total', value: 'voucherTotal', align: 'end' },
        // { text: 'Pendiente de pago', value: 'amountOutstanding', align: 'end' },
        { text: 'Estado', value: 'status' },
        (this.receivedBy.length > 1 ? { text: 'Recibido por', value: 'user.name' } : undefined),
        { text: 'Acciones', value: 'action', sortable: false }
      ]
        .filter((dato) => dato !== undefined)
    },

    vouchersReceivedItems () {
      const types = ['01', '02', '03', '04', '10']
      const items = []
      for (let index = 0; index < this.getVouchersReceived.length; index++) {
        const item = this.getVouchersReceived[index]
        if (types.includes(item.data.documentType) && (this.from < item.date && item.date < this.to)) {
          item.type = item.data.documentType
          items.push(item)
        }
      }
      return items
    },

    receivedBy () {
      const receivedBy = []
      this.vouchersReceivedItems.forEach((voucher, i) => {
        if (voucher.user) receivedBy.push(voucher.user.name)
      })
      return receivedBy.filter((item, i) => receivedBy.indexOf(item) === i)
    },

    ...mapState(['sendingVoucher', 'from', 'to']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('vouchers', ['getVouchersReceived'])

  },

  created () {},
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    clickUpload () {
      this.$refs.xml.click()
    },
    handleFileUpload () {
      this.uploadingVoucher = true
      const xmlFile = this.$refs.xml.files[0]
      // this.certName = this.$refs.file.files[0].name;
      if (xmlFile.type !== 'text/xml' && xmlFile.type !== 'application/xml') {
        alert('El archivo debe ser un XML')
        return
      }
      const formData = new FormData()
      formData.append('xml', xmlFile)
      axios.post(process.env.VUE_APP_API_BASE_URL + '/api/upload-xml-voucher', formData)
        .then(() => {
          return this.showSuccess('Comprobante agragado.')
        })
        .catch(error => {
          console.error(error)
          return this.showError(error)
        })
        .finally(() => {
          this.uploadingVoucher = false
        })
      // console.log(document.getElementById('input-285'));
    },
    close () {
      this.dialog = false
    },
    newPayVoucher (item) {
      /* eslint-disable */
      this.tmp.voucher = { ...item }
      this.tmp.amountOutstanding = item.amountOutstanding
      this.dialog = true
      /* eslint-disable */
    },

    downloadExcel () {
      tools.table2excel(this.vouchersReceivedItems, this.headersExcel, 'Comprobantes')
    },

    getDateString (dateTime) {
      return moment(dateTime).format()
    },

    downloadXML (voucher) {
      this.downloadXmlFE({ key: voucher.key, xml: 'xml' })
    },

    downloadMH () {
      console.log('downloadMH')
    },

    downloadPDF () {
      console.log('downloadPDF')
    },

    submitNC () {
      console.log('submitNC')
    },

    submitND () {
      console.log('submitND')
    },

    ...mapActions(['submitNDNC']),
    ...mapMutations(['setSendingVoucher'])

  },
  mounted() {
    // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
  }
}
</script>
<style type="text/css">

</style>
