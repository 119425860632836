<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Contabilidad
    </div>
    <v-card
      elevation="2"
      class="my-10"
    >
    <v-card-title>
      Importar saldo inicial
    </v-card-title>
    <v-card-text>
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mb-2 mx-2"
            v-bind="attrs"
            v-on="on"
            :loading="loading"
          ><v-icon left>mdi-import</v-icon>
            Importar saldo inicial desde Excel
          </v-btn>
        </template>

        <ValidationObserver v-slot="{ invalid }">
          <v-card>
            <v-card-title>
              Importación de saldo inicial
            </v-card-title>

            <v-card-text>
              <p>Puedes crear el saldo inicial de las cuentas contables a partir de una hoja de cálculo de Excel</p>
              <v-form>
                <v-row>
                  <v-col cols="12" sm="8">
                    <ValidationProvider v-slot="{ errors }" name="excel" rules="required">
                      <v-text-field label="Cargar hoja de cálculo" prepend-icon="mdi-microsoft-excel" :error-messages="errors" v-model="fileName" @click="clickUpload" clearable required readonly></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors, validate }" name="dataFile" rules="required">
                      <input type="file" ref="file" @change="handleFileUpload" @input="validate" hidden>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-text>
              Descarga la plantilla <a @click="downloadExcel">aquí</a>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="dialog = false"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="save"
              >
                Importar datos
              </v-btn>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-dialog>
    </v-card-text>
    <v-card-title>
      Cuentas por defecto del sistema
    </v-card-title>
    <v-card-text>
      <RedaForm
      :options="form"
      v-model="tmp.config"
      @submitForm="submitAccoutingConfig"
      ></RedaForm>
    </v-card-text>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import axios from 'axios'
import { auth } from '@/api/firebaseApi'
import tools from '@/api/tools'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

export default {
  name: 'Product',
  props: ['id'],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    dialog: false,
    tmp: {
      config: {}
    }
  }),
  methods: {
    clickUpload () {
      this.$refs.file.click()
    },
    downloadExcel () {
      tools.table2excel([
        { balance: null, account: null }
      ], [
        { text: 'account', value: 'account' },
        { text: 'balance', value: 'balance' }
      ], 'Plantilla saldo inicial')
    },
    handleFileUpload () {
      this.dataFile = this.$refs.file.files[0]
      this.fileName = this.$refs.file.files[0].name
    },
    async save () {
      this.loading = true
      this.dialog = false
      const token = await auth.currentUser.getIdToken()
      const formData = new FormData()
      formData.append('dataFile', this.dataFile)
      axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/xlsx/initialBalance`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${token}`
          }
        }
      )
        .then(({ data }) => {
          this.showSuccess('Saldo inicial importado exitosamente')
        })
        .catch(error => {
          this.showError('Error al importar saldo inicial')
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('message', ['showError', 'showSuccess']),
    submitAccoutingConfig () {
      this.loading = true
      this.setAccoutingConfig({ ...this.tmp.config })
        .then(() => this.showSuccess('Cuentas guardadas correctamente'))
        .catch((error) => {
          console.error(error)
          this.showError('Error al guardar cuentas')
        })
        .finally(() => { this.loading = false })
    },
    ...mapActions('company', ['setAccoutingConfig'])
  },
  computed: {
    form () {
      return {
        submitText: 'Guardar',
        showSubmit: true,
        inputs: [
          {
            id: 'de01',
            label: 'Cuentas por cobrar',
            name: 'accountReceivable',
            value: 'accountReceivable',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de02',
            label: 'Cuentas por pagar',
            name: 'accountPayable',
            value: 'accountPayable',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('2')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de03',
            label: 'Ingresos por venta de bienes',
            name: 'revenueFromSaleOfGoods',
            value: 'revenueFromSaleOfGoods',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('4')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de04',
            label: 'Ingresos por servicios',
            name: 'revenueFromRenderingOfServices',
            value: 'revenueFromRenderingOfServices',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('4')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de05',
            label: 'IVA por pagado',
            name: 'currentTaxLiabilitiesIVA',
            value: 'currentTaxLiabilitiesIVA',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('2')),
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            cols: 6,
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de06',
            label: 'Propiedades, planta y equipo',
            name: 'propertyPlantAndEquipment',
            value: 'propertyPlantAndEquipment',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de07',
            label: 'Inventarios corrientes mantenidos para la venta',
            name: 'inventoriesHeldForSale',
            value: 'inventoriesHeldForSale',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de08',
            label: 'Materiales y suministros a consumir en producción',
            name: 'materialsAndSuppliesToProduction',
            value: 'materialsAndSuppliesToProduction',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de09',
            label: 'Inventarios de trabajo en curso corriente',
            name: 'workInProgress',
            value: 'workInProgress',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de10',
            label: 'IVA pagado',
            name: 'currentTaxAssetsIVA',
            value: 'currentTaxAssetsIVA',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('1')),
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            cols: 6,
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de11',
            label: 'Gastos de administración',
            name: 'administrativeExpense',
            value: 'administrativeExpense',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('5')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de12',
            label: 'Costos de venta y distribución',
            name: 'distributionCosts',
            value: 'distributionCosts',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('5')),
            cols: 6,
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            itemText: 'name',
            itemValue: 'id'
          },
          {
            id: 'de13',
            label: 'Ganancias acumuladas',
            name: 'retainedEarnings',
            value: 'retainedEarnings',
            type: 'autocomplete',
            items: this.getAccountsDetails.filter(({ code }) => code.startsWith('3')),
            filter: (item, queryText, itemText) => {
              const textOne = item.name.toLowerCase()
              const textTwo = item.id.toString()
              const searchText = queryText.toLowerCase()

              return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            },
            cols: 6,
            itemText: 'name',
            itemValue: 'id'
          }
        ]
      }
    },
    accountingConfig () {
      return this.getAccountingConfig
    },
    ...mapGetters('company', ['getAccountingConfig']),
    ...mapGetters('accounting', ['getAccountsDetails']),
    ...mapState('accounting', [
      'accountReceivable',
      'accountPayable',
      'revenueFromSaleOfGoods',
      'revenueFromRenderingOfServices',
      'currentTaxLiabilitiesIVA'
    ])
  },
  mounted () {
    const config = {
      accountReceivable: this.getAccountingConfig.accountReceivable,
      accountPayable: this.getAccountingConfig.accountPayable,
      revenueFromSaleOfGoods: this.getAccountingConfig.revenueFromSaleOfGoods,
      revenueFromRenderingOfServices: this.getAccountingConfig.revenueFromRenderingOfServices,
      currentTaxLiabilitiesIVA: this.getAccountingConfig.currentTaxLiabilitiesIVA,
      propertyPlantAndEquipment: this.getAccountingConfig.propertyPlantAndEquipment,
      inventoriesHeldForSale: this.getAccountingConfig.inventoriesHeldForSale,
      materialsAndSuppliesToProduction: this.getAccountingConfig.materialsAndSuppliesToProduction,
      workInProgress: this.getAccountingConfig.workInProgress,
      currentTaxAssetsIVA: this.getAccountingConfig.currentTaxAssetsIVA,
      administrativeExpense: this.getAccountingConfig.administrativeExpense,
      distributionCosts: this.getAccountingConfig.distributionCosts,
      retainedEarnings: this.getAccountingConfig.retainedEarnings
    }
    this.tmp.config = { ...config }
  }
}
</script>
