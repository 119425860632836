/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import axios from 'axios'
import fbApi from '@/api/firebaseApi'
import tools from '@/api/tools'

const { db } = fbApi

// initial state
const initialState = {
  messages: [],
  listinerMessages: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getMessages (state) {
    return state.messages
  },
  nextMRA (state) {
    const messages = state.messages.filter(message => {
      return message.message === '1'
    })
    return messages.length + 1
  },
  nextMRR (state) {
    const messages = state.messages.filter(message => {
      return message.message === '3'
    })
    return messages.length + 1
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const messagesQuerySnapshot = await fbApi.db.collection('messages')
        .where('issuer', '==', company)
        .get()
      const messages = []
      messagesQuerySnapshot.forEach((doc) => messages.push({ uid: doc.id, ...doc.data() }))
      commit('setMessages', messages)
      const listinerMessages = messagesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newMessages = []
        querySnapshot.forEach((doc) => newMessages.push({ uid: doc.id, ...doc.data() }))
        commit('setMessages', newMessages)
      })
      commit('setListinerMessages', listinerMessages)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar mensajes de hacienda')
    }
  },
  newMessage: function ({ state, getters, dispatch, rootGetters }, voucher) {
    return new Promise((resolve, reject) => {
      const next = voucher.message === '1' ? getters.nextMRA : getters.nextMRR
      const date = new Date()
      const document = voucher.data
      let numSequence = 5
      if (voucher.message === '1') { numSequence = 5 }
      if (voucher.message === '2') { numSequence = 6 }
      if (voucher.message === '3') { numSequence = 7 }
      const message = {
        key: voucher.key,
        issuerType: document.issuer.type,
        issuer: document.issuer.identification,
        date: date.toISOString(),
        message: voucher.actionMessage,
        // messageDetail:
        activityCode: document.activityCode,
        taxCondition: voucher.taxCondition,
        taxTotal: document.summary.taxTotal,
        total: document.summary.netTotal,
        receiverType: document.receiver.type,
        receiver: voucher.receiver,
        sequence: '00100001' + tools.zfill(numSequence, 2) + tools.zfill(next, 10),
        api: {
          username: rootGetters['company/getCompany'].api.user,
          password: rootGetters['company/getCompany'].api.password,
          cert: rootGetters['company/getCompany'].api.cert,
          environment: rootGetters['company/getCompany'].api.environment
        }
        // payFrom: voucher.payFrom
      }
      // console.log(message);
      axios.post('https://us-central1-facturacionelectronica-387a7.cloudfunctions.net/api/message', message)
        .then(response => {
          db.collection('messages').add(message)
            /* .then(() => {
              return dispatch('vouchers/getXml', message.key, { root: true })
            }) /**/
            .then(() => {
              return dispatch('vouchers/insertXml', {
                message: response.data.xml,
                key: message.key
              }, { root: true })
            }).then(() => {
              resolve(message.key)
            })
            /* .then(() => {
              return db.collection('vouchers').where('key', '==', voucher.key).get()
            })
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                const voucherRef = doc.ref
                voucherRef.update({
                  message: message.key
                })
                  .then(() => { resolve(message.key) })
                  .catch(error => {
                    reject(error)
                  })
              })
            }) /* */
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })/**/
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerMessages) {
      state.listinerMessages()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerMessages (state, listener) {
    state.listinerMessages = listener
  },
  setMessages (state, payload) {
    if (!payload) state.listinerMessages()
    state.messages = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
