<template>
  <v-card
    class="mx-auto pa-6"
    width="260"
    elevation="0"
  >
    <v-list
    >
      <v-list-item
        class="pa-0"
      >
        <v-list-item-avatar>
          <v-img :src="getUser.photoURL" v-if="getUser.photoURL"></v-img>
          <v-icon class="grey" dark v-else>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ (getUser.displayName || getUser.name) | capitalizeWords }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog
      v-model="dialog"
      max-width="848"
    >
      <SupportCard @close="dialog = false"></SupportCard>
    </v-dialog>
    <v-list class="ma-0">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <template
          :to="item.to"
          class="pa-0"
        >
        <v-list-item
          v-for="(item, i) in itemsMenu"
          :key="i"
          :to="item.to"
          @click.stop="dialog = (item.text === 'Soporte')"
          class="pa-0"
        >
          <v-list-item-icon class="ma-3">
            <i v-html="item.icon" v-if="item.custom"></i>
            <v-icon v-text="item.icon" color="tertiary" v-else></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text" class="subtitle-1"></v-list-item-title>
          </v-list-item-content>
        </v-list-item></template>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-card-actions class="py-0">
      <v-list>
        <v-list-item
          class="pa-0"
          @click="$emit('logOut')"
        >
          <v-list-item-icon>
            <i v-html="icons.CerrarSesion"></i>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import icons from '../assets/redaIcons'
import SupportCard from '@/components/SupportCard.vue'
export default {
  name: 'NavigationDrawer',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  components: { SupportCard },
  data () {
    return {
      dialog: false,
      icons,
      selectedItem: 0,
      items: [
        { text: 'Configuración', icon: icons.Configuracion, to: '/company', show: true, custom: true },
        // { text: 'Notificaciones', icon: 'mdi-bell-outline', to: '/messages', show: true },
        { text: 'Cambiar contraseña', icon: icons.CambiarContraseña, to: '/updatePassword/change', show: true, custom: true },
        { text: 'Compañias', icon: 'mdi-domain', to: '/companies', show: true },
        // { text: 'Refiere a un amigo', icon: 'mdi-account-multiple', to: '/referrals', show: true },
        { text: 'Soporte', icon: 'mdi-phone-outline', to: undefined, show: true }
      ]
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente,
    itemsMenu () {
      return this.items.filter(({ show }) => show)
    },
    ...mapGetters('user', [
      'getLogged',
      'getUser',
      'getViews',
      'getAuthorizations'
    ])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    close () {
      this.dialog = false
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
