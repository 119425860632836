const provinces = [
  { text: 'San josé', value: '1' },
  { text: 'Alajuera', value: '2' },
  { text: 'Cartago', value: '3' },
  { text: 'Heredia', value: '4' },
  { text: 'Guanacaste', value: '5' },
  { text: 'Puntarenas', value: '6' },
  { text: 'Limon', value: '7' }
]

const counties = {
  1: [
    { text: 'Central', value: '01' },
    { text: 'Escazú', value: '02' },
    { text: 'Desamparados', value: '03' },
    { text: 'Puriscal', value: '04' },
    { text: 'Tarrazú', value: '05' },
    { text: 'Aserrí', value: '06' },
    { text: 'Mora', value: '07' },
    { text: 'Goicoechea', value: '08' },
    { text: 'Santa Ana', value: '09' },
    { text: 'Alajuelita', value: '10' },
    { text: 'Vázquez De Coronado', value: '11' },
    { text: 'Acosta', value: '12' },
    { text: 'Tibás', value: '13' },
    { text: 'Moravia', value: '14' },
    { text: 'Montes De Oca', value: '15' },
    { text: 'Turrubares', value: '16' },
    { text: 'Dota', value: '17' },
    { text: 'Curridabat', value: '18' },
    { text: 'Pérez Zeledón', value: '19' },
    { text: 'León Cortés Castro', value: '20' }
  ],
  2: [
    { text: 'Central', value: '01' },
    { text: 'San Ramón', value: '02' },
    { text: 'Grecia', value: '03' },
    { text: 'San Mateo', value: '04' },
    { text: 'Atenas', value: '05' },
    { text: 'Naranjo', value: '06' },
    { text: 'Palmares', value: '07' },
    { text: 'Poás', value: '08' },
    { text: 'Orotina', value: '09' },
    { text: 'San Carlos', value: '10' },
    { text: 'Zarcero', value: '11' },
    { text: 'Sarchí', value: '12' },
    { text: 'Upala', value: '13' },
    { text: 'Los Chiles', value: '14' },
    { text: 'Guatuso', value: '15' },
    { text: 'Río Cuarto', value: '16' }
  ],
  3: [
    { text: 'Central', value: '01' },
    { text: 'Paraíso', value: '02' },
    { text: 'La Unión', value: '03' },
    { text: 'Jiménez', value: '04' },
    { text: 'Turrialba', value: '05' },
    { text: 'Alvarado', value: '06' },
    { text: 'Oreamuno', value: '07' },
    { text: 'El Guarco', value: '08' }
  ],
  4: [
    { text: 'Central', value: '01' },
    { text: 'Barva', value: '02' },
    { text: 'Santo Domingo', value: '03' },
    { text: 'Santa Barbara', value: '04' },
    { text: 'San Rafael', value: '05' },
    { text: 'San Isidro', value: '06' },
    { text: 'Belén', value: '07' },
    { text: 'Flores', value: '08' },
    { text: 'San Pablo', value: '09' },
    { text: 'Sarapiquí', value: '10' }
  ],
  5: [
    { text: 'Liberia', value: '01' },
    { text: 'Nicoya', value: '02' },
    { text: 'Santa Cruz', value: '03' },
    { text: 'Bagaces', value: '04' },
    { text: 'Carrillo', value: '05' },
    { text: 'Cañas', value: '06' },
    { text: 'Abangares', value: '07' },
    { text: 'Tilarán', value: '08' },
    { text: 'Nandayure', value: '09' },
    { text: 'La Cruz', value: '10' },
    { text: 'Hojancha', value: '11' }
  ],
  6: [
    { text: 'Central', value: '01' },
    { text: 'Esparza', value: '02' },
    { text: 'Buenos Aires', value: '03' },
    { text: 'Montes De Oro', value: '04' },
    { text: 'Osa', value: '05' },
    { text: 'Quepos', value: '06' },
    { text: 'Golfito', value: '07' },
    { text: 'Coto Brus', value: '08' },
    { text: 'Parrita', value: '09' },
    { text: 'Corredores', value: '10' },
    { text: 'Garabito', value: '11' }
  ],
  7: [
    { text: 'Central', value: '01' },
    { text: 'Pococí', value: '02' },
    { text: 'Siquirres', value: '03' },
    { text: 'Talamanca', value: '04' },
    { text: 'Matina', value: '05' },
    { text: 'Guácimo', value: '06' }
  ]
}

const districts = {
  108: [
    { text: 'Guadalupe', value: '01' },
    { text: 'San Francisco', value: '02' },
    { text: 'Calle Blancos', value: '03' },
    { text: 'Mata De Platano', value: '04' },
    { text: 'Ipís', value: '05' },
    { text: 'Rancho Redondo', value: '06' },
    { text: 'Purral', value: '07' }
  ],
  101: [
    { text: 'Carmen', value: '01' },
    { text: 'Merced', value: '02' },
    { text: 'Hospital', value: '03' },
    { text: 'Catedral', value: '04' },
    { text: 'Zapote', value: '05' },
    { text: 'San Francisco De Dos Rios', value: '06' },
    { text: 'Uruca', value: '07' },
    { text: 'Mata Redonda', value: '08' },
    { text: 'Pavas', value: '09' },
    { text: 'Hatillo', value: '10' },
    { text: 'San Sebastián', value: '11' }
  ],
  114: [
    { text: 'San Vicente', value: '01' },
    { text: 'San Jeronimo', value: '02' },
    { text: 'La Trinidad', value: '03' }
  ],
  115: [
    { text: 'San Pedro', value: '01' },
    { text: 'Sabanilla', value: '02' },
    { text: 'Mercedes', value: '03' },
    { text: 'San Rafael', value: '04' }
  ],
  203: [
    { text: 'Grecia', value: '01' },
    { text: 'San Isidro', value: '02' },
    { text: 'San José', value: '03' },
    { text: 'San Roque', value: '04' },
    { text: 'Tacares', value: '05' },
    { text: 'Rio Cuarto', value: '06' },
    { text: 'Puente De Piedra', value: '07' },
    { text: 'Bolivar', value: '08' }
  ],
  104: [
    { text: 'Santiago', value: '01' },
    { text: 'Mercedes Sur', value: '02' },
    { text: 'Barbacoas', value: '03' },
    { text: 'Grifo Alto', value: '04' },
    { text: 'San Rafael', value: '05' },
    { text: 'Candelarita', value: '06' },
    { text: 'Desamparaditos', value: '07' },
    { text: 'San Antonio', value: '08' },
    { text: 'Chires', value: '09' }
  ],
  105: [
    { text: 'San Marcos', value: '01' },
    { text: 'San Lorenzo', value: '02' },
    { text: 'San Carlos', value: '03' }
  ],
  609: [{ text: 'Parrita', value: '01' }],
  103: [
    { text: 'Desamparados', value: '01' },
    { text: 'San Miguel', value: '02' },
    { text: 'San Juan De Dios', value: '03' },
    { text: 'San Rafael Arriba', value: '04' },
    { text: 'San Rafael Abajo', value: '05' },
    { text: 'San Antonio', value: '06' },
    { text: 'Frailes', value: '07' },
    { text: 'Patarra', value: '08' },
    { text: 'San Cristobal', value: '09' },
    { text: 'Rosario', value: '10' },
    { text: 'Damas', value: '11' },
    { text: 'Gravilias', value: '12' },
    { text: 'Los Guido', value: '13' }
  ],
  201: [
    { text: 'Alajuela', value: '01' },
    { text: 'San José', value: '02' },
    { text: 'Carrizal', value: '03' },
    { text: 'San Antonio', value: '04' },
    { text: 'Guácima', value: '05' },
    { text: 'San Isidro', value: '06' },
    { text: 'Sabanilla', value: '07' },
    { text: 'San Rafael', value: '08' },
    { text: 'Rio Segundo', value: '09' },
    { text: 'Desamparados', value: '10' },
    { text: 'Turrucares', value: '11' },
    { text: 'Tambor', value: '12' },
    { text: 'Garita', value: '13' },
    { text: 'Sarapiquí', value: '14' }
  ],
  206: [
    { text: 'Naranjo', value: '01' },
    { text: 'San Miguel', value: '02' },
    { text: 'San José', value: '03' },
    { text: 'Cirrí Sur', value: '04' },
    { text: 'San Jerónimo', value: '05' },
    { text: 'San Juan', value: '06' },
    { text: 'El Rosario', value: '07' },
    { text: 'Palmitos', value: '08' }
  ],
  117: [
    { text: 'Santa María', value: '01' },
    { text: 'Jardin', value: '02' },
    { text: 'Copey', value: '03' }
  ],
  102: [
    { text: 'Escazú', value: '01' },
    { text: 'San Antonio', value: '02' },
    { text: 'San Rafael', value: '03' }
  ],
  204: [
    { text: 'San Mateo', value: '01' },
    { text: 'Desmonte', value: '02' },
    { text: 'Jesús María', value: '03' },
    { text: 'Labrador', value: '04' }
  ],
  112: [
    { text: 'San Ignacio', value: '01' },
    { text: 'Guaitil', value: '02' },
    { text: 'Palmichal', value: '03' },
    { text: 'Cangrejal', value: '04' },
    { text: 'Sabanillas', value: '05' }
  ],
  305: [
    { text: 'Turrialba', value: '01' },
    { text: 'La Suiza', value: '02' },
    { text: 'Peralta', value: '03' },
    { text: 'Santa Cruz', value: '04' },
    { text: 'Santa Teresita', value: '05' },
    { text: 'Pavones', value: '06' },
    { text: 'Tuis', value: '07' },
    { text: 'Tayutic', value: '08' },
    { text: 'Santa Rosa', value: '09' },
    { text: 'Tres Equis', value: '10' },
    { text: 'La Isabel', value: '11' },
    { text: 'Chirripó', value: '12' }
  ],
  119: [
    { text: 'San Isidro De El General', value: '01' },
    { text: 'El General', value: '02' },
    { text: 'Daniel Flores', value: '03' },
    { text: 'Rivas', value: '04' },
    { text: 'San Pedro', value: '05' },
    { text: 'Platanares', value: '06' },
    { text: 'Pejibaye', value: '07' },
    { text: 'Cajon', value: '08' },
    { text: 'Baru', value: '09' },
    { text: 'Rio Nuevo', value: '10' },
    { text: 'Páramo', value: '11' }
  ],
  211: [
    { text: 'Zarcero', value: '01' },
    { text: 'Laguna', value: '02' },
    { text: 'Tapesco', value: '03' },
    { text: 'Guadalupe', value: '04' },
    { text: 'Palmira', value: '05' },
    { text: 'Zapote', value: '06' },
    { text: 'Brisas', value: '07' }
  ],
  213: [
    { text: 'Upala', value: '01' },
    { text: 'Aguas Claras', value: '02' },
    { text: 'San José o Pizote', value: '03' },
    { text: 'Bijagua', value: '04' },
    { text: 'Delicias', value: '05' },
    { text: 'Dos Rios', value: '06' },
    { text: 'Yolillal', value: '07' },
    { text: 'Canalete', value: '08' }
  ],
  202: [
    { text: 'San Ramón', value: '01' },
    { text: 'Santiago', value: '02' },
    { text: 'San Juan', value: '03' },
    { text: 'Piedades Norte', value: '04' },
    { text: 'Piedades Sur', value: '05' },
    { text: 'San Rafael', value: '06' },
    { text: 'San Isidro', value: '07' },
    { text: 'Angeles', value: '08' },
    { text: 'Alfaro', value: '09' },
    { text: 'Volio', value: '10' },
    { text: 'Concepción', value: '11' },
    { text: 'Zapotal', value: '12' },
    { text: 'Peñas Blancas', value: '13' },
    { text: 'San Lorenzo', value: '14' }
  ],
  207: [
    { text: 'Palmares', value: '01' },
    { text: 'Zaragoza', value: '02' },
    { text: 'Buenos Aires', value: '03' },
    { text: 'Santiago', value: '04' },
    { text: 'Candelaria', value: '05' },
    { text: 'Esquipulas', value: '06' },
    { text: 'La Granja', value: '07' }
  ],
  110: [
    { text: 'Alajuelita', value: '01' },
    { text: 'San Josecito', value: '02' },
    { text: 'San Antonio', value: '03' },
    { text: 'Concepción', value: '04' },
    { text: 'San Felipe', value: '05' }
  ],
  210: [
    { text: 'Quesada', value: '01' },
    { text: 'Florencia', value: '02' },
    { text: 'Buenavista', value: '03' },
    { text: 'Aguas Zarcas', value: '04' },
    { text: 'Venecia', value: '05' },
    { text: 'Pital', value: '06' },
    { text: 'La Fortuna', value: '07' },
    { text: 'La Tigra', value: '08' },
    { text: 'La Palmera', value: '09' },
    { text: 'Venado', value: '10' },
    { text: 'Cutris', value: '11' },
    { text: 'Monterrey', value: '12' },
    { text: 'Pocosol', value: '13' }
  ],
  216: [
    { text: 'Río Cuarto', value: '01' },
    { text: 'Santa Rita', value: '02' },
    { text: 'Santa Isabel', value: '03' }
  ],
  403: [
    { text: 'Santo Domingo', value: '01' },
    { text: 'San Vicente', value: '02' },
    { text: 'San Miguel', value: '03' },
    { text: 'Paracito', value: '04' },
    { text: 'Santo Tomás', value: '05' },
    { text: 'Santa Rosa', value: '06' },
    { text: 'Tures', value: '07' },
    { text: 'Para', value: '08' }
  ],
  308: [
    { text: 'El Tejar', value: '01' },
    { text: 'San Isidro', value: '02' },
    { text: 'Tobosi', value: '03' },
    { text: 'Patio De Agua', value: '04' }
  ],
  306: [
    { text: 'Pacayas', value: '01' },
    { text: 'Cervantes', value: '02' },
    { text: 'Capellades', value: '03' }
  ],
  208: [
    { text: 'San Pedro', value: '01' },
    { text: 'San Juan', value: '02' },
    { text: 'San Rafael', value: '03' },
    { text: 'Carrillos', value: '04' },
    { text: 'Sabana Redonda', value: '05' }
  ],
  307: [
    { text: 'San Rafael', value: '01' },
    { text: 'Cot', value: '02' },
    { text: 'Potrero Cerrado', value: '03' },
    { text: 'Cipreses', value: '04' },
    { text: 'Santa Rosa', value: '05' }
  ],
  401: [
    { text: 'Heredia', value: '01' },
    { text: 'Mercedes', value: '02' },
    { text: 'San Francisco', value: '03' },
    { text: 'Ulloa', value: '04' },
    { text: 'Varablanca', value: '05' }
  ],
  209: [
    { text: 'Orotina', value: '01' },
    { text: 'El Mastate', value: '02' },
    { text: 'Hacienda Vieja', value: '03' },
    { text: 'Coyolar', value: '04' },
    { text: 'La Ceiba', value: '05' }
  ],
  111: [
    { text: 'San Isidro', value: '01' },
    { text: 'San Rafael', value: '02' },
    { text: 'Dulce Nombre De Jesus', value: '03' },
    { text: 'Patalillo', value: '04' },
    { text: 'Cascajal', value: '05' }
  ],
  303: [
    { text: 'Tres Rios', value: '01' },
    { text: 'San Diego', value: '02' },
    { text: 'San Juan', value: '03' },
    { text: 'San Rafael', value: '04' },
    { text: 'Concepción', value: '05' },
    { text: 'Dulce Nombre', value: '06' },
    { text: 'San Ramón', value: '07' },
    { text: 'Rio Azul', value: '08' }
  ],
  404: [
    { text: 'Santa Bárbara', value: '01' },
    { text: 'San Pedro', value: '02' },
    { text: 'San Juan', value: '03' },
    { text: 'Jesús', value: '04' },
    { text: 'Santo Domingo', value: '05' },
    { text: 'Puraba', value: '06' }
  ],
  505: [
    { text: 'Filadelfia', value: '01' },
    { text: 'Palmira', value: '02' },
    { text: 'Sardinal', value: '03' },
    { text: 'Belen', value: '04' }
  ],
  502: [
    { text: 'Nicoya', value: '01' },
    { text: 'Mansión', value: '02' },
    { text: 'San Antonio', value: '03' },
    { text: 'Quebrada Honda', value: '04' },
    { text: 'Sámara', value: '05' },
    { text: 'Nosara', value: '06' },
    { text: 'Belén De Nosarita', value: '07' }
  ],
  402: [
    { text: 'Barva', value: '01' },
    { text: 'San Pedro', value: '02' },
    { text: 'San Pablo', value: '03' },
    { text: 'San Roque', value: '04' },
    { text: 'Santa Lucía', value: '05' },
    { text: 'San José de la Montaña', value: '06' }
  ],
  506: [
    { text: 'Cañas', value: '01' },
    { text: 'Palmira', value: '02' },
    { text: 'San Miguel', value: '03' },
    { text: 'Bebedero', value: '04' },
    { text: 'Porozal', value: '05' }
  ],
  301: [
    { text: 'Oriental', value: '01' },
    { text: 'Occidental', value: '02' },
    { text: 'Carmen', value: '03' },
    { text: 'San Nicolás', value: '04' },
    { text: 'Aguacaliente o San Francisco', value: '05' },
    { text: 'Guadalupe o Arenilla', value: '06' },
    { text: 'Corralillo', value: '07' },
    { text: 'Tierra Blanca', value: '08' },
    { text: 'Dulce Nombre', value: '09' },
    { text: 'Llano Grande', value: '10' },
    { text: 'Quebradilla', value: '11' }
  ],
  406: [
    { text: 'San Isidro', value: '01' },
    { text: 'San José', value: '02' },
    { text: 'Concepción', value: '03' },
    { text: 'San Francisco', value: '04' }
  ],
  510: [
    { text: 'La Cruz', value: '01' },
    { text: 'Santa Cecilia', value: '02' },
    { text: 'La Garita', value: '03' },
    { text: 'Santa Elena', value: '04' }
  ],
  407: [
    { text: 'San Antonio', value: '01' },
    { text: 'La Ribera', value: '02' },
    { text: 'La Asuncion', value: '03' }
  ],
  410: [
    { text: 'Puerto Viejo', value: '01' },
    { text: 'La Virgen', value: '02' },
    { text: 'Las Horquetas', value: '03' },
    { text: 'Llanuras Del Gaspar', value: '04' },
    { text: 'Cureña', value: '05' }
  ],
  507: [
    { text: 'Las Juntas', value: '01' },
    { text: 'Sierra', value: '02' },
    { text: 'San Juan', value: '03' },
    { text: 'Colorado', value: '04' }
  ],
  504: [
    { text: 'Bagaces', value: '01' },
    { text: 'La Fortuna', value: '02' },
    { text: 'Mogote', value: '03' },
    { text: 'Rio Naranjo', value: '04' }
  ],
  304: [
    { text: 'Juan Viñas', value: '01' },
    { text: 'Tucurrique', value: '02' },
    { text: 'Pejibaye', value: '03' }
  ],
  605: [
    { text: 'Puerto Cortés', value: '01' },
    { text: 'Palmar', value: '02' },
    { text: 'Sierpe', value: '03' },
    { text: 'Bahía Ballena', value: '04' },
    { text: 'Piedras Blancas', value: '05' },
    { text: 'Bahía Drake', value: '06' }
  ],
  604: [
    { text: 'Miramar', value: '01' },
    { text: 'La Unión', value: '02' },
    { text: 'San Isidro', value: '03' }
  ],
  606: [
    { text: 'Quepos', value: '01' },
    { text: 'Savegre', value: '02' },
    { text: 'Naranjito', value: '03' }
  ],
  607: [
    { text: 'Golfito', value: '01' },
    { text: 'Puerto Jiménez', value: '02' },
    { text: 'Guaycara', value: '03' },
    { text: 'Pavón', value: '04' }
  ],
  511: [
    { text: 'Hojancha', value: '01' },
    { text: 'Monte Romo', value: '02' },
    { text: 'Puerto Carrillo', value: '03' },
    { text: 'Huacas', value: '04' }
  ],
  611: [{ text: 'Jacó', value: '01' }, { text: 'Tárcoles', value: '02' }],
  120: [
    { text: 'San Pablo', value: '01' },
    { text: 'San Andres', value: '02' },
    { text: 'Llano Bonito', value: '03' },
    { text: 'San Isidro', value: '04' },
    { text: 'Santa Cruz', value: '05' },
    { text: 'San Antonio', value: '06' }
  ],
  501: [
    { text: 'Liberia', value: '01' },
    { text: 'Cañas Dulces', value: '02' },
    { text: 'Mayorga', value: '03' },
    { text: 'Nacascolo', value: '04' },
    { text: 'Curubande', value: '05' }
  ],
  503: [
    { text: 'Santa Cruz', value: '01' },
    { text: 'Bolson', value: '02' },
    { text: 'Veintisiete de Abril', value: '03' },
    { text: 'Tempate', value: '04' },
    { text: 'Cartagena', value: '05' },
    { text: 'Cuajiniquil', value: '06' },
    { text: 'Diria', value: '07' },
    { text: 'Cabo Velas', value: '08' },
    { text: 'Tamarindo', value: '09' }
  ],
  302: [
    { text: 'Paraiso', value: '01' },
    { text: 'Santiago', value: '02' },
    { text: 'Orosi', value: '03' },
    { text: 'Cachí', value: '04' },
    { text: 'Llanos de Santa Lucía', value: '05' }
  ],
  509: [
    { text: 'Carmona', value: '01' },
    { text: 'Santa Rita', value: '02' },
    { text: 'Zapotal', value: '03' },
    { text: 'San Pablo', value: '04' },
    { text: 'Porvenir', value: '05' },
    { text: 'Bejuco', value: '06' }
  ],
  107: [
    { text: 'Colón', value: '01' },
    { text: 'Guayabo', value: '02' },
    { text: 'Tabarcia', value: '03' },
    { text: 'Piedras Negras', value: '04' },
    { text: 'Picagres', value: '05' },
    { text: 'Jaris', value: '06' }
  ],
  608: [
    { text: 'San Vito', value: '01' },
    { text: 'Sabalito', value: '02' },
    { text: 'Aguabuena', value: '03' },
    { text: 'Limoncito', value: '04' },
    { text: 'Pittier', value: '05' }
  ],
  405: [
    { text: 'San Rafael', value: '01' },
    { text: 'San Josecito', value: '02' },
    { text: 'Santiago', value: '03' },
    { text: 'Los Ángeles', value: '04' },
    { text: 'Concepción', value: '05' }
  ],
  704: [
    { text: 'Bratsi', value: '01' },
    { text: 'Sixaola', value: '02' },
    { text: 'Cahuita', value: '03' },
    { text: 'Telire', value: '04' }
  ],
  702: [
    { text: 'Guapiles', value: '01' },
    { text: 'Jiménez', value: '02' },
    { text: 'Rita', value: '03' },
    { text: 'Roxana', value: '04' },
    { text: 'Cariari', value: '05' },
    { text: 'Colorado', value: '06' },
    { text: 'La Colonia', value: '07' }
  ],
  701: [
    { text: 'Limón', value: '01' },
    { text: 'Valle La Estrella', value: '02' },
    { text: 'Rio Blanco', value: '03' },
    { text: 'Matama', value: '04' }
  ],
  601: [
    { text: 'Puntarenas', value: '01' },
    { text: 'Pitahaya', value: '02' },
    { text: 'Chomes', value: '03' },
    { text: 'Lepanto', value: '04' },
    { text: 'Paquera', value: '05' },
    { text: 'Manzanillo', value: '06' },
    { text: 'Guacimal', value: '07' },
    { text: 'Barranca', value: '08' },
    { text: 'Monte Verde', value: '09' },
    { text: 'Isla Del Coco', value: '10' },
    { text: 'Cóbano', value: '11' },
    { text: 'Chacarita', value: '12' },
    { text: 'Chira', value: '13' },
    { text: 'Acapulco', value: '14' },
    { text: 'El Roble', value: '15' },
    { text: 'Arancibia', value: '16' }
  ],
  703: [
    { text: 'Siquirres', value: '01' },
    { text: 'Pacuarito', value: '02' },
    { text: 'Florida', value: '03' },
    { text: 'Germania', value: '04' },
    { text: 'El Cairo', value: '05' },
    { text: 'Alegría', value: '06' }
  ],
  705: [
    { text: 'Matina', value: '01' },
    { text: 'Batán', value: '02' },
    { text: 'Carrandi', value: '03' }
  ],
  706: [
    { text: 'Guácimo', value: '01' },
    { text: 'Mercedes', value: '02' },
    { text: 'Pocora', value: '03' },
    { text: 'Rio Jiménez', value: '04' },
    { text: 'Duacari', value: '05' }
  ],
  212: [
    { text: 'Sarchí Norte', value: '01' },
    { text: 'Sarchí Sur', value: '02' },
    { text: 'Toro Amarillo', value: '03' },
    { text: 'San Pedro', value: '04' },
    { text: 'Rodriguez', value: '05' }
  ],
  106: [
    { text: 'Aserrí', value: '01' },
    { text: 'Tarbaca', value: '02' },
    { text: 'Vuelta De Jorco', value: '03' },
    { text: 'San Gabriel', value: '04' },
    { text: 'Legua', value: '05' },
    { text: 'Monterrey', value: '06' },
    { text: 'Salitrillos', value: '07' }
  ],
  118: [
    { text: 'Curridabat', value: '01' },
    { text: 'Granadilla', value: '02' },
    { text: 'Sanchez', value: '03' },
    { text: 'Tirrases', value: '04' }
  ],
  113: [
    { text: 'San Juan', value: '01' },
    { text: 'Cinco Esquinas', value: '02' },
    { text: 'Anselmo Llorente', value: '03' },
    { text: 'Leon Xiii', value: '04' },
    { text: 'Colima', value: '05' }
  ],
  214: [
    { text: 'Los Chiles', value: '01' },
    { text: 'Caño Negro', value: '02' },
    { text: 'El Amparo', value: '03' },
    { text: 'San Jorge', value: '04' }
  ],
  116: [
    { text: 'San Pablo', value: '01' },
    { text: 'San Pedro', value: '02' },
    { text: 'San Juan De Mata', value: '03' },
    { text: 'San Luis', value: '04' },
    { text: 'Carara', value: '05' }
  ],
  205: [
    { text: 'Atenas', value: '01' },
    { text: 'Jesús', value: '02' },
    { text: 'Mercedes', value: '03' },
    { text: 'San Isidro', value: '04' },
    { text: 'Concepción', value: '05' },
    { text: 'San José', value: '06' },
    { text: 'Santa Eulalia', value: '07' },
    { text: 'Escobal', value: '08' }
  ],
  109: [
    { text: 'Santa Ana', value: '01' },
    { text: 'Salitral', value: '02' },
    { text: 'Pozos', value: '03' },
    { text: 'Uruca', value: '04' },
    { text: 'Piedades', value: '05' },
    { text: 'Brasil', value: '06' }
  ],
  215: [
    { text: 'San Rafael', value: '01' },
    { text: 'Buenavista', value: '02' },
    { text: 'Cote', value: '03' },
    { text: 'Katira', value: '04' }
  ],
  408: [
    { text: 'San Joaquín', value: '01' },
    { text: 'Barrantes', value: '02' },
    { text: 'Llorente', value: '03' }
  ],
  602: [
    { text: 'Espíritu Santo', value: '01' },
    { text: 'San Juan Grande', value: '02' },
    { text: 'Macacona', value: '03' },
    { text: 'San Rafael', value: '04' },
    { text: 'San Jerónimo', value: '05' },
    { text: 'Caldera', value: '06' }
  ],
  409: [
    { text: 'San Pablo', value: '01' },
    { text: 'Rincon De Sabanilla', value: '02' }
  ],
  508: [
    { text: 'Tilarán', value: '01' },
    { text: 'Quebrada Grande', value: '02' },
    { text: 'Tronadora', value: '03' },
    { text: 'Santa Rosa', value: '04' },
    { text: 'Líbano', value: '05' },
    { text: 'Tierras Morenas', value: '06' },
    { text: 'Arenal', value: '07' },
    { text: 'Cabeceras', value: '08' }
  ],
  610: [
    { text: 'Corredor', value: '01' },
    { text: 'La Cuesta', value: '02' },
    { text: 'Canoas', value: '03' },
    { text: 'Laurel', value: '04' }
  ],
  603: [
    { text: 'Buenos Aires', value: '01' },
    { text: 'Volcán', value: '02' },
    { text: 'Potrero Grande', value: '03' },
    { text: 'Boruca', value: '04' },
    { text: 'Pilas', value: '05' },
    { text: 'Colinas', value: '06' },
    { text: 'Changuena', value: '07' },
    { text: 'Biolley', value: '08' },
    { text: 'Brunka', value: '09' }
  ]
}

export default { provinces, counties, districts }
