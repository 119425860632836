<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-select
          :items="getStays"
          label="Alojamientos"
          item-value="name"
        ></v-select>
      </v-col>
      <v-col cols="8" align="right">
        <v-dialog
          v-model="dialogNew"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              Nuevo alojamiento
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Crear nuevo alojamiento
            </v-card-title>

            <!-- <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>

            <v-divider></v-divider> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogNew = false"
              >
                I accept
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <RedaForm
    ref="refFormStay"
    :options="formStay"
    :value="tmpStay"
    @input="setStay"></RedaForm>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import tools from '@/api/tools.js'
// import NewOrder from '@/components/NewOrder.vue'

export default {
  name: 'Stays',

  mounted () {
    this.isMounted = true
    tools.getProvince()
      .then((provinces) => {
        this.infoLocationItems.provinces = provinces
      })
      .catch((error) => {
        console.error(error)
      })
  },

  data () {
    return {
      isMounted: false,
      dialogNew: false,
      infoLocationItems: {
        provinces: [],
        conties: [],
        districts: []
      },
      tmpStay: {
        id: null,
        name: null,
        province: null,
        conty: null,
        district: null,
        others: null,
        servicesAndFacilities: [],
        descripcion: null,
        longDescription: null,
        socialMedia: [],
        roomTypes: [],
        rooms: [],
        ratePlans: [],
        gallery: [],
        email: null,
        web: null,
        phone: null
      },
      tmpRoomType: {
        id: null,
        name: '',
        descripcion: '',
        beds: [],
        facilities: [],
        prices: [],
        maxChildrenRoom: 0
      }
    }
  },

  components: {
    // NewOrder
  },

  computed: {
    ...mapGetters('stays', [
      'getStays'
    ]),
    formStay () {
      return {
        inputs: [
          {
            id: '000',
            label: 'id',
            name: 'id',
            value: 'id',
            type: 'text',
            rules: 'required',
            hiden: true,
            cols: 12
          },
          {
            id: '001',
            label: 'Nombre del alojamiento',
            name: 'Nombre',
            value: 'name',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '002',
            label: 'Provincia',
            name: 'Provincia',
            value: 'province',
            type: 'select',
            items: this.infoLocationItems.provinces,
            rules: 'required',
            cols: 4
          },
          {
            id: '003',
            label: 'Cantón',
            name: 'Cantón',
            value: 'conty',
            type: 'select',
            items: this.infoLocationItems.conties,
            rules: 'required',
            cols: 4
          },
          {
            id: '004',
            label: 'Distrito',
            name: 'Distrito',
            value: 'district',
            type: 'select',
            items: this.infoLocationItems.districts,
            rules: 'required',
            cols: 4
          },
          {
            id: '005',
            label: 'Dirección exacta',
            name: 'Dirección exacta',
            value: 'others',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '006',
            label: 'Descripción',
            name: 'Descripción',
            value: 'descripcion',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '007',
            label: 'Descripción larga',
            name: 'Descripción larga',
            value: 'longDescription',
            type: 'textarea',
            cols: 12
          }
        ]
      }
    }
  },

  methods: {
    ...mapMutations('stays', [
    ]),
    ...mapActions('stays', [
    ]),
    setStay (value) {
      console.log({ ...value })
      if (value.province && value.province !== null) {
        tools.getCounty(value.province)
          .then((counties) => {
            this.infoLocationItems.conties = counties
            return (value.conty && value.conty !== null)
              ? tools.getDistrict(value.province, value.conty)
              : false
          })
          .then((districts) => {
            this.infoLocationItems.districts = districts || []
          })
          .catch((error) => {
            console.error(error)
          })
      }
      this.tmpStay = value
    }
  }
}
</script>
