<template>
  <v-data-table
    :headers="headers"
    :items="voucher.receipts"
    sort-by="date"
    class="elevation-1"
    dense
  >
    <template v-slot:item.dateString="{ item }">
      {{ moment(Number(item.date)).format('LL') }}
    </template>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Pagos recibidos</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              dark
              class="mb-2"
              v-on="on"
              icon
              v-if="amountOutstanding > 0"
            >
              <v-icon left>mdi-cash-plus</v-icon>
            </v-btn>
          </template>
          <NewReceipt
            :voucher="voucher"
            :amountOutstanding="amountOutstanding"
            @cancel="dialog = false"
          ></NewReceipt>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount | currencyFormat(item.currency) }}
    </template>
    <template v-slot:item.paymentType="{ item }">
      {{ paymentMethodsItems[item.paymentType] }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        @click="deleteItem(item)"
        color="error"
        :disabled="item.amount < 0"
      >
        mdi-cash-remove
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NewReceipt from '@/components/NewReceipt.vue'
import moment from 'moment'
// import axios from 'axios'

export default {
  name: 'VoucherReceipts',
  components: { NewReceipt },
  props: [
    'voucher'
  ],
  data: () => ({
    moment,
    dialog: false,
    loading: false,
    headers: [
      { text: 'Fecha', value: 'dateString' },
      { text: 'ID', value: 'uid' },
      { text: 'Método de pago', value: 'paymentType' },
      { text: 'Monto', value: 'amount' },
      { text: 'Moneda', value: 'currency' },
      { text: 'Recibido por', value: 'user.name' },
      { text: 'Observaciones', value: 'others' },
      { text: '', value: 'action' }
    ],
    paymentMethodsItems: {
      '01': 'Efectivo',
      '02': 'Tarjeta',
      '03': 'Cheque',
      '04': 'Transferencia',
      99: 'Otros'
    }
  }),

  computed: {
    ...mapGetters('user', ['getUser']),
    amountOutstanding () {
      let amountOutstanding = Number(this.voucher.data.summary.netTotal)
      const receipts = this.voucher.receipts
      receipts.forEach((item, i) => {
        amountOutstanding -= Number(item.amount)
      })
      return amountOutstanding
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  /* created () {
      this.initialize()
    }, */

  methods: {

    deleteItem (item) {
      if (confirm('¿Estás seguro de que deseas eliminar este pago?')) {
        this.cancelReceipt({ ...item, user: this.getUser.uid, date: Number(moment().format('x')) })
          .then((pay) => {
            this.showSuccess('¡Pago N° ' + pay.id + 'anulado!')
          })
          .catch((error) => {
            this.showError('Ocurrio un error al anular pago')
            console.error(error)
          })
      }
    },

    close () {
      this.dialog = false
    },
    ...mapActions('accountReceivable', ['cancelReceipt']),
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
<style type="text/css">

</style>
