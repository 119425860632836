<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        small
        class=""
        :color="'info'"
      >
        mdi-clipboard-list-outline
      </v-icon>
    </template>
    <v-card :loading="loading">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-title>Direccionar como</v-card-title>
            <v-card-text>
              <v-radio-group v-model="to">
                <v-radio
                  v-for="(item, i) in toItems"
                  :key="i"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card-title v-if="to">Direccionar {{ toItems.find(({value}) => value === to).text }}</v-card-title>
            <v-card-subtitle>{{ item.commercialCode }}: {{ item.description }}</v-card-subtitle>
            <v-card-text>
              <template v-if="to === '01'">
                <v-select
                  label="Departamento/Centro de costo"
                  v-model="cc"
                  style="width: 200px"
                  :items="getCostCenters"
                  item-text="name"
                  return-object
                >
                </v-select>
                <DialogAccounts
                  label="Cuenta de gasto"
                  :showField="true"
                  v-model="concept"
                  :filter="typeCostAccounts[cc.type]"
                  v-if="cc && cc.type !== 'CCP'"
                ></DialogAccounts>
                <v-text-field
                  label="Descripción"
                  v-model="description"
                  style="width: 200px"
                >
                </v-text-field>
                <v-select
                  label="Tipo de costo"
                  v-model="typeCost"
                  style="width: 200px"
                  :items="typeCostItems"
                >
                </v-select>
              </template>
              <template v-if="to === '02'">
                <v-select
                  label="Bodega"
                  v-model="cellar"
                  style="width: 200px"
                  :items="getCellars"
                  item-text="name"
                  item-value="code"
                >
                </v-select>
                <v-autocomplete
                  label="Producto"
                  v-model="product"
                  :items="products"
                  style="width: 200px"
                  item-text="description"
                  item-value="commercialCode"
                  :disabled="loading"
                >
                </v-autocomplete>
                <v-text-field
                  type="number"
                  label="Factor"
                  style="width: 200px"
                  v-model="factor"
                >
                </v-text-field>
              </template>
            </v-card-text>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card-title>Aplicar a</v-card-title>
            <v-card-text>
              <v-radio-group v-model="applyTo">
                <v-radio
                  v-for="(item, i) in applyToItems"
                  :key="i"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-actions>
        <v-btn text @click="e1 -= 1" v-if="e1 !== 1">
          Anterior
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="reset">
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="e1 += 1"
          :disabled="!to"
          v-if="e1 !== 3"
        >
          Siguiente
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="!to"
          v-else
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogAccounts from '../components/DialogAccounts.vue'
import { mapActions, mapGetters } from 'vuex'
import firebaseApi from '../api/firebaseApi'
// import redaApp from '../api/redaApp'

export default {
  name: 'Direct',
  props: {
    item: Object,
    voucher: Object
    // Aquí es donde se declararían las propiedades de tu componente
  },
  components: { DialogAccounts },
  data () {
    return {
      e1: 1,
      to: null,
      dialog: false,
      loading: false,
      cellar: null,
      factor: 1,
      product: null,
      toItems: [
        { text: 'Gasto', value: '01' },
        { text: 'Inventario', value: '02' }
        // { text: 'Activo fijo', value: '03' }
      ],
      typeCostItems: [
        // { value: '00', text: 'Materia prima directa; variable' },
        // { value: '01', text: 'Materia prima indirecta; variable' },
        // { value: '02', text: 'Mano de obra directa; variable' },
        // { value: '03', text: 'Mano de obra indirecta; variable' },
        // { value: '04', text: 'Mano de obra directa; fijo' },
        // { value: '05', text: 'Mano de obra indirecta; fijo' },
        // { value: '06', text: 'Cargos indirectos; variable' },
        // { value: '07', text: 'Cargos indirectos; fijo' },
        { value: '08', text: 'Variable' },
        { value: '09', text: 'Fijo' }
      ],
      concept: null,
      description: null,
      applyTo: null,
      cc: null,
      products: [],
      loadingProducts: false,
      typeCost: '09',
      applyToItems: [
        { text: 'Todos los documentos del mismo proveedor y con el mismo código', value: '01' },
        { text: 'Todos los documentos con el mismo CABYS', value: '02' },
        { text: 'Solo a éste documento', value: '03' }
      ]
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    otherChargesItem () {
      const otherChargesItem = Number(this.voucher.data.summary.otherChargesTotal) / (this.voucher.data.items?.length || 1)
      return otherChargesItem || 0
    },
    typeCostAccounts () {
      return {
        CCA: this.getAccountingConfig.administrativeExpense,
        CCS: this.getAccountingConfig.administrativeExpense,
        CCP: this.getAccountingConfig.workInProgress,
        CCV: this.getAccountingConfig.distributionCosts
      }
    },
    ...mapGetters('siinco', ['getCostCenters']),
    ...mapGetters('inventories', ['getCellars']),
    ...mapGetters('company', ['getCompany', 'getAccountingConfig'])
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    e1 (val) {
      if (val === 2 && this.to === '02') this.getProducts()
    }
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    async saveAOA () {
      const newAOA = {
        concept: this.cc.type === 'CCP' ? this.typeCostAccounts[this.cc.type] : this.concept.id,
        costCenter: this.cc.code,
        description: this.description,
        type: this.typeCost
      }
      if (this.applyTo === '03') {
        const items = this.voucher.items || []
        newAOA.ao = true
        newAOA.line = this.item.line
        newAOA.amount = Number(this.item.subtotal) + this.otherChargesItem
        items.push({ ...newAOA })
        console.log(items)
        return
        // return redaApp.update('vouchers', { items }, this.voucher.uid)
      }
      newAOA.code = this.applyTo === '02' ? this.item.code : `${this.item.commercialCode}|${this.voucher.issuer}`
      console.log({ ...newAOA })
      return this.insertAddressingOrderAlias(newAOA)
    },
    async saveIA () {
      const newIA = {
        cellar: this.cellar,
        factor: this.factor,
        product: this.product
      }
      if (this.applyTo === '03') {
        const items = this.voucher.items || []
        newIA.ia = true
        newIA.line = this.item.line
        newIA.amount = Number(this.item.subtotal) + this.otherChargesItem
        items.push({ ...newIA })
        console.log(items)
        return
        // return redaApp.update('vouchers', { items }, this.voucher.uid)
      }
      newIA.code = this.applyTo === '02' ? this.item.code : `${this.item.commercialCode}|${this.voucher.issuer}`
      console.log({ ...newIA })
      return this.insertInventoryAliases(newIA)
    },
    async saveFA () {
      console.log('saveFA')
    },
    async save () {
      this.loading = true
      const action = {
        '01': 'saveAOA',
        '02': 'saveIA',
        '03': 'saveFA'
      }
      this[action[this.to]]()
        .then((res) => console.log(res))
        .catch((error) => console.error(error))
        .finally(() => {
          this.reset()
        })
    },
    reset () {
      this.e1 = 1
      this.to = null
      this.dialog = false
      this.loading = false
      this.concept = null
      this.description = null
      this.applyTo = null
    },
    async getProducts () {
      this.products = []
      this.loading = true
      try {
        const querySnapshot = await firebaseApi.db.collection('products')
          .where('company', '==', this.getCompany.identification)
          .where('inventoryable', '==', true).get()
        this.products = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    ...mapActions('siinco', ['insertAddressingOrderAlias', 'insertAddressingOrder']),
    ...mapActions('inventories', ['insertInventoryAliases', 'insertInventory'])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    this.description = this.item.description
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
