<template>
  <div>
    <v-alert type="success" v-model="success.show" transition="fade-transition">
      {{ success.message }}
    </v-alert>
    <v-alert type="info" v-model="info.show" transition="fade-transition">
      {{ info.message }}
    </v-alert>
    <v-alert type="warning" v-model="warning.show" transition="fade-transition">
      {{ warning.message }}
    </v-alert>
    <v-alert type="error" v-model="error.show" transition="fade-transition">
      {{ error.message }}
    </v-alert>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
export default {
  name: 'Message',
  computed: {
    ...mapState('message', [
      'success',
      'info',
      'warning',
      'error'
    ])
  },
  watch: {
    success (newValue) {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    },
    info (newValue) {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    },
    warning (newValue) {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    },
    error (newValue) {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    }
  }
}
</script>
<style type="text/css">

</style>
