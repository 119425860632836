<template>
  <div class="order-details">
    <div class="text-types-wrapper">
      <div class="text-types">
        <div class="text">{{ order.num }}</div>
      </div>
    </div>
      <hr class="blueLineMain">
    <div class="client" v-if="order.customer">
      <div class="text-types1">
        <div class="text">Cliente:</div>
      </div>
      <div class="text-types2">
        <div class="text">{{ order.customer.name | capitalizeWords }}</div>
      </div>
      <div class="details" v-if="false">
        <div class="detalles-wrapper">
          <div class="detalles">Detalles</div>
        </div>
      </div>
    </div>
    <div class="deliver" v-if="false">
      <div class="text-types">
        <div class="text">Text</div>
      </div>
      <div class="text-types2">
        <div class="text">Text</div>
      </div>
    </div>
      <hr class="blueLine">

    <div class="deliver" v-for="(item, i) in order.items" :key="i">
      <div class="div">
        <div class="div1">{{ item.quantity }}</div>
      </div>
      <div class="text-types">
        <div class="text">{{ item.product.description }}</div>
      </div>
      <div
        :class="`text-types ${statusObj[item.po.task.status].color}--text`"
        v-if="(item.po.task && item.po.task instanceof Object)"
      >
        <div class="text">{{ statusObj[item.po.task.status].text }}</div>
      </div>
      <div class="details1">
        <v-dialog
          v-model="dialog[i]"
          max-width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="detalles-wrapper"
            >
              <div class="detalles">Detalles</div>
            </div>
          </template>
          <POCard :item="item.po" :orderCard="true" @close="dialog[i] = false" ></POCard>
        </v-dialog>
      </div>
    </div>
      <hr class="blueLine">

    <div class="informacin-formulario-de-prod">
      <img class="majesticonscomments" alt="" :src="require('@/assets/Comments.svg')">

      <div class="agregar-comentarios">Agregar comentarios</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { /* mapActions, */ mapGetters } from 'vuex'
import POCard from '@/components/POCard.vue'
// import TaskEdit from '@/components/TaskEdit.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'OrderCard',
  components: { POCard },
  props: {
    order: Object
  },
  data: () => ({
    search: null,
    loading: false,
    type: 'status',
    dialog: {},
    tmp: [],
    typeItems: [
      { text: 'Prioridad', value: 'priority' },
      { text: 'Estado', value: 'status' },
      { text: 'Responsable', value: 'assignee' }
    ],
    objs: {
      priority: {
        1: { text: 'Alta', color: 'error' },
        2: { text: 'Media', color: 'warning' },
        3: { text: 'Baja', color: 'success' }
      },
      status: {
        '01': { text: 'Pendiente', color: 'grey' },
        '02': { text: 'Iniciada', color: 'info' },
        '03': { text: 'Lista', color: 'success' },
        '04': { text: 'En peligro', color: 'warning' },
        '05': { text: 'Atrasada', color: 'error' }
      }
    },
    statusObj: {
      '01': { text: 'Atrasada', color: 'error' },
      '02': { text: 'En peligro', color: 'warning' },
      '03': { text: 'Pendiente', color: 'warning' },
      '04': { text: 'Iniciada', color: 'info' },
      '05': { text: 'Lista', color: 'primary' },
      '06': { text: 'Entregada', color: 'success' }
    }
  }),

  mounted () {
    this.tmp = JSON.parse(JSON.stringify(this.order.items))
    this.tmp.forEach((element, i) => {
      this.dialog[i] = false
    })
  },

  computed: {
    items () {
      return []
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    updated (po, i) {
      this.dialog[i] = false
      this.$emit('updated', po.orders)
    },
    check (i, event) {
      this.tmp[i].check = event
    },
    emitUpdated () {
      this.$emit('updated')
    }
    // ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
<style scoped>
.text {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-types {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.text-types-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.order-details-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.text-types1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
}
.text-types2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
}
.detalles {
  position: relative;
  letter-spacing: 0.22px;
  font-weight: 500;
}
.detalles-wrapper {
  border-radius: 8px;
  background-color: rgba(255, 109, 176, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
}
.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 2px;
  text-align: center;
  font-size: 12px;
  color: #f72585;
  font-family: Rubik;
}
.client {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: 12px;
}
.deliver {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.div1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.div {
  width: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.details1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 2px;
  text-align: center;
  font-size: 12px;
  color: #f72585;
  font-family: Rubik;
}
.majesticonscomments {
  width: 25px;
  position: relative;
  height: 25px;
  overflow: hidden;
  flex-shrink: 0;
}
.agregar-comentarios {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.informacin-formulario-de-prod {
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 8px;
}
.order-details {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 16px rgba(83, 87, 99, 0.05);
  border-radius: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 12px;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}
body {
  margin: 0;
  line-height: normal;
}
hr.blueLineMain{
  border: 1.6px solid #4361EE;
  width: 100%;
  border-radius: 25px;
}

hr.blueLine{
  border: 1.6px solid #e5eaff;
  width: 100%;
  border-radius: 25px;

}
</style>
