<template>
  <div class="page-content page-container" id="page-content">
    <div>
      <div>
        <div>

          <div class="box box-warning direct-chat direct-chat-warning">
            <div class="box-header with-border">
              <h3 class="box-title">Comentarios</h3>

              <div class="box-tools pull-right">
                <div class="parent">
                  <span data-toggle="tooltip" title="" class="badge bg-Blue" data-original-title="3 New Messages" v-if="comments.length > 0">{{ comments.length }}</span>
                </div>
              </div>
            </div>

            <div class="box-body">

              <div class="direct-chat-messages">

                <div class="direct-chat-msg" :class="{ right: getUser.uid === msg.user }" v-for="(msg, i) in messages" :key="i">
                  <div class="direct-chat-info clearfix">
                  <span class="direct-chat-name pull-left">{{ msg.name }}</span>
                  <span class="direct-chat-timestamp pull-right">{{ msg.timestamp | dateFormat(true) }}</span>
                  </div>

                  <img class="direct-chat-img" :src="msg.photoUrl || 'https://img.icons8.com/material-outlined/24/user--v1.png'" alt="message user image">

                  <div class="direct-chat-text">
                  {{ msg.body }}
                  </div>

                </div>

                <!-- <div class="direct-chat-msg right">
                  <div class="direct-chat-info clearfix">
                  <span class="direct-chat-name pull-right">Sarah Morales</span>
                  <span class="direct-chat-timestamp pull-left">23 Jan 2:05 pm</span>
                  </div>

                  <img class="direct-chat-img" src="https://img.icons8.com/office/36/000000/person-female.png" alt="message user image">

                  <div class="direct-chat-text">
                  De acuerdo, aprobado
                  </div>

                </div>

                <div class="direct-chat-msg">
                  <div class="direct-chat-info clearfix">
                  <span class="direct-chat-name pull-left">Ricardo Martinez</span>
                  <span class="direct-chat-timestamp pull-right">23 Jan 5:37 pm</span>
                  </div>

                  <img class="direct-chat-img" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="message user image">

                  <div class="direct-chat-text">
                  Se le hizo un cambio de marco
                  </div>

                </div>

                <div class="direct-chat-msg right">
                  <div class="direct-chat-info clearfix">
                  <span class="direct-chat-name pull-right">Sarah Morales</span>
                  <span class="direct-chat-timestamp pull-left">23 Jan 6:10 pm</span>
                  </div>

                  <img class="direct-chat-img" src="https://img.icons8.com/office/36/000000/person-female.png" alt="message user image">

                  <div class="direct-chat-text">
                  De acuerdo, aprobado.
                  </div>

                </div> -->

              </div>

            </div>

          </div>
        </div>

      </div>

    </div>

  <div class="footer">
    <v-dialog
      v-model="emojiPanel"
      width="500"
      v-if="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-emoticon-outline</v-icon>
        </v-btn>
      </template>
      <emoji-picker :show="true" @close="toggleEmojiPanel" @click="addEmojiToMessage"></emoji-picker>
    </v-dialog>

    <div style="padding: 4px 8px;"><v-icon>mdi-emoticon-outline</v-icon></div>

    <div class="see-you-all-then-wrapper">
      <div class="see-you-all"><input type="text" v-model="newMessage" @keyup.enter="submit" placeholder="See you all then!"></div>
    </div>
    <div class="mention-parent">
      <v-icon>mdi-at</v-icon>
      <v-icon color="#007AFF" style="cursor: pointer;" @click="submit">mdi-send-variant-outline</v-icon>

    </div>
  </div>
  </div>
</template>
<script>
import EmojiPicker from '@/components/EmojiPicker.vue'
import redaApp from '../api/redaApp'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Comments',
  props: {
    comments: {
      type: Array,
      default: () => ([])
    },
    uid: String
  },
  components: {
    EmojiPicker
  },
  data () {
    return {
      loading: false,
      emojiPanel: false,
      newMessage: ''
    }
  },
  computed: {
    messages () {
      const comments = JSON.parse(JSON.stringify(this.comments))
      return comments.map((comment) => {
        const user =
          this.getUsers.find(({ userUID }) => userUID === comment.user) ||
          {}
        comment.photoUrl = user.photoUrl
        comment.name = user.name
        return comment
      })
    },
    ...mapGetters('user', [
      'getUser',
      'getUsers'
    ])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    isURL (str) {
      try {
        const url = new URL(str) // La asignación evita el error de "no-new"
        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch {
        return false
      }
    },
    submit () {
      this.loading = true
      const comments = this.comments
      comments.push({
        timestamp: new Date().getTime(),
        user: this.getUser.uid,
        body: this.newMessage,
        type: 'text'
      })
      redaApp.update('tasks', { comments }, this.uid).then(() => {
        this.newMessage = ''
        this.showSuccess('Comentario enviado')
        this.$emit('updated')
      }).catch((e) => {
        console.error(e)
        this.showError('Error al enviar comentario')
      }).finally(() => {
        this.loading = false
      })
    },
    toggleEmojiPanel () {
      this.emojiPanel = !this.emojiPanel
    },
    addEmojiToMessage (emoji) {
      this.newMessage += emoji.value
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.stretch-card>.card {
  width: 100%;
  min-width: 100%
}

body {
  background-color: #f9f9fa
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.padding {
  padding: 3rem
}

.box.box-warning {
  border-top-color: #007aff;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
  content: " ";
  display: table;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative
}

.box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
  display: inline-block;
  font-size: 18px;
  font-family: Roboto;
  margin: 0;
  line-height: 1;
}

.box-header>.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.box-header>.box-tools [data-toggle="tooltip"] {
  position: relative;
}

.bg-Blue, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
position: absolute;
top: 4px;
font-weight: 600;
}

.parent {
width: 100%;
position: relative;
border-radius: 4px;
background-color: #F72585;
height: 20px;
width: fit-content;
text-align: center;
font-size: 12px;
color: #fff;
font-family: Roboto;
}

.bg-Blue{
    color: #fff !important;
}

.btn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  font-family: Roboto;
  background: transparent;
  color: #97a0b3;
}

.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 12px;
}
.box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
  content: " ";
  display: table;
}

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto
  ;
}

.direct-chat-messages, .direct-chat-contacts {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -moz-transition: -moz-transform .5s ease-in-out;
  -o-transition: -o-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
}

.direct-chat-msg {
  margin-bottom: 10px;
}

.direct-chat-msg, .direct-chat-text {
  display: block;
}

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto;
}

.direct-chat-timestamp {
  margin: 0px 4px;
  color: #999;
  font-size: 10px;
}

.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
  display: table;
  content: " ";
}

.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}

.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
  font-family: Roboto;
}

.direct-chat-msg, .direct-chat-text {
  display: block;
}

.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}

.direct-chat-text:after, .direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 14px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}

.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}

.direct-chat-text:after, .direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}

:after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.direct-chat-msg:after {
  clear: both;
}

.direct-chat-msg:after {
  content: " ";
  display: table;
}

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  font-family: Roboto;
}

.right .direct-chat-img {
  float: right;
}

.direct-chat-warning .right>.direct-chat-text {
  background: #007aff;
  border-color: #007aff;
  color: #fff;
}

.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
}

.box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
  content: " ";
  display: table;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  font-family: Roboto;
  white-space: nowrap;
}

.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group {
  z-index: 2;
  margin-left: -1px;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.emoji-selector-icon {
  width: 40px;
  position: relative;
  height: 48px;
}
.see-you-all {
  width: 100%;
  position: relative;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.see-you-all input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
}
.see-you-all-then-wrapper {
  align-self: stretch;
  width: calc(100% - 112px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0px;
  box-sizing: border-box;
}
.mention-icon {
  position: absolute;
  top: 16px;
  right: 44px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.paper-airplane-icon {
  position: absolute;
  top: 16px;
  right: 12px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.mention-parent {
  display: flex;
  width: 72px;
  position: relative;
  height: 48px;
  align-items: center;
  justify-content: space-around;
}
.footer {
  width: 100%;
  position: relative;
  box-shadow: 0px 1px 0px #e5e5ea inset;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #3d3d3d;
  font-family: Roboto;
}
</style>
