<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Bien o servicio</v-tab>
      <v-tab>Compras realizadas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <PendingItemClassifier></PendingItemClassifier>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="key"
            class="elevation-1"
          ></v-data-table>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import PendingItemClassifier from '../components/PendingItemClassifier'

export default {
  name: 'PendingItem',

  props: ['code'],

  data: () => ({
    tab: 0
  }),

  components: {
    PendingItemClassifier
  },

  computed: {
    items () {
      return this.getVouchersReceivedPendingItems
        .filter(({ code }) => this.code === code)
        .map((item) => ({ ...item, voucher: this.getVouchersReceived.find(({ key }) => key === item.voucher) }))
    },
    headers () {
      return [
        {
          text: 'Fecha',
          value: 'voucher.data.date'
        },
        { text: 'Comprobante', value: 'voucher.key' },
        { text: 'proveedor', value: 'receiver' },
        { text: 'Detalle', value: 'description' }
      ]
    },
    ...mapGetters('vouchers', ['getVouchersReceivedPendingItems', 'getVouchersReceived'])
  },

  methods: {
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>
