<template>
  <v-container>
    <v-tabs
    >
      <v-tab>Alojamientos</v-tab>
      <v-tab>Habitaciones</v-tab>

      <v-tab-item>
        <Stays />
      </v-tab-item>
      <v-tab-item>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Stays from '../components/Stays'

export default {
  name: 'BookingEngine',

  components: {
    Stays
  },

  computed: {
    ...mapGetters('stays', [
      'getStays'
    ])
  },

  methods: {
    ...mapMutations('stays', [
    ]),
    ...mapActions('stays', [
    ])
  }
}
</script>
