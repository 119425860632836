/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment';
import fbApi from '@/api/firebaseApi'
import tools from '@/api/tools'
import redaApp from '@/api/redaApp'

const { api } = fbApi

// initial state
const initialState = {
  customers: [],
  listinerCustomers: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getCustomers (state, getters, rootState, rootGetters) {
    const customers = []
    state.customers.forEach((customer) => {
      const sales = rootGetters['vouchers/getVouchers'].filter(({ receiver }) => receiver === customer.identification)
      const purchase = rootGetters['vouchers/getVouchersReceived'].filter(({ issuer }) => issuer === customer.identification)
      const receipts = []
      const payments = []
      let totalReceipts = 0
      let totalPayments = 0
      sales.forEach((voucher) => {
        if (voucher.salses) {
          voucher.receipts.forEach((receipt) => {
            totalReceipts += Number(receipt.amount)
            receipts.push({ receipt })
          })
        }
      })
      purchase.forEach((voucher) => {
        if (voucher.payment) {
          voucher.payment.forEach((payment) => {
            totalPayments += Number(payment.amount)
            payments.push({ payment })
          })
        }
      })
      const totalSales = sales.reduce((acc, sale) => acc + Number(sale.data.summary.netTotal), 0)
      const totalPurchase = purchase.reduce((acc, sale) => acc + Number(sale.data.summary.netTotal), 0)
      const amountSalesOutstanding = tools.round10(totalSales - totalReceipts, -5)
      const amountPurchaseOutstanding = tools.round10(totalPurchase - totalPayments, -5)
      const amountOutstanding = tools.round10(amountSalesOutstanding - amountPurchaseOutstanding, -5)
      customers.push({ ...customer, sales, purchase, totalSales, totalReceipts, totalPayments, amountOutstanding })
    })
    return customers
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const customersQuerySnapshot = await fbApi.db.collection('customers')
        .where('company', '==', company)
        .get()
      const customers = []
      customersQuerySnapshot.forEach((doc) => customers.push({ uid: doc.id, ...doc.data() }))
      commit('setCustomers', customers)
      const listinerCustomers = customersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newCustomers = []
        querySnapshot.forEach((doc) => newCustomers.push({ uid: doc.id, ...doc.data() }))
        commit('setCustomers', newCustomers)
      })
      commit('setListinerCustomer', listinerCustomers)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar contactos')
    }
  },
  insertCustomer ({ commit, rootGetters, getters }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload.id) {
        const customers = tools.sortItems(getters.getCustomers, 'id')
        const last = Number(customers[customers.length - 1]?.id) || 0
        payload.id = last + 1
      }
      redaApp.submitRedaApp('insert-customer', { ...payload, company: rootGetters['company/getCompany'].identification })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error))
    })
  },
  deleteCustomer ({ commit }, customer) {
    return new Promise((resolve, reject) => {
      api.deleteItem('customers', customer.uid)
        .then((msj) => {
          resolve(msj)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerCustomers) {
      state.listinerCustomers()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerCustomer (state, listener) {
    state.listinerCustomers = listener
  },
  setCustomers (state, customers) {
    if (!customers) state.listinerCustomers()
    state.customers = customers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
