<template>
  <v-container>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab>
        Inventario
      </v-tab>
      <v-tab>
        Movimientos
      </v-tab>
      <v-tab>
        Bodegas
      </v-tab>
      <v-tab>
        Pendientes de revisión
      </v-tab>
    </v-tabs>
    <Period v-if="tab === 1"></Period>
    <v-tabs-items v-model="tab" v-if="!loading">
      <v-tab-item>
        <v-container fluid>
          <Stock  @update="refresh" :products="products"></Stock>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <Movements :inventory="movs" @update="refresh" :products="products"></Movements>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <Cellars></Cellars>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <PendingEntries></PendingEntries>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script type="text/javascript">
import Movements from '@/components/Movements.vue'
import Stock from '@/components/Stock.vue'
import Cellars from '@/components/Cellars.vue'
import PendingEntries from '@/components/PendingEntries.vue'
import Period from '@/components/Period.vue'
import redaApp from '../api/redaApp'
export default {
  name: 'Cellar',
  components: { Movements, Stock, Cellars, Period, PendingEntries },
  data: () => ({
    tab: null,
    movs: [],
    products: [],
    loading: true
  }),
  mounted () {
    redaApp.getRedaApp('get-inventories')
      .then(({ data }) => {
        this.movs = data.inventories
        this.products = data.products
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    refresh () {
      redaApp.getRedaApp('get-inventories')
        .then(({ data }) => {
          this.movs = data.inventories
          this.products = data.products
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style type="text/css">

</style>
