<template>
  <div>
    <v-card v-if="loading">
      <v-card-text  align="center" justify="center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          class="my-10"
          ></v-progress-circular>
        </v-card-text>
      <v-card-text  align="center" justify="center" class="title">
        Procesando pago...
      </v-card-text>
    </v-card>
    <v-form @submit.prevent="submitForm" ref="form" v-else>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field outlined v-model="cardNumber" label="Número de Tarjeta" :rules="cardNumberRules">
              <template v-slot:prepend-inner>
              <img width="24" :src="require('@/assets/visa.png')" v-if="marcaTarjeta === 'Visa'">
              <img width="24" :src="require('@/assets/mastercard.png')" v-if="marcaTarjeta === 'Mastercard'">
              <img width="24" :src="require('@/assets/amex.png')" v-if="marcaTarjeta === 'AMEX'">
              <v-icon v-if="!marcaTarjeta">mdi-credit-card</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-lock" outlined v-model="cvv" label="Código de Seguridad" placeholder="CVV" :rules="cvvRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-calendar-month" outlined v-model="expMonth" label="Mes de Expiración" placeholder="MM" :rules="expMonthRules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field outlined v-model="expYear" prepend-inner-icon="mdi-calendar-month"  label="Año de Expiración" placeholder="AAAA" :rules="expYearRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field outlined v-model="holderName" label="Nombre del Titular" :rules="holderNameRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!hideSubmit">
          <v-col cols="12">
            <v-btn type="submit" color="primary" :disabled="!formIsValid" x-large>Pagar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'CreditCard',
  props: {
    hideSubmit: Boolean
  },
  data () {
    return {
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
      holderName: '',
      loading: false,
      imgCard: {
        Visa: '../assets/visa.png',
        Mastercard: '../assets/mastercard.png',
        AMEX: '../assets/amex.png'
      }
    }
  },
  mounted () {
    this.$refs.form.resetValidation()
  },
  computed: {
    imgPath () {
      return this.imgCard[this.marcaTarjeta]
    },
    marcaTarjeta () {
      // Eliminar espacios en blanco y guiones
      const numero = this.cardNumber.replace(/\s/g, '')

      // Expresiones regulares para verificar formatos
      const visaRegEx = /^4[0-9]{12}(?:[0-9]{3})?$/
      const mastercardRegEx = /^5[1-5][0-9]{14}$/
      const amexRegEx = /^3[47][0-9]{13}$/

      // Verificar marcas de tarjeta
      if (visaRegEx.test(numero)) {
        return 'Visa'
      } else if (mastercardRegEx.test(numero)) {
        return 'Mastercard'
      } else if (amexRegEx.test(numero)) {
        return 'AMEX'
      } else {
        return false
      }
    },
    payload () {
      return {
        cardNumber: this.cardNumber,
        expMonth: this.expMonth,
        expYear: this.expYear,
        cvv: this.cvv,
        holderName: this.holderName
      }
    },
    formIsValid () {
      // Validar que todos los campos estén completos
      const full = !!this.cardNumber && this.expMonth && this.expYear && !!this.cvv && !!this.holderName
      if (full) return this.$refs.form?.validate()
      return false
    },
    cardNumberRules () {
      const num = this.marcaTarjeta === 'AMEX' ? 15 : 16
      return [
        value => !!value || 'El número de tarjeta es obligatorio',
        value => (value && value.length === num) || `El número de tarjeta debe tener ${num} dígitos`
      ]
    },
    expMonthRules () {
      return [
        value => !!value || 'El mes de expiración es obligatorio',
        value => (value && value.length === 2 && parseInt(value) >= 1 && parseInt(value) <= 12) || 'El mes de expiración es inválido'
      ]
    },
    expYearRules () {
      return [
        value => !!value || 'El año de expiración es obligatorio',
        value => (value && value.length === 4 && parseInt(value) >= new Date().getFullYear()) || 'El año de expiración es inválido'
      ]
    },
    cvvRules () {
      const digits = this.marcaTarjeta === 'AMEX' ? 4 : 3
      return [
        value => !!value || 'El código de seguridad es obligatorio',
        value => (value && value.length === digits) || `El código de seguridad debe tener ${digits} dígitos`
      ]
    },
    holderNameRules () {
      return [
        value => !!value || 'El nombre del titular es obligatorio'
      ]
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      this.$emit('submitPay')
      // Construir el objeto de payload con los datos del formulario
      const payload = {
        card: {
          number: this.cardNumber,
          expMonth: this.expMonth,
          expYear: this.expYear,
          cvv: this.cvv,
          holderName: this.holderName
        },
        key: this.$route.query.key
      }

      // Enviar la solicitud POST al backend con Axios
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/pay-voucher`, payload)
        .then(response => {
          // Manejar la respuesta del backend
          this.$emit('submited', response.data)
          this.$router.push(`/public/successpay?key=${this.$route.query.key}&message=${'success'}`)
        })
        .catch(error => {
          // Manejar errores de la solicitud
          if (error.response.status === 400) {
            this.showError('El método de pago fue denegado. Por favor intentá con otro o ponete en contacto con nuestra área de Soporte.')
          } else {
            this.showError('Error al procesar pago, intentar más tarde')
          }
          this.$emit('submitError', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ])
  }
}
</script>
