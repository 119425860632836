<template>
    <v-data-table
      :headers="headers"
      :items="receiptsItems"
      sort-by="date"
      sort-desc
      class="elevation-1"
      :search="search"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Pagos realizados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="mdi-magnify"
            class="mt-3 py-2 mr-2"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-on="on"
                icon
              >
                <v-icon left>mdi-cash-plus</v-icon>
              </v-btn>
            </template>
            <NewReceipt
              @cancel="dialog = false"
              :isPayment="true"
              :item="edit"
              :voucher="editVoucher"
            ></NewReceipt>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{ paymentMethodsItems[item.paymentType] }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount | currencyFormat(item.currency) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click="editItem(item)"
          color="info"
          :disabled="item.amount < 0"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          color="error"
          :disabled="item.amount < 0"
        >
          mdi-cash-remove
        </v-icon>
      </template>
    </v-data-table>
  </template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NewReceipt from '@/components/NewReceipt.vue'
import moment from 'moment'
// import axios from 'axios'

export default {
  name: 'PaymentsTable',
  components: { NewReceipt },
  props: [
    'voucher'
  ],
  data: () => ({
    dialog: false,
    loading: false,
    search: null,
    headers: [
      { text: 'ID', value: 'uid' },
      { text: 'Fecha', value: 'dateString' },
      { text: 'Realizado a', value: 'receiver.name' },
      { text: 'Método de pago', value: 'paymentType' },
      { text: 'Monto', value: 'amount' },
      { text: 'Moneda', value: 'currency' },
      { text: 'Ingresado por', value: 'user.name' },
      { text: 'Observaciones', value: 'others' },
      { text: '', value: 'action' }
    ],
    paymentMethodsItems: {
      '01': 'Efectivo',
      '02': 'Tarjeta',
      '03': 'Cheque',
      '04': 'Transferencia',
      99: 'Otros'
    },
    edit: null,
    editVoucher: null
  }),

  computed: {
    receiptsItems () {
      const items = []
      this.getPayments.forEach((item) => {
        const voucher = this.getVouchers.find(({ key }) => item.voucher === key)
        const company = this.getCustomers.find(({ identification }) => item.company === identification) || voucher?.data.issuer
        const dateString = moment(item.date).format('L')
        items.push({ ...item, voucher, company, dateString })
      })
      return items
    },
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('vouchers', ['getVouchers']),
    ...mapGetters('payments', ['getPayments'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  /* created () {
        this.initialize()
      }, */

  methods: {

    editItem (item) {
      this.edit = { ...item }
      this.editVoucher = item.voucher
      this.dialog = true
    },

    deleteItem (item) {
      if (confirm('¿Estás seguro de que deseas eliminar este pago?')) {
        this.cancelReceipt(item)
          .then((pay) => {
            this.showSuccess('¡Pago N° ' + pay.id + 'anulado!')
          })
          .catch((error) => {
            this.showError('Ocurrio un error al anular pago')
            console.error(error)
          })
      }
    },

    close () {
      this.edit = null
      this.editVoucher = null
      this.dialog = false
    },
    ...mapActions('accountReceivable', ['cancelReceipt']),
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
  <style type="text/css">

  </style>
