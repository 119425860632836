<template>
  <v-card  :color="item.color">
    <v-card-title>
      {{ item.text }}
    </v-card-title>
    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="(task, index) in item.tasks"
        :key="index"
      >
        <v-list-item-action>
          <TaskActions :tasksIndexed="tasksIndexed" :item="task" @updated="emitUpdated"></TaskActions>
        </v-list-item-action>
        <v-list-item-content>
          {{ task.name }}
        </v-list-item-content>
        <v-list-item-action>
          <TaskEdit :item="task" :tasks="tasks" :staff="staff" @refresh="emitUpdated"></TaskEdit>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import moment from 'moment'
import { /* mapActions, */ mapGetters } from 'vuex'
import TaskActions from '@/components/TaskActions.vue'
import TaskEdit from '@/components/TaskEdit.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'TaskCard',
  components: { TaskActions, TaskEdit },
  props: {
    item: Object,
    tasksIndexed: Object,
    tasks: Array,
    staff: Array
  },
  data: () => ({
    search: null,
    loading: false,
    type: 'status',
    typeItems: [
      { text: 'Prioridad', value: 'priority' },
      { text: 'Estado', value: 'status' },
      { text: 'Responsable', value: 'assignee' }
    ],
    objs: {
      priority: {
        1: { text: 'Alta', color: 'error' },
        2: { text: 'Media', color: 'warning' },
        3: { text: 'Baja', color: 'success' }
      },
      status: {
        '01': { text: 'Pendiente', color: 'grey' },
        '02': { text: 'Iniciada', color: 'info' },
        '03': { text: 'Lista', color: 'success' },
        '04': { text: 'En peligro', color: 'warning' },
        '05': { text: 'Atrasada', color: 'error' }
      }
    }
  }),

  mounted () {
  },

  computed: {
    items () {
      return []
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    emitUpdated () {
      this.$emit('updated')
    }
    // ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
