<template>
  <v-container>
    <AccountReceivable />
  </v-container>
</template>
<script>
import AccountReceivable from '@/components/AccountReceivable.vue'

export default {
  name: 'AccountReceivableView',
  components: { AccountReceivable },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  /* created () {
  }, */

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
