<template>
  <v-card>

    <template
        v-for="(item, i) in getStatementOfFinancialPosition.items"
    >

      <v-list-item  :key="i">
        <v-list-item-content>
          <v-list-item-title class="title">{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="title">{{ item.value | currencyFormat }}</v-list-item-action>
      </v-list-item>

      <template
        v-for="item2 in item.items"
      >
        <v-card-text :key="item2.text" v-if="item2.items[0].items && item2.items[0].items.length > 0">

          <v-list-item  :key="i">
            <v-list-item-content class="subtitle-1 font-weight-medium">
              {{ item2.text }}
            </v-list-item-content>
            <v-list-item-action class="subtitle-1 font-weight-medium">{{ item2.value | currencyFormat }}</v-list-item-action>
          </v-list-item>

          <template v-for="item3 in item2.items">
            <v-card-subtitle :key="item3.text">{{item3.text}}</v-card-subtitle>
            <v-simple-table
              dense
              :key="item3.text + 1"
            >
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(valueItem, k) in deleteZeros(item3.items)"
                    :key="k"
                  >
                    <td>
                      {{ valueItem.text }}
                    </td>
                    <td class="text-right">{{ valueItem.value | currencyFormat }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="subtitle-2 font-weight-medium">
                      {{ 'Total ' + item3.text}}
                    </th>
                    <th class="text-right subtitle-2">{{ item3.value | currencyFormat }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
        <v-card-text :key="item2.text" v-else>
          <v-list-item v-if="item2.text ==='Patrimonio'">
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-medium">{{ item2.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="subtitle-1 font-weight-medium">{{ item2.value | currencyFormat }}</v-list-item-action>
          </v-list-item>
          <v-card-subtitle v-else>{{item2.text}}</v-card-subtitle>
          <v-simple-table
            dense
          >
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(valueItem, k) in deleteZeros(item2.items)"
                  :key="k"
                >
                  <td>
                    {{ valueItem.text }}
                  </td>
                  <td class="text-right">{{ valueItem.value | currencyFormat }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="subtitle-2 font-weight-medium">
                    {{ 'Total ' + item2.text}}
                  </th>
                  <th class="text-right subtitle-2 font-weight-medium">{{ item2.value | currencyFormat }}</th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </template>

  </v-card>
</template>

<script>
import { /* mapState, mapActions,/**/ mapGetters } from 'vuex'
import tools from '@/api/tools.js'
export default {
  name: 'StatementFinancialPosition',
  data () {
    return {}
  },
  methods: {
    deleteZeros (items) {
      return items.filter(({ value }) => value !== 0)
    }
  },
  computed: {
    getStatementOfFinancialPosition () {
      const statementFinancialPosition = []
      const equityAndLiabilities = []
      let equityAndLiabilitiesValue = 0
      const abstract = {}
      this.getAllLedgersTree.forEach((account, i) => {
        if (Number(account.code) < 4) {
          if (account.code === '1') {
            const items = []
            try {
              account.subaccounts.forEach((child, j) => {
                const items2 = []
                child.subaccounts.forEach((child2, k) => {
                  items2.push({ text: child2.name, value: child2.balances.balance })
                })
                if (child.code === '1.01') abstract.noncurrentAssets = { text: child.name, value: child.balances.balance }
                if (child.code === '1.02') abstract.currentAssets = { text: child.name, value: child.balances.balance }
                items.push({ text: child.name, value: child.balances.balance, items: items2 })
              })
            } catch (error) {
              console.log({ ...account })
              throw error
            }
            abstract.asset = { text: account.name, value: account.balances.balance }
            statementFinancialPosition.push({ text: account.name, value: account.balances.balance, items })
          } else {
            const items = []
            equityAndLiabilitiesValue += account.balances.balance
            if (account.subaccounts.length > 0) {
              account.subaccounts.forEach((child, j) => {
                const items2 = []
                child.subaccounts.forEach((child2, k) => {
                  items2.push({ text: child2.name, value: child2.balances.balance })
                })
                if (child.code === '2.01') abstract.noncurrentLiabilities = { text: child.name, value: child.balances.balance }
                if (child.code === '2.02') abstract.currentLiabilities = { text: child.name, value: child.balances.balance }
                items.push({ text: child.name, value: child.balances.balance, items: items2 })
              })
            } else {
              account.subaccounts.forEach((child, j) => {
                items.push({ text: child.name, value: child.balances.balance })
              })
            }
            if (account.code === '2') {
              abstract.liabilities = { text: account.name, value: account.balances.balance }
              equityAndLiabilities.push({
                text: account.name,
                value: account.balances.balance,
                items
              })
            } else {
              abstract.equity = { text: account.name, value: account.balances.balance }
              equityAndLiabilities.push({
                text: account.name,
                value: account.balances.balance,
                items
              })
            }
          }
        }
      })
      statementFinancialPosition.push({ text: 'Patrimonio y pasivos', value: equityAndLiabilitiesValue, items: equityAndLiabilities })
      return {
        items: statementFinancialPosition,
        asset: abstract.asset,
        noncurrentAssets: abstract.noncurrentAssets,
        currentAssets: abstract.currentAssets,
        liabilities: abstract.liabilities,
        noncurrentLiabilities: abstract.noncurrentLiabilities,
        currentLiabilities: abstract.currentLiabilities,
        equity: abstract.equity
      }
    },
    getAllLedgersTree () {
      const accountsTypes = { subaccounts: this.getParentAccounts }
      return tools.getChildren(accountsTypes, this.getAllLedgers, 'subaccounts')
    },
    ...mapGetters('accounting', ['getAllLedgers', 'getParentAccounts'])
  }
}
</script>
