<template>
  <div class="container-root">
    <CustomerData></CustomerData>
    <AddProduct class="my-6"></AddProduct>
    <OtherDataOrder></OtherDataOrder>
  </div>
</template>
<script>
import CustomerData from './CustomerData.vue'
import AddProduct from './AddProduct.vue'
import OtherDataOrder from './OtherDataOrder.vue'

export default {
  name: 'NewOrderMain',
  components: { AddProduct, OtherDataOrder, CustomerData },
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.container-root {
  width: 645px;
  gap: 20.924px;
  padding-top: 80px;
  margin: 0 auto;
}
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
