<template>
  <div class="place-order">
    <div class="place-order-inner">
      <div class="frame-parent">
        <div class="detalles-de-la-orden-parent">
          <div class="detalles-de-la">Detalles de la orden</div>
                <hr style="border: 1.6px solid #e5eaff;
                width: 100%;
                border-radius: 25px;">
          <div class="text-types-parent">
            <div class="text-types">
              <div class="text">{{ getName | capitalizeWords }}</div>
            </div>
            <div class="component-1" v-if="getName" @click="deleteReceiver">
              <img class="union-icon" alt="" :src="require('@/assets/equis.svg')">
            </div>
          </div>
        </div>
        <div class="option-switcher-parent">
          <div class="option-switcher">
            <div
              v-for="(item, i) in itemsSalesCondition"
              :key="i"
              :class="item.value === salesCondition ? 'label-wrapper' : 'label-container'"
              @click="salesCondition = item.value"
            >
                <div class="label">{{ item.text }}</div>
            </div>
          </div>
          <div class="parent-container">
            <div class="group-wrapper">
              <div class="rectangle-parent" :key="i" v-for="(item, i) in itemsDataTable">
                <div class="group-child">
                  <div class="frame-wrapper">
                    <div class="parent">
                      <div class="div">{{ item.quantity }}</div>
                      <div class="impresin-gf-parent">
                        <div class="impresin-gf">{{ item.description | truncarString(22) }}</div>
                        <div class="lona-de-pollitos" v-if="false">Lona de pollitos</div>
                      </div>
                      <div class="frame-group">
                        <div class="wrapper">
                          <div class="div">{{ item.netTotal | currencyFormat(getCurrency) }}</div>
                        </div>
                        <div class="component-1" @click="deleteItem(i)">
                          <img class="union-icon" alt="" :src="require('@/assets/equis.svg')">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="caja-de-total">
            <div class="subtotal-parent">
              <div class="subtotal">Subtotal</div>
              <div class="div2">{{ summary.grossTotal | currencyFormat(getCurrency)}}</div>
            </div>
            <img class="line-table-icon" alt="" src="Line Table.svg">

            <div class="subtotal-parent">
              <div class="subtotal">Descuentos</div>
              <div class="div2">{{ summary.discountTotal | currencyFormat(getCurrency)}}</div>
            </div>
            <img class="line-table-icon" alt="" src="Line Table.svg">

            <div class="subtotal-parent">
              <div class="subtotal">IVA</div>
              <div class="div2">{{ summary.taxTotal | currencyFormat(getCurrency)}}</div>
            </div>
            <img class="line-table-icon" alt="" src="Line Table.svg">

            <div class="subtotal-parent">
              <div class="subtotal">Costos de entrega</div>
              <div class="div2">{{ summary.otherChargesTotal | currencyFormat(getCurrency)}}</div>
            </div>
            <img class="line-table-icon" alt="" src="Line Table.svg">

            <div class="subtotal-parent">
              <div class="subtotal">Total</div>
              <div class="div2">{{ summary.netTotal | currencyFormat(getCurrency)}}</div>
            </div>
            <img class="line-table-icon" alt="" src="Line Table.svg">

          </div>
        </div>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
        <div class="place-order-button"  v-else-if="this.getItems && this.getItems.length > 0" @click="submitInvoice">
          <div class="place-order-button-child">
            <div class="place-order1">Procesar orden</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import AddProduct from './AddProduct.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PlaceOrder',
  // components: { AddProduct },
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      loading: false,
      itemsSalesCondition: [
        { text: 'Contado', value: '01' },
        { text: 'Crédito', value: '02' },
        // { text: 'Consignación', value: '03' },
        // { text: 'Apartado', value: '04' },
        // { text: 'Arrendamiento con Opción de Compra', value: '05' },
        // { text: 'Arrendamiento en Función Financiera', value: '06' },
        { text: 'Otros', value: '99' }
      ],
      salesCondition: '01'
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    getName () {
      return this.$store.state['1'].receiver?.name
    },
    getCurrency () {
      return this.$store.state['1'].currency
    },
    itemsDataTable: function () {
      const items = []
      for (var i = this.getItems.length - 1; i >= 0; i--) {
        items.push({
          index: i,
          quantity: this.getItems[i].quantity,
          description: this.getItems[i].description,
          subtotal: this.getItems[i].subtotal,
          unitPrice: this.getItems[i].unitPrice,
          taxRate: this.getItems[i].taxes
            ? this.getItems[i].taxes[0].allowance
              ? (this.getItems[i].taxes[0].rate - this.getItems[i].taxes[0].allowance.percentage) + '*' : this.getItems[i].taxes[0].rate
            : 0,
          netTotal: this.getItems[i].netTotal
        })
      }
      return items
    },
    getItems () {
      return this.$store.getters['1/getItems']
    },
    getVoucherType () {
      return this.$store.getters['1/getVoucherType']
    },
    summary () {
      return this.$store.getters['1/summary']
    }
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    issueVoucher (payload) {
      return new Promise((resolve, reject) => {
        const action = this.getVoucherType === '13' ? '/submitOrder' : '/issueVoucher'
        this.$store.dispatch('1' + action, payload)
          .then(voucher => {
            resolve(voucher)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    submitInvoice (isPOS) {
      if (this.loading) return
      if (!this.getItems || this.getItems.length <= 0) return
      this.loading = true
      this.issueVoucher(isPOS)
        .then(() => {
          this.$vuetify.goTo(0)
          this.showSuccess('Comprobante emitido correctamente')
          // this.loadingEmit = false
          // console.log(voucher)
          this.reset()
        // return this.inventarySale(voucher);
        })
        .catch(error => {
          this.$vuetify.goTo(0)
          console.error(error)
          this.showError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    cleanInvoice (payload) {
      this.$store.dispatch('1/cleanInvoice', payload)
    },
    deleteReceiver (payload) {
      this.$store.dispatch('1/deleteReceiver', payload)
    },
    reset: function () {
      if (!this.voucher) this.cleanInvoice()
      this.$emit('close')
      /*
      let def = getDefaultData()
      def[keep] = this[keep]
      Object.assign(this.$data, def)
      */
    },
    deleteItem (payload) {
      this.$store.dispatch('1/deleteItem', payload)
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.detalles-de-la {
  width: 288px;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33.4px;
  flex-shrink: 0;
}
.frame-child {
  width: 518.3px;
  position: relative;
  height: 0px;
}
.text {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
}
.text-types {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.line-icon {
  width: 5.5px;
  position: relative;
  height: 11.6px;
}
.text-types-parent {
  width: 452.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}
.detalles-de-la-orden-parent {
  width: 518.3px;
  box-shadow: 0px 8.292390823364258px 27.64px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 0px 16px;
  box-sizing: border-box;
  gap: 8px;
  font-size: 30.41px;
}
.label {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 24px;
  font-weight: 500;
}
.label-wrapper {
  box-shadow: 0px 4px 16px rgba(67, 97, 238, 0.5);
  border-radius: 24px;
  background-color: #4b4ded;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  color: #fff;
}
.label-container {
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
}
.option-switcher {
  border-radius: 24px;
  background-color: #efeffd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  font-size: 14px;
  color: #4361ee;
  font-family: Roboto;
}
.group-child {
  box-shadow: 0px 8px 40px rgba(63, 55, 201, 0.06);
  border-radius: 20px;
  background-color: #fff;
  width: 451.9px;
  height: 40px;
  justify-items: center;
  padding: 8px 12px;
}
.div {
  position: relative;
}
.impresin-gf {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.lona-de-pollitos {
  align-self: stretch;
  position: relative;
  font-size: 12px;
}
.impresin-gf-parent {
  width: 191px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  text-align: left;
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.union-icon {
  width: 16.6px;
  position: relative;
  height: 16.6px;
  object-fit: contain;
}
.component-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.4px;
  box-sizing: border-box;
  cursor: pointer;
}
.frame-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}
.parent {
  width: 396.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frame-wrapper {
  width: 396.7px;
}
.rectangle-parent {
  width: 451.9px;
  position: relative;
  height: 40px;
}
.group-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: right;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.parent-container {
  height: calc(100vh - 480px);
}

.subtotal {
  top: 0px;
  left: 0px;
}
.div2 {
  top: 0px;
  left: 374.54px;
  font-weight: 500;
}
.subtotal-parent {
  display: flex;
  justify-content: space-between;
  width: 430.5px;
  position: relative;
  height: 19px;
}
.line-table-icon {
  width: 451.9px;
  position: relative;
  height: 0px;
}
.caja-de-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
.option-switcher-parent {
  width: 452px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  color: #2a3256;
}
.place-order-button-child {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6.91px;
  background-color: #4361ee;
}
.place-order1 {
  font-weight: 500;
  font-size: larger;
}
.place-order-button {
  width: 451.9px;
  position: relative;
  height: 78.8px;
  color: #fff;
  cursor: pointer;
}
.frame-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.place-order-inner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.place-order {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 16px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  color: #4361ee;
  font-family: Rubik;
  height: calc(100vh - 94px);
}
</style>
