export const units = [
  { text: 'Alquiler de uso habitacional', value: 'Al' },
  { text: 'Alquiler de uso comercial', value: 'Alc' },
  { text: 'Comisiones', value: 'Cm' },
  { text: 'Intereses', value: 'I' },
  { text: 'Otro tipo de servicio', value: 'Os' },
  { text: 'Servicios Profesionales', value: 'Sp' },
  { text: 'Servicios personales', value: 'Spe' },
  { text: 'Servicios técnicos', value: 'St' },
  { text: 'Unidad', value: 'Unid' },
  { text: 'Segundo', value: 's' },
  { text: 'minuto', value: 'min' },
  { text: 'hora', value: 'h' },
  { text: 'día', value: 'd' },
  { text: 'Gramo', value: 'g' },
  { text: 'Kilogramo', value: 'kg' },
  { text: 'tonelada', value: 't' },
  { text: 'Onzas', value: 'Oz' },
  { text: 'mililitro', value: 'mL' },
  { text: 'litro', value: 'L' },
  { text: 'Galóno', value: 'Gal' },
  { text: 'metro cúbico', value: 'm³' },
  { text: 'metro cuadrado', value: 'm²' },
  { text: 'Milímetro', value: 'mm' },
  { text: 'centímetro', value: 'cm' },
  { text: 'Metro', value: 'm' },
  { text: 'Kilometro', value: 'Km' },
  { text: 'pulgada', value: 'ln' }
]
export const conditions = {
  '01': 'Contado',
  '02': 'Crédito',
  '03': 'Consignación',
  '04': 'Apartado',
  '05': 'Arrendamiento con opción de compra',
  '06': 'Arrendamiento en función financiera',
  '07': 'Cobro a favor de un tercero',
  '08': 'Servicios prestados al Estado a crédito',
  '09': 'Pago del servicios prestado al Estado',
  99: 'otros'
}
