import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/lib/util/colors'
import es from 'vuetify/es5/locale/es'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import VentasIngresos from '../assets/icons/VentasIngresos.svg'

console.log(VentasIngresos)

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      VentasIngresos
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com' ? '#091558' : '#3F37C9', // '#091558',background: #2F54EB;
        secondary: process.env.VUE_APP_WEB_BASE_URL === 'https://onvo.redaBitscr.com' ? '#ffa336' : '#6DAAF2',
        tertiary: '#2F54EB',
        quaternary: '#2F54EB'
      }
    }
  }
})
