<template>
  <v-card>
    <v-card-title>{{ task ? task.name : 'Nueva Tarea' }}</v-card-title>
    <v-card-text>
      <RedaForm ref="form" :loading="loading" :options="form" v-model="item" @input="input"></RedaForm>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="save"
        :disabled="!valid"
        :loading="loading"
      >
        {{ task ? 'Guardar' : 'Crear'}}
      </v-btn>

      <v-btn
        text
        @click="reset"
      >
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import redaApp from '../api/redaApp'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'NewTask',
  props: {
    tasks: Array,
    staff: Array,
    task: Object
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      valid: false,
      loading: false,
      isMounted: false,
      provinces: [],
      counties: [],
      districts: [],
      item: {
        start: null,
        duration: null,
        durationType: 'd',
        milestone: false,
        completed: 0,
        deadline: null,
        name: null,
        description: null,
        dependency: null,
        parent: null,
        assignee: null,
        priority: 3,
        todos: []
      },
      defaultItem: {
        start: null,
        duration: null,
        durationType: 'd',
        milestone: false,
        completed: 0,
        deadline: null,
        name: null,
        description: null,
        dependency: null,
        parent: null,
        assignee: null,
        priority: 3,
        todos: []
      }
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    form () {
      return {
        inputs: [
          {
            id: '002',
            label: 'Nombre de la tarea',
            name: 'name',
            value: 'name',
            type: 'text',
            rules: 'required',
            hiden: !!this.task,
            cols: 12
          },
          {
            id: '013',
            label: 'Descripción de la tarea',
            name: 'description',
            value: 'description',
            type: 'textarea',
            cols: 12
          },
          {
            id: '003',
            label: 'Hito',
            name: 'milestone',
            value: 'milestone',
            type: 'checkbox',
            cols: 6
          },
          {
            id: '001',
            label: 'Fecha de inicio',
            name: 'start',
            value: 'start',
            type: 'date',
            cols: 12
          },
          {
            id: '002-a',
            label: 'Duración',
            name: 'duration',
            value: 'duration',
            type: 'number',
            hiden: this.item.milestone,
            cols: 8
          },
          {
            id: '002-b',
            label: 'Unidad de tiempo',
            name: 'durationType',
            value: 'durationType',
            type: 'select',
            items: [
              // { text: 'años', value: 'y' },
              // { text: 'cuartos', value: 'Q' },
              { text: 'meses', value: 'M' },
              { text: 'semanas', value: 'w' },
              { text: 'días', value: 'd' }
              // { text: 'horas', value: 'h' },
              // { text: 'minutos', value: 'm' },
              // { text: 'segundos', value: 's' },
              // { text: 'milisegundos', value: 'ms' }
            ],
            hiden: this.item.milestone,
            cols: 4
          },
          {
            id: '005',
            label: 'Fecha límite',
            name: 'deadline',
            value: 'deadline',
            type: 'date',
            cols: 12
          },
          {
            id: '008',
            label: 'Depende de',
            name: 'dependency',
            value: 'dependency',
            type: 'autocomplete',
            multiple: true,
            hideSelected: true,
            viewData: false,
            clearable: true,
            items: this.dependencyItems,
            itemText: 'name',
            itemValue: 'id',
            cols: 12
          },
          {
            id: '009',
            label: 'Parte de',
            name: 'parent',
            value: 'parent',
            clearable: true,
            type: 'autocomplete',
            items: this.parentItems,
            itemText: 'name',
            itemValue: 'id',
            cols: 6
          },
          {
            id: '010',
            label: 'Responsable',
            name: 'assignee',
            value: 'assignee',
            items: this.staff,
            itemText: 'name',
            itemValue: 'uid',
            type: 'select',
            cols: 6
          },
          {
            id: '011',
            label: 'Prioridad',
            name: 'priority',
            value: 'priority',
            type: 'select',
            items: [
              { value: 1, text: 'Alta' },
              { value: 2, text: 'Media' },
              { value: 3, text: 'Baja' }
            ],
            rules: 'required',
            cols: 12
          },
          {
            id: '012',
            label: 'Lista de verificación',
            name: 'todos',
            value: 'todos',
            type: 'table',
            options: {
              title: 'Subtareas',
              headers: [
                { text: 'N°', value: 'orderedList' },
                { text: 'Subtarea', value: 'text', editable: true },
                { text: '', value: 'actions' }
              ],
              btnIconType: true,
              delete: true,
              defaultItem: {
                text: 'Agrega subtarea aquí...',
                value: false
              },
              insert: {
                simpleInsert: true
              }
            },
            table: {
              dense: true,
              hideDefaultFooter: true
            },
            cols: 12
          }
        ]
      }
    },
    dependencyItems () {
      if (!this.item.start) return this.tasks
      return this.tasks.filter(({ end }) => end && end < this.item.start)
    },
    parentItems () {
      if (!this.item.start) return this.tasks
      return this.tasks.filter(({ start }) => start && start <= this.item.start)
    },
    // valid () {
    //   if (!this.isMounted) return false
    //   return this.$refs.form.valid
    // },
    ...mapGetters('user', ['getUser'])
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    task (val) {
      if (!val) this.item = { ...this.defaultItem }

      this.item.start = val.start || null
      this.item.duration = val.duration?.value || null
      this.item.durationType = val.duration?.type || 'd'
      this.item.milestone = val.milestone || false
      this.item.completed = val.completed || 0
      this.item.deadline = val.deadline || null
      this.item.name = val.name || ''
      this.item.description = val.description || null
      this.item.dependency = val.dependency || null
      this.item.parent = val.parent || null
      this.item.assignee = val.assignee || null
      this.item.priority = val.priority || 3
      this.item.todos = val.todos || []
    }
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    save () {
      this.loading = true
      const newItem = {
        start: this.item.start,
        duration: this.item.duration ? {
          type: this.item.durationType,
          value: this.item.duration
        } : null,
        milestone: this.item.milestone,
        deadline: this.item.deadline,
        name: this.item.name,
        description: this.item.description,
        dependency: this.item.dependency,
        parent: this.item.parent,
        assignee: this.item.assignee,
        priority: this.item.priority,
        todos: this.item.todos,
        created: Number(moment().format('x')),
        createdBy: this.getUser.uid
      }
      if (!this.task?.uid) {
        newItem.created = Number(moment().format('x'))
        newItem.createdBy = this.getUser.uid
      }
      const action = this.task
        ? redaApp.update('tasks', newItem, this.task.uid)
        : redaApp.createTask(newItem)
      action.then((task) => {
        this.$emit('submit')
        this.showSuccess(this.task ? 'Tarea actualizada' : 'Tarea creada')
        this.reset()
      })
        .catch((e) => {
          this.showError('Error al crear Tarea')
          console.error(e)
        })
        .finally(() => { this.loading = false })
    },
    reset () {
      if (this.task) {
        this.item.start = this.task.start || null
        this.item.duration = this.task.duration?.value || null
        this.item.durationType = this.task.duration?.type || 'd'
        this.item.milestone = this.task.milestone || false
        this.item.completed = this.task.completed || 0
        this.item.deadline = this.task.deadline || null
        this.item.name = this.task.name || ''
        this.item.description = this.task.description || null
        this.item.dependency = this.task.dependency || null
        this.item.parent = this.task.parent || null
        this.item.assignee = this.task.assignee || null
        this.item.priority = this.task.priority || 3
        this.item.todos = this.task.todos || []
      } else {
        this.item = { ...this.defaultItem }
      }
      this.$emit('close')
    },
    input () {
      this.valid = this.$refs.form.validate()
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    this.isMounted = true
    if (this.task) {
      this.item.start = this.task.start || null
      this.item.duration = this.task.duration?.value || null
      this.item.durationType = this.task.duration?.type || 'd'
      this.item.milestone = this.task.milestone || false
      this.item.completed = this.task.completed || 0
      this.item.deadline = this.task.deadline || null
      this.item.name = this.task.name || ''
      this.item.description = this.task.description || null
      this.item.dependency = this.task.dependency || null
      this.item.parent = this.task.parent || null
      this.item.assignee = this.task.assignee || null
      this.item.priority = this.task.priority || 3
      this.item.todos = this.task.todos || []
    }
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
