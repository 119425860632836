<template>
  <DataTableCRUD
    :table="table"
    :options="options"
    :value="getCellars"
    @updateItem="insertCellar($event)"
    @newItem="insertCellar($event)"
  ></DataTableCRUD>
</template>

<script>
import { /* mapState,/**/ mapActions, mapGetters } from 'vuex'
// import tools from '@/api/tools.js'
export default {
  name: 'Cellars',
  data () {
    return {}
  },
  methods: {
    ...mapActions('inventories', ['insertCellar'])
  },
  computed: {
    table () {
      return {
        dense: true
      }
    },
    options () {
      return {
        headers: [
          { text: 'Código', value: 'code' },
          { text: 'Nombre', value: 'name' },
          { text: 'Departamento', value: 'cc' },
          { text: 'Tipo', value: 'type' },
          { text: '', value: 'actions', sortable: false }
        ],
        title: 'Bodegas',
        btnIcon: 'mdi-plus',
        btnColor: 'secondary',
        newItemText: 'Nueva bodega',
        editItemText: 'Editar bodega',
        defaultItem: {
          code: null,
          name: null,
          cc: null,
          type: '01'
        },
        form: {
          submitText: 'Crear bodega',
          cancelText: 'Cancelar',
          showSubmit: true,
          showCancel: true,
          inputs: [
            {
              id: 'de01',
              label: 'Código',
              name: 'code',
              value: 'code',
              type: 'text',
              readonly: true,
              hiden: true,
              cols: 12
            },
            {
              id: 'de02',
              label: 'Nombre de la bodega',
              name: 'name',
              value: 'name',
              type: 'text',
              rules: 'required',
              cols: 12
            },
            {
              id: 'de03',
              label: 'Departamento',
              name: 'cc',
              value: 'cc',
              type: 'select',
              itemValue: 'code',
              itemText: 'name',
              items: this.getCostCentersCellar,
              rules: 'required',
              cols: 8
            },
            {
              id: 'de04',
              label: 'Tipo',
              name: 'type',
              value: 'type',
              type: 'select',
              items: [
                { text: 'Inventarios mantenidos para la venta', value: '01' },
                { text: 'Inventarios de trabajo en curso', value: '02' },
                { text: 'Inventarios de materias primas', value: '03' }
              ],
              rules: 'required',
              cols: 4
            }
          ]
        }
      }
    },
    ...mapGetters('inventories', ['getCellars', 'itemsCCCellars', 'itemsTypeCellar']),
    ...mapGetters('siinco', ['getCostCentersCellar'])
  }
}
</script>
