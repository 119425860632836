<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Cliente ID {{ id }}
    </div>
    <v-card
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Ventas
        </div>
        <div class="title text-center">
          {{ getCustomer.totalSales }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <!--<div class="ma-5">
        <div class="text-center caption">
          Compras
        </div>
        <div class="title text-center">
          {{ customer.id }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>-->
      <div class="ma-5">
        <div class="text-center caption">
          Pagos recibidas
        </div>
        <div class="title text-center">
          {{ getCustomer.totalReceipts }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <!--<div class="ma-5">
        <div class="text-center caption">
          Pagos realizados
        </div>
        <div class="title text-center">
          + {{ getCustomer.id }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>-->
      <div class="ma-5">
        <div class="text-center caption">
          Saldo actual
        </div>
        <div class="title text-center">
          {{ getCustomer.amountOutstanding }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
      <v-tabs v-model="tab">
        <v-tab>
          información generales
        </v-tab>
        <v-tab>
          ventas
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <RedaForm
            :options="form"
            v-model="tmp.customer"
            @submitForm="submitCustomer"
            ></RedaForm>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <customer-vouchers :customer="getCustomer"></customer-vouchers>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
  </v-container>
</template>

<script>
// import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import CustomerVouchers from '@/components/CustomerVouchers.vue'
import locationCodes from '@/assets/locationCodes.js'
// import { conditions } from '@/assets/codes'

export default {
  name: 'Customer',
  props: ['id'],
  components: { CustomerVouchers },
  data: () => ({
    tab: 0,
    tmp: {
      customer: {}
    },
    provinces: [],
    counties: [],
    districts: []
  }),
  methods: {
    submitCustomer () {
      this.insertCustomer({ ...this.tmp.customer })
        .then(() => this.showSuccess('Cliente acturalizado correctamente'))
        .catch((error) => {
          console.error(error)
          this.showError('Ocurrió un error a la hora de actualizar cliente')
        })
    },
    ...mapActions('message', ['showSuccess', 'showError']),
    ...mapActions('customers', ['insertCustomer'])
  },
  computed: {
    form () {
      return {
        submitText: 'Actualizar cliente',
        showSubmit: true,
        inputs: [
          {
            id: 'de01',
            text: 'Tipo de cédula',
            label: 'Tipo de cédula',
            items: [
              { value: '01', text: 'Física' },
              { value: '02', text: 'Jurídica' },
              { value: '03', text: 'DIMEX' },
              { value: '04', text: 'NITE' }
            ],
            name: 'typeId',
            value: 'typeId',
            type: 'select',
            // readonly: true,
            cols: 6
          },
          {
            id: 'de01',
            text: 'N° Cédula',
            label: 'N° Cédula',
            name: 'identification',
            value: 'identification',
            type: 'text',
            readonly: true,
            cols: 6
          },
          {
            id: 'de02',
            label: 'Nombre/Razón social',
            name: 'name',
            value: 'name',
            type: 'text',
            readonly: true,
            cols: 6
          },
          {
            id: 'de04',
            label: 'Email',
            name: 'email',
            value: 'email',
            type: 'text',
            rules: 'required',
            cols: 6
          },
          {
            id: 'de05',
            label: 'Telefono',
            name: 'phone',
            value: 'phone',
            type: 'number',
            rules: 'required',
            cols: 6
          },
          {
            id: 'de06',
            label: 'Tipo de precio',
            name: 'priceList',
            value: 'priceList',
            type: 'select',
            items: this.getCompany.priceList,
            rules: 'required',
            cols: 6
          },
          {
            id: 'de07',
            type: 'form',
            options: {
              inputs: [
                {
                  id: 'l01',
                  label: 'Provincia',
                  name: 'province',
                  value: 'province',
                  type: 'select',
                  items: locationCodes.provinces,
                  cols: 4
                },
                {
                  id: 'l02',
                  label: 'Cantón',
                  name: 'county',
                  value: 'county',
                  type: 'select',
                  items: this.countiesItems,
                  cols: 4
                },
                {
                  id: 'l03',
                  label: 'Distrito',
                  name: 'district',
                  value: 'district',
                  type: 'select',
                  items: this.districtsItems,
                  cols: 4
                },
                {
                  id: 'l04',
                  label: 'Otras señas',
                  name: 'others',
                  value: 'others',
                  type: 'text',
                  cols: 12
                }
              ]
            },
            value: 'location'
          }
        ]
      }
    },
    countiesItems () {
      return this.tmp.customer.location
        ? locationCodes.counties[this.tmp.customer.location.province] : []
    },
    districtsItems () {
      return this.tmp.customer.location
        ? locationCodes.districts[
          this.tmp.customer.location.province + this.tmp.customer.location.county
        ] : []
    },
    customer () {
      const customer = this.customers.find((cus) => cus.uid === this.id || cus.identification === this.id)
      return customer
    },
    getCustomer () {
      const customer = this.getCustomers.find((cus) => cus.uid === this.id || cus.identification === this.id)
      return customer
    },
    ...mapState('customers', ['customers']),
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('company', ['getCompany'])
  },
  mounted () {
    this.tmp.customer = { ...this.customer }
  }
}
</script>
