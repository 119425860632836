/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment'
import fbApi from '@/api/firebaseApi'
import moment from 'moment'
import tools from '@/api/tools'

const { api } = fbApi

const generateSummary = (items, otherCharges = []) => {
  let servicesTaxableTotal = 0
  let servicesExentTotal = 0
  let servicesExoneTotal = 0
  let goodsTaxableTotal = 0
  let goodsExentTotal = 0
  let goodsExoneTotal = 0
  let discountTotal = 0
  let taxTotal = 0
  let otherChargesTotal = 0
  otherCharges.forEach(({ amount }) => {
    otherChargesTotal += Number(amount)
  })
  items.forEach((item) => {
    if (item.taxes) {
      const allowances = []
      item.taxes.forEach(({ allowance }) => {
        if (allowance) allowances.push(allowance)
      })
      if (
        item.unit === 'Al' ||
        item.unit === 'Alc' ||
        item.unit === 'Cm' ||
        item.unit === 'I' ||
        item.unit === 'Os' ||
        item.unit === 'Sp' ||
        item.unit === 'Spe' ||
        item.unit === 'St'
      ) {
        if (allowances.length > 0) {
          servicesExoneTotal += item.total
        } else {
          servicesTaxableTotal += item.total
        }
      } else {
        if (allowances.length > 0) {
          goodsExoneTotal += item.total
        } else {
          goodsTaxableTotal += item.total
        }
      }
      taxTotal += item.taxNet
    } else if (
      item.unit === 'Al' ||
      item.unit === 'Alc' ||
      item.unit === 'Cm' ||
      item.unit === 'I' ||
      item.unit === 'Os' ||
      item.unit === 'Sp' ||
      item.unit === 'Spe' ||
      item.unit === 'St'
    ) {
      servicesExentTotal += item.total
    } else {
      goodsExentTotal += item.total
    }
    if (item.discounts) {
      item.discounts.forEach(({ discountAmount }) => {
        discountTotal += discountAmount
      })
    }
  })
  const taxableTotal = tools.round10(servicesTaxableTotal + goodsTaxableTotal, -5)
  const exentTotal = tools.round10(servicesExentTotal + goodsExentTotal, -5)
  const exoneTotal = tools.round10(servicesExoneTotal + goodsExoneTotal, -5)
  const subtotal = tools.round10(taxableTotal + exentTotal + exoneTotal, -5)
  const netTotal = tools.round10(subtotal - discountTotal + taxTotal + otherChargesTotal, -5)
  const summary = {
    servicesTaxableTotal,
    servicesExentTotal,
    servicesExoneTotal,
    goodsTaxableTotal,
    goodsExentTotal,
    goodsExoneTotal,
    taxableTotal,
    exentTotal,
    exoneTotal,
    subtotal,
    discountTotal,
    taxTotal,
    otherChargesTotal,
    netTotal
  }
  return summary
}

// initial state
const initialState = {
  subscriptions: [],
  tmpSubscription: {
    id: null,
    receiver: null,
    company: null,
    activityCode: null,
    condition: '01',
    creditTerm: null,
    items: [],
    currency: 'CRC',
    otherCharges: [],
    others: null,
    active: true
  },
  tmpItem: {
    commercialCode: '',
    quantity: 0,
    unitPrice: 0
  },
  listinerSubscriptions: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getSubscriptions (state, getters, rootState, rootGetters) {
    const subscriptions = []
    state.subscriptions.forEach((element) => {
      const items = []
      element.items.forEach(item => {
        item.total = tools.round10(item.quantity * item.unitPrice, -5)
        item.subtotal = item.discount && item.discount > 0 ? tools.round10(item.total - item.discount, -5) : item.total
        item.taxableBase = item.subtotal
        let taxNet = 0
        if (item.taxes) {
          for (var i = item.taxes.length - 1; i >= 0; i--) {
            item.taxes[i].taxFactor = item.taxes[i].rate / 100
            item.taxes[i].total = item.taxableBase * item.taxes[i].taxFactor
            taxNet += item.taxes[i].total
          }
        }
        item.taxNet = tools.round10(taxNet, -5)
        item.netTotal = tools.round10(item.subtotal + item.taxNet, -5)
        items.push({ ...item })
      })
      const summary = generateSummary(items, element.otherCharges)
      const vouchers = rootGetters['vouchers/getVouchers'].filter(({ subscription }) => element.id === subscription)
      const totalIssued = tools.round10(vouchers.reduce((acc, curr) => acc + Number(curr.data.summary.netTotal), 0), -2)
      const pending = vouchers.filter(({ collected }) => !collected)
      const dateString = moment(element.date, 'x').format('L')
      const amountOutstanding = tools.round10(pending.reduce((prev, voucher) => prev + voucher.amountOutstanding, 0), -2)
      const receiver = rootGetters['customers/getCustomers'].find(({ id, identification }) => element.receiver === id || element.receiver === identification)
      const expirationDate = pending[pending.length - 1]?.expirationDate
      const lastBilling = element.period ? Number(moment(element.startDate, 'x').add(element.period, 'M').format('x')) : null
      const lastBillingString = lastBilling ? moment(lastBilling, 'x').format('L') : null
      subscriptions.push({ ...element, items, totalIssued, vouchers, receiver, amountOutstanding, expirationDate, summary, dateString, lastBilling, lastBillingString })
    })
    return subscriptions
  },
  getTmpSubscription (state, getters, rootState, rootGetters) {
    return {
      id: null,
      voucherType: '01',
      headquarters: rootGetters['company/getBranch'].code,
      terminal: rootGetters['company/getBranch'].terminal,
      date: Number(moment().format('x')),
      startDate: Number(moment().format('x')),
      receiver: null,
      company: rootGetters['company/getCompany'].identification,
      activityCode: rootGetters['company/getCompany'].activities
        ? rootGetters['company/getCompany'].activities[0].code
        : null,
      condition: '01',
      creditTerm: null,
      frequency: 1,
      period: null,
      items: [],
      currency: 'CRC',
      otherCharges: [],
      others: null,
      active: true
    }
  },
  getForm (state, getters, rootState, rootGetters) {
    return {
      showSubmit: true,
      submitText: 'Crear',
      showCancel: true,
      cancelText: 'Cancelar',
      inputs: [
        {
          id: 's01',
          label: 'Cliente',
          name: 'receiver',
          value: 'receiver',
          type: 'autocomplete',
          items: rootGetters['customers/getCustomers'],
          itemText: 'name',
          itemValue: 'id',
          rules: 'required',
          cols: 12
        },
        {
          id: 's03',
          label: 'Condición de Venta',
          name: 'condition',
          value: 'condition',
          type: 'select',
          items: [
            { text: 'Contado', value: '01' },
            { text: 'Crédito', value: '02' },
            { text: 'Consignación', value: '03' },
            { text: 'Apartado', value: '04' },
            { text: 'Arrendamiento con Opción de Compra', value: '05' },
            { text: 'Arrendamiento en Función Financiera', value: '06' },
            { text: 'Otros', value: '99' }
          ],
          rules: 'required',
          cols: 6
        },
        {
          id: 's04',
          label: 'Plazo credito',
          name: 'creditTerm',
          value: 'creditTerm',
          type: 'number',
          cols: 6
        },
        {
          id: 's02',
          label: 'Actividad Económica',
          name: 'activityCode',
          value: 'activityCode',
          type: 'select',
          items: rootGetters['company/getCompany'].activities,
          itemText: 'description',
          itemValue: 'code',
          rules: rootGetters['company/getCompany'].activities ? 'required' : '',
          cols: 6
        },
        {
          id: 's06',
          label: 'Moneda',
          name: 'currency',
          value: 'currency',
          type: 'select',
          items: [
            { text: 'Colones', value: 'CRC' },
            { text: 'Dólares', value: 'USD' }
          ],
          rules: 'required',
          cols: 6
        },
        {
          id: 's07',
          label: 'Frecuencia en meses',
          name: 'frequency',
          value: 'frequency',
          type: 'number',
          rules: 'numeric',
          cols: 6
        },
        {
          id: 's08',
          label: 'Duración en meses (opcional)',
          name: 'period',
          value: 'period',
          type: 'number',
          rules: 'numeric',
          cols: 6
        },
        {
          id: 's09',
          label: 'Observaciones',
          name: 'others',
          value: 'others',
          type: 'textarea',
          cols: 12
        },
        {
          id: 's10',
          label: 'Lineas de productos',
          name: 'items',
          value: 'items',
          type: 'table',
          // rules: 'required',
          table: {
            dense: true,
            hideDefaultFooter: true,
            calculateWidths: true
          },
          options: {
            headers: [
              { text: 'Código', value: 'commercialCode' },
              { text: 'Detalle', value: 'description' },
              { text: 'Precio', value: 'unitPrice', editable: { type: 'number', title: 'Actualizar Precio' } },
              { text: 'Cantidad', value: 'quantity', editable: { type: 'number', title: 'Actualizar Cantidad' } },
              { text: 'Total linea', value: 'netTotal' },
              { text: '', value: 'actions', sortable: false }
            ],
            insert: {
              itemValue: 'commercialCode',
              itemText: 'description',
              items: rootGetters['products/getProducts'],
              inserts: [
                { inputKey: 'commercialCode', objKey: 'commercialCode' },
                { inputKey: 'code', objKey: 'cabys' },
                { inputKey: 'description', objKey: 'description' },
                { inputKey: 'unit', objKey: 'unit' },
                { inputKey: 'unitPrice', objKey: 'unitPrice' },
                { inputKey: 'taxes', objKey: 'taxes' }
              ],
              filter: (item, queryText) => {
                const textOne = item.description.toLowerCase()
                const textTwo = item.commercialCode.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                  textTwo.indexOf(searchText) > -1
              }
            },
            defaultItem: {
              commercialCode: null,
              code: null,
              description: null,
              unit: null,
              unitPrice: 0,
              taxes: null,
              quantity: 1
            }
          },
          cols: 12
        }
      ]
    }
  },
  getOptions (state, getters, rootState, rootGetters) {
    return {
      title: 'Facturas recurrentes',
      headers: [
        { text: 'Fecha de emisión', value: 'dateString' },
        { text: 'N°', value: 'id' },
        { text: 'Cliente', value: 'receiver.name' },
        { text: 'Total', value: 'summary.netTotal' },
        { text: 'Monto pendiente', value: 'amountOutstanding' },
        { text: 'Finaliza', value: 'lastBillingString' },
        { text: '', value: 'actions', sortable: false }
      ],
      form: getters.getForm,
      defaultItem: getters.getTmpSubscription,
      newItemText: 'Crear nueva factura recurrente',
      info: {
        path: '/subscription/'
      },
      importName: 'subscriptions'
    }
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const subscriptionsQuerySnapshot = await fbApi.db.collection('subscriptions')
        .where('company', '==', company)
        .get()
      const subscriptions = []
      subscriptionsQuerySnapshot.forEach((doc) => subscriptions.push({ uid: doc.id, ...doc.data() }))
      commit('setSubscriptions', subscriptions)
      const listinerSubscriptions = subscriptionsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newSubscriptions = []
        querySnapshot.forEach((doc) => newSubscriptions.push({ uid: doc.id, ...doc.data() }))
        commit('setSubscriptions', newSubscriptions)
      })
      commit('setListinerSubscriptions', listinerSubscriptions)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recibidos')
    }
  },
  insertSubscription ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const company = rootGetters['company/getCompany'].identification
      api.insertItem('subscriptions', { ...payload, user: rootGetters['user/getUser'].uid, company })
        .then((subscription) => resolve(subscription))
        .catch((error) => {
          reject(error)
        })
    })
  },
  async enableDisable ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return dispatch('insertSubscription', payload)
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerSubscriptions) {
      state.listinerSubscriptions()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerSubscriptions (state, listener) {
    state.listinerSubscriptions = listener
  },
  setSubscriptions: (state, subscriptions) => {
    if (!subscriptions) state.listinerSubscriptions()
    state.subscriptions = subscriptions
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
