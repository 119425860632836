/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools'
// import tools from '@/api/tools';

const { api, db } = fbApi

// initial state
const initialState = {
  receipts: [],
  // accountReceivable: [],
  tmpOrder: {
    id: null,
    company: null,
    date: null,
    voucher: null,
    amount: 0,
    paymentType: '01',
    receiptsFrom: '01',
    user: null,
    others: null
  },
  listenerReceipts: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getAccountReceivable (state, getters, rootState, rootGetters) {
    const accountReceivable = []
    const vouchers = rootGetters['vouchers/getVouchers']
      .filter((voucher) => voucher.data.condition === '02')
    vouchers.forEach((item, i) => {
      const receipts = getters.getReceipts
        .filter((receipt) => receipt.voucher === item.key)
      let amountOutstanding = Number(item.data.summary.netTotal)
      receipts.forEach((receipt, j) => {
        amountOutstanding -= Number(receipt.amount)
      })

      accountReceivable.push({
        expirationDate: moment(item.data.date).add(Number(item.data.creditTerm), 'days').calendar(),
        voucher: item,
        receipts,
        amountOutstanding
      })
    })

    return accountReceivable
  },
  getReceipts ({ receipts }, getters, rootState, rootGetters) {
    const pays = []
    receipts.forEach((item, i) => {
      const dateString = moment(Number(item.date)).format('lll')
      const user = rootGetters['user/getUsers'].find(({ userUID }) => item.user === userUID)
      pays.push({ ...item, user, dateString })
    })
    return pays
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const receiptsQuerySnapshot = await fbApi.db.collection('receipts')
        .where('company', '==', company)
        .get()
      const receipts = []
      receiptsQuerySnapshot.forEach((doc) => receipts.push({ uid: doc.id, ...doc.data() }))
      commit('setReceipts', receipts)
      const listenerReceipts = receiptsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newReceipts = []
        querySnapshot.forEach((doc) => newReceipts.push({ uid: doc.id, ...doc.data() }))
        commit('setReceipts', newReceipts)
      })
      commit('setListenerReceipts', listenerReceipts)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recividos')
    }
  },
  insertReceipt ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const date = payload.date || Number(moment().format('x'))
      if (payload.uid) {
        db.collection('receipts').doc(payload.uid).update({ ...payload, updatedBy: rootGetters['user/getUser'].uid })
          .then((receipts) => resolve(receipts))
          .catch((error) => {
            reject(error)
          })
      } else {
        api.insertItem('receipts', {
          ...payload,
          user: rootGetters['user/getUser'].uid,
          date,
          cashDaily: rootGetters['cashDaily/getCashDaily']?.uid || null
        })
          .then((receipts) => resolve(receipts))
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  cancelReceipt ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('insertReceipt', {
        ...payload,
        amount: (0 - Number(payload.amount)),
        id: null,
        others: 'Anula pago N° ' + payload.id
      })
        .then((receipt) => resolve(receipt))
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listenerJournal) {
      state.listenerJournal()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListenerReceipts (state, payload) {
    state.listenerReceipts = payload
  },
  setReceipts: (state, payload) => {
    if (!payload) state.listenerReceipts()
    state.receipts = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
