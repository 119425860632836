import axios from 'axios'
import moment from 'moment'

const endPoint = 'https://api.hacienda.go.cr/'
const dollarURL = `${endPoint}indicadores/tc/dolar`
const dollarHistoryURL = `${endPoint}indicadores/tc/dolar/historico`
const allowanceURL = `${endPoint}fe/ex`

export default {
  getDollarExcachange () {
    return new Promise((resolve, reject) => {
      axios.get(dollarURL)
        .then((response) => {
          resolve({ buy: response.data.compra.valor, sell: response.data.venta.valor })
        })
        .catch((error) => reject(error))
    })
  },
  getAllowance (autorizacion) {
    return new Promise((resolve, reject) => {
      axios.get(allowanceURL, { params: { autorizacion: autorizacion.toLowerCase() } })
        .then((response) => {
          const data = response.data
          const payload = {
            docType: data.tipoDocumento.codigo,
            docNumber: data.numeroDocumento,
            institution: data.nombreInstitucion,
            date: data.fechaEmision,
            percentage: data.porcentajeExoneracion,
            expirationDate: Number(moment(data.fechaVencimiento).format('x')),
            cabys: data.cabys || null,
            ownCabys: data.poseeCabys
          }
          resolve({ data, payload })
        })
        .catch((error) => reject(error))
    })
  },
  getDollarHistoryExcachange (from, to) {
    return new Promise((resolve, reject) => {
      const d = moment(from, 'x').format('YYYY-MM-DD')
      const h = to ? moment(to, 'x').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      axios.get(dollarHistoryURL, { params: { d, h } })
        .then((response) => {
          resolve(response.data.map((item) => ({ ...item, fecha: Number(moment(item.fecha).format('x')) })))
        })
        .catch((error) => reject(error))
    })
  }
}
