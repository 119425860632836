<template>
  <v-container>
    <Clients />
  </v-container>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import Clients from '@/components/Clients.vue'
// import AccountReceivable from '@/components/AccountReceivable.vue'

export default {
  name: 'Customers',
  components: { Clients },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  /* created () {
  }, */

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
