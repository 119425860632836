/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools';

const { db, fb, api } = fbApi

// initial state
const initialState = {
  cashDaily: [],
  listenerCashDaily: null,
  movementTypes: {
    OC: 'Apertura',
    CC: 'Cierre',
    PCC: 'Cierre parcial'
  }
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getArching: (state, getters, rootState, rootGetters) => {
    if (!getters.getCashDaily) return null
    /* {
      date: moment().format('x'),
      sales:{
        salesCondition: {
          "01": 0,
          "02": 0,
          "03": 0,
          "04": 0,
          "99": 0,
        },
        paymentMethods: {
          "01": 0,
          "02": 0,
          "03": 0,
          "04": 0,
          "99": 0,
        },
      },
      accountsReceivable: {
        paymentMethods: {
          "01": 0,
          "02": 0,
          "03": 0,
          "04": 0,
          "99": 0,
        },
      },
      payments: 0
    } /**/
    const sales = {
      salesCondition: {
        '01': 0,
        '02': 0,
        '03': 0,
        '04': 0,
        99: 0
      },
      paymentMethods: getters.getCashDaily.receiptsAmount
    }
    const vouchers = rootGetters['vouchers/getVouchers'].filter((voucher) => {
      return voucher.cashDaily === getters.getCashDaily.id
    })
    vouchers.forEach((voucher) => {
      sales.salesCondition[voucher.data.condition] += Number(voucher.data.summary.netTotal)
    })
    return {
      sales,
      payments: getters.getCashDaily.paymentsAmount,
      amount: getters.getCashDaily.amount
    }
  },
  getAllCashDaily (state, getters, rootState, rootGetters) {
    const cashAllDaily = []
    state.cashDaily.forEach((item) => {
      const sales = {
        '01': 0,
        '02': 0,
        '03': 0,
        '04': 0,
        99: 0
      }
      const vouchers = rootGetters['vouchers/getVouchers'].filter((voucher) => {
        return voucher.cashDaily === item.id
      })
      vouchers.forEach((voucher) => {
        sales[voucher.data.condition] += Number(voucher.data.summary.netTotal)
      })
      const close = item.movements.find((mov) => mov.movent === 'CC')
      const open = !close
      const initAmount = {
        '01': 0,
        '02': 0,
        '03': 0,
        '04': 0,
        99: 0
      }
      const receipts = rootGetters['accountReceivable/getReceipts'].filter(({ cashDaily }) => cashDaily === item.uid)
      const payments = rootGetters['payments/getPayments'].filter(({ cashDailyPay }) => cashDailyPay === item.uid)
      const openAmount = item.movements.find((mov) => mov.movent === 'OC')?.amount
      const receiptsAmount = receipts.reduce((acc, receipt) => {
        acc[receipt.paymentType] += Number(receipt.amount)
        return acc
      }, { ...initAmount })
      const paymentsAmount = payments.reduce((acc, payment) => {
        acc[payment.paymentType] += Number(payment.amount)
        return acc
      }, { ...initAmount })
      const subAmount = {
        '01': receiptsAmount['01'] - paymentsAmount['01'],
        '02': receiptsAmount['02'] - paymentsAmount['02'],
        '03': receiptsAmount['03'] - paymentsAmount['03'],
        '04': receiptsAmount['04'] - paymentsAmount['04'],
        99: receiptsAmount['99'] - paymentsAmount['99']
      }
      const expectedAmount = {
        '01': Number(openAmount['01']) + subAmount['01'],
        '02': Number(openAmount['02']) + subAmount['02'],
        '03': Number(openAmount['03']) + subAmount['03'],
        '04': Number(openAmount['04']) + subAmount['04'],
        99: Number(openAmount['99']) + subAmount['99']
      }
      const amount = {
        '01': Number(openAmount['01']) + subAmount['01'] - (close?.amount['01'] || 0),
        '02': Number(openAmount['02']) + subAmount['02'] - (close?.amount['02'] || 0),
        '03': Number(openAmount['03']) + subAmount['03'] - (close?.amount['03'] || 0),
        '04': Number(openAmount['04']) + subAmount['04'] - (close?.amount['04'] || 0),
        99: Number(openAmount['99']) + subAmount['99'] - (close?.amount['99'] || 0)
      }
      const arching = { openAmount, expectedAmount, subAmount, salesCondition: sales, receiptsAmount, paymentsAmount, closeAmount: close?.amount || { ...initAmount }, amount }
      // const user = item.user?.name ? item.user : rootGetters['user/getUsers'].find(({ userUID }) => userUID === item.user)
      cashAllDaily.push({ ...item, sales, expectedAmount, vouchers, open, receipts, receiptsAmount, amount, payments, paymentsAmount, subAmount, arching, closeAmount: close?.amount || { ...initAmount } })
    })
    return cashAllDaily
  },
  getCashDaily (state, getters, rootState, rootGetters) {
    const cashDaily = getters.getAllCashDaily.find(cash => {
      return cash.user === rootGetters['user/getUser'].uid && cash.open
    })
    return cashDaily
  },
  getPayments (state, getters, rootState, rootGetters) {
    let payments = 0
    const messages = rootGetters['messages/getMessages'].filter(message => {
      return message.payFrom === getters.getCashDaily?.id
    })
    messages.forEach((item) => {
      payments += Number(item.total)
    })
    return payments
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const cashDailyQuerySnapshot = await fbApi.db.collection('cashDaily')
        .where('company', '==', company)
        .get()
      const cashDaily = []
      cashDailyQuerySnapshot.forEach((doc) => cashDaily.push({ uid: doc.id, ...doc.data() }))
      commit('setCashDaily', cashDaily)
      const listenerCashDaily = cashDailyQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newCashDaily = []
        querySnapshot.forEach((doc) => newCashDaily.push({ uid: doc.id, ...doc.data() }))
        commit('setCashDaily', newCashDaily)
      })
      commit('setListenerCashDaily', listenerCashDaily)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar caja diaria')
    }
  },
  openCash ({ state, getters, rootGetters }, initialBalance) {
    return new Promise((resolve, reject) => {
      console.log(initialBalance, getters.getCashDaily?.open)
      if (getters.getCashDaily?.open) resolve()
      const date = moment().format('x')
      const cash = {
        id: state.cashDaily.length + 1,
        company: rootGetters['company/getCompany'].identification,
        date: date,
        movements: [
          {
            date: date,
            movent: 'OC',
            amount: {
              '01': initialBalance,
              '02': 0,
              '03': 0,
              '04': 0,
              99: 0
            }
          }
        ],
        user: rootGetters['user/getUser'].uid
      }
      db.collection('cashDaily').add(cash)
        .then(docRef => {
          console.log({ ...docRef })
          resolve(docRef)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  closeCash ({ getters, state, dispatch }, cash) {
    return new Promise((resolve, reject) => {
      const cashDaily = state.cashDaily.find(({ uid }) => uid === getters.getCashDaily.uid)
      // const cashDailyAll = getters.getAllCashDaily.find(({ uid }) => uid === getters.getCashDaily.uid)
      if (cashDaily) {
        const movements = [...cashDaily.movements]
        movements.push({
          date: Number(moment().format('x')),
          movent: 'CC',
          amount: cash
        })
        api.insertItem('cashDaily', { ...cashDaily, movements }, 'id', true)
          .then(async () => {
            const cashDailyAll = await dispatch('getCashNew', { ...cashDaily, movements })
            console.log({ ...cashDailyAll })
            resolve({ ...cashDailyAll, movements })
          })
          .catch((error) => reject(error))
      } else {
        reject(new Error('Caja díaria no existe o ha sido cerrada'))
      }
    })
  },
  newArching ({ getters }, arching) {
    return new Promise((resolve, reject) => {
      const cashDaily = getters.getCashDaily
      if (cashDaily) {
        db.collection('cashDaily').where('id', '==', cashDaily.id).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const refCash = doc.ref
              refCash.update({
                arching: fb.firestore.FieldValue.arrayUnion(arching)
              })
                .then(() => {
                  resolve(arching)
                })
            })
          })
      } else {
        reject(new Error('Caja díaria no existe o ha sido cerrada'))
      }
    })
  },
  getCashNew ({ state, commit, dispatch, getters, rootGetters }, item) {
    const sales = {
      '01': 0,
      '02': 0,
      '03': 0,
      '04': 0,
      99: 0
    }
    const vouchers = rootGetters['vouchers/getVouchers'].filter((voucher) => {
      return voucher.cashDaily === item.id
    })
    vouchers.forEach((voucher) => {
      sales[voucher.data.condition] += Number(voucher.data.summary.netTotal)
    })
    const close = item.movements.find((mov) => mov.movent === 'CC')
    const open = !close
    const initAmount = {
      '01': 0,
      '02': 0,
      '03': 0,
      '04': 0,
      99: 0
    }
    const receipts = rootGetters['accountReceivable/getReceipts'].filter(({ cashDaily }) => cashDaily === item.uid)
    const payments = rootGetters['payments/getPayments'].filter(({ cashDailyPay }) => cashDailyPay === item.uid)
    const openAmount = item.movements.find((mov) => mov.movent === 'OC')?.amount
    const receiptsAmount = receipts.reduce((acc, receipt) => {
      acc[receipt.paymentType] += Number(receipt.amount)
      return acc
    }, { ...initAmount })
    const paymentsAmount = payments.reduce((acc, payment) => {
      acc[payment.paymentType] += Number(payment.amount)
      return acc
    }, { ...initAmount })
    const subAmount = {
      '01': receiptsAmount['01'] - paymentsAmount['01'],
      '02': receiptsAmount['02'] - paymentsAmount['02'],
      '03': receiptsAmount['03'] - paymentsAmount['03'],
      '04': receiptsAmount['04'] - paymentsAmount['04'],
      99: receiptsAmount['99'] - paymentsAmount['99']
    }
    const expectedAmount = {
      '01': Number(openAmount['01']) + subAmount['01'],
      '02': Number(openAmount['02']) + subAmount['02'],
      '03': Number(openAmount['03']) + subAmount['03'],
      '04': Number(openAmount['04']) + subAmount['04'],
      99: Number(openAmount['99']) + subAmount['99']
    }
    const amount = {
      '01': Number(openAmount['01']) + subAmount['01'] - (close?.amount['01'] || 0),
      '02': Number(openAmount['02']) + subAmount['02'] - (close?.amount['02'] || 0),
      '03': Number(openAmount['03']) + subAmount['03'] - (close?.amount['03'] || 0),
      '04': Number(openAmount['04']) + subAmount['04'] - (close?.amount['04'] || 0),
      99: Number(openAmount['99']) + subAmount['99'] - (close?.amount['99'] || 0)
    }
    const arching = { openAmount, expectedAmount, subAmount, salesCondition: sales, receiptsAmount, paymentsAmount, closeAmount: close?.amount || { ...initAmount }, amount }
    // const user = item.user?.name ? item.user : rootGetters['user/getUsers'].find(({ userUID }) => userUID === item.user)
    return { ...item, sales, expectedAmount, vouchers, open, receipts, receiptsAmount, amount, payments, paymentsAmount, subAmount, arching, closeAmount: close?.amount || { ...initAmount } }
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listenerCashDaily) {
      state.listenerCashDaily()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setCashDaily (state, payload) {
    if (payload === false) {
      state.cashDaily = []
      state.listenerCashDaily()
    } else {
      state.cashDaily = payload
    }
  },
  setListenerCashDaily (state, listener) {
    state.listenerCashDaily = listener
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
