export default {
  movementsCodes: [
    { name: 'COMPRA', movement: 'entry' },
    { name: 'VENTA', movement: 'exit' },
    // {name: 'REMISION', movement: 'exit'},
    // {name: 'CANC ENTRADA AJUSTE', movement: 'exit'},
    // {name: 'CANC SALIDA AJUSTE', movement: 'entry'},
    // {name: 'CANC ENTRADA TRASP', movement: 'exit'},
    // {name: 'CANC SALIDA TRASP', movement: 'entry'},
    // {name: 'CANC ENTRADA FABR', movement: 'exit'},
    // {name: 'CANC SALIDA FABR', movement: 'entry'},
    // {name: 'CANC ENTR SUCURSAL', movement: 'exit'},
    // {name: 'CANC SALIDA SUCURSAL', movement: 'entry'},
    // {name: 'CANC DE MERMA', movement: 'entry'},
    // {name: 'CANC.DE COMPRA', movement: 'exit'},
    // {name: 'CANC.DE DEVOLUCION', movement: 'exit'},
    // {name: 'CANC.DE FACTURA', movement: 'entry'},
    // {name: 'CANC.DE REMISION', movement: 'entry'},
    { name: 'DEVOLUCIÓN DE COMPRA', movement: 'exit' },
    { name: 'DEVOLUCIÓN DE VENTA', movement: 'entry' },
    { name: 'ENTRADA X AJUSTE A INVENTARIO', movement: 'entry' },
    { name: 'ENTRADA POR FABRICACION', movement: 'entry' },
    // {name: 'ENTRADA DESDE SUCURSAL', movement: 'entry'},
    { name: 'ENTRADA POR TRASPASO', movement: 'entry' },
    // { name: 'INVENTARIO INICIAL', movement: 'entry' },
    { name: 'MERMA DE MERCANCIA', movement: 'exit' },
    { name: 'SALIDA X AJUSTE A INVENTARIO', movement: 'exit' },
    { name: 'SALIDA POR FABRICACION', movement: 'exit' },
    // {name: 'SALIDA A SUCURSAL', movement: 'exit'},
    { name: 'SALIDA POR TRASPASO', movement: 'exit' }
  ]
}
