<template>
  <v-container>
    <v-form ref="form" @submit.prevent="submitForm">

      <v-text-field
        label="Título"
        v-model="blogPost.title"
        :rules="requiredRule"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Subtítulo"
        v-model="blogPost.subtitle"
        :rules="requiredRule"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Slug"
        v-model="blogPost.slug"
        :rules="requiredRule"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Etiquetas"
        v-model="blogPost.tags"
        :rules="requiredRule"
        hint="separadas por coma ','"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Categorías"
        v-model="blogPost.categories"
        :rules="requiredRule"
        hint="separadas por coma ','"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Palabras clave para SEO"
        v-model="blogPost.metaKeywords"
        :rules="requiredRule"
        hint="separadas por coma ','"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        label="Meta descripción para SEO"
        v-model="blogPost.metaDescription"
        :rules="requiredRule"
        outlined
        dense
      ></v-text-field>

      <v-textarea
        label="Contenido"
        v-model="blogPost.content"
        :rules="requiredRule"
        outlined
        dense
      ></v-textarea>

      <v-select
        label="Status"
        v-model="blogPost.status"
        :items="itemsStatus"
        :rules="requiredRule"
        outlined
        dense
        v-if="this.uid"
      ></v-select>

      <v-btn color="primary" @click="submitForm" :loading="loading">Publicar</v-btn>
      <v-btn text @click="saveForm" v-if="!loading">Guradar como borrador</v-btn>

      <pre v-if="false">{{ blogPost }}</pre>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import redaApp from '../api/redaApp'

export default {
  name: 'BlogForm',
  props: {
    item: Object
  },
  components: {
  },
  data () {
    return {
      itemsStatus: [
        { text: 'Publicado', value: 'published' },
        { text: 'Borrador', value: 'draft' },
        { text: 'Archivado', value: 'archived' }
      ],
      uid: null,
      blogPost: {
        title: '',
        subtitle: '',
        slug: '',
        tags: '',
        categories: '',
        metaKeywords: '',
        metaDescription: '',
        author: {
          name: '',
          profileUrl: null
        },
        publishDate: null,
        updateDate: null,
        content: '',
        status: null
      },
      loading: false,
      requiredRule: [(v) => !!v || 'Este campo es obligatorio'],
      urlOptionalRule: [
        (v) => {
          if (v === '' || v == null) return true
          const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
              '((([a-zA-Z0-9]{1,3})|([a-zA-Z0-9\\-]{1,3}))\\.)?' + // sub-domain or sub-domain + main-domain
              '([a-zA-Z0-9][a-zA-Z0-9\\-]{0,61})' + // main-domain
              '\\.[a-zA-Z]{2,6}' + // extension
              '((:[0-9]{1,4})|([a-zA-Z0-9\\/-]{0,}))', // port and URI
            'g'
          )
          return pattern.test(v) || 'Must be a valid URL'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  methods: {
    async insertBlog (payload) {
      if (payload.company) payload.company = this.getCompany.identification
      payload.tags = payload.tags.split(',').map(item => item.trim())
      payload.metaKeywords = payload.metaKeywords.split(',').map(item => item.trim())
      payload.categories = payload.categories.split(',').map(item => item.trim())
      if (this.uid) {
        return redaApp.update('blogPosts', payload, this.uid)
      }
      return redaApp.create('blogPosts', payload)
    },
    submitForm () {
      if (this.$refs.form.validate()) {
        this.loading = true
        console.log('Form is valid and ready to submit')
        this.blogPost.author.name = this.getUser?.name || null
        this.blogPost.publishDate = new Date().getTime()
        this.blogPost.updateDate = new Date().getTime()
        this.blogPost.status = 'published'
        console.log(this.blogPost)
        this.insertBlog({ ...this.blogPost })
          .then(() => this.showSuccess('Blog guardado'))
          .catch((e) => {
            console.error(e)
            this.showError('Error al guardar blog')
          })
          .finally(() => {
            this.loading = false
          })
        // You can send `this.blogPost` as JSON via an API request
      }
    },
    saveForm () {
      if (this.$refs.form.validate()) {
        this.loading = true
        console.log('Form is valid and ready to save')
        this.blogPost.author.name = this.getUser?.name || null
        this.blogPost.publishDate = null
        this.blogPost.updateDate = new Date().getTime()
        this.blogPost.status = 'draft'
        console.log(this.blogPost)
        this.insertBlog({ ...this.blogPost })
          .then(() => this.showSuccess('Blog guardado'))
          .catch((e) => {
            console.error(e)
            this.showError('Error al guardar blog')
          })
          .finally(() => {
            this.loading = false
          })
        // You can send `this.blogPost` as JSON via an API request
      }
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
  },
  watch: {
    item (val) {
      if (val) {
        this.uid = val.uid || null
        this.blogPost.title = val.title || ''
        this.blogPost.subtitle = val.subtitle || ''
        this.blogPost.slug = val.slug || ''
        this.blogPost.tags = val.tags?.toString() || ''
        this.blogPost.categories = val.categories?.toString() || ''
        this.blogPost.metaKeywords = val.metaKeywords?.toString() || ''
        this.blogPost.metaDescription = val.metaDescription || ''
        this.blogPost.author = val.author || {
          name: '',
          profileUrl: null
        }
        this.blogPost.profileUrl = val.profileUrl || null
        this.blogPost.publishDate = val.publishDate || null
        this.blogPost.updateDate = val.updateDate || null
        this.blogPost.content = val.content || ''
        this.blogPost.status = val.status || null
      }
    }
  },
  mounted: function () {
    if (this.item) {
      this.uid = this.item.uid || null
      this.blogPost.title = this.item.title || ''
      this.blogPost.subtitle = this.item.subtitle || ''
      this.blogPost.slug = this.item.slug || ''
      this.blogPost.tags = this.item.tags?.toString() || ''
      this.blogPost.categories = this.item.categories?.toString() || ''
      this.blogPost.metaKeywords = this.item.metaKeywords?.toString() || ''
      this.blogPost.metaDescription = this.item.metaDescription || ''
      this.blogPost.author = this.item.author || {
        name: '',
        profileUrl: null
      }
      this.blogPost.profileUrl = this.item.profileUrl || null
      this.blogPost.publishDate = this.item.publishDate || null
      this.blogPost.updateDate = this.item.updateDate || null
      this.blogPost.content = this.item.content || ''
      this.blogPost.status = this.item.status || null
    }
  }
}
</script>
