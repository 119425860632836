<template>
  <v-data-table
    :headers="headers"
    :items="receiptsItems"
    sort-by="date"
    sort-desc
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Cajas Diarias</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-row v-if="loading" align="center" justify="center">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <div class="d-flex flex-row" v-else>
        <v-tooltip bottom v-for="(option, index) in actions" :key="index">
          <template v-slot:activator="{ on, attrs }" v-if="!(option.text === 'Descargar Respuesta Hacienda' && item.data.documentType === '10')">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-1"
              :color="option.color || 'info'"
              @click="option.text === 'Realizar pago' ? newPayVoucher(item) : option.callback(item)"
              :disabled="(option.text === 'Realizar pago' && (Number(item.amountOutstanding) <= 0 || item.deleted || !item.isVoucherSale)) || (option.text === 'Descargar Respuesta Hacienda' && (item.data.documentType === '11' || item.data.documentType === '10'))"
            >
              {{ option.icon }}
            </v-icon>
          </template>
          <span>{{ option.text }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import printer from '@/api/printer.js'
// import axios from 'axios'

export default {
  name: 'CashDailyTable',
  data: () => ({
    dialog: false,
    loading: false,
    headers: [
      { text: 'N°', value: 'id' },
      { text: 'Fecha', value: 'dateString' },
      { text: 'Cajero/a', value: 'user.name' },
      { text: 'Monto en efectivo', value: 'subAmount.01' },
      { text: 'Balance efectivo', value: 'amount.01' },
      { text: '', value: 'action' }
    ],
    paymentMethodsItems: {
      '01': 'Efectivo',
      '02': 'Tarjeta',
      '03': 'Cheque',
      '04': 'Transferencia',
      99: 'Otros'
    }
  }),

  computed: {
    actions () {
      return [
        { text: 'Imprimir Arqueo', callback (item) { printer.printArching(item) }, icon: 'mdi-printer' }
      ]
    },
    receiptsItems () {
      const items = []
      this.getAllCashDaily.forEach((item) => {
        items.push({ ...item, dateString: moment(item.date, 'x').format('LL') })
      })
      return items
    },
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('vouchers', ['getVouchers']),
    ...mapGetters('cashDaily', ['getAllCashDaily'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  /* created () {
      this.initialize()
    }, */

  methods: {

    deleteItem (item) {
      if (confirm('¿Estás seguro de que deseas eliminar este pago?')) {
        this.cancelReceipt(item)
          .then((pay) => {
            this.showSuccess('¡Pago N° ' + pay.id + 'anulado!')
          })
          .catch((error) => {
            this.showError('Ocurrio un error al anular pago')
            console.error(error)
          })
      }
    },

    close () {
      this.dialog = false
    },
    ...mapActions('accountReceivable', ['cancelReceipt']),
    ...mapActions('message', ['showSuccess', 'showError'])
  }
}
</script>
<style type="text/css">

</style>
