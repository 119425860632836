<template>
  <v-container>
    <Clients :contacts="staff" staff="true" />
  </v-container>
</template>

<script>
import { /* mapState,/**/ mapActions, mapGetters } from 'vuex'
// import tools from '@/api/tools.js'
import Clients from '@/components/Clients.vue'
// import views from '@/assets/moduleViews'
// const { income, expenses, marketing, products, accounting, config, reports, Customers, Biller } = views
export default {
  name: 'Staff',
  components: { Clients },
  data () {
    return {}
  },
  methods: {
    ...mapActions('company', ['insertStaff'])
  },
  computed: {
    staff () {
      return this.getStaff.map(({ contact }) => contact)
    },
    // table () {
    //   return {
    //     dense: true
    //   }
    // },
    // options () {
    //   return {
    //     headers: [
    //       { text: 'N°', value: 'id' },
    //       { text: 'Nombre', value: 'user.name' },
    //       { text: 'Email', value: 'user.email' },
    //       { text: 'Usuario', value: 'userUID' },
    //       { text: '', value: 'actions', sortable: false }
    //     ],
    //     // info: { path: '/staff/' },
    //     title: 'Personal',
    //     btnIcon: 'mdi-plus',
    //     btnColor: 'secondary',
    //     newItemText: 'Nuevo personal',
    //     editItemText: 'Editar personal',
    //     defaultItem: {
    //       typeId: '01',
    //       identification: null,
    //       name: null,
    //       email: null,
    //       phone: null,
    //       createUser: false,
    //       authorizations: []
    //     },
    //     form: {
    //       submitText: 'Crear personal',
    //       cancelText: 'Cancelar',
    //       showSubmit: true,
    //       showCancel: true,
    //       inputs: [
    //         {
    //           id: 'de02',
    //           label: 'Tipo de identificación',
    //           name: 'typeId',
    //           value: 'typeId',
    //           type: 'select',
    //           items: [
    //             { text: 'Nacional', value: '01' },
    //             { text: 'DIMEX', value: '02' },
    //             { text: 'Pasaporte', value: '03' },
    //             { text: 'Otro', value: '04' }
    //           ],
    //           cols: 12
    //         },
    //         {
    //           id: 'de03',
    //           label: 'Identificación',
    //           name: 'identification',
    //           value: 'identification',
    //           type: 'text',
    //           cols: 12
    //         },
    //         {
    //           id: 'de06',
    //           label: 'Nombre completo',
    //           name: 'name',
    //           value: 'name',
    //           type: 'text',
    //           rules: 'required',
    //           cols: 12
    //         },
    //         {
    //           id: 'de04',
    //           label: 'Email',
    //           name: 'email',
    //           value: 'email',
    //           type: 'text',
    //           rules: 'required|email',
    //           cols: 12
    //         },
    //         {
    //           id: 'de05',
    //           label: 'Teléfono',
    //           name: 'phone',
    //           value: 'phone',
    //           type: 'text',
    //           rules: 'required|digits:8',
    //           cols: 12
    //         },
    //         {
    //           id: 'de08',
    //           label: 'Crear usuario',
    //           name: 'createUser',
    //           value: 'createUser',
    //           type: 'checkbox',
    //           cols: 12
    //         },
    //         {
    //           id: 'de07',
    //           label: 'Módulos autorizados',
    //           items: this.getModuleItems,
    //           name: 'authorizations',
    //           value: 'authorizations',
    //           type: 'checkboxesGroup',
    //           cols: 12
    //         }
    //       ]
    //     }
    //   }
    // },
    // getModuleItems () {
    //   const modules = { ...income, ...expenses, ...marketing, ...products, ...accounting, ...config, ...reports, Customers, Biller }
    //   return this.getCompany.modules.map((module) => ({ value: module, text: modules[module]?.title })).filter((item) => !!item)
    // },
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('company', ['getStaff', 'getCompany']),
    ...mapGetters('user', ['getViews'])
    // ...mapGetters('siinco', ['getCostCentersProduction'])
  }
}
</script>
