/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment';
import fbApi from '../../api/firebaseApi'
import errorsStorage from '@/api/errorsStorage'
import tools from '../../api/tools'
import redaApp from '../../api/redaApp'

const { db, storage } = fbApi

const salesVoucherTypes = {
  '01': true,
  '04': true,
  '09': true,
  10: true
}

// initial state
const initialState = {
  loadingVouchers: false,
  vouchers: [],
  vouchersReceived: [],
  vouchersReceivedPending: [],
  vouchersReceivedClassified: [],
  voucherItems: [],
  voucherReceivedItems: [],
  vouchersReceivedPendingItems: [],
  voucherSalesItems: [],
  listinerVouchers: null,
  listinerVouchersReceived: null,
  listinerVoucherItems: null,
  listinerVoucherReceivedItems: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getVouchersReceived (state, getters, rootState, rootGetters) {
    const vouchers = []
    const vouchersReceived = state.vouchersReceived
    for (let i = 0; i < vouchersReceived.length; i++) {
      const item = vouchersReceived[i]
      const currency = item.data.summary.currency
      const exchangeRate = currency === 'CRC' ? 1 : Number(item.data.summary.exchangeRate)
      const payments = rootGetters['payments/getPayments'].filter((payment) => payment.voucher === item.key)
      const debitNotes = vouchersReceived.filter((voucher) => voucher.data.reference?.number === item.key && voucher.data.documentType === '02')
      const creditNotes = vouchersReceived.filter((voucher) => voucher.data.reference?.number === item.key && voucher.data.documentType === '03')
      // const user = rootGetters['user/getUsers'].find(({ userUID }) => item.user === userUID)
      // const message = rootGetters['messages/getMessages'].find(({ key }) => key === item.key)
      const items = []
      item.classificationPending = false
      const otherChargesItem = Number(item.data.summary.otherChargesTotal) / item.data.items.length
      for (let index = 0; index < item.data.items.length; index++) {
        const line = item.data.items[index]
        const value = Number(line.subtotal) + otherChargesItem
        const ao = rootGetters['siinco/getAddressingOrdersAliasesIndexed'][line.code] ||
          rootGetters['siinco/getAddressingOrdersAliasesIndexed'][`${line.commercialCode}|${item.issuer}`] ||
          null
        const inventory = rootGetters['inventories/getInventoryAliasesIndexed'][line.code] ||
          rootGetters['inventories/getInventoryAliasesIndexed'][`${line.commercialCode}|${item.issuer}`] ||
          null
        const fixedAsset = rootGetters['fixedAssets/getFixedAssets'].find(({ voucher }) => voucher === item.key)
        const classified = !!(ao || inventory || fixedAsset)
        if (!classified) item.classificationPending = true
        items.push({ ...line, ao: ao ? { ...ao, amount: value } : null, inventory, fixedAsset, classified, otherChargesItem, value })
      }
      // let classificationPending = false
      // const otherChargesItem = Number(item.data.summary.otherChargesTotal) / item.data.items.length
      // for (let index = 0; index < item.data.items.length; index++) {
      //   const line = item.data.items[index]
      //   const ao = rootGetters['siinco/getAddressingOrdersAliases'].find(({ code }) => line.commercialCode === code || line.code === code)
      //   const inventaryAlias = rootGetters['inventories/getInventoryAliases'].find(({ code }) => {
      //     return line.commercialCode?.toString() === code.toString() || line.code?.toString() === code.toString()
      //   })
      //   const inventary = inventaryAlias ? {
      //     date: item.date,
      //     product: line.commercialCode,
      //     detail: 'COMPRA',
      //     exit: null,
      //     entry: rootGetters['inventaries/getCellars'].find(({ code }) => code === inventaryAlias.cellar),
      //     quantity: Number(line.quantity) * Number(inventaryAlias.factor || 1),
      //     cost: Number(line.subtotal) + otherChargesItem,
      //     docs: [],
      //     voucher: item.key,
      //     user: item.user
      //   } : null
      //   const fixedAsset = rootGetters['fixedAssets/getFixedAssets'].find(({ voucher }) => voucher === item.key)
      //   const classified = !!(ao || inventary || fixedAsset)
      //   if (!classified) classificationPending = true
      //   items.push({ ...line, ao: ao ? { ...ao, amount: Number(line.subtotal) + otherChargesItem } : null, inventary, fixedAsset, classified, otherChargesItem, product: inventaryAlias?.code })
      // }
      let amountOutstanding = Number(item.data.summary.netTotal)
      debitNotes.forEach(({ data }, j) => {
        amountOutstanding += Number(data.summary.netTotal)
      })
      creditNotes.forEach(({ data }, j) => {
        amountOutstanding -= Number(data.summary.netTotal)
      })
      payments.forEach(({ amount }, j) => {
        amountOutstanding -= Number(amount)
      })
      const collected = (amountOutstanding === 0)
      let totalVouchersCRC = Number(item.data.summary.netTotal) * exchangeRate
      let totalVouchersTaxCRC = Number(item.data.summary.netTotal) * exchangeRate
      if (item.data.documentType === '03') {
        totalVouchersTaxCRC = 0 - totalVouchersTaxCRC
        totalVouchersCRC = 0 - totalVouchersCRC
      }
      vouchers.push({ ...item, totalVouchersTaxCRC, payments, amountOutstanding, debitNotes, creditNotes, collected, items, totalVouchersCRC })
    }
    return vouchers
  },
  getVouchersReceivedPending ({ vouchersReceivedPending }) {
    return vouchersReceivedPending
  },
  // getVouchersReceivedClassified ({ vouchersReceivedClassified }) {
  //   return vouchersReceivedClassified
  // },
  getVouchers (state, getters, rootState, rootGetters) {
    const products = rootGetters['products/getProducts']
    const vouchers = []
    const vouchersBD = state.vouchers
    const vouAux = vouchersBD.filter((voucher) => !voucher.hiden)
    for (let index = 0; index < vouAux.length; index++) {
      const item = vouAux[index]
      const currency = item.data.summary.currency
      const exchangeRate = currency === 'CRC' ? 1 : Number(item.data.summary.exchangeRate)
      let totalVouchersCRC = Number(item.data.summary.netTotal) * exchangeRate
      let totalVouchersTaxCRC = Number(item.data.summary.taxTotal) * exchangeRate
      const branch = rootGetters['company/getCompany'].branches.find(({ code }) => item.data.sequence.substr(0, 3) === code) ||
        rootGetters['company/getCompany'].branches.find(({ code }) => code === '001')
      const isVoucherSale = salesVoucherTypes[item.data.documentType]
      const receipts = item.receipts || []
      const debitNotes = vouchersBD.filter((voucher) => voucher.data.reference?.number === item.key && voucher.data.documentType === '02')
      const creditNotes = vouchersBD.filter((voucher) => voucher.data.reference?.number === item.key && voucher.data.documentType === '03')
      // const user = rootGetters['user/getUsers'].find(({ userUID }) => item.user === userUID)
      // const message = rootGetters['messages/getMessages'].find(({ key }) => key === item.key)
      let voucherTotal = Number(item.data.summary.netTotal)
      const currencyVou = item.data.summary.currency
      const exchangeRateVou = Number(item.data.summary.exchangeRate)
      const items = []
      for (let i = 0; i < item.data.items.length; i++) {
        const line = item.data.items[i]
        const product = products.find(({ commercialCode }) => commercialCode === line.commercialCode)
        items.push({ ...line, product })
      }
      debitNotes.forEach(({ data }, j) => {
        voucherTotal += Number(data.summary.netTotal)
      })
      creditNotes.forEach(({ data }, j) => {
        voucherTotal -= Number(data.summary.netTotal)
      })
      let amountOutstanding = voucherTotal
      receipts.forEach((receipt, j) => {
        if (!receipt) return
        try {
          const value = receipt.currency === currencyVou ? Number(receipt.amount)
            : receipt.currency === 'CRC' ? Number(receipt.amount) / exchangeRateVou
              : Number(receipt.amount) * exchangeRateVou
          amountOutstanding -= value
        } catch (error) {
          console.log(item.key)
          throw error
        }
      })
      amountOutstanding = Number(tools.round10(amountOutstanding, 0))
      const collected = (amountOutstanding <= 0)
      const deleted = (voucherTotal <= 0)
      const summary = item.data.summary
      const goodsTotal = tools.round10(Number(summary.goodsExentTotal) + Number(summary.goodsExoneTotal) + Number(summary.goods_taxable_total), -2)
      const servicesTotal = tools.round10(Number(summary.servicesExentTotal) + Number(summary.servicesExoneTotal) + Number(summary.servicesTaxableTotal), -2)
      if (item.data.documentType === '03') {
        totalVouchersTaxCRC = 0 - totalVouchersTaxCRC
        totalVouchersCRC = 0 - totalVouchersCRC
      }
      vouchers.push({ ...item, totalVouchersTaxCRC, totalVouchersCRC, items, servicesTotal, receipts, goodsTotal, voucherTotal, amountOutstanding, debitNotes, creditNotes, collected, deleted, branch, isVoucherSale })
    }
    return vouchers
  },
  // getVouchersReceivedPendingItems ({ voucherReceivedItems }) {
  //   return voucherReceivedItems.filter(({ classified }) => !classified)
  // },
  // getVoucherSalesItems ({ voucherItems, vouchers }) {
  //   const types = ['01', '04', '10']
  //   return voucherItems
  //     .filter(({ type, deleted }) => types.includes(type) && !deleted)
  //     .map((item) => ({
  //       ...item,
  //       activityCode: vouchers.find(({ key }) => key === item.voucher)?.data?.activityCode
  //     }))
  // },
  nextND (state, getters, rootState, rootGetters) {
    const vouchers = state.vouchers.filter(voucher => voucher.data.documentType === '02' && Number(voucher.branch) === Number(rootGetters['company/getBranch'].code))
    return vouchers.length + 1
  },
  nextNC (state, getters, rootState, rootGetters) {
    const vouchers = state.vouchers.filter(voucher => voucher.data.documentType === '03' && Number(voucher.branch) === Number(rootGetters['company/getBranch'].code))
    return vouchers.length + 1
  }
}

// actions
const actions = {
  async updateVouchers ({ state, commit, dispatch, getters, rootGetters }) {
    commit('setLoadingVouchers', true)
    // const vouchers = await redaApp.runVouchers({ from: state.from, to: state.to })
    // commit('updateVouchers', vouchers)
  },
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      // dispatch('updateVouchers').catch((e) => console.error(e))
      commit('setLoadingVouchers', true)
      const vouchersQuerySnapshot = await fbApi.db.collection('vouchers')
        .where('issuer', '==', company)
        .get()
      const listinerVouchers = vouchersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        // if (!querySnapshot.isEqual(vouchersQuerySnapshot)) dispatch('updateVouchers').catch((e) => console.error(e))
        commit('setLoadingVouchers', true)
        const vouchers = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
        commit('setVouchers', vouchers)
        commit('setLoadingVouchers', false)
      })
      commit('setListinerVouchers', listinerVouchers)
      const vouchersReceivedQuerySnapshot = await fbApi.db.collection('vouchers')
        .where('receiver', '==', company)
        .get()
      const listinerVouchersReceived = vouchersReceivedQuerySnapshot.query.onSnapshot((querySnapshot) => {
        // if (!querySnapshot.isEqual(vouchersReceivedQuerySnapshot)) dispatch('updateVouchers').catch((e) => console.error(e))
        commit('setLoadingVouchers', true)
        const vouchersReceived = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
        commit('setVouchersReceived', vouchersReceived)
        commit('setLoadingVouchers', false)
      })
      commit('setListinerVouchersReceived', listinerVouchersReceived)

      // const voucherItemsQuerySnapshot = await fbApi.db.collection('voucherItems')
      //   .where('issuer', '==', company)
      //   .get()
      // const listinerVoucherItems = voucherItemsQuerySnapshot.query.onSnapshot((querySnapshot) => {
      //   // if (!querySnapshot.isEqual(voucherItemsQuerySnapshot)) dispatch('updateVouchers').catch((e) => console.error(e))
      //   const voucherItems = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
      //   commit('setVoucherItems', voucherItems)
      // })
      // commit('setListinerVoucherItems', listinerVoucherItems)

      // const voucherReceivedItemsQuerySnapshot = await fbApi.db.collection('voucherItems')
      //   .where('receiver', '==', company)
      //   .get()
      // const listinerVoucherReceivedItems = voucherReceivedItemsQuerySnapshot.query.onSnapshot((querySnapshot) => {
      //   // if (!querySnapshot.isEqual(voucherReceivedItemsQuerySnapshot)) dispatch('updateVouchers').catch((e) => console.error(e))
      //   const voucherReceivedItems = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
      //   commit('setVoucherReceivedItems', voucherReceivedItems)
      // })
      // commit('setListinerVoucherReceivedItems', listinerVoucherReceivedItems)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar comprobantes')
    }
  },
  insertVoucher ({ state }, voucher) {
    return new Promise((resolve, reject) => {
      const index = state.vouchers.findIndex(item => {
        return item.key === voucher.key
      })
      if (index === -1) {
        db.collection('vouchers').add(voucher)
          .then(() => {
            resolve()
          })
      } else {
        reject(new Error('El Comprobante ya existe'))
      }
    })
  },
  insertXml: function ({ state }, { key, xml, message, pdf }) {
    return new Promise((resolve, reject) => {
      const storageRef = storage.ref()
      if (xml) {
        const xmlRef = storageRef.child(`xml/${key}.xml`)
        xmlRef.putString(xml, 'base64')
          .catch((error) => {
            reject(error)
          })
      }
      if (message) {
        const xmlRef = storageRef.child(`message/${key}.xml`)
        xmlRef.putString(message, 'base64')
          .catch((error) => {
            reject(error)
          })
      }
      if (pdf) {
        const xmlRef = storageRef.child(`pdf/${key}.pdf`)
        xmlRef.putString(pdf, 'data_url')
          .catch((error) => {
            reject(error)
          })
      }
      resolve()
      /* db.collection('xml').where('key', '==', xml.key).get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              const xmlRef = doc.ref
              xmlRef.update(xml)
                .then(() => { resolve() })
                .catch(error => reject(error))
            })
          } else {
            db.collection('xml').add(xml)
              .then(() => { resolve() })
              .catch(error => reject(error))
          }
        }) /* */
    })
  },
  getXml: function ({ state }, key) {
    return new Promise((resolve, reject) => {
      db.collection('xml').where('key', '==', key).get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            resolve(querySnapshot.docs[0].data())
          /* querySnapshot.forEach(doc => {
            resolve(doc.data());
          }); /* */
          } else {
            reject(new Error('El Comprobante no existe en la base de datos'))
          }
        })
    })
  },
  downloadFileVoucher (state, payload) {
    // Create a reference to the file we want to download
    const storageRef = storage.ref()
    const starsRef = storageRef.child(`${payload.xml}/${payload.key}.${payload.xml === 'pdf' ? 'pdf' : 'xml'}`)

    // Get the download URL
    starsRef.getDownloadURL()
      .then((url) => {
        window.open(url)
        // Insert url into an <img> tag to "download"
      })
      .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
        console.error(errorsStorage[error.code])
      })
  },
  downloadDoc ({ state }, { path, fileName }) {
    return new Promise((resolve, reject) => {
      redaApp.getFile({ path })
        .then((data) => {
          const file = tools.dataURItoBlob(data)
          const reader = new FileReader()
          reader.onload = function (event) {
            const save = document.createElement('a')
            save.href = event.target.result
            save.target = '_blank'
            save.download = fileName
            const clicEvent = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true
            })
            save.dispatchEvent(clicEvent);
            (window.URL || window.webkitURL).revokeObjectURL(save.href)
            resolve()
          }
          resolve(reader.readAsDataURL(file))
        })
        .catch((error) => reject(error))
    })
  },
  downloadPdf ({ state }, payload) {
    return new Promise((resolve, reject) => {
      redaApp.getPDF(payload)
        .then((data) => {
          const file = tools.dataURItoBlob(data)
          const reader = new FileReader()
          reader.onload = function (event) {
            const save = document.createElement('a')
            save.href = event.target.result
            save.target = '_blank'
            save.download = 'FE-' + payload
            const clicEvent = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true
            })
            save.dispatchEvent(clicEvent);
            (window.URL || window.webkitURL).revokeObjectURL(save.href)
          }
          resolve(reader.readAsDataURL(file))
        })
        .catch((error) => reject(error))
    })
  },
  downloadXmlFE: function (state, payload) {
    return new Promise((resolve, reject) => {
      db.collection('xml').where('key', '==', payload.key).get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            reject(new Error(`El Comprobante ${payload.key} no se encuentra almacenado en el sistema`))
          }
          querySnapshot.forEach(doc => {
            const docType = payload.xml === 'pdf' ? 'application/pdf' : 'application/xml'
            let stringFromB64 = ''
            // if (payload.xml === 'pdf') {window.open(doc.data()[payload.xml], "_blank"); return}
            // let stringFromB64 = tools.b64DecodeUnicode(doc.data()[payload.xml]); //payload.xml === 'pdf' ? tools.b64DecodeUnicode(doc.data()[payload.xml].replace("data:application/pdf;filename=generated.pdf;base64,", "")) : tools.b64DecodeUnicode(doc.data()[payload.xml]) ;
            if (!(payload.xml === 'pdf')) {
              stringFromB64 = tools.b64DecodeUnicode(doc.data()[payload.xml])
            }
            const file = payload.xml === 'pdf'
              ? tools.dataURItoBlob(doc.data()[payload.xml])
              : new Blob([stringFromB64], { type: docType })
            const reader = new FileReader()
            reader.onload = function (event) {
              const save = document.createElement('a')
              save.href = event.target.result
              save.target = '_blank'
              save.download = 'FE-' + payload.key + (payload.xml === 'pdf' ? '.pdf' : '.xml')
              const clicEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
              })
              save.dispatchEvent(clicEvent);
              (window.URL || window.webkitURL).revokeObjectURL(save.href)
            }
            reader.readAsDataURL(file)
            // console.log(atob(doc.data().xml));
            // window.open().document.write('<iframe src="' + 'data:text/xml,' +encodeURI(atob(doc.data().xml))  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            // window.open('data:,Hello%2C%20World!');
            // window.open('data:text/xml;base64,'+doc.data().xml);
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  insertVoucherReceiver: function ({ state }, voucher) {
    return new Promise((resolve, reject) => {
      db.collection('vouchers').where('key', '==', voucher.key).get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            db.collection('vouchers').add(voucher)
              .then(() => {
                resolve(voucher)
              })
          } else {
            reject(new Error('El Comprobante ya existe'))
          }
        })
        .catch(error => reject(error))
    })
  },
  deleteVoucherReceiver: function ({ dispatch }, voucher) {
    return new Promise((resolve, reject) => {
      db.collection('vouchers').where('key', '==', voucher.key).get()
        .then(querySnapshot => {
          const voucherRef = querySnapshot.docs[0].ref
          voucherRef.delete()
            .then(() => {
              dispatch('deleteXml', voucher.key)
                .then(() => {
                  resolve()
                })
                .catch(error => {
                  reject(error)
                })
            })
            .catch(error => {
              reject(error)
            })
        })
    })
  },
  reset ({ state, commit }) {
    if (state.listinerVouchersReceived) {
      state.listinerVouchersReceived()
    }
    if (state.listinerVouchers) {
      state.listinerVouchers()
    }
    commit('reset')
  }
}

// mutations
const mutations = {
  setLoadingVouchers (state, payload) {
    state.loadingVouchers = payload
  },
  reset (state) {
    if (state.listinerVouchersReceived) {
      state.listinerVouchersReceived()
    }
    if (state.listinerVouchers) {
      state.listinerVouchers()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerVoucherReceivedItems (state, listener) {
    state.listinerVoucherReceivedItems = listener
  },
  setListinerVoucherItems (state, listener) {
    state.listinerVoucherItems = listener
  },
  setListinerVouchersReceived (state, listener) {
    state.listinerVouchersReceived = listener
  },
  setListinerVouchers (state, listener) {
    state.listinerVouchers = listener
  },
  setVouchers (state, payload) {
    if (!payload) state.listinerVouchers()
    state.vouchers = payload
    state.loadingVouchers = false
  },
  setVoucherReceivedItems (state, payload) {
    if (!payload) state.listinerVoucherReceivedItems()
    state.voucherReceivedItems = payload
  },
  setVoucherItems (state, payload) {
    if (!payload) state.listinerVoucherItems()
    state.voucherItems = payload
  },
  setVouchersReceived (state, payload) {
    if (!payload) state.listinerVouchersReceived()
    state.vouchersReceived = payload
  },
  updateVouchers (state, payload) {
    console.log('updateVouchers')
    state.loadingVouchers = false
    state.vouchers = payload.vouchers
    state.vouchersReceived = payload.vouchersReceived
    state.vouchersReceivedPending = payload.vouchersReceivedPending
    state.vouchersReceivedClassified = payload.vouchersReceivedClassified
    state.voucherItems = payload.voucherItems
    state.voucherReceivedItems = payload.voucherReceivedItems
    state.vouchersReceivedPendingItems = payload.vouchersReceivedPendingItems
    state.voucherSalesItems = payload.voucherSalesItems
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
