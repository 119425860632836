<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Factura recurrente N° {{ id }}
    </div>
    <v-row class="ma-auto">
      <v-btn
        v-for="(action, i) in actions" @click="action.callback" :key="i"
        class="ma-2"
      >
        <v-icon left>
          {{action.icon}}
        </v-icon>
        {{action.text}}
      </v-btn>
    </v-row>
    <v-card
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Monto
        </div>
        <div class="title text-center">
          {{ subscription.summary.netTotal }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Total Emitido
        </div>
        <div class="title text-center">
          {{ subscription.totalIssued }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Pagado
        </div>
        <div class="title success--text text-center">
          {{ subscription.totalIssued - subscription.amountOutstanding }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5 pr-11">
        <div class="text-center caption">
          Por Cobrar
        </div>
        <div class="title error--text text-center">
          {{ subscription.amountOutstanding }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
      <v-tabs v-model="tab">
        <v-tab>
          Información General
        </v-tab>
        <v-tab>
          Comprobantes Emitidos
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row  class="ma-6">
            <v-col cols="12" sm="6">
              <v-card-title>
                {{ getCompany.tradename || getCompany.name }}
              </v-card-title>
              <v-card-subtitle>
                <div  v-if="getCompany.tradename">{{ getCompany.name }}</div>
                <div>{{ getCompany.identification }}</div>
                <div>{{ getCompany.email }}</div>
                <div>{{ getCompany.phone }}</div>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card-title :class="[subscription.active ? 'success--text' : 'error--text']">
                {{ subscription.active ? 'activa'.toUpperCase() : 'deshabilitada'.toUpperCase() }}
              </v-card-title></v-col>
          </v-row>
          <v-simple-table dense class="ma-6">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Código de la Actividad Económica </th>
                  <td>{{ subscription.activityCode }}</td>
                  <th>Frecuencia</th>
                  <td>{{ subscription.frequency }} {{ subscription.frequency > 1 ? 'Mesas' : 'Mes' }}</td>
                </tr>
                <tr>
                  <th>Fecha de Inicio</th>
                  <td>{{ subscription.dateString }}</td>
                  <th>Fecha de Finalización</th>
                  <td>{{ subscription.period || 'Sin definir' }}</td>
                </tr>
                <tr>
                  <th v-if="subscription.receiver">Cliente</th>
                  <td v-if="subscription.receiver">
                    <router-link :to="'/customer/' + subscription.receiver.identification">{{ subscription.receiver.name }}</router-link>
                  </td>
                  <th>Condiciones de la venta</th>
                  <td>{{ conditions[subscription.condition] }}</td>
                </tr>
                <tr v-if="subscription.condition === '02'">
                  <th>Plazo del crédito (en días)</th>
                  <td>{{ subscription.creditTerm }}</td>
                  <th>Fecha de vencimiento</th>
                  <td>{{ moment(subscription.date).add(Number(subscription.creditTerm), 'days').calendar() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-data-table
            dense
            :headers="headers"
            :items="subscription.items"
            :items-per-page="subscription.items.length"
            hide-default-footer
            class="elevation-1 ma-6"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Detalle del Servicio, Mercancía u otro</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-row class="ma-6">
            <v-col cols="12" sm="6" style="height: 200px;">
              <div v-if="subscription.user">Creada por {{ subscription.user.name || subscription.user }}</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item v-for="({ text, value }, i) in summary" :key="i">
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="text"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-list-item-title v-text="value" class="text-right"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="'Total Factura Recurrente'"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-list-item-title v-text="subscription.summary.netTotal" class="text-right py-2 headline"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <SubscriptionVouchers :vouchers="subscription.vouchers"></SubscriptionVouchers>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import { /* mapState, /* */ mapActions, mapGetters } from 'vuex'
import { conditions } from '@/assets/codes'
import SubscriptionVouchers from '@/components/SubscriptionVouchers'
import vex from 'vex-js'
// import vexDialog from 'vex-dialog'

// Main css
import 'vex-js/dist/css/vex.css'

// Themes (Import all themes you want to use here)
import 'vex-js/dist/css/vex-theme-default.css'
import 'vex-js/dist/css/vex-theme-os.css'

// Options
vex.defaultOptions.className = 'vex-theme-default'

// Register vex-dialog
// vex.registerPlugin(vexDialog)
// const smalltalk = require('smalltalk');

export default {
  name: 'Subscription',
  props: ['id'],
  components: {
    SubscriptionVouchers
  },
  data: () => ({
    show: false,
    tab: 0,
    types: {
      '01': 'Factura electrónica',
      '02': 'Nota de débito electrónica',
      '03': 'Nota de crédito electrónica',
      '04': 'Tiquete electrónico',
      '08': 'Factura electrónica de compras',
      '09': 'Factura electrónica de exportación',
      10: 'Tiquete de venta'
    },
    typesBool: {
      '01': true,
      '02': false,
      '03': false,
      '04': true,
      '05': false,
      '06': false,
      '07': false,
      '08': false,
      '09': true,
      10: true
    },
    conditions,
    moment,
    headers: [
      { text: 'N°', value: 'line' },
      { text: 'Código', value: 'commercialCode' },
      { text: 'CABYS', value: 'code' },
      { text: 'Cantidad', value: 'quantity' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Detalle', value: 'description' },
      { text: 'Precio', value: 'unit_price' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: 'Impuestos', value: 'tax_net' },
      { text: 'Total', value: 'net_total' }
    ]
  }),
  methods: {
    ...mapActions('subscriptions', ['enableDisable']),
    ...mapActions('message', ['showSuccess', 'showError'])
  },
  computed: {
    actions () {
      const actions = [{
        text: `${this.subscription.active ? 'Deshabilitar' : 'Habilitar'}`,
        callback: () => {
          vex.dialog.confirm({
            message: `¿Desea ${this.subscription.active ? 'deshabilitar' : 'habilitar'} facturación recurrente?`,
            callback: (data) => {
              if (!data) {
                return
              }
              this.enableDisable({ id: this.subscription.id, active: !this.subscription.active })
                .catch((error) => {
                  console.error(error)
                  this.showError(`No es posible ${this.subscription.active ? 'deshabilitar' : 'habilitar'}, intente más tarde`)
                })
            }
          })
        },
        icon: this.subscription.active ? 'mdi-file-cancel' : 'mdi-file-check'
      }]
      return actions.filter(({ hidden }) => !hidden)
    },
    subscription () {
      return this.getSubscriptions.find((vou) => vou.id === Number(this.id))
    },
    summary () {
      const { summary } = this.subscription
      return [
        { text: 'Venta Neta', value: summary.subtotal },
        { text: 'Total Descuentos', value: summary.discountTotal },
        { text: 'Subtotal', value: summary.subtotal - summary.discountTotal },
        { text: 'Total Impuestos', value: summary.taxTotal }
      ]
    },
    ...mapGetters('subscriptions', ['getSubscriptions']),
    ...mapGetters('vouchers', ['getVouchers', 'getVouchersReceived', 'nextND', 'nextNC']),
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
