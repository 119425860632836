// import tools from "@/api/tools.js"
const priceList = {
  namespaced: true,
  state: {
    prices: []
  },
  mutations: {
    setPrices (state, payload) {
      state.prices = payload
    },
    insertPrice (state, payload) {
      const index = state.prices.findIndex(item => { return item.priceList === payload.priceList })
      payload.price = Number(payload.price)
      if (index > -1) { state.prices.splice(index, 1, payload) } else { state.prices.push(payload) }
    },
    deletePrice (state, index) {
      state.prices.splice(index, 1)
    },
    cleanPrices (state) {
      state.prices = []
    }
  },
  actions: {
    setPrices ({ commit }, prices) {
      return new Promise((resolve, reject) => {
        try {
          commit('setPrices', prices)
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    insertPrice ({ commit }, price) {
      return new Promise((resolve, reject) => {
        try {
          commit('insertPrice', price)
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    deletePrice ({ commit, getters }, price) {
      return new Promise((resolve, reject) => {
        const index = getters.getPrices.findIndex(item => { return item.priceList === price.priceList })
        if (index > -1) { commit('deletePrice', index) } else { reject(new Error('El precio no existe')) }
      })
    },
    cleanPrices ({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('cleanPrices')
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }
  },
  getters: {
    getPrices (state) {
      return state.prices
    }
  }
}

export default priceList
