/* eslint quote-props: ["error", "as-needed", { "numbers": true }] */
import moment from 'moment'
import tools from '@/api/tools.js'

const accounting = {
  summarize ({ journal, fromDate, toDate, initialBalance }) {
    const from = Number(fromDate) || 0
    const to = Number(toDate) || Number(moment().format('x'))
    const ledger = []
    const transactions = journal.filter((entry) => entry.date >= from && entry.date < to)

    /* transactions.forEach((trans) => {
      let saldo = 0
      trans.accounts.forEach(({ debit, credit }) => {
        saldo += debit
        saldo -= credit
      })
      if (saldo !== 0) console.log(trans.num, saldo)
    }) */

    let previousBalances = []
    if (from) {
      previousBalances = this.summarize({
        initialBalance,
        journal,
        toDate: from
      })
    } else {
      previousBalances = initialBalance || []
    }
    previousBalances.forEach((balance) => {
      ledger.push({
        code: balance.code,
        name: balance.name,
        previousBalance: balance.balance,
        debit: 0,
        credit: 0,
        balance: balance.balance
      })
    })
    transactions.forEach((entry) => {
      entry.accounts.forEach((account) => {
        const index = ledger.findIndex((item) => item.code === account.code)
        const prevBalance = previousBalances.find(
          (balance) => (balance.code === account.code)
        ) || { balance: 0 }
        if (index === -1) {
          ledger.push({
            code: account.code,
            name: account.name,
            previousBalance: prevBalance.balance,
            debit: account.debit,
            credit: account.credit,
            balance: /^[15]/.test(account.code)
              ? (account.debit - account.credit) + prevBalance.balance
              : (account.credit - account.debit) + prevBalance.balance
          })
        } else {
          ledger[index].debit += account.debit
          ledger[index].credit += account.credit
          ledger[index].balance = /^[15]/.test(account.code)
            ? (ledger[index].debit - ledger[index].credit) + prevBalance.balance
            : (ledger[index].credit - ledger[index].debit) + prevBalance.balance
        }
      })
    })
    return ledger
  },
  check (ledger) {
    const trialBalance = {
      debit: 0, credit: 0, difference: 0, reprobate: false
    }
    ledger.forEach((account) => {
      trialBalance.debit += account.debit
      trialBalance.credit += account.credit
    })
    trialBalance.difference = tools.round10(trialBalance.debit - trialBalance.credit, -5)
    if (trialBalance.difference !== 0) {
      trialBalance.reprobate = true
    }
    return trialBalance
  },
  getAccountBalance ({ initialBalance, journal, account, from, to }) {
    const fromDate = from || 0
    const toDate = to || Number(moment().format('x'))
    const entries = journal.filter(({ accounts }) => accounts.find((entry) => entry.code.startsWith(account)))
    const entriesFiltered = entries.filter(({ date }) => fromDate < date && date < toDate)
    const ledger = this.summarize({
      initialBalance,
      journal: entries,
      fromDate,
      toDate
    })
    const balances = this.getSummarizeBalance({ ledger, code: account })
    return {
      entries: entriesFiltered,
      ledger,
      balances
    }
  },
  getSummarizeBalance ({ ledger, code }) {
    // const name = ledger.find((account) => account.code === code)?.name;
    const accounts = ledger.filter((account) => account.code.substring(0, code.length) === code)
    const balance = {
      prevBalance: accounts.reduce((acc, entry) => acc + entry.previousBalance, 0),
      debit: accounts.reduce((acc, entry) => acc + entry.debit, 0),
      credit: accounts.reduce((acc, entry) => acc + entry.credit, 0),
      balance: accounts.reduce((acc, entry) => acc + entry.balance, 0)
    }
    return balance
  },
  getCashSummaryAccount ({ initialBalance, entries, account, cashAndCashEquivalents, fromDate, toDate }) {
    const summary = this.getAccountBalance({
      initialBalance,
      journal: entries,
      from: fromDate,
      to: toDate,
      account
    })
    return this.getAccountBalance({
      initialBalance,
      journal: summary.entries,
      from: fromDate,
      to: toDate,
      account: cashAndCashEquivalents
    })
  },
  genIncomeStatement (accounts) {
    const income = accounts.find(({ code }) => code === '4')
    const expenses = accounts.find(({ code }) => code === '5')
    const revenue = income.children.find(({ code }) => code === '4.01').balances.credit
    const costOfSales = expenses.children.find(({ code }) => code === '5.01').balances.debit
    const grossProfit = Number(revenue) - Number(costOfSales)
    const otherIncome = income.children.find(({ code }) => code === '4.03').balances.credit
    const distributionCosts = expenses.children.find(({ code }) => code === '5.02').balances.debit
    const administrativeExpense = expenses.children.find(({ code }) => code === '5.03').balances.debit
    const otherExpenseByFunction = expenses.children.find(({ code }) => code === '5.04').balances.debit
    const otherGainsLosses = 0
    const financeIncome = income.children.find(({ code }) => code === '4.02').balances.credit
    const financeCosts = expenses.children.find(({ code }) => code === '5.05').balances.debit
    const shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod = 0
    const profitLossBeforeTax = grossProfit +
      otherIncome +
      otherGainsLosses -
      distributionCosts -
      administrativeExpense -
      otherExpenseByFunction +
      financeIncome -
      financeCosts
    const incomeTaxExpenseContinuingOperations = 0
    const profitLossFromContinuingOperations = Number(profitLossBeforeTax) -
      Number(incomeTaxExpenseContinuingOperations)
    const profitLossFromDiscontinuedOperations = 0
    const profitLoss = Number(profitLossFromContinuingOperations) +
    Number(profitLossFromDiscontinuedOperations)
    const items = [
      { text: 'Ingresos de actividades ordinarias', value: revenue },
      { text: 'Costo de ventas', value: costOfSales },
      { text: 'Ganancia bruta', value: grossProfit },
      { text: 'Otros ingresos', value: otherIncome },
      { text: 'Costos de distribución', value: distributionCosts },
      { text: 'Gastos de administración', value: administrativeExpense },
      { text: 'Otros gastos, por función', value: otherExpenseByFunction },
      { text: 'Otras ganancias (pérdidas)', value: otherGainsLosses },
      { text: 'Ingresos financieros', value: financeIncome },
      { text: 'Costos financieros', value: financeCosts },
      { text: 'Participación en las ganancias (pérdidas) de asociadas y negocios conjuntos que se contabilicen utilizando el método de la participación', value: shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod },
      { text: 'Ganancia (pérdida), antes de impuestos', value: profitLossBeforeTax },
      { text: 'Gasto (ingreso) por impuestos, operaciones continuadas', value: incomeTaxExpenseContinuingOperations },
      { text: 'Ganancia (pérdida) procedente de operaciones continuadas', value: profitLossFromContinuingOperations },
      { text: 'Ganancia (pérdida) procedente de operaciones discontinuadas', value: profitLossFromDiscontinuedOperations },
      { text: 'Ganancia (pérdida)', value: profitLoss }
    ]
    const incomeStatement = {
      items,
      revenue,
      costOfSales,
      grossProfit,
      otherIncome,
      distributionCosts,
      administrativeExpense,
      otherExpenseByFunction,
      otherGainsLosses,
      financeIncome,
      financeCosts,
      shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod,
      profitLossBeforeTax,
      incomeTaxExpenseContinuingOperations,
      profitLossFromContinuingOperations,
      profitLossFromDiscontinuedOperations,
      profitLoss
    }
    return incomeStatement
    /*  */
  },
  genStatementFinancialPosition (accounts) {
    const statementFinancialPosition = []
    const equityAndLiabilities = []
    let equityAndLiabilitiesValue = 0
    const abstract = {}
    accounts.forEach((account, i) => {
      if (Number(account.code) < 4) {
        if (account.code === '1') {
          const items = []
          account.children.forEach((child, j) => {
            const items2 = []
            child.children.forEach((child2, k) => {
              items2.push({ text: child2.name, value: child2.balances.balance })
            })
            if (child.code === '1.01') abstract.noncurrentAssets = { text: child.name, value: child.balances.balance }
            if (child.code === '1.02') abstract.currentAssets = { text: child.name, value: child.balances.balance }
            items.push({ text: child.name, value: child.balances.balance, items: items2 })
          })
          abstract.asset = { text: account.name, value: account.balances.balance }
          statementFinancialPosition.push({ text: account.name, value: account.balances.balance, items })
        } else {
          const items = []
          equityAndLiabilitiesValue += account.balances.balance
          if (account.children.length > 0) {
            account.children.forEach((child, j) => {
              const items2 = []
              child.children.forEach((child2, k) => {
                items2.push({ text: child2.name, value: child2.balances.balance })
              })
              if (child.code === '2.01') abstract.noncurrentLiabilities = { text: child.name, value: child.balances.balance }
              if (child.code === '2.02') abstract.currentLiabilities = { text: child.name, value: child.balances.balance }
              items.push({ text: child.name, value: child.balances.balance, items: items2 })
            })
          } else {
            account.children.forEach((child, j) => {
              items.push({ text: child.name, value: child.balances.balance })
            })
          }
          if (account.code === '2') {
            abstract.liabilities = { text: account.name, value: account.balances.balance }
            equityAndLiabilities.push({
              text: account.name,
              value: account.balances.balance,
              items
            })
          } else {
            abstract.equity = { text: account.name, value: account.balances.balance }
            equityAndLiabilities.push({
              text: account.name,
              value: account.balances.balance,
              items
            })
          }
        }
      }
    })
    statementFinancialPosition.push({ text: 'Patrimonio y pasivos', value: equityAndLiabilitiesValue, items: equityAndLiabilities })
    return {
      items: statementFinancialPosition,
      asset: abstract.asset,
      noncurrentAssets: abstract.noncurrentAssets,
      currentAssets: abstract.currentAssets,
      liabilities: abstract.liabilities,
      noncurrentLiabilities: abstract.noncurrentLiabilities,
      currentLiabilities: abstract.currentLiabilities,
      equity: abstract.equity
    }
  },
  genStatementOfCashFlows (accounts, cashAndCashEquivalents) {
    const types = {
      '1.01': 2,
      '1.02': 1,
      '2.01': 3,
      '2.02': 1,
      '3': 3,
      '4': 1,
      '5': 1
    }
    const cash = accounts.find(({ code }) => code === cashAndCashEquivalents)
    const transactions = []
    const initialCashBalance = cash.balances.prevBalance
    let cashBalance = cash.balances.prevBalance
    const increaseDecreaseInCashAndCashEquivalents = cash.balances.balance - cash.balances.prevBalance
    cash.entries.forEach(({ accounts }, i) => {
      accounts.forEach(({ code, debit, credit, name }, j) => {
        if (code.substring(0, cashAndCashEquivalents.length) !== cashAndCashEquivalents) {
          const value = credit - debit
          transactions.push({
            code,
            value,
            name,
            type: types[code.substring(0, (Number(code[0]) > 2 ? 1 : 4))]
          })
          cashBalance += value
        }
      })
    })

    const cashFlowsFromUsedInOperatingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 1 ? trans.value : 0), 0)
    const cashFlowsFromUsedInInvestingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 2 ? trans.value : 0), 0)
    const cashFlowsFromUsedInFinancingActivities = transactions.reduce((acc, trans) => acc + (trans.type === 3 ? trans.value : 0), 0)

    const items = [
      {
        text: 'Flujos de efectivo procedentes de (utilizados en) actividades de operación',
        value: transactions.reduce((acc, trans) => acc + (trans.type === 1 ? trans.value : 0), 0),
        items: [
          {
            text: 'Cobros procedentes de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value > 0 ? value : 0), 0),
            items: [
              { text: 'Cuentas comerciales por cobrar y otras cuentas por cobrar corrientes', value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value > 0 ? value : 0), 0) }
            ]
            /* items: transactions.map(({ name, value, type }, i) => {
              if (type === 1 && value > 0) return { text: name, value }
            }).filter((element) => element) */
          },
          {
            text: 'Pagos por concepto de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 1 && value < 0 ? value : 0), 0),
            items: transactions.map(({ name, value, type }, i) => {
              if (type === 1 && value < 0) return { text: name, value }
            }).filter((element) => element)
          }
        ]
      },
      {
        text: 'Flujos de efectivo procedentes de (utilizados en) actividades de inversión',
        value: transactions.reduce((acc, trans) => acc + (trans.type === 2 ? trans.value : 0), 0),
        items: [
          {
            text: 'Importes por venta o cobro procedentes de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 2 && value > 0 ? value : 0), 0),
            items: transactions.map(({ name, value, type }, i) => {
              if (type === 2 && value > 0) return { text: name, value }
            }).filter((element) => element)
          },
          {
            text: 'Erogaciones por compra o por pago de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 2 && value < 0 ? value : 0), 0),
            items: transactions.map(({ name, value, type }, i) => {
              if (type === 2 && value < 0) return { text: name, value }
            }).filter((element) => element)
          }
        ]
      },
      {
        text: 'Flujos de efectivo procedentes de (utilizados en) actividades de financiación',
        value: transactions.reduce((acc, trans) => acc + (trans.type === 3 ? trans.value : 0), 0),
        items: [
          {
            text: 'Importes procedentes de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 3 && value > 0 ? value : 0), 0),
            items: transactions.map(({ name, value, type }, i) => {
              if (type === 3 && value > 0) return { text: name, value }
            }).filter((element) => element)
          },
          {
            text: 'Pagos por concepto de',
            value: transactions.reduce((acc, { type, value }) => acc + (type === 3 && value < 0 ? value : 0), 0),
            items: transactions.map(({ name, value, type }, i) => {
              if (type === 3 && value < 0) return { text: name, value }
            }).filter((element) => element)
          }
        ]
      },
      { text: 'Incremento (disminución) neto de efectivo y equivalentes al efectivo', value: increaseDecreaseInCashAndCashEquivalents },
      { text: 'Efectivo y equivalentes al efectivo al principio del periodo', value: initialCashBalance },
      { text: 'Efectivo y equivalentes al efectivo al final del periodo', value: cashBalance }
    ]

    const statementOfCashFlows = {
      items,
      cash,
      initialCashBalance,
      cashBalance,
      increaseDecreaseInCashAndCashEquivalents,
      cashFlowsFromUsedInOperatingActivities,
      cashFlowsFromUsedInInvestingActivities,
      cashFlowsFromUsedInFinancingActivities
    }
    return statementOfCashFlows
  },
  genStatementOfChangesInEquity (accounts) {
    const equity = accounts.find(({ code }) => code === '3')
    const items = [{
      text: equity.name,
      initBalance: equity.balances.prevBalance,
      debits: equity.balances.debit,
      credits: equity.balances.credit,
      balance: equity.balances.balance,
      items: accounts.filter(({ code }) => code[0] === '3' && code.length === 4)
        .map(({ balances, name, code }) => ({
          code,
          text: name,
          initBalance: balances.prevBalance,
          debits: balances.debit,
          credits: balances.credit,
          balance: balances.balance
        })).sort(function (a, b) {
          if (a.code > b.code) {
            return 1
          }
          if (a.code < b.code) {
            return -1
          }
          return 0
        })
    }]
    return { items, equity }
  },
  getAllLedgers (getLedger, getAccountsCatalog) {
    const ledger = []
    for (let index = 0; index < getAccountsCatalog.length; index++) {
      const account = getAccountsCatalog[index]
      const accounts = getLedger.filter(({ code }) => code.startsWith(account.code))
      const balances = accounts.reduce((acc, curr) => {
        try {
          acc.previousBalance += Number(curr.previousBalance)
          acc.debit += Number(curr.debit)
          acc.credit += Number(curr.credit)
          acc.balance += Number(curr.balance)
        } catch (error) {
          console.log({ ...acc })
          throw error
        }
        return acc
      }, {
        previousBalance: 0,
        debit: 0,
        credit: 0,
        balance: 0
      })
      ledger.push({ ...account, balances })
    }
    return ledger
  },
  accountsDefault: [
    {
      id: 1,
      code: '1',
      name: 'Activos',
      description: '',
      subaccounts: [],
      editable: false
    },
    {
      id: 2,
      code: '2',
      name: 'Pasivos',
      description: '',
      subaccounts: [],
      editable: false
    },
    {
      id: 3,
      code: '3',
      name: 'Patrimonio',
      description: '',
      subaccounts: [],
      editable: false
    },
    {
      id: 4,
      code: '4',
      name: 'Ingresos',
      description: '',
      subaccounts: [],
      editable: false
    },
    {
      id: 5,
      code: '5',
      name: 'Egresos',
      description: '',
      subaccounts: [],
      editable: false
    }
  ]
}

export default accounting
