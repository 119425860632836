/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment';
// import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools'
import redaApp from '@/api/redaApp'
import firebaseApi from '../../api/firebaseApi'

const { api } = firebaseApi

// initial state
const initialState = {
  products: [],
  listinerProducts: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getProducts (state, getters, rootState, rootGetters) {
    const products = []
    state.products.forEach((element) => {
      // const salesItems = rootGetters['vouchers/getVoucherItems']
      //   .filter(({ commercialCode }) => commercialCode === element.commercialCode)
      // const purchasingItems = rootGetters['vouchers/getVoucherReceivedItems']
      //   .filter(({ commercialCode, product }) => commercialCode === element.commercialCode || product === element.commercialCode)
      // const summary = {
      //   quantity: tools.round10(salesItems.reduce((acc, item) => Number(item.quantity) + acc, 0), -5),
      //   taxNet: tools.round10(salesItems.reduce((acc, item) => Number(item.tax_net) + acc, 0), -5),
      //   subtotal: tools.round10(salesItems.reduce((acc, item) => Number(item.subtotal) + acc, 0), -5),
      //   netTotal: tools.round10(salesItems.reduce((acc, item) => Number(item.net_total) + acc, 0, -5))
      // }
      const inventories = rootGetters['inventories/getInventories']
        .filter(({ product }) => product === element.commercialCode)
      const unitPrice = element.prices[0].price
      const stocks = rootGetters['inventories/getCellars'].map((cellar) => {
        let entries = 0
        let exits = 0
        let returnSales = 0
        let totalValue = 0
        inventories.forEach((mov) => {
          if (mov.entry === cellar.code) {
            entries += mov.quantity
            if (mov.detail === 'DEVOLUCIÓN DE VENTA') { returnSales += mov.quantity } else { totalValue += (mov.cost * mov.quantity) }
          }
          if (mov.exit === cellar.code) exits += mov.quantity
        })
        const stock = entries - exits
        const exitWithoutReturns = entries - returnSales
        const unitCost = exitWithoutReturns === 0 ? 0 : totalValue / exitWithoutReturns
        const currentvalue = unitCost * stock
        return { code: cellar.code, cellar, entries, exits, stock, totalValue, currentvalue, unitCost }
      })
      products.push({ ...element, unitPrice, inventories, stocks })
    })
    return products
  },
  getInventoryable (state, getters, rootState, rootGetters) {
    return state.products.filter(({ inventoryable }) => inventoryable).map(({ commercialCode }) => commercialCode)
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
    //   const productsQuerySnapshot = await fbApi.db.collection('products')
    //     .where('company', '==', company)
    //     .get()
    //   const products = []
    //   productsQuerySnapshot.forEach((doc) => products.push({ uid: doc.id, ...doc.data() }))
    //   commit('setProducts', products)
    //   const listinerProducts = productsQuerySnapshot.query.onSnapshot((querySnapshot) => {
    //     const newProducts = []
    //     querySnapshot.forEach((doc) => newProducts.push({ uid: doc.id, ...doc.data() }))
    //     commit('setProducts', newProducts)
    //   })
    //  commit('setListinerProducts', listinerProducts)
      commit('setProducts', [])
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recibidos')
    }
  },
  insertProduct ({ commit, rootGetters, getters }, payload) {
    return new Promise((resolve, reject) => {
      redaApp.submitRedaApp('insert-product', { ...payload, company: rootGetters['company/getCompany'].identification })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error))
    })
  },
  deleteProduct ({ commit }, product) {
    return new Promise((resolve, reject) => {
      redaApp.deleteProduct(product.commercialCode)
        .then((msj) => {
          resolve(msj)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getProduct ({ state, commit, dispatch, getters, rootGetters }, id) {
    const company = rootGetters['company/getCompany']?.identification
    return api.getItem('products', id, 'commercialCode', company)
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerProducts) {
      state.listinerProducts()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerProducts (state, listener) {
    state.listinerProducts = listener
  },
  setProducts (state, products) {
    if (!products) state.listinerProducts()
    state.products = products
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
