<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Estados financieros</v-tab>
      <v-tab>Indicadores financieros</v-tab>
    </v-tabs>
    <Period></Period>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <FinancialStatements />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <FinancialReasons />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
import FinancialStatements from '../components/FinancialStatements'
import Period from '../components/Period'
import FinancialReasons from '../components/FinancialReasons.vue'

export default {
  name: 'FinancialReporting',

  data: () => ({
    tab: 0
  }),

  components: {
    FinancialStatements,
    FinancialReasons,
    Period
  },

  computed: {
    ...mapGetters([])
  },

  methods: {
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>
