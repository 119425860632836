/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product", "doc", querySnapshot, cashDaily, company, customers, inventories, invoice, message, messages, orders, priceList, products, user] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import cashDaily from './modules/cashDaily'
import company from './modules/company'
import customers from './modules/customers'
import inventories from './modules/inventories'
import invoice from './modules/invoice'
import message from './modules/message'
import messages from './modules/messages'
import orders from './modules/orders'
import priceList from './modules/priceList'
import products from './modules/products'
import vouchers from './modules/vouchers'
import user from './modules/user'
import accountReceivable from './modules/accountReceivable'
import payments from './modules/payments'
import stays from './modules/stays'
import posts from './modules/posts'
import accounting from './modules/accounting'
import siinco from './modules/siinco'
import fixedAssets from './modules/fixedAssets'
import subscriptions from './modules/subscriptions'
import chats from './modules/chats'
import { auth } from '@/api/firebaseApi.js'
import apiCE from '@/api/apiCE'
// import router from '@/router'

const year = moment().year()

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingApp: true,
    from: Number(moment({ year, month: 0, day: 1, hour: 0, minute: 0, second: 0 }).format('x')),
    to: Number(moment().hour(23).minute(59).format('x')),
    CRC: 1,
    USD: 1,
    USDHistory: []
  },
  getters: {
    getFrom (state) {
      return state.from
    },
    getTo (state) {
      return state.to
    },
    getExchangeRateUSD: (state) => {
      return state.USD
    },
    getExchange: (state) => {
      return { USD: state.USD, CRC: state.CRC }
    }
  },
  mutations: {
    setFrom (state, payload) {
      state.from = payload
    },
    setTo (state, payload) {
      state.to = payload
    },
    setUSD (state, payload) {
      state.USD = payload
    },
    setUSDHistory (state, payload) {
      state.USDHistory = payload
    },
    setLoadingApp (state, payload) {
      state.loadingApp = payload
    }
  },
  actions: {
    /* initApp: function ({ commit, state, dispatch, getters }) {
      apiCE.getDollarExcachange().then(({ sell }) => commit('setUSD', sell))
        .catch((error) => console.error(error))
      auth.onAuthStateChanged((userObj) => {
        commit('setLoadingApp', true)
        // dispatch('user/setUser', userObj);
        if (userObj) {
          db.collection('users').where('userUID', '==', userObj.uid)
            .onSnapshot((querySnapshot) => {
              const newUser = {}
              querySnapshot.forEach((doc) => {
                newUser.logged = true
                newUser.name = doc.data().name
                newUser.displayName = userObj.displayName
                newUser.email = userObj.email
                newUser.emailVerified = userObj.emailVerified
                newUser.photoURL = userObj.photoURL
                newUser.isAnonymous = userObj.isAnonymous
                newUser.uid = userObj.uid
                newUser.providerData = userObj.providerData
                newUser.authorizations = doc.data().authorizations
                newUser.active = doc.data().active
                newUser.currentCompany = doc.data().currentCompany
                newUser.companies = doc.data().companies
              })
              dispatch('user/setUser', newUser)
                .then(() => {
                  commit('company/setCompany', newUser.currentCompany)
                  const currentCompany = newUser.companies.find(company => { return company.id === newUser.currentCompany })
                  commit('company/setBranch', currentCompany.defaultBranch)

                  newUser.companies.forEach((company) => {
                    db.collection('companies').where('identification', '==', company.id).onSnapshot((querySnapshot) => {
                      querySnapshot.forEach(function (doc) {
                        const company = doc.data()
                        commit('company/setCompanies', company)
                      })
                    })
                  })

                  db.collection('apiVouchers').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    if (querySnapshot.empty) {
                      commit('company/setApiVoucher', { stag: false, prod: false })
                      numberPos.forEach((num) => {
                        commit(`${num}/setVoucherType`, '10')
                      })
                    } else {
                      querySnapshot.forEach((doc) => {
                        const apiVoucher = doc.data()
                        commit('company/setApiVoucher', { stag: !!apiVoucher.stag, prod: !!apiVoucher.prod })
                        numberPos.forEach((num) => {
                          commit(`${num}/setVoucherType`, '01')
                        })
                      })
                    }
                  })

                  db.collection('subscriptions').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const auxSubscriptions = []
                    querySnapshot.forEach(function (doc) {
                      auxSubscriptions.push(doc.data())
                    })
                    commit('subscriptions/setSubscriptions', auxSubscriptions)
                  })

                  db.collection('chats').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const auxChats = []
                    querySnapshot.forEach(function (doc) {
                      auxChats.push(doc.data())
                    })
                    commit('chats/setChats', auxChats)
                  })

                  db.collection('stays').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const auxStays = []
                    querySnapshot.forEach(function (doc) {
                      auxStays.push(doc.data())
                    })
                    commit('stays/setStays', auxStays)
                    commit('stays/setCurrentStay', currentCompany.defaultStay)
                  })

                  db.collection('costCenters').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const ccs = []
                    querySnapshot.forEach(function (doc) {
                      ccs.push(doc.data())
                    })
                    commit('siinco/setCostCenters', ccs)
                  })

                  db.collection('users').onSnapshot((querySnapshotCustomers) => {
                    const users = []
                    querySnapshotCustomers.forEach(function (doc) {
                      const { companies } = doc.data()
                      companies.forEach((item, i) => {
                        if (item.id === newUser.currentCompany) users.push(doc.data())
                      })
                    })
                    commit('user/setUsers', users)
                  })

                  db.collection('customers')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshotCustomers) => {
                      const cust = []
                      querySnapshotCustomers.forEach(function (doc) {
                        cust.push(doc.data())
                      })
                      commit('customers/setCustomers', cust)
                    })

                  db.collection('products')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshotProducts) => {
                      const productsTmp = []
                      querySnapshotProducts.forEach(function (product) {
                        productsTmp.push(product.data())
                      })
                      commit('products/setProducts', productsTmp)
                    })

                  db.collection('cellars').where('company', '==', currentCompany.id).onSnapshot((querySnapshot) => {
                    const cellars = []
                    querySnapshot.forEach(function (cellar) {
                      cellars.push(cellar.data())
                    })
                    commit('inventories/setCellars', cellars)
                  })

                  db.collection('inventories').where('company', '==', currentCompany.id).onSnapshot((querySnapshot) => {
                    const inventories = []
                    querySnapshot.forEach(function (inventory) {
                      inventories.push(inventory.data())
                    })
                    commit('inventories/setInventories', inventories)
                  })

                  db.collection('inventoryAliases').where('company', '==', currentCompany.id).onSnapshot((querySnapshot) => {
                    const aliases = []
                    querySnapshot.forEach(function (alias) {
                      aliases.push(alias.data())
                    })
                    commit('inventories/setInventoryAliases', aliases)
                  })

                  db.collection('addressingOrders').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const aos = []
                    querySnapshot.forEach(function (doc) {
                      aos.push(doc.data())
                    })
                    commit('siinco/setAddressingOrders', aos)
                  })

                  db.collection('addressingOrdersAliases').where('company', '==', newUser.currentCompany).onSnapshot((querySnapshot) => {
                    const aoa = []
                    querySnapshot.forEach(function (doc) {
                      aoa.push(doc.data())
                    })
                    commit('siinco/setAddressingOrdersAliases', aoa)
                  })

                  db.collection('fixedAssets')
                    .where('company', '==', newUser.currentCompany)
                    .onSnapshot((querySnapshot) => {
                      const items = []
                      querySnapshot.forEach(function (doc) {
                        items.push(doc.data())
                      })
                      commit('fixedAssets/setFixedAssets', items)
                    })
                  /*  db.collection('orders').onSnapshot((querySnapshotProducts) => {
                    const ordersTmp = []
                    querySnapshotProducts.forEach(function (order) {
                      ordersTmp.push(order.data())
                    })
                    commit('orders/setOrders', ordersTmp)
                  }) /**//*

                  db.collection('vouchers')
                    .where('issuer', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const voucs = []
                      querySnapshot.forEach(function (voucher) {
                        if (voucher.data().data.documentType !== '08') { voucs.push(voucher.data()) }
                      })
                      commit('vouchers/setVouchers', voucs)
                    })

                  db.collection('vouchers')
                    .where('receiver', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const voucs = []
                      querySnapshot.forEach(function (voucher) {
                        voucs.push(voucher.data())
                      })
                      commit('vouchers/setVouchersReceived', voucs)
                    })

                  db.collection('receipts')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const voucs = []
                      querySnapshot.forEach(function (voucher) {
                        voucs.push(voucher.data())
                      })
                      commit('accountReceivable/setReceipts', voucs)
                    })

                  db.collection('receipts')
                    .where('receiver', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const pays = []
                      querySnapshot.forEach(function (payment) {
                        pays.push(payment.data())
                      })
                      commit('payments/setPayments', pays)
                    })

                  db.collection('cashDaily')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const cashDaily = []
                      querySnapshot.forEach(function (cash) {
                        if (cash.data().user.userUID === newUser.userUID) {
                          cashDaily.push(cash.data())
                        }
                      })
                      commit('cashDaily/setCashDaily', cashDaily)
                    })

                  db.collection('messages').where('receiver', '==', currentCompany.id).onSnapshot((querySnapshot) => {
                    const messages = []
                    querySnapshot.forEach(function (message) {
                      messages.push(message.data())
                    })
                    commit('messages/setMessages', messages)
                  })/**//*

                  db.collection('accounts')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const accounts = []
                      querySnapshot.forEach((account) => {
                        accounts.push(account.data())
                      })
                      commit('accounting/setAccounts', accounts)
                    })

                  db.collection('journal')
                    .where('company', '==', currentCompany.id)
                    .onSnapshot((querySnapshot) => {
                      const entries = []
                      querySnapshot.forEach((doc) => {
                        entries.push(doc.data())
                      })
                      commit('accounting/setJournal', entries)
                    })

                  // router.push('/')
                  commit('setLoadingApp', false)
                })
                .catch((error) => {
                  console.error(error)
                })
            })
        } else {
          commit('setLoadingApp', true)
          commit('company/setCompanies', false)
          commit('company/setCompany', '')
          commit('chats/setChats', [])
          commit('messages/setMessages', [])
          commit('products/setProducts', [])
          commit('inventories/setCellars', [])
          commit('inventories/setInventories', [])
          commit('inventories/setInventoryAliases', [])
          // commit('orders/setOrders', false)
          commit('vouchers/setVouchers', [])
          commit('vouchers/setVouchersReceived', [])
          commit('cashDaily/setCashDaily', [])
          commit('stays/setStays', [])
          commit('stays/setCurrentStay', null) /**//*
          commit('accounting/setAccounts', [])
          commit('accounting/setJournal', [])
          commit('siinco/setCostCenters', [])
          commit('payments/setPayments', [])
          commit('siinco/setAddressingOrders', [])
          commit('siinco/setAddressingOrdersAliases', [])
          dispatch('user/setUser', userObj)
            .then(() => {
              commit('setLoadingApp', false)
            })
        }
      })
    }, /* */
    async initApp ({ commit, state, dispatch, getters }) {
      try {
        console.log(state.from, state.to)
        apiCE.getDollarExcachange().then(({ sell }) => commit('setUSD', sell))
          .catch((error) => console.error(error))
        auth.onAuthStateChanged(async (userObj) => {
          commit('setLoadingApp', true)
          if (userObj) {
            await dispatch('user/init', userObj)
          } else {
            await dispatch('user/reset')
          }
          commit('setLoadingApp', false)
        })
      } catch (error) {
        commit('setLoadingApp', false)
        console.error(error)
        dispatch('message/showError', 'No se pudo iniciar app')
      }
    }
  },
  modules: {
    accounting,
    cashDaily,
    chats,
    company,
    customers,
    inventories,
    invoice,
    message,
    messages,
    orders,
    priceList,
    products,
    stays,
    user,
    vouchers,
    payments,
    accountReceivable,
    siinco,
    fixedAssets,
    subscriptions,
    posts
  }
})
