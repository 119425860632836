/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools'
// import tools from '@/api/tools';

const { api, db } = fbApi

// initial state
const initialState = {
  payments: [],
  // accountReceivable: [],
  tmpPayment: {
    id: null,
    company: null,
    date: null,
    paymentType: '01',
    docs: [],
    amount: 0,
    currency: 'CRC',
    exchangeRate: 1,
    user: null,
    others: null
  },
  listinerPayments: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getOptions ({ tmpPayment }, getters, rootState, rootGetters) {
    return {
      headers: [
        { text: 'Fecha', value: 'date' },
        { text: 'N°', value: 'id' },
        { text: 'Método de pago', value: 'paymentType' },
        { text: 'Monto', value: 'amount' },
        { text: 'Registrado por', value: 'user.name' },
        { text: 'Detalle', value: 'others' },
        { text: '', value: 'action' }
      ],
      title: 'Pagos realizados',
      btnIcon: 'mdi-plus',
      btnColor: 'secondary',
      newItemText: 'Nuevo pago',
      editItemText: 'Editar pago',
      defaultItem: {
        ...tmpPayment,
        company: rootGetters['company/getCompany'].identification,
        date: moment().format('x'),
        user: rootGetters['user/getUser'].uid
      },
      form: {
        submitText: 'Registrar pago',
        cancelText: 'Cerrar',
        showSubmit: true,
        showCancel: true,
        inputs: [
          {
            id: 'de01',
            label: 'Número',
            name: 'id',
            value: 'id',
            type: 'text',
            readonly: true,
            hiden: true,
            cols: 12
          },
          {
            id: 'de01',
            label: 'Compañia',
            name: 'company',
            value: 'company',
            type: 'text',
            readonly: true,
            hiden: true,
            cols: 12
          },
          {
            id: 'de03',
            label: 'Fecha',
            name: 'date',
            value: 'date',
            type: 'date',
            rules: 'required',
            cols: 6
          },
          {
            id: 'de04',
            label: 'Método de pago',
            name: 'paymentType',
            value: 'paymentType',
            items: [
              { value: '01', text: 'Efectivo' },
              { value: '02', text: 'Tarjeta' },
              { value: '03', text: 'Cheque' },
              { value: '04', text: 'Transferencia' },
              { value: '99', text: 'Otros' }
            ],
            type: 'select',
            rules: 'required',
            cols: 6
          },
          {
            id: 'de05',
            label: 'Monto',
            name: 'amount',
            value: 'amount',
            type: 'number',
            rules: 'required',
            cols: 12
          },
          {
            id: 'de06',
            label: 'Detalle',
            name: 'others',
            value: 'others',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: 'de07',
            label: 'Usuario',
            name: 'user',
            value: 'user',
            type: 'text',
            readonly: true,
            hiden: true,
            cols: 12
          }
        ]
      }
    }
  },
  getPayments ({ payments }, getters, rootState, rootGetters) {
    const pays = []
    payments.forEach((item, i) => {
      const user = rootGetters['user/getUsers'].find(({ userUID }) => item.user === userUID)
      pays.push({ ...item, user })
    })
    return pays
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const paymentsQuerySnapshot = await fbApi.db.collection('payments')
        .where('company', '==', company)
        .get()
      const payments = []
      paymentsQuerySnapshot.forEach((doc) => payments.push({ uid: doc.id, ...doc.data() }))
      commit('setPayments', payments)
      const listinerPayments = paymentsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newPayments = []
        querySnapshot.forEach((doc) => newPayments.push({ uid: doc.id, ...doc.data() }))
        commit('setPayments', newPayments)
      })
      commit('setListinerPayments', listinerPayments)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recibidos')
    }
  },
  insertPayment ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const date = payload.date || Number(moment().format('x'))
      if (payload.uid) {
        db.collection('payments').doc(payload.uid).update({ ...payload, updatedBy: rootGetters['user/getUser'].uid })
          .then((receipts) => resolve(receipts))
          .catch((error) => {
            reject(error)
          })
      } else {
        api.insertItem('payments', { ...payload, user: rootGetters['user/getUser'].uid, date })
          .then((receipts) => resolve(receipts))
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  cancelPayment ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('insertReceipt', {
        ...payload,
        amount: (0 - Number(payload.amount)),
        id: null,
        others: 'Anula pago N° ' + payload.id
      })
        .then((payment) => resolve(payment))
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerPayments) {
      state.listinerPayments()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerPayments (state, listener) {
    state.listinerPayments = listener
  },
  setPayments: (state, payload) => {
    if (!payload) state.listinerPayments()
    state.payments = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
