<template>
  <div class="frame-container" :style="`width: ${width};`">
    <CdInputText
      :label="label"
      :value="getText(selectedItem)"
      :required="required"
      :width="width"
      @blur="blur"
      @focus="onFocus"
      @KeyDown="onKeyDown"
      @Copy="onCopy"
      @input="onInput"
      icon="chevron"
      @click:append="clickAppend"
      ref="inputText"
    ></CdInputText>
    <div class="autocomplete-items" v-if="isMenuActive">
      <div v-for="(item, i) in filteredItems" :key="i" @click="selectItem(item)">{{ getText(item) }}</div>
    </div>
  </div>
</template>
<script>
import tools from '../../api/tools'
import CdInputText from './CdInputText.vue'
const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
})
export default {
  name: 'CdSelect',
  components: {
    CdInputText
  },
  props: {
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    value: null,
    items: Array,
    textNull: {
      type: String,
      default: 'Seleccionar opción'
    },
    itemValue: {
      type: [String, Number],
      default: 'value'
    },
    itemText: {
      type: [String, Number],
      default: 'text'
    },
    returnObject: Boolean,
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      }
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: 'disabled'
    },
    searchInput: {
      type: String
    },
    noFilter: Boolean,
    multiple: Boolean,
    width: {
      type: String,
      default: '100%'
    },
    hideSelected: Boolean,
    isInteractive: {
      type: Boolean,
      default: true
    },
    widthLabelString: {
      type: [String, Number],
      default: 20
    }
  },
  data () {
    return {
      lazySearch: this.searchInput,
      isMenuActive: false,
      selectedItems: [],
      selectedIndex: -1,
      lazyValue: this.value,
      initialValue: null,
      isFocused: false
    }
  },
  computed: {
    allItems () {
      return this.items || []
    },
    /* Used by other components to overwrite */
    computedItems () {
      return this.allItems
    },
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', this.lazyValue)
      }
    },
    selectedItem () {
      if (this.multiple) return null

      return this.selectedItems.find(i => {
        return this.valueComparator(this.getValue(i), this.getValue(this.internalValue))
      })
    },
    filteredItems () {
      if (!this.isSearching || this.noFilter || this.internalSearch == null) return this.allItems

      return this.allItems.filter(item => {
        const value = tools.getPropertyFromItem(item, this.itemText)
        const text = value != null ? String(value) : ''

        return this.filter(item, String(this.internalSearch), text)
      })
    },
    isSearching () {
      return (
        this.multiple &&
        this.searchIsDirty
      ) || (
        this.searchIsDirty &&
        this.internalSearch !== this.getText(this.selectedItem)
      )
    },
    internalSearch: {
      get () {
        return this.lazySearch
      },
      set (val) { // TODO: this should be `string | null` but it breaks lots of other types
        // emit update event only when the new
        // search value is different from previous
        if (this.lazySearch !== val) {
          this.lazySearch = val
          this.$emit('update:search-input', val)
        }
      }
    },
    searchIsDirty () {
      return this.internalSearch != null &&
        this.internalSearch !== ''
    }
  },
  watch: {
    items: {
      immediate: true,
      handler (val) {
        // if (this.cacheItems) {
        //   // Breaks vue-test-utils if
        //   // this isn't calculated
        //   // on the next tick
        //   this.$nextTick(() => {
        //     this.cachedItems = this.filterDuplicates(this.cachedItems.concat(val))
        //   })
        // }

        this.setSelectedItems()
      }
    },
    internalValue (val) {
      this.initialValue = val
      this.setSelectedItems()

      // if (this.multiple) {
      //   this.$nextTick(() => {
      //     this.$refs.menu?.updateDimensions()
      //   })
      // }
      // if (this.hideSelected) {
      //   this.$nextTick(() => {
      //     this.onScroll()
      //   })
      // }
    },
    value (val) {
      this.lazyValue = val
    }
  },
  methods: {
    blur (e) {
      setTimeout(() => {
        this.isMenuActive = false
        this.isFocused = false
        this.selectedIndex = -1
      // this.setMenuIndex(-1)
      }, 500)
    },
    clickAppend () {
      this.$refs.inputText.onFocus()
    },
    onFocus (e) {
      this.activateMenu()
    },
    getText (item) {
      return tools.getPropertyFromItem(item, this.itemText, item)
    },
    setSelectedItems () {
      const selectedItems = []
      const values = !this.multiple || !Array.isArray(this.internalValue)
        ? [this.internalValue]
        : this.internalValue

      for (const value of values) {
        const index = this.allItems.findIndex(v => this.valueComparator(
          this.getValue(v),
          this.getValue(value)
        ))

        if (index > -1) {
          selectedItems.push(this.allItems[index])
        }
      }

      this.selectedItems = selectedItems
    },
    getDisabled (item) {
      return tools.getPropertyFromItem(item, this.itemDisabled, false)
    },
    deleteCurrentItem () {
      const curIndex = this.selectedIndex
      const curItem = this.selectedItems[curIndex]

      // Do nothing if input or item is disabled
      if (
        !this.isInteractive ||
        this.getDisabled(curItem)
      ) return

      const lastIndex = this.selectedItems.length - 1

      // Select the last item if
      // there is no selection
      if (
        this.selectedIndex === -1 &&
        lastIndex !== 0
      ) {
        this.selectedIndex = lastIndex

        return
      }

      const length = this.selectedItems.length
      const nextIndex = curIndex !== length - 1
        ? curIndex
        : curIndex - 1
      const nextItem = this.selectedItems[nextIndex]

      if (!nextItem) {
        this.setValue(this.multiple ? [] : null)
      } else {
        this.selectItem(curItem)
      }

      this.selectedIndex = nextIndex
    },
    onKeyDown (e) {
      const keyCode = e.keyCode

      // if (
      //   e.ctrlKey ||
      //   ![keyCodes.home, keyCodes.end].includes(keyCode)
      // ) {
      //   VSelect.options.methods.onKeyDown.call(this, e)
      // }

      // The ordering is important here
      // allows new value to be updated
      // and then moves the index to the
      // proper location
      this.changeSelectedIndex(keyCode)
    },
    onCopy (event) {
      if (this.selectedIndex === -1) return

      const currentItem = this.selectedItems[this.selectedIndex]
      const currentItemText = this.getText(currentItem)
      // eslint-disable-next-line no-unused-expressions
      event.clipboardData?.setData('text/plain', currentItemText)
      // eslint-disable-next-line no-unused-expressions
      event.clipboardData?.setData('text/vnd.vuetify.autocomplete.item+plain', currentItemText)
      event.preventDefault()
    },
    changeSelectedIndex (keyCode) {
      // Do not allow changing of selectedIndex
      // when search is dirty
      if (this.searchIsDirty) return

      if (this.multiple && keyCode === keyCodes.left) {
        if (this.selectedIndex === -1) {
          this.selectedIndex = this.selectedItems.length - 1
        } else {
          this.selectedIndex--
        }
      } else if (this.multiple && keyCode === keyCodes.right) {
        if (this.selectedIndex >= this.selectedItems.length - 1) {
          this.selectedIndex = -1
        } else {
          this.selectedIndex++
        }
      } else if (keyCode === keyCodes.backspace || keyCode === keyCodes.delete) {
        this.deleteCurrentItem()
      }
    },
    activateMenu () {
      if (
        !this.isInteractive ||
        this.isMenuActive
      ) return

      this.isMenuActive = true
    },
    clearableCallback () {
      this.internalSearch = null
    },
    updateSelf () {
      if (
        !this.searchIsDirty &&
        !this.internalValue
      ) return

      if (
        !this.multiple &&
        !this.valueComparator(
          this.internalSearch,
          this.getValue(this.internalValue)
        )
      ) {
        this.setSearch()
      }
    },
    onInput (e) {
      if (
        this.selectedIndex > -1 ||
        !e.target
      ) return

      const target = e.target
      const value = target.value

      // If typing and menu is not currently active
      if (target.value) this.activateMenu()

      if (!this.multiple && value === '') this.deleteCurrentItem()

      this.internalSearch = value
      // this.badInput = target.validity && target.validity.badInput
    },
    setSearch () {
      // Wait for nextTick so selectedItem
      // has had time to update
      this.$nextTick(() => {
        if (
          !this.multiple ||
          !this.internalSearch ||
          !this.isMenuActive
        ) {
          this.internalSearch = (
            !this.selectedItems.length ||
            this.multiple ||
            this.hasSlot
          )
            ? null
            : this.getText(this.selectedItem)
        }
      })
    },
    findExistingIndex (item) {
      const itemValue = this.getValue(item)

      return (this.internalValue || []).findIndex((i) => this.valueComparator(this.getValue(i), itemValue))
    },
    selectItem (item) {
      if (!this.multiple) {
        this.setValue(this.returnObject ? item : this.getValue(item))
        this.isMenuActive = false
      } else {
        const internalValue = (this.internalValue || []).slice()
        const i = this.findExistingIndex(item)

        i !== -1 ? internalValue.splice(i, 1) : internalValue.push(item)
        this.setValue(internalValue.map((i) => {
          return this.returnObject ? i : this.getValue(i)
        }))

        // There is no item to re-highlight
        // when selections are hidden
        if (this.hideSelected) {
          this.setMenuIndex(-1)
        } else {
          const index = this.computedItems.indexOf(item)
          if (~index) {
            this.$nextTick(() => this.$refs.menu.getTiles())
            setTimeout(() => this.setMenuIndex(index))
          }
        }
      }
    },
    getValue (item) {
      return tools.getPropertyFromItem(item, this.itemValue, this.getText(item))
    },
    setValue (value) {
      if (!this.valueComparator(value, this.internalValue)) {
        this.internalValue = value
        this.$emit('change', value)
      }
    },
    valueComparator (a, b) {
      return tools.deepEqual(a, b)
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.cedula {
  position: relative;
  font-weight: 500;
}
.wrapper {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #000;
}
.div {
  position: relative;
  font-weight: 500;
  color: #f72585;
}
.cedula-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.div1 {
  flex: 1;
  position: relative;
}
input {
  width: 100%;
  flex: 1;
  position: relative;
  border: none;
  outline: none;
  background: transparent;
  padding: 6px 16px;
}
.frame-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.autocomplete-items {
  position: absolute;
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
  cursor: pointer;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
</style>
