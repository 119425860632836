<template>
  <v-container>
    <v-container fluid class="d-inline-flex justify-end" v-if="false">
      <div></div>
    </v-container>
    <Calendar v-if="!loading" :tasks="tasks"/>
    <v-container style="height: 400px;" v-else>
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Cargando eventos y tareas...
        </v-col>
        <v-col cols="6" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import Calendar from '@/components/Calendar.vue'
import redaApp from '../api/redaApp'
// import firebaseApi from '../api/firebaseApi'

export default {
  name: 'CalendarView',
  components: { Calendar },
  data: () => ({
    loading: true,
    staff: [],
    tasks: []
  }),

  computed: {
  // ...mapGetters()
  },

  watch: {
  },

  mounted () {
    this.refresh()
  },

  created () {
  },

  methods: {
    async refresh () {
      this.loading = true
      try {
        // this.staff = []
        const resTasks = await redaApp.getRedaApp('get-tasks')
        this.tasks = resTasks.data.tasks
        // for (let i = 0; i < this.getCompany.staff.length; i++) {
        //   const staff = this.getCompany.staff[i]
        //   if (staff.contact) {
        //     const item = await firebaseApi.api.getItemByUid('customers', staff.contact)
        //     if (item) this.staff.push(item)
        //   }
        // }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  // ...mapMutations(),
  // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
