/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
import tools from '@/api/tools'
// import tools from '@/api/tools';

const { api } = fbApi

// initial state
const initialState = {
  orders: [],
  tmpOrder: {
    id: null,
    client: {
      typeId: null,
      identification: null,
      name: null,
      email: null,
      phone: null,
      priceList: 'normal'
    },
    items: [],
    address: {
      province: null,
      conty: null,
      district: null,
      others: null
    },
    observations: '',
    movements: []
  },
  listinerOrders: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getObservations (state) {
    return state.tmpOrder.observations
  },
  getClient (state) {
    return state.tmpOrder.client
  },
  getOrders ({ orders }) {
    return orders
  },
  getAddress (state) {
    return state.tmpOrder.address
  },
  getItems (state) {
    const items = []
    state.tmpOrder.items.forEach(item => {
      item.total = tools.round10(item.quantity * item.unitPrice, -5)
      item.subtotal = item.discount !== null && item.discount > 0 ? tools.round10(item.total - item.discount, -5) : item.total
      item.taxableBase = item.subtotal
      let taxNet = 0
      if (item.taxes !== null) {
        for (let i = item.taxes.length - 1; i >= 0; i--) {
          item.taxes[i].taxFactor = item.taxes[i].rate / 100
          item.taxes[i].total = item.taxableBase * item.taxes[i].taxFactor
          taxNet += item.taxes[i].total
        }
      }
      item.taxNet = tools.round10(taxNet, -5)
      item.netTotal = tools.round10(item.subtotal + item.taxNet, -5)
      items.push(item)
    })
    return items
  },
  servicesTaxableTotal (state, getters) {
    let servicesTaxableTotal = 0
    for (let i = getters.getItems.length - 1; i >= 0; i--) {
      if (getters.getItems[i].unit === 'Sp') {
        servicesTaxableTotal += (getters.getItems[i].taxes ? tools.round10(getters.getItems[i].subtotal, -5) : 0)
      }
    }
    return tools.round10(servicesTaxableTotal, -5)
  },
  servicesExentTotal (state, getters) {
    let servicesExentTotal = 0
    for (let i = getters.getItems.length - 1; i >= 0; i--) {
      if (getters.getItems[i].unit === 'Sp') {
        servicesExentTotal += (getters.getItems[i].taxes ? 0 : tools.round10(getters.getItems[i].subtotal, -5))
      }
    }
    return tools.round10(servicesExentTotal, -5)
  },
  goodsTaxableTotal (state, getters) {
    let goodsTaxableTotal = 0
    for (let i = getters.getItems.length - 1; i >= 0; i--) {
      if (getters.getItems[i].unit !== 'Sp') {
        goodsTaxableTotal += (getters.getItems[i].taxes ? tools.round10(getters.getItems[i].subtotal, -5) : 0)
      }
    }
    return tools.round10(goodsTaxableTotal, -5)
  },
  goodsExentTotal (state, getters) {
    let goodsExentTotal = 0
    for (let i = getters.getItems.length - 1; i >= 0; i--) {
      if (getters.getItems[i].unit !== 'Sp') {
        goodsExentTotal += (getters.getItems[i].taxes ? 0 : tools.round10(getters.getItems[i].subtotal, -5))
      }
    }
    return tools.round10(goodsExentTotal, -5)
  },
  taxableTotal (state, getters) {
    return tools.round10(getters.servicesTaxableTotal + getters.goodsTaxableTotal, -5)
  },
  exentTotal (state, getters) {
    return tools.round10(getters.servicesExentTotal + getters.goodsExentTotal, -5)
  },
  subtotal (state, getters) {
    return tools.round10(getters.taxableTotal + getters.exentTotal, -5)
  },
  taxTotal (state, getters) {
    let taxTotal = 0
    for (let i = getters.getItems.length - 1; i >= 0; i--) {
      taxTotal += getters.getItems[i].taxNet
    }
    return tools.round10(taxTotal, -5)
  },
  netTotal (state, getters) {
    return tools.round10(getters.taxTotal + getters.subtotal, -5)
  },
  summary (state, getters) {
    return {
      servicesTaxableTotal: getters.servicesTaxableTotal,
      servicesExentTotal: getters.servicesExentTotal,
      servicesExoneTotal: 0,
      goodsTaxableTotal: getters.goodsTaxableTotal,
      goodsExentTotal: getters.goodsExentTotal,
      goodsExoneTotal: 0,
      taxableTotal: getters.taxableTotal,
      exentTotal: getters.exentTotal,
      exoneTotal: 0,
      subtotal: getters.subtotal,
      discountTotal: 0,
      grossTotal: getters.subtotal,
      taxTotal: getters.taxTotal,
      vatReturned: 0,
      otherChargesTotal: 0,
      netTotal: getters.netTotal
    }
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const ordersQuerySnapshot = await fbApi.db.collection('orders')
        .where('company', '==', company)
        .get()
      const orders = []
      ordersQuerySnapshot.forEach((doc) => orders.push({ uid: doc.id, ...doc.data() }))
      commit('setOrders', orders)
      const listinerOrders = ordersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newOrders = []
        querySnapshot.forEach((doc) => newOrders.push({ uid: doc.id, ...doc.data() }))
        commit('setOrders', newOrders)
      })
      commit('setListinerOrders', listinerOrders)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar Ordenes')
    }
  },
  submitOrder ({ state, commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      const { tmpOrder } = state
      if (tmpOrder.id === null) {
        const {
          displayName,
          email,
          emailVerified,
          photoURL,
          isAnonymous,
          uid,
          providerData,
          staff,
          active
        } = rootGetters['user/getUser']
        const movement = {
          date: Number(moment().format('x')),
          value: '01',
          user: {
            displayName,
            email,
            emailVerified,
            photoURL,
            isAnonymous,
            uid,
            providerData,
            staff,
            active
          },
          observations: ''
        }
        commit('newMovement', movement)
      }
      dispatch(
        'customers/insertCustomer',
        {
          ...getters.getClient,
          addresses: [
            { ...getters.getAddress, name: 'principal' }
          ]
        },
        { root: true }
      )
      api.insertItem('orders', tmpOrder)
        .then((order) => {
          resolve(order ? 'Pedido ' + order.id + ' actualizado' : 'Pedido ingresado')
          commit('setOrderDefault')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addItem ({ state, commit, getters }, item) {
    return new Promise((resolve, reject) => {
      const itemState = state.tmpOrder.items.find(
        ({ commercialCode }) => commercialCode === item.commercialCode
      )

      if (itemState) {
        const index = state.tmpOrder.items.indexOf(itemState)
        commit('editItem', { index, item })
        resolve(`${item.commercialCode} updated!`)
      } else {
        commit('newItem', item)
        resolve(`${item.commercialCode} created!`)
      }

      /* const schema = {
        properties: {
          code: { type: 'string', },
          commercialCode: { type: 'string', },
          quantity: { type: 'float32', },
          description: { type: 'string', },
          unit: { type: 'string', },
          unitPrice: { type: 'float32', },
        },
        optionalProperties: {
          discount: {type: "string"},
          taxes: {type: "string"},
        }
      };

      tools.validate(schema, item)
        .then(() => {

          const itemState = state.tmpOrder.items.find(
            ({commercialCode}) => commercialCode === item.commercialCode
          );

          if (itemState) {
            const index = state.tmpOrder.items.indexOf(itemState);
            commit('editItem', { index, item });
            resolve(`${item.commercialCode} updated!`);
          } else {
            commit('newItem', item);
            resolve(`${item.commercialCode} created!`);
          }
        })
        .catch((error) => {
          reject(error);
        }); /* */
    })
  },
  insertClient ({ state, commit, getters }, client) {
    commit('setClient', client)
  },
  newMovement ({ state, commit, getters, rootGetters }, movement) {
    const user = {
      displayName: rootGetters['user/getUser'].displayName,
      email: rootGetters['user/getUser'].email,
      emailVerified: rootGetters['user/getUser'].emailVerified,
      photoURL: rootGetters['user/getUser'].photoURL,
      isAnonymous: rootGetters['user/getUser'].isAnonymous,
      uid: rootGetters['user/getUser'].uid,
      providerData: rootGetters['user/getUser'].providerData,
      staff: rootGetters['user/getUser'].staff,
      active: rootGetters['user/getUser'].active
    }
    commit('newMovement', { ...movement, user })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerOrders) {
      state.listinerOrders()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerOrders (state, listener) {
    state.listinerOrders = listener
  },
  newMovement: (state, payload) => {
    state.tmpOrder.movements.push(payload)
  },
  updateQuantity: (state, payload) => {
    state.tmpOrder.items[payload.index].quantity = payload.quantity
  },
  deleteItem: (state, i) => {
    state.tmpOrder.items.splice(i, 1)
  },
  deleteClient: (state, orders) => {
    state.tmpOrder.client = null
  },
  setOrders: (state, orders) => {
    if (!orders) state.listinerOrders()
    state.orders = orders
  },
  setClient: (state, client) => {
    state.tmpOrder.client = client
  },
  setAddress: (state, address) => {
    state.tmpOrder.address = address
  },
  setObservations: (state, observations) => {
    let obs = observations
    if (!obs) obs = ''
    state.tmpOrder.observations = obs
  },
  setOrderDefault: (state) => {
    state.tmpOrder = {
      id: null,
      client: {
        typeId: null,
        identification: null,
        name: null,
        email: null,
        phone: null,
        priceList: 'normal'
      },
      items: [],
      address: {
        province: null,
        conty: null,
        district: null,
        others: null
      },
      observations: '',
      movements: []
    }
  },
  editItem: (state, { index, item }) => {
    state.tmpOrder.items[index] = item
  },
  newItem: (state, item) => {
    state.tmpOrder.items.push(item)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
