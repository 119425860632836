<template>
  <v-data-table
    :headers="headers"
    :items="vouchers"
    sort-by="date"
    class="elevation-1"
    sort-desc
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ received ? 'Compras' : 'Ventas' }} de {{ product.description }}</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
      {{moment(item.date).format('l')}}
    </template>
    <template v-slot:item.voucher="{ item }">
      <router-link :to="'/voucher/' + item.voucher">{{item.voucher}}</router-link>
    </template>
    <template v-slot:item.receiver="{ item }">
      <router-link :to="'/customer/' + item.receiver" v-if="item.receiver">{{item.receiver}}</router-link>
    </template>
    <template v-slot:item.issuer="{ item }">
      <router-link :to="'/customer/' + item.issuer" v-if="item.issuer">{{item.issuer}}</router-link>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import firebaseApi from '../api/firebaseApi'

export default {
  name: 'ProductVouchers',
  props: [
    'product',
    'received'
  ],
  components: {},
  data: () => ({
    moment,
    voucher: null,
    amountOutstanding: null,
    dialog: false,
    vouchers: [],
    listenerVouchers: null
  }),

  mounted: function () {
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerVouchers()
  },

  computed: {
    headers () {
      return [
        { text: 'Fecha de emisión', value: 'date' },
        { text: 'Comprobante', value: 'voucher' },
        { text: this.received ? 'Proveedor' : 'Cliente', value: this.received ? 'issuer' : 'receiver' },
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Total', value: 'net_total' }
      ]
    },
    ...mapState('inventories', [
      'inventoryAliases'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  methods: {
    async reset () {
      if (this.listenerVouchers) this.listenerVouchers()
      const company = !this.received ? 'issuer' : 'receiver'
      const ia = this.received ? this.inventoryAliases.find(({ product }) => product === this.product) : null
      const iaCode = ia?.code.split('|')[0]
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerVouchers = firebaseApi.db.collection('voucherItems')
        .where(company, '==', this.getCompany.identification)
        .where('commercialCode', '==', (iaCode || this.product))
        .onSnapshot(({ docs }) => {
          this.vouchers = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.vouchers.push({ ...doc.data(), uid: doc.id })
          }
        })
    },
    ...mapActions('products', ['insertProduct', 'deleteProduct'])
  }
}
</script>
<style type="text/css">

</style>
