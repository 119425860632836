<template>
  <v-container>
    <v-row>
      <v-col cols="12" :sm="input.cols" v-for="(input, i) in inputs" :key="i">
        <v-list-item-content two-line v-if="input.type !== 'date' || input.type !== 'table'">
          <v-list-item-title>{{ input.label }}</v-list-item-title>
          <v-list-item-subtitle>{{ input.itemsIndexed ? input.itemsIndexed[value[input.value]] : value[input.value] }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-file-input
          v-if="input.type === 'file'"
          show-size
          truncate-length="15"
          :multiple="input.multiple"
          :value="files[input.value]"
        ></v-file-input>
        <div   v-if="input.type === 'date'">
          <v-date-picker
            :value="dateParse(value[input.value])"
            readonly
          ></v-date-picker>
        </div>
        <DataTableCRUD
          v-if="false"
          v-show="!input.hiden"
          :table="input.table"
          :options="input.options"
          :value="value[input.value]"
        ></DataTableCRUD>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'

export default {
  name: 'RedaDisplay',
  components: {
  },
  props: [
    'value',
    'options',
    'inactive',
    'loading'
  ],
  data: () => ({
    menu: {},
    isMounted: false,
    files: {},
    moment
  }),
  mounted () {
    this.isMounted = true
  },
  computed: {
    inputs () {
      return this.options?.inputs.map((input) => {
        if (input.items && input.items.length > 0) {
          input.itemsIndexed = input.items.reduce((acc, curr) => {
            if (curr.value && curr.text) {
              acc[curr.value] = curr.text
              return acc
            }
            acc[curr] = curr
            return acc
          }, {})
        }
        return input
      })
    }
  },
  methods: {
    dateParse (date) {
      if (!date) return date
      return moment(date, 'x').format().substr(0, 10)
    }
  }
}
</script>
