<template>
    <v-card>
      <v-card-title>Clasificador de compras de bienes y servicios</v-card-title>
    </v-card>
  </template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions
} from 'vuex'

export default {
  name: 'PendingItemClassifier',
  components: {},
  props: [],
  beforeCreate: function () {},
  created: function () {},
  beforeMount: function () {},
  mounted: function () {},
  beforeUpdate: function () {},
  updated: function () {},
  beforeDestroy: function () {},
  destroyed: function () {},
  data: () => ({
    snackbar: false,
    snackbarText: null
  }),
  watch: {},
  methods: {
    ...mapMutations([]),
    ...mapActions([])
  },
  computed: {
    ...mapState([]),
    ...mapGetters([
    ])
  }
}
</script>
