<template>
  <div class="my-auto" :style="button && 'cursor: pointer;'">
    <i v-html="icon"></i>
  </div>
</template>
<script>
import redaIcons from '../assets/redaIcons'
export default {
  name: 'RedaIcon',
  components: {},
  props: {
    iconName: String,
    button: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    icon () {
      return redaIcons[this.iconName]
    }
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
