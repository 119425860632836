<template>
  <v-container>
    <v-card
      :loading="loading"
    >
      <v-card-title>
        Tareas
        <v-spacer></v-spacer>
        <v-btn icon :loading="loading" @click="refresh" class="mx-6"><v-icon large>mdi-sync</v-icon></v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" :loading="loading" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <NewTask
            @close="dialog = false"
            @submit="refresh"
            :tasks="tasks"
            :staff="staff"
          ></NewTask>
        </v-dialog>
      </v-card-title>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
      >
        <v-tab>
          Tablero
        </v-tab>
        <v-tab>
          Lista
        </v-tab>
        <v-tab>
          Cronograma
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container fluid>
            <TasksCards
              :tasks="tasks"
              :tasksIndexed="tasksIndexed"
              @updated="refresh"
              :staff="staff"
            ></TasksCards>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <TasksTable
            :tasks="tasks"
            :tasksIndexed="tasksIndexed"
            :staff="staff"
            @updated="refresh"
          ></TasksTable>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <TasksGantt :tasks="tasks" @updated="refresh"></TasksGantt>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script type="text/javascript">
import TasksGantt from '@/components/TasksGantt.vue'
import TasksTable from '@/components/TasksTable.vue'
import TasksCards from '@/components/TasksCards.vue'
import NewTask from '@/components/NewTask.vue'
import redaApp from '../api/redaApp'
import firebaseApi from '../api/firebaseApi'
import { /* mapState, mapActions, /**/mapGetters } from 'vuex'
export default {
  name: 'Tasks',
  components: { TasksGantt, NewTask, TasksTable, TasksCards },
  data: () => ({
    tab: null,
    loading: false,
    dialog: false,
    tasks: [],
    staff: [],
    tasksIndexed: {}
  }),
  methods: {
    async refresh () {
      this.loading = true
      try {
        this.staff = []
        const resTasks = await redaApp.getRedaApp('get-tasks')
        this.tasks = resTasks.data.tasks
        const tasksIndexed = this.tasks.reduce((acc, curr) => {
          acc[curr.uid] = curr
          return acc
        }, {})
        this.tasksIndexed = { ...tasksIndexed }
        for (let i = 0; i < this.getCompany.staff.length; i++) {
          const staff = this.getCompany.staff[i]
          if (staff.contact && typeof staff.contact === 'string') {
            const item = await firebaseApi.api.getItemByUid('customers', staff.contact)
            if (item) this.staff.push(item)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.refresh()
    // redaApp.submitRedaApp('get-tasks')
    //   .then(({ data }) => {
    //     this.tasks = data.inventories
    //   })
    //   .catch((e) => console.error(e))
    //   .finally(() => {
    //     this.loading = false
    //   })
  },
  computed: {
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
<style type="text/css">

</style>
