/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment';
// import fbApi from '@/api/firebaseApi';
// import tools from '@/api/tools';

// const { api } = fbApi;

// initial state
const initialState = {
  success: {
    show: false,
    message: ''
  },
  info: {
    show: false,
    message: ''
  },
  warning: {
    show: false,
    message: ''
  },
  error: {
    show: false,
    message: ''
  }
}

const state = () => ({ ...initialState })

// getters
const getters = {
}

// actions
const actions = {
  showSuccess: function ({ commit }, message) {
    commit('showSuccess', message)
    setTimeout(() => {
      commit('hideSuccess')
    }, 5000)
  },
  showInfo: function ({ commit }, message) {
    commit('showInfo', message)
    setTimeout(() => {
      commit('hideInfo')
    }, 5000)
  },
  showWarning: function ({ commit }, message) {
    commit('showWarning', message)
    setTimeout(() => {
      commit('hideWarning')
    }, 5000)
  },
  showError: function ({ commit }, message) {
    commit('showError', message)
    setTimeout(() => {
      commit('hideError')
    }, 5000)
  }
}

// mutations
const mutations = {
  showSuccess: function (state, payload) {
    state.success.show = true
    state.success.message = payload
  },
  showInfo: function (state, payload) {
    state.info.show = true
    state.info.message = payload
  },
  showWarning: function (state, payload) {
    state.warning.show = true
    state.warning.message = payload
  },
  showError: function (state, payload) {
    state.error.show = true
    state.error.message = payload
  },
  hideSuccess: function (state) {
    state.success.show = false
  },
  hideInfo: function (state) {
    state.info.show = false
  },
  hideWarning: function (state) {
    state.warning.show = false
  },
  hideError: function (state) {
    state.error.show = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
