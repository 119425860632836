<template>
  <DataTableCRUD
    :table="table"
    :options="options"
    :value="getFixedAssets"
    @updateItem="insertFixedAsset($event)"
    @newItem="insertFixedAsset($event)"
  >
  </DataTableCRUD>
</template>

<script>
import { /* mapState,/**/ mapActions, mapGetters } from 'vuex'
import depreciationCodes from '@/assets/depreciation'
// import tools from '@/api/tools.js'
export default {
  name: 'NoncurrentAssets',
  data () {
    return {}
  },
  methods: {
    insertAsset ({
      id,
      costCenter,
      acquisitionDate,
      description,
      type,
      category,
      historicalCost
    }) {
      return new Promise((resolve, reject) => {
        this.insertFixedAsset({
          id,
          costCenter,
          acquisitionDate,
          description,
          category: category.asset,
          annualDepreciationFee: category.annualDepreciation,
          usefulLife: category.usefulLife,
          depreciationMethod: '01',
          docsRef: [],
          historicalCost
        })
          .then((asset) => {
            resolve(asset)
            this.showSuccess('Activo N° ' + id + ' fue registrado')
          })
          .catch((error) => {
            console.error(error)
            this.showError('Error al registrar activo')
          })
      })
    },
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ]),
    ...mapActions('fixedAssets', ['insertFixedAsset'])
  },
  computed: {
    table () {
      return {
        dense: true
      }
    },
    options () {
      return {
        headers: [
          { text: 'N°', value: 'id' },
          { text: 'Descripción', value: 'description' },
          { text: 'Departamento', value: 'costCentersString' },
          { text: 'Fecha de adquisición', value: 'dateString' },
          { text: 'Valor histórico', value: 'historicalCost' },
          { text: 'Categoria Hacienda', value: 'category' },
          { text: 'Depareciación anual', value: 'annualDepreciationFee' },
          { text: 'Vida útil', value: 'usefulLife' },
          { text: 'Método de depareciación', value: 'depreciationMethod' },
          { text: '', value: 'actions', sortable: false }
        ],
        title: 'Activos no corrientes',
        btnIcon: 'mdi-plus',
        btnColor: 'secondary',
        newItemText: 'Nuevo activo',
        editItemText: 'Editar activo',
        readonly: true,
        defaultItem: {
          id: null,
          costCenter: null,
          acquisitionDate: null,
          description: null,
          category: null,
          annualDepreciationFee: null,
          usefulLife: null,
          depreciationMethod: '01',
          docsRef: [],
          historicalCost: 0
        },
        form: {
          submitText: 'Registrar activo',
          cancelText: 'Cancelar',
          showSubmit: true,
          showCancel: true,
          inputs: [
            {
              id: 'de01',
              label: 'Número',
              name: 'id',
              value: 'id',
              type: 'text',
              readonly: true,
              hiden: true,
              cols: 12
            },
            {
              id: 'de02',
              label: 'Descripción',
              name: 'description',
              value: 'description',
              type: 'text',
              rules: 'required',
              cols: 12
            },
            {
              id: 'de03',
              label: 'Departamento',
              name: 'costCenter',
              value: 'costCenter',
              type: 'select',
              itemValue: 'code',
              itemText: 'name',
              items: this.getCostCenters,
              rules: 'required',
              cols: 6
            },
            {
              id: 'de04',
              label: 'Categoria Hacienda',
              name: 'category',
              value: 'category',
              type: 'autocomplete',
              items: depreciationCodes,
              rules: 'required',
              itemText: 'asset',
              returnObject: true,
              cols: 6
            }
          ]
        }
      }
    },
    ...mapGetters('inventories', ['getCellars', 'itemsCCCellars', 'itemsTypeCellar']),
    ...mapGetters('fixedAssets', ['getFixedAssets']),
    ...mapGetters('siinco', ['getCostCenters'])
  }
}
</script>
