<template>
  <div class="box">
    <i v-if="icon" class="icon-container" v-html="icons[icon]"></i>
    <div class="typography">{{ label }}</div>
  </div>
</template>
<script>
import icons from '../../assets/redaIcons'
export default {
  name: 'RedaBtn',
  components: {},
  props: {
    icon: String,
    label: String
  },
  data () {
    return {
      icons
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.typography {
  color: var(--light-text-color, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12.659px; /* 105.494% */
  letter-spacing: 0.115px;
    display: flex; /* Activa el modo flexbox */
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido (opcional) */
}

.box {
  display: inline-flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
    border-radius: 21.29px;
    background-color: #f72585;
    flex-direction: row;
    text-align: center;
    cursor: pointer;
}
.icon-container {
    display: flex; /* Activa el modo flexbox */
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido (opcional) */
    padding-bottom: 2.5px;
}
</style>
