const deductionsTypeItems = [{ text: 'Procentaje', value: '01' }, { text: 'Valor', value: '02' }]
const deductions = [
  { text: 'SEM', value: 0.055, type: '01' },
  { text: 'IVM', value: 0.04, type: '01' },
  { text: 'Aporte Trabajador Banco Popular', value: 0.01, type: '01' }
]
const contributions = [
  { text: 'SEM', value: 0.0925, type: '01' },
  { text: 'IVM', value: 0.0525, type: '01' },
  { text: 'Cuota Patronal Banco Popular', value: 0.0025, type: '01' },
  { text: 'Asignaciones Familiares', value: 0.05, type: '01' },
  { text: 'IMAS', value: 0.005, type: '01' },
  { text: 'INA', value: 0.015, type: '01' },
  { text: 'Aporte Patrono Banco Popular', value: 0.0025, type: '01' },
  { text: 'Fondo de Capitalización Laboral', value: 0.015, type: '01' },
  { text: 'Fondo de Pensiones Complementarias', value: 0.02, type: '01' },
  { text: 'INS', value: 0.01, type: '01' }
]
export default {
  deductionsTypeItems,
  deductions,
  contributions
}
