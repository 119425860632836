<template>
  <v-container>
    <div class="headline my-7 ml-2">
      {{ staff }}
    </div>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
  </v-container>
</template>

<script>
import { /* mapState, mapActions, /* */ mapGetters } from 'vuex'

export default {
  name: 'Staff',
  props: ['staff'],
  components: { },
  data: () => ({
  }),
  methods: {},
  computed: {
    getThisStaff () {
      return this.staff.find(({ id }) => this.staff === id)
    },
    ...mapGetters('company', ['getStaff'])
  }
}
</script>
