<template>
  <v-container>
    <BlogsTable :blogs="blogs"/>
  </v-container>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import BlogsTable from '@/components/BlogsTable.vue'
import firebaseApi from '../api/firebaseApi'
import { mapGetters } from 'vuex'
// import AccountReceivable from '@/components/AccountReceivable.vue'

export default {
  name: 'Blogs',
  components: { BlogsTable },
  data: () => ({
    blogs: [],
    listenerBlogs: null
  }),
  mounted: function () {
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerBlogs()
  },
  methods: {
    reset () {
      if (this.listenerBlogs) this.listenerBlogs()
      this.listenerBlogs = firebaseApi.db.collection('blogPosts')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.blogs = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.blogs.push({ ...doc.data(), uid: doc.id })
          }
        })
    }
  },
  computed: {
    ...mapGetters('company', [
      'getCompany'
    ])
    // ...mapGetters()
  },

  watch: {
  }
  /* created () {
  }, */
}
</script>
<style type="text/css">
</style>
