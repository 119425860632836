/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools'

// initial state
const initialState = {
  posts: [],
  listinerPosts: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getPosts ({ posts }) {
    return posts
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const postsQuerySnapshot = await fbApi.db.collection('posts')
        .where('company', '==', company)
        .get()
      const posts = []
      postsQuerySnapshot.forEach((doc) => posts.push({ uid: doc.id, ...doc.data() }))
      commit('setPosts', posts)
      const listinerPosts = postsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newPosts = []
        querySnapshot.forEach((doc) => newPosts.push({ uid: doc.id, ...doc.data() }))
        commit('setPosts', newPosts)
      })
      commit('setListinerPosts', listinerPosts)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar posts')
    }
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerPosts) {
      state.listinerPosts()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerPosts (state, listener) {
    state.listinerPosts = listener
  },
  setPosts: (state, posts) => {
    if (!posts) state.listinerPosts()
    state.posts = posts
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
