import redaIcons from '../assets/redaIcons'

const income = {
  IssueVoucher: { title: 'Emitir factura', to: '/issueVoucher', icon: redaIcons.EmitirComprobante, custom: true },
  Vouchers: { title: 'Facturas Emitidas', to: '/vouchers', icon: redaIcons.ComprobanteEmitidos, custom: true },
  Subscriptions: { title: 'Facturas recurrentes', to: '/subscriptions', icon: redaIcons.ComprobanteRecurrentes, custom: true },
  CashDaily: { title: 'Cajas Diarias', to: '/cashDaily', icon: redaIcons.PagosRealizados, custom: true },
  Receipts: { title: 'Pagos recibidos', to: '/receipts', icon: redaIcons.PagosRealizados, custom: true },
  AccountReceivableView: { title: 'Cuentas por cobrar', to: '/accountReceivableView', icon: redaIcons.CuentasPorCobrar, custom: true }
}
const marketing = {
  Chats: { title: 'Chats', to: '/chats', icon: 'mdi-wechat' },
  Blogs: { title: 'Blogs', to: '/blogs', icon: 'mdi-post-outline' },
  Posts: { title: 'Calendario de contenido', to: '/posts', icon: 'mdi-calendar-clock' }
}
const eventsAndTasks = {
  Calendar: { title: 'Calendario', to: '/calendar', icon: 'mdi-calendar-cursor' },
  Tasks: { title: 'Tareas', to: '/tasks', icon: 'mdi-order-bool-ascending-variant' }
}
const expenses = {
  VouchersReceived: { title: 'Facturas Recibidos', to: '/vouchersReceived', icon: redaIcons.ComprobanteRecibidos, custom: true },
  PendingVouchers: { title: 'Facturas Pendientes', to: '/pendingVouchers', icon: redaIcons.ComprobantePendientes, custom: true },
  AccountReceivable: { title: 'Cuentas por pagar', to: '/accountReceivable', icon: redaIcons.CuentasPorPagar, custom: true },
  Payments: { title: 'Pagos realizados', to: '/payments', icon: redaIcons.PagosRealizados, custom: true }
}
const contacts = {
  Customers: { title: 'Contactos', to: '/customers', icon: 'mdi-contacts' }
}
const products = {
  Products: { title: 'Productos y servicios', to: '/products', icon: 'mdi-tag-outline' },
  Cellar: { title: 'Inventario', to: '/cellar', icon: 'mdi-package-variant-closed' },
  Orders: { title: 'Ordenes y Pédidos', to: '/orders', icon: 'mdi-clipboard-list-outline' },
  Production: { title: 'Producción', to: '/production', icon: 'mdi-account-hard-hat-outline' }
}
const accounting = {
  BanksAndCash: { title: 'Bancos y efectivo', to: '/banksAndCash', icon: 'mdi-bank' },
  ChartOfAccounts: { title: 'Catálogo de cuentas', to: '/chartOfAccounts', icon: 'mdi-file' },
  AccountingEntries: { title: 'Asientos contables', to: '/accountingEntries', icon: 'mdi-file' },
  TaxReturn: { title: 'Declaraciones fiscales', to: '/taxReturn', icon: 'mdi-file' },
  FixedAssets: { title: 'Activos fijos', to: '/fixedAssets', icon: 'mdi-file' }
}
const config = {
  Company: { title: 'Compañia', to: '/company', icon: 'mdi-file' },
  AccountingConfig: { title: 'Contabilidad', to: '/accountingConfig', icon: 'mdi-file' }
}
const reports = {
  FinancialReporting: { title: 'Información Financiera', to: '/financialReporting', icon: 'mdi-file' }
}
const orvigrafik = {
  NewOrder: { title: 'Nueva Orden', to: '/newOrder', icon: 'mdi-file' },
  Orders: { title: 'Ordenes de Trabajo', to: '/orders', icon: 'mdi-clipboard-list-outline' },
  // OrviOrders: { title: 'Pedidos', to: '/orviOrders', icon: 'mdi-clipboard-list-outline' },
  OrviProducts: { title: 'Fórmulas de producción', to: '/orviProducts', icon: 'mdi-factory' },
  OrviProduction: { title: 'Producción', to: '/production', icon: 'mdi-account-hard-hat-outline' },
  ApprovalOrders: { title: 'Aprovación OT', to: '/approvalOrders', icon: 'mdi-clipboard-check-multiple-outline' }
}
const Customers = {
  title: 'Contactos',
  icon: 'mdi-contacts'
}

const Biller = {
  title: 'Punto de venta',
  icon: 'mdi-cash-register'
}

// const Companies = {
//   title: 'Compañias',
//   icon: 'mdi-domain'
// }

const moduleViews = [
  'NewOrder',
  'IssueVoucher',
  'Subscriptions',
  'Vouchers',
  'Receipts',
  'Customers',
  'CashDaily',
  'Calendar',
  'Tasks',
  'AccountReceivableView',
  'VouchersReceived',
  'PendingVouchers',
  'AccountReceivable',
  'Payments',
  'Products',
  'Cellar',
  'Production',
  'ChartOfAccounts',
  'AccountingEntries',
  'FixedAssets',
  'Company',
  'FinancialReporting',
  'Oders',
  'Biller',
  'Companies',
  'TaxReturn',
  'chats',
  'AccountingConfig',
  'Blogs',
  'ApprovalOrders',
  'OrviProduction'
]
export default {
  orvigrafik,
  moduleViews,
  income,
  expenses,
  contacts,
  products,
  eventsAndTasks,
  marketing,
  accounting,
  config,
  reports,
  Customers,
  Biller
  // Companies
}
