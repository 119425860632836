<template>
  <v-data-table
    :headers="headers"
    :items="inventories"
    class="elevation-1"
    sort-by="num"
    :search="search"
    sort-desc
    dense
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Movimientos de Inventario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="500px" v-if="!voucher && !product">
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              class="mb-2"
              v-on="on"
            ><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Nuevo movimiento</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :value="dateParsed"
                      label="Fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      @click.stop="menuDate = true"
                    ></v-text-field>
                    <div>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        absolute
                        min-width="auto"
                      >
                        <v-date-picker
                          :value="dateParsed"
                          @change="setDate($event)"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Producto"
                      :items="itemsProductsCode"
                      v-model="editedItem.product"
                      :filter="customFilter"
                      autofocus
                      item-text="name"
                      item-value="code"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="details"
                      label="Detalle"
                      v-model="editedItem.detail"
                      item-text="name"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="cellarItems"
                      label="Entra a"
                      v-model="editedItem.entry"
                      :disabled="!isEntry"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="cellarItems"
                      label="Sale de"
                      v-model="editedItem.exit"
                      :disabled="isEntry"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field type="number" v-model="editedItem.quantity" label="Cantidad"></v-text-field>
                  </v-col>
                  <v-col cols="12"  sm="6">
                    <v-text-field type="number" v-model="editedItem.cost" label="Costo unitario"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Registrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!--<template v-slot:item.entry="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>
    <template v-slot:item.exit="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>-->
    <template v-slot:item.date="{ item }">
      {{getDateFormat(item.date)}}
    </template>
    <template v-slot:item.unitCost="{ item }">
      {{item.unitCost | currencyFormat }}
    </template>
    <template v-slot:item.cost="{ item }">
      {{item.cost | currencyFormat }}
    </template>
    <template v-slot:item.quantity="{ item }">
      {{item.quantity | numberFormat }}
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import tools from '@/api/tools.js'
import mvs from '@/api/movements.js'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'movements',
  components: {},
  props: {
    voucher: String,
    product: String,
    inventory: Array,
    products: Array
  },
  data: () => ({
    search: null,
    isLoading: false,
    menuDate: false,
    dialog: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'num'
      },
      { text: 'Fecha', value: 'date' },
      { text: 'Producto', value: 'product.description' },
      { text: 'Detalle', value: 'detail' },
      { text: 'Entra a', value: 'entry.name' },
      { text: 'Sale de', value: 'exit.name' },
      { text: 'Cantidad', value: 'quantity', align: 'end' },
      { text: 'Costo unitario', value: 'unitCost', align: 'end' },
      { text: 'Costo total', value: 'cost', align: 'end' }
    ],
    details: mvs.movementsCodes,
    movements: [
      { value: 'entry', text: 'Entrada' },
      { value: 'exit', text: 'Salida' }
    ],
    editedIndex: -1,
    editedItem: {
      date: null,
      product: '',
      detail: null,
      entry: null,
      exit: null,
      quantity: 1,
      cost: 0,
      docs: []
    },
    defaultItem: {
      date: null,
      product: '',
      detail: null,
      entry: null,
      exit: null,
      quantity: 1,
      cost: 0,
      docs: []
    }
  }),

  mounted () {
    this.editedItem.date = Number(moment().format('x'))
  },

  computed: {
    isEntry () {
      return this.editedItem.detail?.movement === 'entry'
    },
    dateParsed () {
      return moment(this.editedItem.date, 'x').format().substr(0, 10)
    },
    cellarItems () {
      const cellars = this.getCellars.map((cellar) => ({ text: cellar.name, value: cellar.code }))
      cellars.unshift({ text: 'Ninguna', value: null })
      return cellars
    },
    inventories () {
      const inventories = this.inventory.map((mov) => ({
        ...mov,
        product: this.products.find(({ commercialCode }) => commercialCode === mov.commercialCode)
      }))
      if (this.voucher) return inventories.filter(({ voucher }) => voucher === this.voucher)
      if (this.product) return inventories.filter(({ product }) => product?.commercialCode === this.product)
      return inventories
    },

    getProductTmp () {
      const product = this.getProducts.find(item => {
        return item.commercialCode === this.editedItem.product
      })
      return product
    },

    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    itemsProductsCode () {
      const items = []
      for (var i = this.getProducts.length - 1; i >= 0; i--) {
        if (this.getProducts[i].inventoryable) {
          items.push({ name: this.getProducts[i].description, code: this.getProducts[i].commercialCode })
        }
      }
      return items
    },
    getProducts () {
      return this.products || []
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ]),
    ...mapGetters('inventories', [
      'getStock',
      'getCellars'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    'editedItem.movement' (val) {
      if (val === 'exit') {
        const product = this.getProductTmp
        const unitCost = Number(product.entriesValue || 0) / Number(product.entries || 1)
        if (unitCost) { this.editedItem.cost = unitCost }
      }
    },
    'editedItem.detail' (val) {
      if (val.movement === 'exit') {
        this.editedItem.entry = null
        const product = this.getProductTmp
        const unitCost = Number(product.entriesValue || 0) / Number(product.entries || 1)
        if (unitCost) { this.editedItem.cost = Number(unitCost.toFixed(2)) }
        return
      }
      this.editedItem.exit = null
    }
  },

  methods: {
    setDate (value) {
      this.menuDate = false
      this.editedItem.date = Number(moment(value).format('x'))
    },

    round (number) {
      return tools.round10(number, -2)
    },

    getDateFormat (date) {
      return moment(date, 'x').format('L')
    },

    inputDetail () {
      const movement = this.details.find(item => {
        return item.name === this.editedItem.detail?.name
      })
      this.editedItem.movement = movement.movement
      /* if (
          this.editedItem.detail === 'Venta'
          || this.editedItem.detail === 'Perdida'
        ) {
          this.editedItem.movement = 'exit';
        } else {
          this.editedItem.movement = 'entry';
        }/**/
    },

    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      const index = this.desserts.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.date = Number(moment().format('x'))
        this.editedIndex = -1
      })
    },

    save () {
      const inventory = {}
      // inventory.id = this.inventories.length + 1
      if (!this.editedItem.detail) {
        return
      }
      if (this.isEntry && !this.editedItem.entry) {
        return
      }
      if (!this.isEntry && !this.editedItem.exit) {
        return
      }
      inventory.date = this.editedItem.date
      inventory.company = this.getCompany.identification
      inventory.product = this.editedItem.product
      inventory.detail = this.editedItem.detail.name
      inventory.entry = this.editedItem.entry
      inventory.exit = this.editedItem.exit
      inventory.quantity = this.editedItem.quantity
      inventory.cost = Number(this.editedItem.cost * this.editedItem.quantity)
      inventory.docs = this.editedItem.docs
      inventory.user = this.getUser?.uid || null
      this.insertInventory(inventory)
        .then(() => {
          this.$emit('update')
          this.close()
        })
    },
    ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
