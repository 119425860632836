<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    :items-per-page="voucher.data.items.length"
    dense
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Direccionamiento de compras y gastos</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom v-for="(option, index) in items" :key="index">
        <template v-slot:activator="{ on, attrs }" v-if="!(option.text === 'Descargar Respuesta Hacienda' && item.data.documentType === '10')">
          <v-icon
            v-bind="attrs"
            v-on="on"
            small
            class="mr-1"
            :color="option.color || 'info'"
            @click.stop="option.text === 'Realizar pago' ? newPayVoucher(item) : option.callback(item)"
            :disabled="(option.text === 'Realizar pago' && (Number(item.amountOutstanding) <= 0 || item.deleted || !item.isVoucherSale)) || (option.text === 'Descargar Respuesta Hacienda' && (item.data.documentType === '11' || item.data.documentType === '10'))"
          >
            {{ option.icon }}
          </v-icon>
        </template>
        <span>{{ option.text }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.value="{ item }">
      {{ item.value | currencyFormat(voucher.data.summary.currency) }}
    </template>
    <template v-slot:item.addressing="{ item }">
      <div v-if="item.inventory">Inventario en {{ item.inventory.product }}</div>
      <div v-if="item.ao">Gasto en {{ getAccountsCatalogIndexed[item.ao.concept].name }}</div>
      <div v-if="item.fixedAsset">Activo {{ item.fixedAsset }}</div>
      <div v-if="!item.classified">Pendiente</div>
    </template>
  </v-data-table>
</template>

<script>
import { /* mapState,  mapActions,/* */ mapGetters } from 'vuex'
export default {
  name: 'Addressing',
  props: {
    voucher: Object
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    items () {
      const items = [...this.voucher.items]
      return items
    },
    headers () {
      const headers = [
        { text: 'N°', value: 'line' },
        { text: 'Código', value: 'commercialCode' },
        { text: 'Detalle', value: 'description' },
        { text: 'Cantidad', value: 'quantity', align: 'end' },
        { text: 'Valor', value: 'value', align: 'end' },
        { text: 'Direccionado a', value: 'addressing' },
        { text: '', value: 'action' }
      ]
      return headers
    },
    getAccountsCatalogIndexed () {
      return this.getAccountsCatalog.reduce((acc, curr) => {
        if (!acc[curr.id]) acc[curr.id] = curr
        return acc
      }, {})
    },
    ...mapGetters('accounting', ['getAccountsCatalog'])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
