<template>
  <v-component :is="component"></v-component>
</template>

<script>
import About from '@/views/About'
import SignUp from '@/views/SignUp'
import PayVoucher from '@/views/PayVoucher'
import Terms from '@/views/Terms'
import SuccessPayment from '@/views/SuccessPayment.vue'

export default {
  name: 'Public',
  props: ['component'],
  components: { About, signup: SignUp, pay: PayVoucher, terms: Terms, successpay: SuccessPayment }
}
</script>
