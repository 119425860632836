/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
// import moment from 'moment'
import fbApi from '@/api/firebaseApi'
// import tools from '@/api/tools'

const { api } = fbApi

const formModule = {
  inputs: [],
  listinerStays: null
}

// initial state
const initialState = {
  stays: [],
  listinerStays: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getStay (state) {
    if (!(state.stays.length === 0)) {
      const index = state.stays.findIndex(item => {
        return item.id === state.currentStay
      })
      return index > -1 ? state.stays[index] : state.currentStay
    } else {
      return state.currentStay
    }
  },
  getStays ({ stays }) {
    return stays
  },
  getRoomTypes ({ roomTypes }) {
    return roomTypes
  },
  getTmpRoomType ({ tmpRoomType }) {
    return tmpRoomType
  },
  getBeds ({ tmpRoomType }) {
    return tmpRoomType.beds
  },
  getFacilities ({ tmpRoomType }) {
    return tmpRoomType.facilities
  },
  getPrices ({ tmpRoomType }) {
    return tmpRoomType.prices
  },
  getForm () {
    return formModule
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const staysQuerySnapshot = await fbApi.db.collection('stays')
        .where('company', '==', company)
        .get()
      const stays = []
      staysQuerySnapshot.forEach((doc) => stays.push({ uid: doc.id, ...doc.data() }))
      commit('setStays', stays)
      const listinerStays = staysQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newStays = []
        querySnapshot.forEach((doc) => newStays.push({ uid: doc.id, ...doc.data() }))
        commit('setStays', newStays)
      })
      commit('setListinerStays', listinerStays)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar pagos recibidos')
    }
  },
  submitRoomType ({ state, commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      const { tmpRoomType } = state
      api.insertItem('roomTypes', tmpRoomType)
        .then((roomType) => {
          resolve(roomType ? 'Tipo de hanitación ' + roomType.id + ' actualizado' : 'Tipo de hanitación ingresado')
          commit('setRoomTypeDefault')
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerStays) {
      state.listinerStays()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerStays (state, listener) {
    state.listinerStays = listener
  },
  setRoomTypeDefault: (state) => {
    state.tmpRoomType = {
      id: null,
      name: '',
      descripcion: '',
      beds: [],
      facilities: [],
      prices: [],
      maxChildrenRoom: 0
    }
  },
  setCurrentStay: (state, stay) => {
    state.currentStay = stay || null
  },
  setStays: (state, stays) => {
    if (!stays) state.listinerStays()
    state.stays = stays
  },
  setRoomTypes: (state, roomTypes) => {
    state.roomTypes = roomTypes
  },
  setTmpRoomType: (state, roomType) => {
    state.tmpRoomType = { ...roomType }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
