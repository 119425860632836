<template>
  <div class="datos-del-cliente">
    <div class="frame-parent">
      <div class="datos-del-cliente-wrapper">
        <div class="datos-del-cliente1">Datos del cliente</div>
      </div>
      <div class="frame-group">
        <cd-input-text v-model="tmp.receiver.identification" @change="getByIdentification()" width="128.7px" label="Cedula" :required="true"></cd-input-text>
        <cd-input-text v-model="tmp.receiver.name" width="234.5px" label="Nombre del cliente" :required="true"></cd-input-text>
        <cd-input-text v-model="tmp.receiver.allowanceNumber" width="120.69px" label="Autorización" :required="false"></cd-input-text>
      </div>
      <div class="frame-parent2">
        <cd-input-text v-model="tmp.receiver.email" type="email" width="234.5px" label="Correo electronico" :required="true"></cd-input-text>
        <cd-input-text v-model="tmp.receiver.phone" width="115.39px" label="Teléfono" :required="true"></cd-input-text>
        <CdSelect
          v-model="priceList"
          width="158.35px"
          label="Tipo de cliente"
          :required="true"
          :items="getCompany.priceList"
        ></CdSelect>
      </div>
      <div class="frame-container">
        <div class="direccin-wrapper">
          <div class="cedula">Dirección</div>
        </div>
        <div class="frame-parent7">
          <CdSelect
            width="158.35px"
            label="Provincia"
            :required="false"
            :items="provinces"
            v-model="tmp.receiver.location.province"
            @change="getCounty"
          ></CdSelect>
          <CdSelect
            width="158.35px"
            label="Cantón"
            :required="false"
            :items="counties"
            v-model="tmp.receiver.location.county"
            @change="getDistrict"
          ></CdSelect>
          <CdSelect
            width="158.35px"
            label="Distrito"
            :required="false"
            :items="districts"
            v-model="tmp.receiver.location.district"
          ></CdSelect>
        </div>
      </div>
    </div>
    <div class="frame-group">
      <cd-input-text style="max-width: 548.23px;" v-model="tmp.receiver.location.others" width="100%" label="Otras señas"></cd-input-text>
    </div>
    <div class="frame-group">
      <cd-input-text style="max-width: 250px;" v-model="tmp.receiver.applicant.name" width="100%" label="Nombre solicitante"></cd-input-text>
      <cd-input-text style="max-width: 150px;" type="email" v-model="tmp.receiver.applicant.email" width="100%" label="Correo electrónico"></cd-input-text>
      <cd-input-text style="max-width: 110px;" v-model="tmp.receiver.applicant.phone" width="100%" label="Correo electrónico"></cd-input-text>
    </div>
    <div class="buttonbtnprimary-colorbtn-r" v-if="false">
      <div class="btn-text">Agregar solicitante</div>
    </div>
    <hr>

    <div class="buttonbtnprimary-colorbtn-r1" style="width: 111.6px; height: 28.67px;" v-if="loadingReceiver">
      <div class="btn-text"><v-progress-circular :size="20" indeterminate></v-progress-circular></div>
    </div>
    <div class="buttonbtnprimary-colorbtn-r1" @click="insertReceiver" v-else>
      <div class="btn-text">Agregar cliente</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import apiCE from '@/api/apiCE.js'
import tools from '@/api/tools.js'
import CdInputText from '@/components/cdInputs/CdInputText.vue'
import CdSelect from '@/components/cdInputs/CdSelect.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CustomerData',
  components: { CdInputText, CdSelect },
  props: {
  // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      loadingReceiver: false,
      priceList: 'general',
      tmp: {
        receiver: {
          uid: null,
          typeId: null,
          id: null,
          identification: null,
          name: null,
          email: null,
          phone: null,
          editing: false,
          location: {
            province: null,
            county: null,
            district: null,
            others: null
          },
          applicant: {
            email: null,
            phone: null,
            name: null
          },
          customer: {},
          allowanceNumber: null,
          allowance: null
        },
        item: {
          cabys: null,
          code: null,
          quantity: 1,
          description: null,
          price: {
            price: null,
            currency: 'CRC',
            priceList: 'general'
          },
          inventoryable: false,
          taxed: false,
          netTotal: 0,
          unit: 'Unid'
        },
        taxes: {
          code: '01',
          rate_code: '08',
          rate: 13
        },
        others: {
          otherText: null
        },
        searchCode: null,
        commandCode: null
      },
      provinces: [],
      counties: [],
      districts: [],
      customer: null
    }
  },
  computed: {
    ...mapGetters('customers', [
      'getCustomers'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
  // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    getAllowance () {
      return new Promise((resolve, reject) => {
        apiCE.getAllowance(this.tmp.receiver.allowanceNumber)
          .then((allowance) => resolve(allowance))
          .catch((e) => reject(e))
      })
    },
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key)
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/canton/' + this.tmp.receiver.location.county + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key)
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    insertReceiver: async function () {
      this.loadingReceiver = true
      let allowance = this.tmp.receiver.allowance
      if (this.tmp.receiver.allowanceNumber) {
        try {
          allowance = await this.getAllowance(this.tmp.receiver.allowanceNumber)
        } catch (error) {
          if (!allowance) {
            console.error(error)
            this.tmp.receiver.errorAllowance = 'No se pudo verificar exoneración'
          }
          this.loadingReceiver = false
          throw error
        }
      }
      const newCustomer = {
        uid: this.tmp.receiver.uid,
        id: this.tmp.receiver.id,
        typeId: this.tmp.receiver.typeId,
        identification: this.tmp.receiver.identification,
        name: this.tmp.receiver.name,
        email: this.tmp.receiver.email,
        phone: this.tmp.receiver.phone,
        location: {
          province: this.tmp.receiver.location.province,
          county: this.tmp.receiver.location.county,
          district: this.tmp.receiver.location.district,
          others: this.tmp.receiver.location.others
        },
        priceList: this.priceList,
        allowance
      }
      const applicantName = this.tmp.receiver.applicant.name
      if (applicantName && applicantName !== '') {
        const applicant = this.tmp.receiver.applicant
        const contacts = this.customer?.contacts || []
        const i = contacts.findIndex(({ name }) => name === applicantName)
        if (i > -1) { contacts[i] = { ...applicant } } else { contacts.push({ ...applicant }) }
        newCustomer.contacts = contacts
        this.setApplicant(this.tmp.receiver.applicant)
      }
      const cus = await this.insertCustomer(newCustomer)
      const newReceiver = {
        name: this.tmp.receiver.name,
        identificationDocument: {
          documentType: this.tmp.receiver.typeId,
          number: this.tmp.receiver.identification
        },
        phone: this.tmp.receiver.phone,
        email: this.tmp.receiver.email
      }
      if (allowance) newReceiver.allowance = allowance.payload
      if (
        this.tmp.receiver.location.province &&
        this.tmp.receiver.location.county &&
        this.tmp.receiver.location.district &&
        this.tmp.receiver.location.others &&
        this.tmp.receiver.location.province !== null &&
        this.tmp.receiver.location.county !== null &&
        this.tmp.receiver.location.district !== null &&
        this.tmp.receiver.location.others !== null
      ) {
        newReceiver.location = {
          province: this.tmp.receiver.location.province,
          county: tools.zfill(this.tmp.receiver.location.county, 2),
          district: tools.zfill(this.tmp.receiver.location.district, 2),
          others: this.tmp.receiver.location.others
        }
      }
      this.setReceiver(newReceiver)
      this.setReceiverId(cus.uid || cus)
      // this.$set(this.invoice, "receiver", newReceiver)
      this.dialogReceiver = false
      this.cleanReceiver()
    },
    setCustomer: function () {
      const customer = { ...this.tmp.customer }
      if (Object.keys(customer).length > 0) {
        this.tmp.receiver.uid = customer.uid
        this.tmp.receiver.id = customer.id
        this.tmp.receiver.typeId = customer.typeId
        this.tmp.receiver.name = customer.name
        this.tmp.receiver.identification = customer.identification
        this.tmp.receiver.email = customer.email
        this.tmp.receiver.phone = customer.phone
        this.tmp.receiver.allowance = customer.allowance && Object.keys(customer.allowance).length > 0
          ? customer.allowance : null
        this.priceList = customer.priceList
        this.tmp.receiver.allowanceNumber = customer.allowance?.payload.docNumber || null

        if (customer.location) {
          this.tmp.receiver.location.province = customer.location.province
          this.tmp.receiver.location.others = customer.location.others
          this.getCounty()
            .then(() => {
              this.tmp.receiver.location.county = customer.location.county
              return this.getDistrict()
            })
            .then(() => {
              this.tmp.receiver.location.district = customer.location.district
            })
            .catch(error => {
              console.error(error)
            })
        }
      }
    },
    getByIdentification: function () {
      if (!this.tmp.receiver.identification) return
      const index = this.getCustomers.findIndex(customer => {
        return customer.identification === this.tmp.receiver.identification
      })
      if (index > -1) {
        this.customer = this.getCustomers[index]
        this.tmp.receiver.uid = this.getCustomers[index].uid
        this.tmp.receiver.typeId = this.getCustomers[index].typeId
        this.tmp.receiver.name = this.getCustomers[index].name
        this.tmp.receiver.email = this.getCustomers[index].email
        this.tmp.receiver.phone = this.getCustomers[index].phone
        this.priceList = this.getCustomers[index].priceList
        this.tmp.receiver.allowanceNumber = this.getCustomers[index].allowance?.payload.docNumber || null
        this.tmp.receiver.allowance = this.getCustomers[index].allowance && Object.keys(this.getCustomers[index].allowance).length > 0
          ? this.getCustomers[index].allowance : null

        if (this.getCustomers[index].location) {
          this.tmp.receiver.location.province = this.getCustomers[index].location.province
          this.tmp.receiver.location.others = this.getCustomers[index].location.others
          this.getCounty()
            .then(() => {
              this.tmp.receiver.location.county = this.getCustomers[index].location.county
              return this.getDistrict()
            })
            .then(() => {
              this.tmp.receiver.location.district = this.getCustomers[index].location.district
            })
            .catch(error => {
              console.error(error)
            })
        }

        if (this.customer?.contacts?.length > 0) {
          this.tmp.receiver.applicant.name = this.customer.contacts[0].name || null
          this.tmp.receiver.applicant.email = this.customer.contacts[0].email || null
          this.tmp.receiver.applicant.phone = this.customer.contacts[0].phone || null
        }
      } else {
        this.customer = null
        axios
          .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.tmp.receiver.identification)
          .then(response => {
          // var receiver = {id: id, typeId: response.data.tipoIdentificacion, name: response.data.nombre}
            this.tmp.receiver.typeId = response.data.tipoIdentificacion
            this.tmp.receiver.name = response.data.nombre
          })
      }
    },
    cleanReceiver: function () {
      this.tmp.receiver = {
        typeId: null,
        id: null,
        name: null,
        email: null,
        phone: null,
        location: {
          province: null,
          county: null,
          district: null,
          others: null
        },
        applicant: {
          email: null,
          phone: null,
          name: null
        }
      }
      this.tmp.customer = {}
      this.loadingReceiver = false
    },
    setReceiver (payload) {
      this.$store.dispatch('1/setReceiver', payload)
    },
    setReceiverId (payload) {
      this.$store.commit('1/setReceiverId', payload)
    },
    setApplicant (payload) {
      this.$store.commit('1/setApplicant', payload)
    },
    ...mapActions('customers', [
      'insertCustomer'
    ])
  },
  beforeCreate () {
  // Acciones antes de crear el componente
  },
  created () {
  // Acciones al crear el componente
  },
  beforeMount () {
  // Acciones antes de montar el componente
  },
  mounted () {
    this.getProvince()
  },
  beforeUpdate () {
  // Acciones antes de actualizar el componente
  },
  updated () {
  // Acciones al actualizar el componente
  },
  beforeDestroy () {
  // Acciones antes de destruir el componente
  },
  destroyed () {
  // Acciones al destruir el componente
  }
}
</script>
<style scoped>
hr {
  border: 1.6px solid #e5eaff;
      width: 100%;
      border-radius: 25px;
}
.datos-del-cliente1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 500;
}
.datos-del-cliente-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #480ca8;
  font-family: Rubik;
}
.cedula {
  position: relative;
  font-weight: 500;
}
.div {
  position: relative;
  font-weight: 500;
  color: #f72585;
}
.cedula-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.div1 {
  flex: 1;
  position: relative;
}
.wrapper {
  width: 105px;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.frame-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.nombre-del-cliente-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx {
  width: 200.5px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.autorizacin-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div3 {
  width: 86.7px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.frame-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}
.xxxxxxxxxxxxxx {
  width: 81.4px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.mayorista {
  width: 101.7px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.icon-chevron {
  width: 16px;
  position: relative;
  height: 16px;
  flex-shrink: 0;
  object-fit: contain;
}
.mayorista-parent {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frame-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}
.direccin-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.provincia-parent {
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 6px;
}
.frame-parent7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
  font-size: 11px;
  color: #000;
}
.frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.frame {
  width: 150px;
  border-radius: 8px;
  border: 1px solid #4361ee;
  box-sizing: border-box;
  height: 23px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  font-size: 11px;
  color: #000;
}
.btn-text {
  position: relative;
  letter-spacing: 0.12px;
  line-height: 12.66px;
  font-weight: 500;
}
.buttonbtnprimary-colorbtn-r {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  text-align: center;
  color: #fff;
}
.datos-del-cliente-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}
.buttonbtnprimary-colorbtn-r1 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.datos-del-cliente {
  width: 100%;
  position: relative;
  border-radius: 20px;
  background-color: #fff;
  border: 1.5px solid #4361ee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 24px;
  gap: 24px;
  text-align: left;
  font-size: 12px;
  color: #3f37c9;
  font-family: Roboto;
}
</style>
