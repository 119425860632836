<template>
  <v-container>
    <PaymentsTable />
  </v-container>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import PaymentsTable from '@/components/PaymentsTable.vue'

export default {
  name: 'Payments',
  components: { PaymentsTable },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  created () {
  },

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
