export default {
  'audio/aac': {
    extension: '.aac',
    documentType: 'AAC audio',
    mimeType: 'audio/aac'
  },
  'application/x-abiword': {
    extension: '.abw',
    documentType: 'AbiWord document',
    mimeType: 'application/x-abiword'
  },
  'image/apng': {
    extension: '.apng',
    documentType: 'Animated Portable Network Graphics (APNG) image',
    mimeType: 'image/apng'
  },
  'application/x-freearc': {
    extension: '.arc',
    documentType: 'Archive document (multiple files embedded)',
    mimeType: 'application/x-freearc'
  },
  'image/avif': {
    extension: '.avif',
    documentType: 'AVIF image',
    mimeType: 'image/avif'
  },
  'video/x-msvideo': {
    extension: '.avi',
    documentType: 'AVI: Audio Video Interleave',
    mimeType: 'video/x-msvideo'
  },
  'application/vnd.amazon.ebook': {
    extension: '.azw',
    documentType: 'Amazon Kindle eBook format',
    mimeType: 'application/vnd.amazon.ebook'
  },
  'application/octet-stream': {
    extension: '.bin',
    documentType: 'Any kind of binary data',
    mimeType: 'application/octet-stream'
  },
  'image/bmp': {
    extension: '.bmp',
    documentType: 'Windows OS/2 Bitmap Graphics',
    mimeType: 'image/bmp'
  },
  'application/x-bzip': {
    extension: '.bz',
    documentType: 'BZip archive',
    mimeType: 'application/x-bzip'
  },
  'application/x-bzip2': {
    extension: '.bz2',
    documentType: 'BZip2 archive',
    mimeType: 'application/x-bzip2'
  },
  'application/x-cdf': {
    extension: '.cda',
    documentType: 'CD audio',
    mimeType: 'application/x-cdf'
  },
  'application/x-csh': {
    extension: '.csh',
    documentType: 'C-Shell script',
    mimeType: 'application/x-csh'
  },
  'text/css': {
    extension: '.css',
    documentType: 'Cascading Style Sheets (CSS)',
    mimeType: 'text/css'
  },
  'text/csv': {
    extension: '.csv',
    documentType: 'Comma-separated values (CSV)',
    mimeType: 'text/csv'
  },
  'application/msword': {
    extension: '.doc',
    documentType: 'Microsoft Word',
    mimeType: 'application/msword'
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    extension: '.docx',
    documentType: 'Microsoft Word (OpenXML)',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  'application/vnd.ms-fontobject': {
    extension: '.eot',
    documentType: 'MS Embedded OpenType fonts',
    mimeType: 'application/vnd.ms-fontobject'
  },
  'application/epub+zip': {
    extension: '.epub',
    documentType: 'Electronic publication (EPUB)',
    mimeType: 'application/epub+zip'
  },
  'application/gzip. Note, Windows and macOS upload .gz files with the non-standard MIME type application/x-gzip.': {
    extension: '.gz',
    documentType: 'GZip Compressed Archive',
    mimeType: 'application/gzip. Note, Windows and macOS upload .gz files with the non-standard MIME type application/x-gzip.'
  },
  'image/gif': {
    extension: '.gif',
    documentType: 'Graphics Interchange Format (GIF)',
    mimeType: 'image/gif'
  },
  'text/html': {
    extension: '.htm, .html',
    documentType: 'HyperText Markup Language (HTML)',
    mimeType: 'text/html'
  },
  'image/vnd.microsoft.icon': {
    extension: '.ico',
    documentType: 'Icon format',
    mimeType: 'image/vnd.microsoft.icon'
  },
  'text/calendar': {
    extension: '.ics',
    documentType: 'iCalendar format',
    mimeType: 'text/calendar'
  },
  'application/java-archive': {
    extension: '.jar',
    documentType: 'Java Archive (JAR)',
    mimeType: 'application/java-archive'
  },
  'image/jpeg': {
    extension: '.jpeg, .jpg',
    documentType: 'JPEG images',
    mimeType: 'image/jpeg'
  },
  'text/javascript (Specifications: HTML and RFC 9239)': {
    extension: '.js',
    documentType: 'JavaScript',
    mimeType: 'text/javascript (Specifications: HTML and RFC 9239)'
  },
  'application/json': {
    extension: '.json',
    documentType: 'JSON format',
    mimeType: 'application/json'
  },
  'application/ld+json': {
    extension: '.jsonld',
    documentType: 'JSON-LD format',
    mimeType: 'application/ld+json'
  },
  'audio/midi, audio/x-midi': {
    extension: '.mid, .midi',
    documentType: 'Musical Instrument Digital Interface (MIDI)',
    mimeType: 'audio/midi, audio/x-midi'
  },
  'text/javascript': {
    extension: '.mjs',
    documentType: 'JavaScript module',
    mimeType: 'text/javascript'
  },
  'audio/mpeg': {
    extension: '.mp3',
    documentType: 'MP3 audio',
    mimeType: 'audio/mpeg'
  },
  'video/mp4': {
    extension: '.mp4',
    documentType: 'MP4 video',
    mimeType: 'video/mp4'
  },
  'video/mpeg': {
    extension: '.mpeg',
    documentType: 'MPEG Video',
    mimeType: 'video/mpeg'
  },
  'application/vnd.apple.installer+xml': {
    extension: '.mpkg',
    documentType: 'Apple Installer Package',
    mimeType: 'application/vnd.apple.installer+xml'
  },
  'application/vnd.oasis.opendocument.presentation': {
    extension: '.odp',
    documentType: 'OpenDocument presentation document',
    mimeType: 'application/vnd.oasis.opendocument.presentation'
  },
  'application/vnd.oasis.opendocument.spreadsheet': {
    extension: '.ods',
    documentType: 'OpenDocument spreadsheet document',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet'
  },
  'application/vnd.oasis.opendocument.text': {
    extension: '.odt',
    documentType: 'OpenDocument text document',
    mimeType: 'application/vnd.oasis.opendocument.text'
  },
  'audio/ogg': {
    extension: '.oga',
    documentType: 'Ogg audio',
    mimeType: 'audio/ogg'
  },
  'video/ogg': {
    extension: '.ogv',
    documentType: 'Ogg video',
    mimeType: 'video/ogg'
  },
  'application/ogg': {
    extension: '.ogx',
    documentType: 'Ogg',
    mimeType: 'application/ogg'
  },
  'font/otf': {
    extension: '.otf',
    documentType: 'OpenType font',
    mimeType: 'font/otf'
  },
  'image/png': {
    extension: '.png',
    documentType: 'Portable Network Graphics',
    mimeType: 'image/png'
  },
  'application/pdf': {
    extension: '.pdf',
    documentType: 'Adobe Portable Document Format (PDF)',
    mimeType: 'application/pdf'
  },
  'application/x-httpd-php': {
    extension: '.php',
    documentType: 'Hypertext Preprocessor (Personal Home Page)',
    mimeType: 'application/x-httpd-php'
  },
  'application/vnd.ms-powerpoint': {
    extension: '.ppt',
    documentType: 'Microsoft PowerPoint',
    mimeType: 'application/vnd.ms-powerpoint'
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    extension: '.pptx',
    documentType: 'Microsoft PowerPoint (OpenXML)',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  'application/vnd.rar': {
    extension: '.rar',
    documentType: 'RAR archive',
    mimeType: 'application/vnd.rar'
  },
  'application/rtf': {
    extension: '.rtf',
    documentType: 'Rich Text Format (RTF)',
    mimeType: 'application/rtf'
  },
  'application/x-sh': {
    extension: '.sh',
    documentType: 'Bourne shell script',
    mimeType: 'application/x-sh'
  },
  'image/svg+xml': {
    extension: '.svg',
    documentType: 'Scalable Vector Graphics (SVG)',
    mimeType: 'image/svg+xml'
  },
  'application/x-tar': {
    extension: '.tar',
    documentType: 'Tape Archive (TAR)',
    mimeType: 'application/x-tar'
  },
  'image/tiff': {
    extension: '.tif, .tiff',
    documentType: 'Tagged Image File Format (TIFF)',
    mimeType: 'image/tiff'
  },
  'video/mp2t': {
    extension: '.ts',
    documentType: 'MPEG transport stream',
    mimeType: 'video/mp2t'
  },
  'font/ttf': {
    extension: '.ttf',
    documentType: 'TrueType Font',
    mimeType: 'font/ttf'
  },
  'text/plain': {
    extension: '.txt',
    documentType: 'Text, (generally ASCII or ISO 8859-n)',
    mimeType: 'text/plain'
  },
  'application/vnd.visio': {
    extension: '.vsd',
    documentType: 'Microsoft Visio',
    mimeType: 'application/vnd.visio'
  },
  'audio/wav': {
    extension: '.wav',
    documentType: 'Waveform Audio Format',
    mimeType: 'audio/wav'
  },
  'audio/webm': {
    extension: '.weba',
    documentType: 'WEBM audio',
    mimeType: 'audio/webm'
  },
  'video/webm': {
    extension: '.webm',
    documentType: 'WEBM video',
    mimeType: 'video/webm'
  },
  'image/webp': {
    extension: '.webp',
    documentType: 'WEBP image',
    mimeType: 'image/webp'
  },
  'font/woff': {
    extension: '.woff',
    documentType: 'Web Open Font Format (WOFF)',
    mimeType: 'font/woff'
  },
  'font/woff2': {
    extension: '.woff2',
    documentType: 'Web Open Font Format (WOFF)',
    mimeType: 'font/woff2'
  },
  'application/xhtml+xml': {
    extension: '.xhtml',
    documentType: 'XHTML',
    mimeType: 'application/xhtml+xml'
  },
  'application/vnd.ms-excel': {
    extension: '.xls',
    documentType: 'Microsoft Excel',
    mimeType: 'application/vnd.ms-excel'
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    extension: '.xlsx',
    documentType: 'Microsoft Excel (OpenXML)',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  'application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes. You can assign a specific MIME type to a file with .xml extension depending on how its contents are meant to be interpreted. For instance, an Atom feed is application/atom+xml, but application/xml serves as a valid default.': {
    extension: '.xml',
    documentType: 'XML',
    mimeType: 'application/xml is recommended as of RFC 7303 (section 4.1), but text/xml is still used sometimes. You can assign a specific MIME type to a file with .xml extension depending on how its contents are meant to be interpreted. For instance, an Atom feed is application/atom+xml, but application/xml serves as a valid default.'
  },
  'application/vnd.mozilla.xul+xml': {
    extension: '.xul',
    documentType: 'XUL',
    mimeType: 'application/vnd.mozilla.xul+xml'
  },
  'application/zip. Note, Windows uploads .zip files with the non-standard MIME type application/x-zip-compressed.': {
    extension: '.zip',
    documentType: 'ZIP archive',
    mimeType: 'application/zip. Note, Windows uploads .zip files with the non-standard MIME type application/x-zip-compressed.'
  },
  "video/3gpp; audio/3gpp if it doesn't contain video": {
    extension: '.3gp',
    documentType: '3GPP audio/video container',
    mimeType: "video/3gpp; audio/3gpp if it doesn't contain video"
  },
  "video/3gpp2; audio/3gpp2 if it doesn't contain video": {
    extension: '.3g2',
    documentType: '3GPP2 audio/video container',
    mimeType: "video/3gpp2; audio/3gpp2 if it doesn't contain video"
  },
  'application/x-7z-compressed': {
    extension: '.7z',
    documentType: '7-zip archive',
    mimeType: 'application/x-7z-compressed'
  }
}
