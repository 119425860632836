<template>
  <v-container>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab>
        Asientos contables
      </v-tab>
      <v-tab>
        Balance de comprobación
      </v-tab>
    </v-tabs>

    <Period></Period>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <GeneralJournal></GeneralJournal>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <GeneralLedger></GeneralLedger>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>
<script>
import GeneralJournal from '@/components/GeneralJournal.vue'
import GeneralLedger from '@/components/GeneralLedger.vue'
import Period from '@/components/Period.vue'
export default {
  name: 'AccountingEntries',
  components: { GeneralJournal, GeneralLedger, Period },
  data: () => ({
    tab: 0
  })
}
</script>
<style type="text/css">

</style>
