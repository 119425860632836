<template>
  <v-container>
    <div class="headline my-7 ml-2">
      {{ isPayment ? 'Pago' : 'Cobro' }} {{ uid }}
    </div>
    <v-card
      elevation="2"
      class="my-10"
      v-if="!loading"
      :loading="sending"
    >
      <v-tabs v-model="tab">
        <v-tab>
          Información generales
        </v-tab>
        <v-tab>
          Asientos contables
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <NewReceipt
            @cancel="loading = false"
            :item="tmp.receipt"
            :view="true"
          ></NewReceipt>
        </v-tab-item>
        <v-tab-item>
          <Entries :entries="entry"></Entries>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Entries from '../components/Entries.vue'
import NewReceipt from '../components/NewReceipt.vue'

export default {
  name: 'Receipt',
  props: ['uid'],
  components: { Entries, NewReceipt },
  data: () => ({
    tab: 0,
    receipt: null,
    tmp: {
      receipt: {}
    },
    loading: true,
    sending: false,
    isPayment: false
  }),
  methods: {
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
  },
  computed: {
    entry () {
      return this.getJournal.filter(({ payment, receipt }) => payment === this.receipt?.uid || receipt === this.receipt?.uid)
    },
    ...mapGetters('accountReceivable', ['getReceipts']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('payments', ['getPayments']),
    ...mapGetters('accounting', [
      'getJournal'
    ])
  },
  mounted () {
    let receipt = this.getReceipts.find(({ uid }) => uid === this.uid)
    if (!receipt) {
      receipt = this.getPayments.find(({ uid }) => uid === this.uid)
      this.isPayment = true
    }
    this.receipt = receipt
    this.tmp.receipt = { ...this.receipt }
    this.loading = false
  }
}
</script>
