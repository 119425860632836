<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="650"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="!icon"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <i
          v-html="svgIcon"
          v-bind="attrs"
          v-on="on"
          v-else></i>
      </template>
      <ProductionFormula :products="products" :productUid="item && item.uid"></ProductionFormula>
    </v-dialog>
  </div>
</template>
<script>
import ProductionFormula from '@/components/ProductionFormula.vue'
import redaIcons from '../assets/redaIcons'
export default {
  name: 'PFNewDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object,
    products: Array,
    icon: String
  },
  components: {
    ProductionFormula
  },
  computed: {
    svgIcon () {
      return redaIcons[this.icon] || null
    }
  }
}
</script>
