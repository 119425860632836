<template>
  <v-list dense nav>
    <v-list-item-group
      v-model="selectedItem"
      color="primary"
    >
      <v-list-item v-for="(chat, index) in chats" :key="index">
        <v-list-item-avatar>
          <v-img :src="chat.avatar" alt="Avatar" v-if="chat.avatar"></v-img>
          <v-icon v-else>
            mdi-account
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ chat.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ chat.lastMessage.body }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text v-text="chat.lastMessageTime"></v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'ChatBar',
  data: () => ({
    selectedItem: null
  }),
  props: {
    chats: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    selectedItem (newValue, oldValue) {
      this.$emit('selectItem', newValue)
    }
  }
}
</script>
