<template>
  <v-container>
    <ProductionOrders
      :productionOrders="productionOrders"
      :products="products"
    />
    <v-tabs
      v-if="false"
    >
      <v-tab>Ordenes de producción</v-tab>

      <v-tab-item>
        <ProductionOrders
          :productionOrders="productionOrders"
          :products="products"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import firebaseApi from '../api/firebaseApi'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ProductionOrders from '@/components/ProductionOrders.vue'
import moment from 'moment'

export default {
  name: 'Production',

  components: {
    ProductionOrders
  },

  data () {
    return {
      e1: 1,
      loading: false,
      products: [],
      listener: null,
      tasks: [],
      childs: {}
      // Aquí es donde se declararían las variables de tu componente
    }
  },

  computed: {
    productionOrders () {
      const productionOrders = []
      const tasks = this.tasks
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i]
        if (task.product) {
          const childs = this.childs[task.uid] || []
          const completedChilds = childs.reduce((acc, curr) => {
            return curr.completed + acc
          }, 0) / (childs.length || 1)
          const start = (task.start || task.created)
          const end = task.duration
            ? Number(moment(start, 'x')
              .add(task.duration.value, task.duration.type)
              .format('x'))
            : null
          const todos = task.todos || []
          const todosLength = todos?.length || 0
          const checked = todos
            .reduce((acc, { value }) => {
              acc += Number(value)
              return acc
            }, 0)
          const completed = task.completed ? task.completed : todosLength > 0
            ? checked / todosLength : (completedChilds || 0)
          const status = completed > 0 ? (completed < 1 ? '04' : '05') : '03'

          const product = this.productsIndexed[task.product]
          if (!product) console.log(task.product)
          const i = childs
            .findIndex(({ completed }) => Number(completed) === 0)
          task.commercialCode = product?.commercialCode || task.product?.commercialCode
          task.product = product
          task.nextStep = childs[i + 1] || null
          task.currStep = childs[i] || { ...task }
          productionOrders.push({
            ...task,
            id: task.uid,
            start,
            end,
            completed: Number(completed.toFixed(2)),
            status: status !== '05' &&
        task.deadline && task.deadline < new Date().getTime()
              ? '01' : status,
            childs
          })
        }
      }
      return productionOrders
    },
    ...mapGetters('company', ['getCompany'])
  },

  methods: {
    async getProducts () {
      this.products = []
      this.productsIndexed = {}
      this.loading = true
      try {
        const querySnapshot = await firebaseApi.db.collection('products')
          .where('company', '==', this.getCompany.identification).get()
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const doc = querySnapshot.docs[i]
          const product = { ...doc.data(), uid: doc.id }
          this.products.push(product)
          this.productsIndexed[product.commercialCode] = product
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async reset () {
      this.loading = true
      if (this.listener) this.listener()
      this.listener = firebaseApi.db.collection('tasks')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.tasks = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const task = { ...doc.data(), uid: doc.id }
            if (task.product || task.parent) {
              this.tasks[task.uid] = task
              if (task.parent) {
                if (!this.childs[task.parent]) this.childs[task.parent] = []
                this.childs[task.parent].push(task)
              }
            }
            this.tasks.push(task)
          }
          this.loading = false
        })
    },
    ...mapMutations([]),
    ...mapActions([])
  },
  mounted: async function () {
    await this.getProducts()
    this.reset()
  },
  beforeDestroy: function () {
    this.listener()
    console.log('beforeDestroy')
  }
}
</script>
