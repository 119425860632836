<template>
  <v-card>

    <v-card-text v-for="item in getStatementOfChangesInEquity.items" :key="item.text">

      <v-simple-table
        dense
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(header, i) in headers"
                :key="i"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item2, j) in item.items"
              :key="j"
            >
              <td v-for="header in headers" :key="header.text" :class="!isNaN(item[header.value]) ? 'text-right' : ''">
                {{ item2[header.value] | currencyFormat }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th v-for="header in headers" :key="header.text" class="subtitle-1 font-weight-medium" :class="!isNaN(item[header.value]) ? 'text-right' : ''">
                {{ item[header.value] | currencyFormat }}
              </th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>

<script>
import { /* mapState, mapActions,/**/ mapGetters } from 'vuex'
// import tools from '@/api/tools.js'
export default {
  name: 'StatementOfChangesInEquity',
  data () {
    return {
      headers: [
        { text: 'Cuentas', value: 'text' },
        { text: 'Patrimonio al comienzo del periodo', value: 'initBalance' },
        { text: 'Disminución', value: 'debits' },
        { text: 'Incrementos', value: 'credits' },
        { text: 'Patrimonio al final del periodo', value: 'balance' }
      ]
    }
  },
  methods: {
  },
  computed: {
    getStatementOfChangesInEquity () {
      const equity = this.getAllLedgers.find(({ code }) => code === '3')
      const items = [{
        text: equity.name,
        initBalance: equity.balances.prevBalance,
        debits: equity.balances.debit,
        credits: equity.balances.credit,
        balance: equity.balances.balance,
        items: this.getAllLedgers.filter(({ code }) => code[0] === '3' && code.length === 4)
          .map(({ balances, name, code }) => ({
            code,
            text: name,
            initBalance: balances.prevBalance,
            debits: balances.debit,
            credits: balances.credit,
            balance: balances.balance
          })).sort(function (a, b) {
            if (a.code > b.code) {
              return 1
            }
            if (a.code < b.code) {
              return -1
            }
            return 0
          })
      }]
      return { items, equity }
    },
    ...mapGetters('accounting', ['getAllLedgers'])
  }
}
</script>
