<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title>Actualizar post</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

      <v-card-text>
        <BlogForm :item="item"></BlogForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import BlogForm from '@/components/BlogForm.vue'
export default {
  name: 'BlogDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object
  },
  components: {
    BlogForm
  }
}
</script>
