<template>
  <v-card>
    <v-card-title>Nueva Compañia</v-card-title>
    <v-card-text>
      <RedaForm ref="form" :loading="loading" :options="form" v-model="item"></RedaForm>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="save"
        :disabled="!valid"
        :loading="loading"
      >
        Crear
      </v-btn>

      <v-btn
        text
        @click="reset"
      >
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import tools from '../api/tools'
import redaApp from '../api/redaApp'
import { mapActions } from 'vuex'
export default {
  name: 'NewCompany',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      loading: false,
      isMounted: false,
      provinces: [],
      counties: [],
      districts: [],
      item: {
        identification: null,
        name: null,
        email: null,
        phone: null,
        tradename: null,
        environment: 'prod',
        province: null,
        county: null,
        district: null,
        others: null,
        subscriptionPlan: null,
        userName: null,
        userEmail: null,
        subscription: false
      },
      defaultItem: {
        identification: null,
        name: null,
        email: null,
        phone: null,
        tradename: null,
        environment: 'prod',
        province: null,
        county: null,
        district: null,
        others: null,
        subscriptionPlan: null,
        userName: null,
        userEmail: null,
        subscription: false
      }
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    form () {
      return {
        inputs: [
          {
            id: '001',
            label: 'Identificación',
            name: 'identification',
            value: 'identification',
            type: 'text',
            rules: { required: true, regex: /^(?!0\d)\d{9}$|^\d{10}$|^(?!0\d)\d{11,12}$|^\d{10}$/ },
            cols: 12
          },
          {
            id: '002',
            label: 'Nombre',
            name: 'name',
            value: 'name',
            type: 'text',
            readonly: true,
            cols: 12
          },
          {
            id: '003',
            label: 'Correo Electrónico',
            name: 'email',
            value: 'email',
            type: 'text',
            rules: 'required|email',
            cols: 6
          },
          {
            id: '004',
            label: 'Teléfono',
            name: 'phone',
            value: 'phone',
            type: 'text',
            rules: 'required|digits:8',
            cols: 6
          },
          {
            id: '005',
            label: 'Nombre Comercial',
            name: 'tradename',
            value: 'tradename',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '007',
            label: 'Provincia',
            name: 'province',
            value: 'province',
            type: 'select',
            rules: 'required',
            items: this.provinces,
            cols: 4
          },
          {
            id: '008',
            label: 'Cantón',
            name: 'county',
            value: 'county',
            type: 'select',
            rules: 'required',
            items: this.counties,
            cols: 4
          },
          {
            id: '009',
            label: 'Distrito',
            name: 'district',
            value: 'district',
            type: 'select',
            items: this.districts,
            rules: 'required',
            cols: 4
          },
          {
            id: '010',
            label: 'Otras Señas',
            name: 'others',
            value: 'others',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '011',
            label: 'Plan',
            name: 'subscriptionPlan',
            value: 'subscriptionPlan',
            type: 'select',
            items: [
              { value: 'pm01', text: 'Plan Profesional Modalidad Mensual' },
              { value: 'pm02', text: 'Plan Comercial Modalidad Mensual' },
              { value: 'pm03', text: 'Plan Industrial Modalidad Mensual' },
              { value: 'pa01', text: 'Plan Profesional Modalidad Anual' },
              { value: 'pa02', text: 'Plan Comercial Modalidad Anual' },
              { value: 'pa03', text: 'Plan Industrial Modalidad Anual' }
            ],
            rules: 'required',
            cols: 12
          },
          {
            id: '012',
            label: 'Nombre del Usuario',
            name: 'userName',
            value: 'userName',
            type: 'text',
            rules: 'required',
            cols: 6
          },
          {
            id: '013',
            label: 'Email del Usuario',
            name: 'userEmail',
            value: 'userEmail',
            type: 'text',
            rules: 'required|email',
            cols: 6
          },
          {
            id: '014',
            label: 'Crear factura recurrente',
            name: 'subscription',
            value: 'subscription',
            type: 'checkbox',
            hiden: true,
            cols: 12
          }
        ]
      }
    },
    valid () {
      if (!this.isMounted) return false
      return this.$refs.form.valid
    }
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    'item.identification': async function (val, oldVal) {
      const regex = /^(?!0\d)\d{9}$|^\d{10}$|^(?!0\d)\d{11,12}$|^\d{10}$/
      if (!regex.test(val)) return
      const data = await this.getCompanyById(val)
      this.item.name = data.nombre
    },
    'item.province': async function (val, oldVal) {
      if (!val) return
      this.getCounty()
    },
    'item.county': async function (val, oldVal) {
      if (!val) return
      this.getDistrict()
    }
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    save () {
      this.loading = true
      const addZero = (value) => value.length === 1 ? '0' + value : value
      const newCompany = {
        identification: this.item.identification,
        name: this.item.name,
        email: this.item.email,
        phone: this.item.phone,
        tradename: this.item.tradename,
        environment: this.item.environment,
        location: {
          province: this.item.province,
          county: addZero(this.item.county),
          district: addZero(this.item.district),
          others: this.item.others
        },
        subscriptionPlan: this.item.subscriptionPlan,
        userName: this.item.userName || this.item.name,
        userEmail: this.item.userEmail || this.item.email,
        subscription: this.item.subscription
      }
      redaApp.createCompany(newCompany)
        .then(() => {
          this.showSuccess('Compañia creada')
          this.reset()
        })
        .catch((e) => {
          this.showError('Error al crear compañia')
          console.error(e)
        })
        .finally(() => { this.loading = false })
    },
    reset () {
      this.item = { ...this.defaultItem }
      this.$emit('close')
    },
    async getCompanyById (ident) {
      try {
        const response = await axios.get('https://api.hacienda.go.cr/fe/ae?identificacion=' + ident)
        return response.data
      } catch (error) {
        tools.handleAxiosError(error)
        throw new Error('Error al obtener información del ministerio de hacienda')
      }
    },
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.item.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key)
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.item.province + '/canton/' + this.item.county + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key)
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    this.getProvince()
    this.isMounted = true
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
