<template>
  <div class="formulas-de-produccin">
  <div class="crea-los-pasos-requeridos-wrapper">
    <div class="selecciona-los-materiales">Crea los pasos requeridos</div>
  </div>
  <div class="frame-parent17">
    <div class="frame-group">
      <div class="nombre-del-producto-parent">
        <div class="nombre-del-producto">Nombre del paso</div>
        <div class="div">*</div>
      </div>
      <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper">
        <input type="text" class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" v-model="step.name" >
      </div>
    </div>
    <div class="frame-group">
      <div class="cdigo-cabys-parent">
        <div class="nombre-del-producto">Duracion</div>
        <div class="div">*</div>
      </div>
      <div class="wrapper2">
        <input type="number" name="" id=""  class="div13" v-model.number="step.duration.value">
      </div>
    </div>
    <div class="frame-div">
      <div class="cdigo-cabys-parent">
        <div class="nombre-del-producto">Unidad de medida</div>
        <div class="div">*</div>
      </div>
      <RedaSelect :items="timeUnits" width="170px" v-model="step.duration.type"></RedaSelect>
    </div>
  </div>
  <div class="frame-parent21">
    <div class="nombre-del-producto-parent">
      <div class="nombre-del-producto">Descripción del paso</div>
      <div class="div">*</div>
    </div>
    <div class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-frame">
      <input type="text" class="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" v-model="step.description" >
    </div>
  </div>
  <div class="frame-parent22">
    <div class="frame-div">
      <div class="cdigo-cabys-parent">
        <div class="nombre-del-producto">Departamento encargado</div>
        <div class="div">*</div>
      </div>
      <RedaAutocomplete v-if="getCostCenters.length > 7" :items="getCostCenters" itemValue="uid" itemText="name" v-model="step.cc"></RedaAutocomplete>
      <RedaSelect v-else :items="getCostCenters" itemValue="uid" itemText="name" v-model="step.cc"></RedaSelect>
    </div>
    <div class="frame-div" v-if="false">
      <div class="cdigo-cabys-parent" >
        <div class="nombre-del-producto">Agregar responsable</div>
        <div class="div">*</div>
      </div>
      <div class="seleccione-la-unidad-group">
        <div class="seleccione-el-departamento">Seleccione el departamento</div>
        <img class="icon-chevron" alt="" src="Icon / Chevron.svg">

      </div>
    </div>
  </div>
  <Materials :materialItems="materialProducts" v-model="step.drm"></Materials>
  <div @click="addStep"><RedaBtn icon="Add"  label="Crear paso" ></RedaBtn></div>
  <img class="frmulas-de-produccin-child1" alt="" src="Line 4.svg">

  <div class="frame-parent25">
    <div class="materiales-requeridos-wrapper">
      <div class="nombre-del-producto">Pasos</div>
    </div>
    <div class="frame-parent10" v-for="(item, i) in steps" :key="i">
      <div class="link-parent">
        <div class="link4">
          <div class="label">{{ i + 1 }}</div>
        </div>
        <div class="link5">
          <div class="label">{{ item.name }}</div>
        </div>
      </div>
      <div class="link18">
        <div class="label">{{ ccIndexed[item.cc].name }}</div>
      </div>
      <v-icon @click="deleteItem(i)" small>mdi-delete</v-icon>
    </div>
  </div>
  <img class="frmulas-de-produccin-child2" alt="" src="Line 5.svg">
  </div>
</template>
<script>
import RedaSelect from '@/components/redaInputs/RedaSelect.vue'
import RedaAutocomplete from '@/components/redaInputs/RedaAutocomplete.vue'
import Materials from '@/components/Materials.vue'
import RedaBtn from '@/components/redaInputs/RedaBtn.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AddSteps',
  components: {
    RedaSelect,
    RedaAutocomplete,
    Materials,
    RedaBtn
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    materialProducts: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      steps: this.value,
      step: {
        drm: [],
        duration: {
          type: 'h',
          value: 1
        },
        name: null,
        description: null,
        cc: null
      },
      defaultStep: {
        drm: [],
        duration: {
          type: 'h',
          value: 1
        },
        name: null,
        description: null,
        cc: null
      },
      timeUnits: [
        // { value: 'y', text: '' },
        // { value: 'Q', text: '' },
        // { value: 'M', text: '' },
        { value: 'w', text: 'Semanas' },
        { value: 'd', text: 'Días' },
        { value: 'h', text: 'Horas' },
        { value: 'm', text: 'Minutos' },
        { value: 's', text: 'Segundos' }
        // { value: 'ms', text: '' }
      ],
      materials: []
    }
  },
  computed: {
    ccIndexed () {
      return this.getCostCenters.reduce((acc, cur) => {
        acc[cur.uid] = cur
        return acc
      }, {})
    },
    ...mapGetters('siinco', ['getCostCenters'])
  },
  watch: {
    value (val) {
      this.steps = JSON.parse(JSON.stringify(val))
    }
  },
  methods: {
    addStep () {
      if (!this.step.cc) return null
      this.steps.push(JSON.parse(JSON.stringify(this.step)))
      this.$emit('input', this.steps)
      this.clean()
    },
    clean () {
      this.step = JSON.parse(JSON.stringify(this.defaultStep))
    },
    deleteStep (i) {
      this.steps.splice(i, 1)
      this.$emit('input', this.steps)
    }
  },
  beforeCreate () {
  // Acciones antes de crear el componente
  },
  created () {
  // Acciones al crear el componente
  },
  beforeMount () {
  // Acciones antes de montar el componente
  },
  mounted () {
  // Acciones al montar el componente
  },
  beforeUpdate () {
  // Acciones antes de actualizar el componente
  },
  updated () {
  // Acciones al actualizar el componente
  },
  beforeDestroy () {
  // Acciones antes de destruir el componente
  },
  destroyed () {
  // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.frmulas-de-produccin1 {
  width: 397px;
  position: relative;
  line-height: 36px;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}
.frmulas-de-produccin-wrapper {
  align-self: stretch;
  border-radius: 4px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  font-size: 34px;
  color: #fff;
  font-family: Rubik;
}
.nombre-del-producto {
  position: relative;
  font-weight: 500;
}
.div {
  position: relative;
  font-weight: 500;
  color: #f72585;
}
.nombre-del-producto-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx {
  flex: 1;
  position: relative;
  border: none;
  outline: none;
  background: transparent;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper {
  width: 232.5px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  box-sizing: border-box;
  font-size: 11px;
  color: #000;
}
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.cdigo-cabys-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.wrapper {
  width: 118.7px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  box-sizing: border-box;
  font-size: 11px;
  color: #000;
}
.container {
  width: 128.7px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  font-size: 11px;
  color: #000;
}
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.frame-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.frmulas-de-produccin-child {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.selecciona-los-materiales {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 500;
}
.selecciona-los-materiales-que-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #480ca8;
  font-family: Rubik;
}
.selecciona-el-material {
  position: relative;
  font-weight: 500;
  white-space: pre-wrap;
}
.icon-chevron {
  width: 16px;
  position: relative;
  height: 16px;
  flex-shrink: 0;
  object-fit: contain;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-parent {
  width: 232.5px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  box-sizing: border-box;
}
.label {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 16px;
}
.label1 {
  position: relative;
  font-size: 11px;
  letter-spacing: -0.02em;
  line-height: 16px;
  font-family: Roboto;
  color: #535763;
  text-align: left;
}
.link {
  width: 218px;
  border-radius: 8px;
  background-color: #e7eefe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
  gap: 6px;
  text-align: center;
  font-size: 20px;
  color: #f72585;
  font-family: Rubik;
}
.link1 {
  width: 218px;
  border-radius: 8px;
  background-color: #e7eefe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
}
.links-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  color: #535763;
}
.frame-parent3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 11px;
  color: #000;
}
.div7 {
  width: 100%;
  position: relative;
  border: none;
  outline: none;
  background: transparent;
}
input[type="number"] {
  text-align: right;
}
.frame {
  width: 100%;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 4px;
  box-sizing: border-box;
  font-size: 11px;
  color: #000;
}
.precio-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.clasificacin-del-material-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 12px;
}
.material-opcional {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: none;
  outline: none;
  background: transparent;
}
.material-opcional-parent {
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  box-sizing: border-box;
  gap: 6px;
  color: #000;
}
.link3 {
  width: 139px;
  border-radius: 8px;
  background-color: #e7eefe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
  color: #535763;
}
.seleccione-la-unidad-parent {
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  box-sizing: border-box;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frame-parent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
}
.frame-parent6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  font-size: 11px;
}
.frame-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.group-icon {
  width: 12px;
  position: relative;
  height: 12px;
}
.btn-text {
  position: relative;
  letter-spacing: 0.12px;
  line-height: 12.66px;
  font-weight: 500;
}
.buttonbtnprimary-colorbtn-r {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.frmulas-de-produccin-item {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.materiales-requeridos-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  color: #3f37c9;
}
.link4 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
}
.link5 {
  width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
}
.link-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.miedit-icon {
  width: 16px;
  position: relative;
  height: 16px;
  flex-shrink: 0;
}
.mingcutedelete-line-icon {
  width: 20px;
  position: relative;
  height: 20px;
  flex-shrink: 0;
}
.iconos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.frame-parent10 {
  border-radius: 6px;
  border: 0.5px solid #535763;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
}
.label8 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 16px;
  white-space: pre-wrap;
}
.frame-parent9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.frmulas-de-produccin-inner {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.frame-parent13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.line-icon {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.crea-los-pasos-requeridos-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  font-family: Rubik;
}
.div13 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  outline: none;
  background: transparent;
}
.wrapper2 {
  width: 58px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 23px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 11px;
  color: #000;
}
.seleccione-la-unidad-group {
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frame-parent17 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-frame {
  width: 516px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 23px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  box-sizing: border-box;
  font-size: 11px;
  color: #000;
}
.frame-parent21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.seleccione-el-departamento {
  width: 139.7px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.frame-parent22 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.buttonbtnprimary-colorbtn-r1 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.frmulas-de-produccin-child1 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.link18 {
  width: 150px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  box-sizing: border-box;
}
.mireorder-alt-icon {
  width: 15px;
  position: relative;
  height: 15px;
  flex-shrink: 0;
}
.frame-parent25 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.frmulas-de-produccin-child2 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.crea-tu-formulario-personaliza-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  font-family: Rubik;
}
.obligatorio {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.obligatorio-wrapper {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  position: absolute;
  top: 1.37px;
  left: 1.37px;
  box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
  border-radius: 68.34px;
  background-color: #fff;
  width: 13.7px;
  height: 13.7px;
}
.tggle {
  width: 30.1px;
  position: relative;
  border-radius: 68.34px;
  background-color: #535763;
  height: 16.4px;
}
.switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 0px 0px;
  gap: 8px;
}
.frame-parent29 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.opciones-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: #3f37c9;
}
.frame-child {
  width: 18.1px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #4361ee;
  box-sizing: border-box;
  height: 18.1px;
}
.vector-icon {
  width: 17px;
  position: relative;
  height: 17px;
  object-fit: contain;
}
.ellipse-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.frame-parent32 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frmulas-de-produccin-child3 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.button1 {
  position: absolute;
  top: 1.37px;
  left: 15.03px;
  box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
  border-radius: 68.34px;
  background-color: #fff;
  width: 13.7px;
  height: 13.7px;
}
.tggle1 {
  width: 30.1px;
  position: relative;
  border-radius: 68.34px;
  background-color: #3f37c9;
  height: 16.4px;
}
.frame-parent33 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.rectangle-div {
  width: 18px;
  position: relative;
  border: 2px solid #4361ee;
  box-sizing: border-box;
  height: 18px;
}
.frame-parent36 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  font-size: 11px;
  color: #000;
}
.frmulas-de-produccin-child4 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.buttonbtnprimary-colorbtn-r2 {
  border-radius: 21.29px;
  background-color: #7209b7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r3 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 6px;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  text-align: center;
  color: #fff;
}
.frmulas-de-produccin-child5 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.agrega-los-precios-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  font-family: Rubik;
}
.buttonbtnprimary-colorbtn-r4 {
  border-radius: 8px 0px 0px 8px;
  background-color: #7209b7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r5 {
  border-radius: 0px 8px 8px 0px;
  background-color: #ebf1ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  color: #4361ee;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #fff;
}
.seleccione-la-categora {
  width: 116.8px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.ellipse-div {
  width: 15px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #4361ee;
  box-sizing: border-box;
  height: 15px;
}
.frame-parent41 {
  border-radius: 8px;
  height: 22.7px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0px;
  box-sizing: border-box;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.frame-parent37 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 24px;
}
.buttonbtnprimary-colorbtn-r6 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.frmulas-de-produccin-child6 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.frame-parent42 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.frmulas-de-produccin-child7 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.agrega-los-precios-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  font-family: Rubik;
}
.btn-text7 {
  width: 104px;
  position: relative;
  letter-spacing: 0.12px;
  line-height: 12.66px;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}
.buttonbtnprimary-colorbtn-r7 {
  border-radius: 8px 0px 0px 8px;
  background-color: #ebf1ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r8 {
  border-radius: 0px 8px 8px 0px;
  background-color: #7209b7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  color: #fff;
  cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frmulas-de-produccin-inner1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #4361ee;
}
.el-precio-calculado {
  width: 303.7px;
  position: relative;
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 16px;
  font-family: Rubik;
  color: #000;
  display: flex;
  align-items: center;
}
.div28 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: none;
  outline: none;
  background: transparent;
}
.wrapper4 {
  width: 57.3px;
  border-radius: 8px;
  background-color: #ebf1ff;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.div29 {
  position: relative;
  font-size: 14px;
}
.frame-parent49 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  font-size: 11px;
  color: #000;
}
.frame-child4 {
  width: 15px;
  position: relative;
  height: 15px;
}
.group-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.vector-icon4 {
  width: 8.4px;
  position: relative;
  height: 8.4px;
}
.frame-parent52 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
.switch2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.frame-parent46 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.buttonbtnprimary-colorbtn-r9 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  gap: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.frmulas-de-produccin-child8 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.frame-parent53 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 11px;
  color: #535763;
}
.frmulas-de-produccin-child9 {
  width: 546.5px;
  position: relative;
  max-height: 100%;
}
.buttonbtnprimary-colorbtn-r10 {
  border-radius: 21.29px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.cursorpointer-icon {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 2103.92px;
  left: 284.8px;
  height: 24px;
  flex-shrink: 0;
}
.cursorpointer-icon1 {
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: 1709.26px;
  left: 116.5px;
  height: 24px;
  flex-shrink: 0;
}
.formulas-de-produccin {
  position: relative;
  border-radius: 20px;
  background-color: #f6faff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 24px;
  text-align: left;
  font-size: 12px;
  color: #3f37c9;
  font-family: Roboto;
}

</style>
