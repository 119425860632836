<template>
  <div class="new-order">
    <div class="order-main"><NewOrderMain></NewOrderMain></div>
    <div class="place-order-main"><PlaceOrder></PlaceOrder></div>
  </div>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import NewOrderMain from '@/components/NewOrderMain.vue'
import PlaceOrder from '../components/PlaceOrder.vue'
// import AccountReceivable from '@/components/AccountReceivable.vue'

export default {
  name: 'NewOrder',
  components: { NewOrderMain, PlaceOrder },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  mounted () {
    this.setVoucherType('13')
  },

  watch: {
  },

  /* created () {
  }, */

  methods: {
    setVoucherType (payload) {
      this.$store.dispatch('1/setVoucherType', payload)
    }
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style scoped>
.order-main {
  height: calc(100vh - 95px);
  width: calc(100% - 520px);
  overflow: auto;
  text-align: center;
  overflow: auto;
}
.place-order-main {
  width: 520px;
}
.new-order {
  display: flex;
}
@media (max-width: 768px) {
  .new-order {
    flex-direction: column;
  }
  .place-order-main {
    width: 100%;
    height: 100%;
  }
  .order-main {
    width: 100%;
    height: 100%;
  }
}
</style>
