<template>
  <v-data-iterator
    :items="items"
    hide-default-footer
  >
    <template v-slot:header>
      <v-toolbar
        class="ma-2"
        flat
      >
        <v-toolbar-title class="py-3">
          <v-select
            v-model="type"
            :items="typeItems"
            label="Agrupar por"
          ></v-select>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:default="props">
      <v-row>
        <v-col
          v-for="item in props.items"
          :key="item.value"
          cols="12"
          :sm="12 / (props.items.length > 0 ? (props.items.length > 4 ? 4 : props.items.length) : 1)"
        >
        <TaskCard :tasksIndexed="tasksIndexed" :tasks="tasks" :staff="staff" :item="item" @updated="emitUpdated"></TaskCard>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TaskCard from '@/components/TaskCard.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'TasksCards',
  components: { TaskCard },
  props: {
    tasks: Array,
    staff: Array,
    tasksIndexed: Object
  },
  data: () => ({
    search: null,
    loading: false,
    type: 'priority',
    typeItems: [
      { text: 'Prioridad', value: 'priority' },
      { text: 'Estado', value: 'status' },
      { text: 'Responsable', value: 'assignee' }
    ],
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      '01': { text: 'Pendiente', color: 'grey' },
      '02': { text: 'Iniciada', color: 'info' },
      '03': { text: 'Lista', color: 'success' },
      '04': { text: 'En peligro', color: 'warning' },
      '05': { text: 'Atrasada', color: 'error' }
    },
    default: {
      priority: [
        { type: 'priority', value: 1, tasks: [], text: 'Alta', color: 'error' },
        { type: 'priority', value: 2, tasks: [], text: 'Media', color: 'warning' },
        { type: 'priority', value: 3, tasks: [], text: 'Baja', color: 'success' }
      ],
      status: [
        { type: 'status', value: '01', tasks: [], text: 'Atrasada', color: 'error' },
        { type: 'status', value: '02', tasks: [], text: 'En peligro', color: 'warning' },
        { type: 'status', value: '03', tasks: [], text: 'Pendiente', color: 'grey' },
        { type: 'status', value: '04', tasks: [], text: 'Iniciada', color: 'info' },
        { type: 'status', value: '05', tasks: [], text: 'Lista', color: 'success' }
      ]
    }
  }),

  mounted () {
  },

  computed: {
    items () {
      const defaultItem = { ...this.default }
      defaultItem.assignee = this.staff?.map(({ uid, name }) => {
        return {
          type: 'assignee',
          value: uid,
          tasks: [],
          text: name,
          color: ''
        }
      }) || []
      const items = JSON.parse(JSON.stringify(defaultItem[this.type]))
      const indexes = items.reduce((acc, { value }, i) => {
        acc[value] = i
        return acc
      }, {})
      for (let i = 0; i < this.tasks.length; i++) {
        const task = this.tasks[i]
        if (task[this.type]) {
          const taskType = this.type === 'assignee' ? task[this.type].uid : task[this.type]
          items[indexes[taskType]].tasks.push(task)
        }
      }
      return items.filter(({ tasks }) => tasks.length > 0)
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    emitUpdated () {
      this.$emit('updated')
    }
  }
}
</script>
