<template>
  <div class="frame-parent41">
      <div class="group-parent" v-for="(item, i) in allItems" :key="i">
        <input type="radio" :value="item.value" v-model="internalValue"  class="ellipse-div">
        <div class="label">{{ getText(item) | truncarString(widthLabelString) }}</div>
      </div>
  </div>
</template>
<script>
const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
})

function deepEqual (a, b) {
  if (a === b) return true

  if (
    a instanceof Date &&
    b instanceof Date &&
    a.getTime() !== b.getTime()
  ) {
    // If the values are Date, compare them as timestamps
    return false
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false
  }

  const props = Object.keys(a)

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false
  }

  return props.every(p => deepEqual(a[p], b[p]))
}
function getNestedValue (obj, path, fallback) {
  const last = path.length - 1

  if (last < 0) return obj === undefined ? fallback : obj

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback
    }
    obj = obj[path[i]]
  }

  if (obj == null) return fallback

  return obj[path[last]] === undefined ? fallback : obj[path[last]]
}
function getObjectValueByPath (obj, path, fallback) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback
  if (obj[path] !== undefined) return obj[path]
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback)
}
function getPropertyFromItem (
  item,
  property,
  fallback
) {
  if (property == null) return item === undefined ? fallback : item

  if (item !== Object(item)) return fallback === undefined ? item : fallback

  if (typeof property === 'string') return getObjectValueByPath(item, property, fallback)

  if (Array.isArray(property)) return getNestedValue(item, property, fallback)

  if (typeof property !== 'function') return fallback

  const value = property(item, fallback)

  return typeof value === 'undefined' ? fallback : value
}
export default {
  name: 'RedaSelect',
  props: {
    value: null,
    items: Array,
    textNull: {
      type: String,
      default: 'Seleccionar opción'
    },
    itemValue: {
      type: [String, Number],
      default: 'value'
    },
    itemText: {
      type: [String, Number],
      default: 'text'
    },
    returnObject: Boolean,
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      }
    },
    itemDisabled: {
      type: [String, Array, Function],
      default: 'disabled'
    },
    searchInput: {
      type: String
    },
    noFilter: Boolean,
    multiple: Boolean,
    width: {
      type: String,
      default: '100%'
    },
    hideSelected: Boolean,
    isInteractive: {
      type: Boolean,
      default: true
    },
    widthLabelString: {
      type: [String, Number],
      default: 20
    }
  },
  data () {
    return {
      lazySearch: this.searchInput,
      isMenuActive: false,
      selectedItems: [],
      selectedIndex: -1,
      lazyValue: this.value,
      initialValue: null
    }
  },
  computed: {
    allItems () {
      return this.items || []
    },
    /* Used by other components to overwrite */
    computedItems () {
      return this.allItems
    },
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', this.lazyValue)
      }
    },
    selectedItem () {
      if (this.multiple) return null

      return this.selectedItems.find(i => {
        return this.valueComparator(this.getValue(i), this.getValue(this.internalValue))
      })
    },
    filteredItems () {
      if (!this.isSearching || this.noFilter || this.internalSearch == null) return this.allItems

      return this.allItems.filter(item => {
        const value = getPropertyFromItem(item, this.itemText)
        const text = value != null ? String(value) : ''

        return this.filter(item, String(this.internalSearch), text)
      })
    },
    isSearching () {
      return (
        this.multiple &&
        this.searchIsDirty
      ) || (
        this.searchIsDirty &&
        this.internalSearch !== this.getText(this.selectedItem)
      )
    },
    internalSearch: {
      get () {
        return this.lazySearch
      },
      set (val) { // TODO: this should be `string | null` but it breaks lots of other types
        // emit update event only when the new
        // search value is different from previous
        if (this.lazySearch !== val) {
          this.lazySearch = val
          this.$emit('update:search-input', val)
        }
      }
    },
    searchIsDirty () {
      return this.internalSearch != null &&
        this.internalSearch !== ''
    }
  },
  watch: {
    items: {
      immediate: true,
      handler (val) {
        // if (this.cacheItems) {
        //   // Breaks vue-test-utils if
        //   // this isn't calculated
        //   // on the next tick
        //   this.$nextTick(() => {
        //     this.cachedItems = this.filterDuplicates(this.cachedItems.concat(val))
        //   })
        // }

        this.setSelectedItems()
      }
    },
    internalValue (val) {
      this.initialValue = val
      this.setSelectedItems()

      // if (this.multiple) {
      //   this.$nextTick(() => {
      //     this.$refs.menu?.updateDimensions()
      //   })
      // }
      // if (this.hideSelected) {
      //   this.$nextTick(() => {
      //     this.onScroll()
      //   })
      // }
    },
    value (val) {
      this.lazyValue = val
    }
  },
  methods: {
    getText (item) {
      return getPropertyFromItem(item, this.itemText, item)
    },
    setSelectedItems () {
      const selectedItems = []
      const values = !this.multiple || !Array.isArray(this.internalValue)
        ? [this.internalValue]
        : this.internalValue

      for (const value of values) {
        const index = this.allItems.findIndex(v => this.valueComparator(
          this.getValue(v),
          this.getValue(value)
        ))

        if (index > -1) {
          selectedItems.push(this.allItems[index])
        }
      }

      this.selectedItems = selectedItems
    },
    getDisabled (item) {
      return getPropertyFromItem(item, this.itemDisabled, false)
    },
    deleteCurrentItem () {
      const curIndex = this.selectedIndex
      const curItem = this.selectedItems[curIndex]

      // Do nothing if input or item is disabled
      if (
        !this.isInteractive ||
        this.getDisabled(curItem)
      ) return

      const lastIndex = this.selectedItems.length - 1

      // Select the last item if
      // there is no selection
      if (
        this.selectedIndex === -1 &&
        lastIndex !== 0
      ) {
        this.selectedIndex = lastIndex

        return
      }

      const length = this.selectedItems.length
      const nextIndex = curIndex !== length - 1
        ? curIndex
        : curIndex - 1
      const nextItem = this.selectedItems[nextIndex]

      if (!nextItem) {
        this.setValue(this.multiple ? [] : null)
      } else {
        this.selectItem(curItem)
      }

      this.selectedIndex = nextIndex
    },
    onKeyDown (e) {
      const keyCode = e.keyCode

      // if (
      //   e.ctrlKey ||
      //   ![keyCodes.home, keyCodes.end].includes(keyCode)
      // ) {
      //   VSelect.options.methods.onKeyDown.call(this, e)
      // }

      // The ordering is important here
      // allows new value to be updated
      // and then moves the index to the
      // proper location
      this.changeSelectedIndex(keyCode)
    },
    onCopy (event) {
      if (this.selectedIndex === -1) return

      const currentItem = this.selectedItems[this.selectedIndex]
      const currentItemText = this.getText(currentItem)
      // eslint-disable-next-line no-unused-expressions
      event.clipboardData?.setData('text/plain', currentItemText)
      // eslint-disable-next-line no-unused-expressions
      event.clipboardData?.setData('text/vnd.vuetify.autocomplete.item+plain', currentItemText)
      event.preventDefault()
    },
    changeSelectedIndex (keyCode) {
      // Do not allow changing of selectedIndex
      // when search is dirty
      if (this.searchIsDirty) return

      if (this.multiple && keyCode === keyCodes.left) {
        if (this.selectedIndex === -1) {
          this.selectedIndex = this.selectedItems.length - 1
        } else {
          this.selectedIndex--
        }
      } else if (this.multiple && keyCode === keyCodes.right) {
        if (this.selectedIndex >= this.selectedItems.length - 1) {
          this.selectedIndex = -1
        } else {
          this.selectedIndex++
        }
      } else if (keyCode === keyCodes.backspace || keyCode === keyCodes.delete) {
        this.deleteCurrentItem()
      }
    },
    activateMenu () {
      if (
        !this.isInteractive ||
        this.isMenuActive
      ) return

      this.isMenuActive = true
    },
    clearableCallback () {
      this.internalSearch = null
    },
    updateSelf () {
      if (
        !this.searchIsDirty &&
        !this.internalValue
      ) return

      if (
        !this.multiple &&
        !this.valueComparator(
          this.internalSearch,
          this.getValue(this.internalValue)
        )
      ) {
        this.setSearch()
      }
    },
    onInput (e) {
      if (
        this.selectedIndex > -1 ||
        !e.target
      ) return

      const target = e.target
      const value = target.value

      // If typing and menu is not currently active
      if (target.value) this.activateMenu()

      if (!this.multiple && value === '') this.deleteCurrentItem()

      this.internalSearch = value
      // this.badInput = target.validity && target.validity.badInput
    },
    setSearch () {
      // Wait for nextTick so selectedItem
      // has had time to update
      this.$nextTick(() => {
        if (
          !this.multiple ||
          !this.internalSearch ||
          !this.isMenuActive
        ) {
          this.internalSearch = (
            !this.selectedItems.length ||
            this.multiple ||
            this.hasSlot
          )
            ? null
            : this.getText(this.selectedItem)
        }
      })
    },
    findExistingIndex (item) {
      const itemValue = this.getValue(item)

      return (this.internalValue || []).findIndex((i) => this.valueComparator(this.getValue(i), itemValue))
    },
    selectItem (item) {
      if (!this.multiple) {
        this.setValue(this.returnObject ? item : this.getValue(item))
        this.isMenuActive = false
      } else {
        const internalValue = (this.internalValue || []).slice()
        const i = this.findExistingIndex(item)

        i !== -1 ? internalValue.splice(i, 1) : internalValue.push(item)
        this.setValue(internalValue.map((i) => {
          return this.returnObject ? i : this.getValue(i)
        }))

        // There is no item to re-highlight
        // when selections are hidden
        if (this.hideSelected) {
          this.setMenuIndex(-1)
        } else {
          const index = this.computedItems.indexOf(item)
          if (~index) {
            this.$nextTick(() => this.$refs.menu.getTiles())
            setTimeout(() => this.setMenuIndex(index))
          }
        }
      }
    },
    getValue (item) {
      return getPropertyFromItem(item, this.itemValue, this.getText(item))
    },
    setValue (value) {
      if (!this.valueComparator(value, this.internalValue)) {
        this.internalValue = value
        this.$emit('change', value)
      }
    },
    valueComparator (a, b) {
      return deepEqual(a, b)
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.frmulas-de-produccin1 {
    width: 397px;
    position: relative;
    line-height: 36px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.frmulas-de-produccin-wrapper {
    align-self: stretch;
    border-radius: 4px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    font-size: 34px;
    color: #fff;
    font-family: Rubik;
}
.nombre-del-producto {
    position: relative;
    font-weight: 500;
}
.div {
    position: relative;
    font-weight: 500;
    color: #f72585;
}
.nombre-del-producto-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx {
    flex: 1;
    position: relative;
    border: none;
    outline: none;
    background: transparent;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-wrapper {
    width: 232.5px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    box-sizing: border-box;
    font-size: 11px;
    color: #000;
}
.frame-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
}
.cdigo-cabys-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
.wrapper {
    width: 118.7px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    box-sizing: border-box;
    font-size: 11px;
    color: #000;
}
.container {
    width: 128.7px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    box-sizing: border-box;
    font-size: 11px;
    color: #000;
}
.frame-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
}
.frame-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.frmulas-de-produccin-child {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.selecciona-los-materiales {
    position: relative;
    letter-spacing: 0.15px;
    line-height: 24px;
    font-weight: 500;
}
.selecciona-los-materiales-que-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    color: #480ca8;
    font-family: Rubik;
}
.selecciona-el-material {
    position: relative;
    font-weight: 500;
    white-space: pre-wrap;
}
.icon-chevron {
    width: 16px;
    position: relative;
    height: 16px;
    flex-shrink: 0;
    object-fit: contain;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-parent {
    width: 232.5px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    box-sizing: border-box;
}
.label {
    position: relative;
    letter-spacing: -0.02em;
    line-height: 16px;
}
.label1 {
    position: relative;
    font-size: 11px;
    letter-spacing: -0.02em;
    line-height: 16px;
    font-family: Roboto;
    color: #535763;
    text-align: left;
}
.link {
    width: 218px;
    border-radius: 8px;
    background-color: #e7eefe;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
    gap: 6px;
    text-align: center;
    font-size: 20px;
    color: #f72585;
    font-family: Rubik;
}
.link1 {
    width: 218px;
    border-radius: 8px;
    background-color: #e7eefe;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
}
.links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    color: #535763;
}
.frame-parent3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-size: 11px;
    color: #000;
}
.div7 {
    width: 100%;
    position: relative;
    border: none;
    outline: none;
    background: transparent;
}
input[type="number"] {
  text-align: right;
}
.frame {
  width: 100%;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 4px;
    box-sizing: border-box;
    font-size: 11px;
    color: #000;
}
.precio-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.clasificacin-del-material-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12px;
}
.material-opcional {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border: none;
    outline: none;
    background: transparent;
}
.material-opcional-parent {
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    box-sizing: border-box;
    gap: 6px;
    color: #000;
}
.link3 {
    width: 139px;
    border-radius: 8px;
    background-color: #e7eefe;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
    color: #535763;
}
.seleccione-la-unidad-parent {
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    box-sizing: border-box;
    gap: 6px;
    font-size: 11px;
    color: #000;
}
.frame-parent8 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
}
.frame-parent6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    font-size: 11px;
}
.frame-parent1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}
.group-icon {
    width: 12px;
    position: relative;
    height: 12px;
}
.btn-text {
    position: relative;
    letter-spacing: 0.12px;
    line-height: 12.66px;
    font-weight: 500;
}
.buttonbtnprimary-colorbtn-r {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.frmulas-de-produccin-item {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.materiales-requeridos-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    color: #3f37c9;
}
.link4 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
}
.link5 {
    width: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
}
.link-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}
.miedit-icon {
    width: 16px;
    position: relative;
    height: 16px;
    flex-shrink: 0;
}
.mingcutedelete-line-icon {
    width: 20px;
    position: relative;
    height: 20px;
    flex-shrink: 0;
}
.iconos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.frame-parent10 {
    border-radius: 6px;
    border: 0.5px solid #535763;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 26px;
}
.label8 {
    position: relative;
    letter-spacing: -0.02em;
    line-height: 16px;
    white-space: pre-wrap;
}
.frame-parent9 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.frmulas-de-produccin-inner {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.frame-parent13 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.line-icon {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.crea-los-pasos-requeridos-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Rubik;
}
.div13 {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    outline: none;
    background: transparent;
}
.wrapper2 {
    width: 58px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 23px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: #000;
}
.seleccione-la-unidad-group {
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    box-sizing: border-box;
    gap: 6px;
    font-size: 11px;
    color: #000;
}
.frame-parent17 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-frame {
    width: 516px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 23px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    box-sizing: border-box;
    font-size: 11px;
    color: #000;
}
.frame-parent21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
}
.seleccione-el-departamento {
    width: 139.7px;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.frame-parent22 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.buttonbtnprimary-colorbtn-r1 {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.frmulas-de-produccin-child1 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.link18 {
    width: 150px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 12px;
    box-sizing: border-box;
}
.mireorder-alt-icon {
    width: 15px;
    position: relative;
    height: 15px;
    flex-shrink: 0;
}
.frame-parent25 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.frmulas-de-produccin-child2 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.crea-tu-formulario-personaliza-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Rubik;
}
.obligatorio {
    align-self: stretch;
    position: relative;
    font-weight: 500;
}
.obligatorio-wrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.button {
    position: absolute;
    top: 1.37px;
    left: 1.37px;
    box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
    border-radius: 68.34px;
    background-color: #fff;
    width: 13.7px;
    height: 13.7px;
}
.tggle {
    width: 30.1px;
    position: relative;
    border-radius: 68.34px;
    background-color: #535763;
    height: 16.4px;
}
.switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 0px 0px;
    gap: 8px;
}
.frame-parent29 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.opciones-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.frame-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.frame-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #3f37c9;
}
.frame-child {
    width: 18.1px;
    position: relative;
    border-radius: 50%;
    border: 2px solid #4361ee;
    box-sizing: border-box;
    height: 18.1px;
}
.vector-icon {
    width: 17px;
    position: relative;
    height: 17px;
    object-fit: contain;
}
.ellipse-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.frame-parent32 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    font-size: 11px;
    color: #000;
}
.frmulas-de-produccin-child3 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.button1 {
    position: absolute;
    top: 1.37px;
    left: 15.03px;
    box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
    border-radius: 68.34px;
    background-color: #fff;
    width: 13.7px;
    height: 13.7px;
}
.tggle1 {
    width: 30.1px;
    position: relative;
    border-radius: 68.34px;
    background-color: #3f37c9;
    height: 16.4px;
}
.frame-parent33 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.rectangle-div {
    width: 18px;
    position: relative;
    border: 2px solid #4361ee;
    box-sizing: border-box;
    height: 18px;
}
.frame-parent36 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    font-size: 11px;
    color: #000;
}
.frmulas-de-produccin-child4 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.buttonbtnprimary-colorbtn-r2 {
    border-radius: 21.29px;
    background-color: #7209b7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r3 {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 6px;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    text-align: center;
    color: #fff;
}
.frmulas-de-produccin-child5 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.agrega-los-precios-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Rubik;
}
.buttonbtnprimary-colorbtn-r4 {
    border-radius: 8px 0px 0px 8px;
    background-color: #7209b7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r5 {
    border-radius: 0px 8px 8px 0px;
    background-color: #ebf1ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    color: #4361ee;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: #fff;
}
.seleccione-la-categora {
    width: 116.8px;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.ellipse-div {
    width: 15px;
    position: relative;
    border-radius: 50%;
    border: 2px solid #4361ee;
    box-sizing: border-box;
    height: 15px;
}
input[type="radio"].ellipse-div {
  appearance: none; /* Oculta el radio button original */
  cursor: pointer;
}
input[type="radio"].ellipse-div:checked {
  background-color: #4361ee;
}
.frame-parent41 {
    border-radius: 8px;
    height: 22.7px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 0px;
    box-sizing: border-box;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.frame-parent37 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 24px;
}
.buttonbtnprimary-colorbtn-r6 {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.frmulas-de-produccin-child6 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.frame-parent42 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.frmulas-de-produccin-child7 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.agrega-los-precios-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Rubik;
}
.btn-text7 {
    width: 104px;
    position: relative;
    letter-spacing: 0.12px;
    line-height: 12.66px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.buttonbtnprimary-colorbtn-r7 {
    border-radius: 8px 0px 0px 8px;
    background-color: #ebf1ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r8 {
    border-radius: 0px 8px 8px 0px;
    background-color: #7209b7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    color: #fff;
    cursor: pointer;
}
.buttonbtnprimary-colorbtn-r-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.frmulas-de-produccin-inner1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: #4361ee;
}
.el-precio-calculado {
    width: 303.7px;
    position: relative;
    font-size: 10px;
    letter-spacing: 0.4px;
    line-height: 16px;
    font-family: Rubik;
    color: #000;
    display: flex;
    align-items: center;
}
.div28 {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border: none;
    outline: none;
    background: transparent;
}
.wrapper4 {
  width: 57.3px;
    border-radius: 8px;
    background-color: #ebf1ff;
    height: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.div29 {
    position: relative;
    font-size: 14px;
}
.frame-parent49 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 11px;
    color: #000;
}
.frame-child4 {
    width: 15px;
    position: relative;
    height: 15px;
}
.group-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
.vector-icon4 {
    width: 8.4px;
    position: relative;
    height: 8.4px;
}
.frame-parent52 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
}
.switch2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.frame-parent46 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}
.buttonbtnprimary-colorbtn-r9 {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.frmulas-de-produccin-child8 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.frame-parent53 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    color: #535763;
}
.frmulas-de-produccin-child9 {
    width: 546.5px;
    position: relative;
    max-height: 100%;
}
.buttonbtnprimary-colorbtn-r10 {
    border-radius: 21.29px;
    background-color: #f72585;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}
.cursorpointer-icon {
    width: 24px;
    position: absolute;
    margin: 0 !important;
    top: 2103.92px;
    left: 284.8px;
    height: 24px;
    flex-shrink: 0;
}
.cursorpointer-icon1 {
    width: 24px;
    position: absolute;
    margin: 0 !important;
    top: 1709.26px;
    left: 116.5px;
    height: 24px;
    flex-shrink: 0;
}
.formulas-de-produccin {
    position: relative;
    border-radius: 20px;
    background-color: #f6faff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 24px;
    text-align: left;
    font-size: 12px;
    color: #3f37c9;
    font-family: Roboto;
}

</style>
