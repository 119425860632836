<template>
  <div>
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="informacin-formulario-de-prod7"
          v-bind="attrs"
          v-on="on"
        >
            <img class="lsiconmanagement-outline" alt="" :src="require('@/assets/Comments.svg')">

            <div class="accin-subir-archivo">Agregar comentarios</div>
        </div>
      </template>
      <Comments :comments="item.comments" :uid="item.uid" @close="dialog = false"></Comments>
    </v-dialog>
  </div>
</template>
<script>
import Comments from '@/components/Comments.vue'
export default {
  name: 'DialogComments',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Comments
  }
}
</script>
<style scoped>
.informacin-formulario-de-prod7 {
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 8px;
  cursor: pointer;
}
.lsiconmanagement-outline {
  width: 25px;
  position: relative;
  height: 25px;
  overflow: hidden;
  flex-shrink: 0;
}
.accin-subir-archivo {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
</style>
