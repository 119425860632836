<template>
  <v-data-table
    :headers="headers"
    :items="purchasesTotalsFactor"
    class="elevation-1"
    hide-default-footer
  >
  <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-toolbar-title>D-105-1 Declaración Jurada del Régimen de Tributación Simplificada Impuesto sobre el Valor Agregado</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
  </template>
    <template v-slot:body.append>
      <tr>
        <td colspan="2"></td>
        <td>Total Impuestos a pagar</td>
        <th class="title">
          {{taxTotal}}
        </th>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions
} from 'vuex'
import moment from 'moment'

export default {
  name: 'D105IVA',
  components: {},
  props: [],
  beforeCreate: function () {},
  created: function () {},
  beforeMount: function () {},
  mounted: function () {},
  beforeUpdate: function () {},
  updated: function () {},
  beforeDestroy: function () {},
  destroyed: function () {},
  data: () => ({
    from: 0,
    to: moment().format('x'),
    headers: [
      {
        text: '',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Monto',
        sortable: false,
        value: 'amount'
      },
      {
        text: 'Factor',
        sortable: false,
        value: 'factor'
      },
      {
        text: 'Total',
        sortable: false,
        value: 'tax'
      }
    ]
  }),
  watch: {},
  methods: {
    ...mapMutations([]),
    ...mapActions([])
  },
  computed: {
    taxTotal () {
      let total = 0
      this.purchasesTotalsFactor.forEach(({ tax }) => {
        total += tax
      })
      return total
    },
    taxFactors () {
      return [
        { code: '01', value: 0.00125, name: 'Compras al 1%' },
        { code: '02', value: 0.0033, name: 'Compras al 2%' },
        { code: '08', value: 0.020, name: 'Compras al 13%' }
      ]
    },
    purchasesTotalsFactor () {
      const total = []
      this.taxFactors.forEach((item) => {
        const factorTotal = {
          code: item.code,
          name: item.name,
          amount: this.purchasesTotals[item.code],
          factor: item.value,
          tax: this.purchasesTotals[item.code] * item.value
        }
        total.push(factorTotal)
      })
      return total
    },
    purchasesTotals () {
      const total = {
        '01': 0,
        '02': 0,
        '03': 0,
        '04': 0,
        '05': 0,
        '06': 0,
        '07': 0,
        '08': 0
      }
      this.purchases.forEach((item) => {
        total['01'] += Number(item.ivaTotal['01'])
        total['02'] += Number(item.ivaTotal['02'])
        total['03'] += Number(item.ivaTotal['03'])
        total['04'] += Number(item.ivaTotal['04'])
        total['05'] += Number(item.ivaTotal['05'])
        total['06'] += Number(item.ivaTotal['06'])
        total['07'] += Number(item.ivaTotal['07'])
        total['08'] += Number(item.ivaTotal['08'])
      })
      return total
    },
    purchases () {
      return this.getMessages.filter((purchase) => {
        return Number(moment(purchase.date).format('x')) > Number(this.from) &&
          Number(moment(purchase.date).format('x')) < Number(this.to)
      })
    },
    ...mapState([]),
    ...mapGetters('messages', [
      'getMessages'
    ])
  }
}
</script>
