<template>
  <v-container>
    <div class="headline my-7 ml-2">
      {{types[voucher.data.documentType]}} {{ (received) ? 'Recibida' : '' }} N° {{ id }}
    </div>
    <v-row class="ma-auto">
      <v-btn
        v-for="(action, i) in actions" @click="action.callback" :key="i"
        class="ma-2"
      >
        <v-icon left>
          {{action.icon}}
        </v-icon>
        {{action.text}}
      </v-btn>
    </v-row>
    <v-card
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
      v-if="salesTypes[voucher.data.documentType]"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Valor Comprobante
        </div>
        <div class="title text-center">
          {{ voucher.data.summary.netTotal | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Notas de Dédito
        </div>
        <div class="title text-center">
          + {{ totalDN | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Notas de Crédito
        </div>
        <div class="title text-center">
          - {{ (0 - totalCN) | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Valor Total
        </div>
        <div class="title text-center">
          {{ total | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          {{ received ? 'Pagado' : 'Cobrado' | currencyFormat(voucher.data.summary.currency) }}
        </div>
        <div class="title success--text text-center">
          {{ (total - voucher.amountOutstanding) | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5 pr-11">
        <div class="text-center caption">
          {{ received ? 'Por Pagar' : 'Por Cobrar' }}
        </div>
        <div class="title error--text text-center">
          {{ voucher.amountOutstanding | currencyFormat(voucher.data.summary.currency) }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
      <v-tabs v-model="tab">
        <v-tab>
          información general
        </v-tab>
        <v-tab v-if="salesTypes[voucher.data.documentType]">
          Notas de Crédito
        </v-tab>
        <v-tab v-if="salesTypes[voucher.data.documentType]">
          Notas de Débito
        </v-tab>
        <v-tab v-if="voucher.responseMessage">
          Mensaje de Hacienda
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row  class="ma-6">
            <v-col cols="12" sm="6">
              <v-card-title>
                {{ voucher.data.issuer.tradename || voucher.data.issuer.name }}
              </v-card-title>
              <v-card-subtitle>
                <div  v-if="voucher.data.issuer.tradename">{{ voucher.data.issuer.name }}</div>
                <div>{{ voucher.data.issuer.identification }}</div>
                <div>{{ voucher.data.issuer.email }}</div>
                <div>{{ voucher.data.issuer.phone }}</div>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12" sm="6" v-if="voucher.treasuryMessage">
              <v-card-title v-if="voidedVoucher || voucher.deleted" class="error--text">
                {{ "anulada".toUpperCase() }}
              </v-card-title>
              <v-card-title v-else :class="[voucher.treasuryMessage === 'rechazado' ? 'error--text' : voucher.treasuryMessage === 'aceptado' ? 'success--text' : '']">
                {{ voucher.treasuryMessage.toUpperCase() }}
              </v-card-title>
            </v-col>
          </v-row>
          <v-simple-table dense class="ma-6">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Código de la Actividad Económica </th>
                  <td>{{ voucher.data.activityCode }}</td>
                  <th>Clave</th>
                  <td>{{ voucher.data.key }}</td>
                </tr>
                <tr>
                  <th>Numeración consecutiva</th>
                  <td>{{ voucher.data.sequence }}</td>
                  <th>Fecha de Emisión</th>
                  <td>{{ voucher.data.date }}</td>
                </tr>
                <tr>
                  <th v-if="voucher.data.receiver">Cliente</th>
                  <td v-if="voucher.data.receiver">
                    <router-link :to="'/customer/' + voucher.receiver">{{ voucher.data.receiver.name }}</router-link>
                  </td>
                  <th>Condiciones de la venta</th>
                  <td>{{ conditions[voucher.data.condition] }}</td>
                </tr>
                <tr v-if="voucher.data.condition === '02'">
                  <th>Plazo del crédito (en días)</th>
                  <td>{{ voucher.data.creditTerm }}</td>
                  <th>Fecha de vencimiento</th>
                  <td>{{ moment(voucher.data.date).add(Number(voucher.data.creditTerm), 'days').calendar() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-data-table
            dense
            :headers="headers"
            :items="voucher.data.items"
            :items-per-page="voucher.data.items.length"
            hide-default-footer
            class="elevation-1 ma-6"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Detalle del Servicio, Mercancía u otro</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-row class="ma-6">
            <v-col cols="12" sm="6" style="height: 200px;">
              <div v-if="voucher.user">Emitida por {{ voucher.seller || voucher.user.name }}</div>
              <div style="height: 100%;" class="d-flex flex-column " v-if="!received">
                <div>
                  <v-btn class="ma-1" color="primary" @click="copyLink" v-if="getCompany.onvo">Copiar link de pago
                    <v-icon
                      right
                      dark
                    >
                      mdi-content-copy
                    </v-icon>
                    <v-snackbar
                      v-model="show"
                    >
                      ¡Link copiado!

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="#ffffff"
                          text
                          v-bind="attrs"
                          @click="show = false"
                        >
                          Cerrar
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-btn>
                </div>
                <div>
                  <v-dialog
                    v-model="dialog"
                    width="250"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-if="getCompany.onvo"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Código QR
                        <v-icon
                          right
                          dark
                        >
                          mdi-qrcode
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card>

                      <v-img
                        :src="linkQR"
                      ></v-img>
                      <v-card-actions>
                        <v-btn
                          block
                          color="primary"
                          @click="getQR(); dialog = false"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-tray-arrow-down
                          </v-icon>
                          Descargar imagen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item v-for="({ text, value }, i) in summary" :key="i">
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="text"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-list-item-title class="text-right">{{ value | currencyFormat(voucher.data.summary.currency) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="'Total Comprobante'"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <v-list-item-title class="text-right py-2 headline">{{ voucher.data.summary.netTotal | currencyFormat(voucher.data.summary.currency) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="salesTypes[voucher.data.documentType]">
          <credit-notes :creditNotes="voucher.creditNotes"></credit-notes>
        </v-tab-item>
        <v-tab-item v-if="salesTypes[voucher.data.documentType]">
          <credit-notes :creditNotes="voucher.debitNotes"></credit-notes>
        </v-tab-item>
        <v-tab-item v-if="voucher.responseMessage">
          <RedaForm
            :options="displayMessage"
            v-model="voucher.responseMessage"
          ></RedaForm>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
      v-if="voucher.data.reference"
    >
      <v-card-title>
        Información de Referencia
      </v-card-title>
      <RedaForm
        :options="displayReference"
        v-model="voucher.data.reference"
      ></RedaForm>
    </v-card>
    <Entries :entries="entries"></Entries>
  </v-container>
</template>

<script>
import moment from 'moment'
import { /* mapState, /* */ mapActions, mapGetters } from 'vuex'
import CreditNotes from '@/components/CreditNotes.vue'
import { conditions } from '@/assets/codes'
import redaApp from '@/api/redaApp.js'
import vex from 'vex-js'
import axios from 'axios'
import Entries from '../components/Entries.vue'
// import vexDialog from 'vex-dialog'

// Main css
import 'vex-js/dist/css/vex.css'

// Themes (Import all themes you want to use here)
import 'vex-js/dist/css/vex-theme-default.css'
import 'vex-js/dist/css/vex-theme-os.css'

// Options
vex.defaultOptions.className = 'vex-theme-default'

// Register vex-dialog
// vex.registerPlugin(vexDialog)
// const smalltalk = require('smalltalk');

const electronicVoucher = ['01', '02', '03', '04', '08', '09']

export default {
  name: 'Voucher',
  props: ['id', 'receipts'],
  components: { CreditNotes, Entries },
  data: () => ({
    show: false,
    dialog: false,
    tab: 0,
    types: {
      '01': 'Factura electrónica',
      '02': 'Nota de débito electrónica',
      '03': 'Nota de crédito electrónica',
      '04': 'Tiquete electrónico',
      '08': 'Factura electrónica de compras',
      '09': 'Factura electrónica de exportación',
      10: 'Tiquete de venta'
    },
    salesTypes: {
      '01': true,
      '02': false,
      '03': false,
      '04': true,
      '05': false,
      '06': false,
      '07': false,
      '08': false,
      '09': true,
      10: true
    },
    conditions,
    moment,
    headers: [
      { text: 'N°', value: 'line' },
      { text: 'Código', value: 'commercialCode' },
      { text: 'CABYS', value: 'code' },
      { text: 'Cantidad', value: 'quantity' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Detalle', value: 'description' },
      { text: 'Precio', value: 'unit_price' },
      { text: 'Subtotal', value: 'subtotal' },
      { text: 'Impuestos', value: 'tax_net' },
      { text: 'Total', value: 'net_total' }
    ]
  }),
  methods: {
    copyLink () {
      navigator.clipboard.writeText(this.paymentLink)
        .then(() => {
          setTimeout(() => {
            this.show = false
          }, 3000)
          this.show = true
        })
    },
    getQR () {
      // Realizar la petición HTTP utilizando Axios
      axios({
        url: this.linkQR,
        method: 'GET',
        responseType: 'blob' // Importante: especificar el tipo de respuesta como blob
      })
        .then(response => {
          // Crear una URL del objeto Blob que contiene la imagen
          const urlImagen = window.URL.createObjectURL(new Blob([response.data]))

          // Crear un elemento <a> para descargar la imagen
          const linkDescarga = document.createElement('a')
          linkDescarga.href = urlImagen
          linkDescarga.download = 'qrcode.png'

          // Simular un clic en el enlace para iniciar la descarga
          linkDescarga.click()

          // Limpiar la URL del objeto Blob
          window.URL.revokeObjectURL(urlImagen)
        })
        .catch(error => {
          console.error('Error al descargar la imagen:', error)
        })
    },
    ...mapActions('vouchers', ['downloadDoc', 'downloadPdf']),
    ...mapActions('message', ['showSuccess', 'showError'])
  },
  computed: {
    entries () {
      return this.getJournal.filter(({ voucher }) => voucher === this.id)
    },
    linkQR () {
      return 'https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=' + encodeURIComponent(this.paymentLink)
    },
    paymentLink () {
      return `${process.env.VUE_APP_WEB_BASE_URL}/public/pay?key=${this.voucher.key}`
    },
    actions () {
      const actions = [{
        text: 'Reenviar Correo',
        callback: () => {
          vex.dialog.open({
            message: 'Reenviar correo electrónico',
            input: [
              '<input name="to" type="email" placeholder="Enviar a..." required />',
              '<input name="cc" type="email" placeholder="Con copia a..." />'
            ].join(''),
            buttons: [
              { ...vex.dialog.buttons.YES, text: 'Enviar' },
              { ...vex.dialog.buttons.NO, text: 'Cancelar' }
            ],
            callback: (data) => {
              if (!data) {
                return
              }
              const payload = {
                to: data.to,
                cc: data.cc,
                key: this.id
              }
              redaApp.sendEmailVoucher(payload)
                .then(() => this.showSuccess('Comprobante reenviado a ' + data.to))
                .catch((error) => {
                  this.showError('Error al reenviar correo')
                  console.error(error)
                })
            }
          })
        },
        icon: 'mdi-email-fast'
      },
      {
        text: 'pdf',
        callback: () => {
          this.downloadPdf(this.id)
            .catch((error) => console.error(error))
        },
        icon: 'mdi-file-download'
      },
      {
        text: 'xml',
        callback: () => {
          this.downloadDoc({ path: `xml/${this.id}.xml`, fileName: `FE-${this.id}` })
            .catch((error) => console.error(error))
        },
        icon: 'mdi-file-code'
      },
      {
        text: 'Respuesta Hacienda',
        hidden: !electronicVoucher.includes(this.voucher.data.documentType),
        callback: () => {
          this.downloadDoc({ path: `mh/${this.id}.xml`, fileName: `MH-${this.id}` })
            .catch((error) => console.error(error))
        },
        icon: 'mdi-file-code-outline'
      },
      {
        text: 'Anular',
        hidden: !this.salesTypes[this.voucher.data.documentType] || this.voucher.deleted || this.voidedVoucher,
        callback: () => {
          vex.dialog.open({
            message: 'Anular documento',
            input: [
              '<input name="reason" type="text" placeholder="Razón por la que se anula" required />'
            ].join(''),
            buttons: [
              { ...vex.dialog.buttons.YES, text: 'Anular' },
              { ...vex.dialog.buttons.NO, text: 'Cancelar' }
            ],
            callback: (data) => {
              if (!data) {
                return
              }
              const payload = {
                key: this.id,
                reason: data.reason,
                number: this.nextNC,
                env: this.getCompany.env
              }
              redaApp.newCN(payload)
                .then(() => this.showSuccess('Comprobante anulado correctamente'))
                .catch((error) => {
                  this.showError('Error al anular comprobante')
                  console.error(error)
                })
            }
          })
        },
        icon: 'mdi-file-cancel'
      }]
      return actions.filter(({ hidden }) => !hidden)
    },
    displayReference () {
      if (!this.voucher.data.reference) return {}
      return {
        submitText: 'Información de Referencia',
        showSubmit: false,
        inputs: [
          {
            id: 'de00',
            text: 'Tipo de documento de referencia',
            label: 'Tipo de documento de referencia',
            items: [
              { value: '01', text: 'Factura electrónica' },
              { value: '02', text: 'Nota de débito electrónica' },
              { value: '03', text: 'Nota de crédito electrónica' },
              { value: '04', text: 'Tiquete electrónico' },
              { value: '05', text: 'Nota de despacho' },
              { value: '06', text: 'Contrato' },
              { value: '07', text: 'Procedimiento' },
              { value: '08', text: 'Comprobante emitido en contingencia' },
              { value: '09', text: 'Devolución mercadería' },
              { value: '10', text: 'Sustituye factura rechazada por el Ministerio de Hacienda' },
              { value: '11', text: 'Sustituye factura rechazada por el Receptor del comprobante' },
              { value: '12', text: 'Sustituye Factura de exportación' },
              { value: '13', text: '*Facturación mes vencido' },
              { value: '14', text: 'Comprobante aportado por contribuyente del Régimen de Tributación Simplificado' },
              { value: '15', text: 'Sustituye una Factura electrónica de Compra' },
              { value: '99', text: 'Otros' }
            ],
            name: 'documentType',
            value: 'document_type',
            type: 'display',
            readonly: true,
            cols: 12
          },
          {
            id: 'de01',
            text: 'Número',
            label: 'Número',
            name: 'number',
            value: 'number',
            type: 'display',
            readonly: true,
            cols: 12
          },
          {
            id: 'de02',
            label: 'Fecha de emisión del documento de referencia',
            name: 'date',
            value: 'date',
            type: 'display',
            readonly: true,
            cols: 12
          },
          {
            id: 'de04',
            text: 'Código de referencia',
            label: 'Código de referencia',
            items: [
              { value: '01', text: 'Anula Documento de Referencia' },
              { value: '02', text: 'Corrige monto' },
              { value: '04', text: 'Referencia a otro documento' },
              { value: '05', text: 'Sustituye comprobante provisional por contingencia' },
              { value: '99', text: 'Otros' }
            ],
            name: 'code',
            value: 'code',
            type: 'display',
            readonly: true,
            cols: 12
          },
          {
            id: 'de05',
            label: 'Razón de referencia',
            name: 'reason',
            value: 'reason',
            type: 'display',
            readonly: true,
            cols: 12
          }
        ]
      }
    },
    displayMessage () {
      if (!this.voucher.responseMessage) return {}
      return {
        showSubmit: false,
        inputs: [
          {
            id: 'de00',
            text: 'Nombre Emisor',
            label: 'Nombre Emisor',
            name: 'issuerName',
            value: 'issuerName',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de01',
            text: 'Tipo Identificacion Emisor',
            label: 'Tipo Identificacion Emisor',
            items: [
              { value: '01', text: 'Física' },
              { value: '02', text: 'Jurídica' },
              { value: '03', text: 'DIMEX' },
              { value: '04', text: 'NITE' }
            ],
            name: 'issuerIdentificationType',
            value: 'issuerIdentificationType',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de02',
            text: 'Número Cedula Emisor',
            label: 'Número Cedula Emisor',
            name: 'issuingIdentification',
            value: 'issuingIdentification',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de03',
            text: 'Nombre Receptor',
            label: 'Nombre Receptor',
            name: 'receiverName',
            value: 'receiverName',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de04',
            text: 'Tipo Identificacion Receptor',
            label: 'Tipo Identificacion Receptor',
            items: [
              { value: '01', text: 'Física' },
              { value: '02', text: 'Jurídica' },
              { value: '03', text: 'DIMEX' },
              { value: '04', text: 'NITE' }
            ],
            name: 'receiverIdentificationType',
            value: 'receiverIdentificationType',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de05',
            text: 'Numero Cedula Receptor',
            label: 'Numero Cedula Receptor',
            name: 'receiverIdentification',
            value: 'receiverIdentification',
            type: 'display',
            readonly: true,
            cols: 4
          },
          {
            id: 'de06',
            text: 'Mensaje',
            label: 'Mensaje',
            items: [
              { value: '1', text: 'aceptado' },
              { value: '3', text: 'rechazado' }
            ],
            name: 'message',
            value: 'message',
            type: 'display',
            readonly: true,
            cols: 12
          },
          {
            id: 'de07',
            label: 'Detalle Mensaje',
            name: 'detail',
            value: 'detail',
            type: 'textarea',
            readonly: true,
            cols: 12
          },
          {
            id: 'de08',
            label: 'Monto Total Impuesto',
            name: 'tax',
            value: 'tax',
            type: 'display',
            readonly: true,
            cols: 6
          },
          {
            id: 'de09',
            label: 'Total Factura',
            name: 'total',
            value: 'total',
            type: 'display',
            readonly: true,
            cols: 6
          }
        ]
      }
    },
    received () {
      return this.voucher.receiver === this.getCompany.identification
    },
    voucher () {
      return this.getVouchers.find((vou) => vou.key === this.id) ||
        this.getVouchersReceived.find((vou) => vou.key === this.id)
    },
    summary () {
      const { summary } = this.voucher.data
      return [
        { text: 'Venta Neta', value: summary.grossTotal },
        { text: 'Total Descuentos', value: summary.discountTotal },
        { text: 'Subtotal', value: summary.subtotal },
        { text: 'Total Impuestos', value: summary.taxTotal },
        { text: 'Total Otros Cargos', value: summary.otherChargesTotal }
      ]
    },
    total () {
      return Number(this.voucher.data.summary.netTotal) + this.totalDN - (-this.totalCN)
    },
    totalDN () {
      let debitNotes = 0
      this.voucher.debitNotes.forEach((item, i) => {
        debitNotes += Number(item.data.summary.netTotal)
      })
      return debitNotes
    },
    totalCN () {
      let creditNotes = 0
      this.voucher.creditNotes.forEach((item, i) => {
        creditNotes -= Number(item.data.summary.netTotal)
      })
      return creditNotes
    },
    voidedVoucher () {
      return this.total <= 0
    },
    ...mapGetters('vouchers', ['getVouchers', 'getVouchersReceived', 'nextND', 'nextNC']),
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('accounting', ['getJournal'])
  }
}
</script>
