<template>
  <v-card>
    <v-card-title>Compañia</v-card-title>
    <v-card-text>
      <reda-form
        :value="getCompany"
        :options="options"
      ></reda-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyConfig',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente
    options () {
      return {
        submitText: 'Actualizar',
        showSubmit: true,
        inputs: [
          {
            id: '000',
            label: 'Tipo ID',
            name: 'type',
            value: 'type',
            type: 'display',
            items: [
              { value: '01', text: 'Física' },
              { value: '02', text: 'Jurídica' },
              { value: '03', text: 'DIMEX' },
              { value: '04', text: 'NITE' }
            ],
            readonly: true,
            cols: 2
          },
          {
            id: '001',
            label: 'N°',
            name: 'identification',
            value: 'identification',
            type: 'display',
            rules: 'required',
            readonly: true,
            cols: 2
          },
          {
            id: '002',
            label: 'Nombre/Razón social',
            name: 'name',
            value: 'name',
            type: 'display',
            rules: 'required',
            readonly: true,
            cols: 4
          },
          {
            id: '003',
            label: 'Nombre comercial',
            name: 'tradename',
            value: 'tradename',
            type: 'text',
            cols: 4
          },
          {
            id: '006',
            name: 'activities',
            value: 'activities',
            type: 'table',
            options: {
              title: 'Actividades económicas',
              readonly: true,
              headers: [
                { text: 'Código', value: 'code' },
                { text: 'Descripción', value: 'description' },
                { text: 'Estado', value: 'state' },
                { text: 'Tipo', value: 'type' }
              ]
            },
            table: {
              dense: true,
              hideDefaultFooter: true
            },
            cols: 12
          },
          {
            id: '004',
            label: 'Telefono',
            name: 'phone',
            value: 'phone',
            type: 'number',
            rules: 'required',
            cols: 6
          },
          {
            id: '005',
            label: 'Correo electrónico',
            name: 'email',
            value: 'email',
            type: 'text',
            rules: 'required',
            cols: 6
          }
        ]
      }
    },
    ...mapGetters('company', ['getCompany'])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
