<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Orden de producción ref: {{ id }}
    </div>
    <v-card
      :loading="loading"
      elevation="2"
      class="d-flex flex-row justify-space-around my-10"
    >
      <div class="ma-5 pl-11">
        <div class="text-center caption">
          Producto
        </div>
        <div class="title text-center">
          {{ product.commercialCode }}: {{ product.description }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
      ></v-divider>
      <div class="ma-5">
        <div class="text-center caption">
          Cantidad por producir
        </div>
        <div class="title text-center">
          {{ order.quantity }}
        </div>
      </div>
      <v-divider
        class="ma-5"
        inset
        vertical
         v-if="false"
      ></v-divider>
      <div class="ma-5" v-if="false">
        <div class="text-center caption">
          Creada por
        </div>
        <div class="title text-center">
          {{ order.createdBy.name }}
        </div>
      </div>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
      :loading="loading"
    >
      <v-card-title>Materia prima directa</v-card-title>
      <DataTableCRUD
        :options="drmTable"
        :table="{
          dense: true,
          hideDefaultFooter: true,
        }"
        v-model="tmp.drm"
      ></DataTableCRUD>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          @click="save"
          :loading="loading"
        >Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      elevation="2"
      class="my-10"
    >
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import redaApp from '../api/redaApp'
import { /* mapState,  /* */mapActions, mapGetters } from 'vuex'
// import VoucherReceipts from '@/components/VoucherReceipts.vue'

export default {
  name: 'ProductionOrder',
  props: ['id'],
  components: {},
  data: () => ({
    moment,
    order: {},
    products: [],
    loading: true,
    tmp: {
      drm: []
    }
  }),
  mounted () {
    this.getOrder()
    this.getInventory()
  },
  methods: {
    getOrder () {
      this.loading = true
      redaApp.getRedaApp('po', this.id)
        .then(({ data }) => {
          this.order = { ...data }
          this.tmp.drm = JSON.parse(JSON.stringify(this.order.drm))
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getInventory () {
      this.loading = true
      redaApp.getRedaApp('get-inventories')
        .then(({ data }) => {
          this.products = data.products
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loading = false
        })
    },
    save () {
      this.loading = true
      const newItem = {
        drm: this.tmp.drm
      }
      redaApp.update('productionOrders', newItem, this.id).then((task) => {
        this.$emit('submit')
        this.showSuccess('Orden de producción actualizada')
      })
        .catch((e) => {
          this.showError('Error al crear orden de producción')
          console.error(e)
        })
        .finally(() => { this.loading = false })
    },
    ...mapActions('user', []),
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
  },
  computed: {
    getItems () {
      const products = this.products.filter(({ inventoryable }) => inventoryable)
      return products.map((product) => {
        const cost = Number(product.entriesValue || 0) / Number(product.entries || 1)
        const stock = Object.entries(product.stocks).reduce((acc, cur) => acc + cur[1], 0)
        const initialInventory = Number(product.initialInventory || 0)
        return {
          product,
          code: product.commercialCode,
          description: product.description,
          entries: 0,
          exits: 0,
          cost,
          value: cost,
          currentvalue: Number(stock || 0) * cost,
          stock: Number(stock || 0) + initialInventory,
          stocks: product.stocks
        }
      })
    },
    product () {
      return this.products.find(({ commercialCode }) => commercialCode === this.order.product)
    },
    headers () {
      return [
        {
          text: 'Material',
          value: 'material'
        },
        { text: 'Cantidad', value: 'quantity', editable: { type: 'number', title: 'Actualizar Cantidad' } },
        // { text: 'Costo unitario', value: 'unitCost' },
        { text: '', value: 'actions' }
      ]
    },
    drmTable () {
      return {
        headers: this.headers,
        newItemText: 'Agregar material',
        editItemText: 'Actualizar material',
        delete: true,
        defaultItem: {
          material: null,
          quantity: 1,
          unitCost: 0
        },
        insert: {
          itemValue: 'code',
          itemText: 'description',
          items: this.getItems,
          inserts: [
            { inputKey: 'material', objKey: 'code' },
            { inputKey: 'unitCost', objKey: 'cost' }
          ],
          filter: (item, queryText) => {
            const textOne = item.description.toLowerCase()
            const textTwo = item.code.toLowerCase()
            const textThree = String(item.code).toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
              textTwo.indexOf(searchText) > -1 ||
              textThree.indexOf(searchText) > -1
          }
        },
        form: {
          submitText: 'Guardar',
          showSubmit: true,
          inputs: [
            {
              id: 'de01',
              label: 'Materiales',
              items: this.getItems,
              name: 'material',
              value: 'material',
              type: 'autocomplete',
              cols: 8,
              itemText: 'description',
              itemValue: 'code',
              hideSelected: true
            },
            {
              id: 'de02',
              label: 'Cantidad',
              name: 'quantity',
              value: 'quantity',
              type: 'number',
              cols: 4
            }
          ]
        }
      }
    },
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
