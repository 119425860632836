import { render, staticRenderFns } from "./POCard.vue?vue&type=template&id=733a47f1&scoped=true"
import script from "./POCard.vue?vue&type=script&lang=js"
export * from "./POCard.vue?vue&type=script&lang=js"
import style0 from "./POCard.vue?vue&type=style&index=0&id=733a47f1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733a47f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBadge,VIcon,VProgressCircular})
