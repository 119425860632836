<template>
  <div>
    <v-card v-if="loading">
      <v-card-text  align="center" justify="center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          class="my-10"
          ></v-progress-circular>
        </v-card-text>
      <v-card-text  align="center" justify="center" class="title">
        Buscando su SINPE...
      </v-card-text>
    </v-card>
    <v-form @submit.prevent="confirm = true" ref="form" v-show="!loading && !confirm">
      <v-container>
        <v-row class="pt-5">
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-card-account-details" outlined v-model.trim="identification" label="Número de identificación ligado a tu cuenta SINPE Móvil*" :rules="identificationRules" @input="getById()"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field prepend-inner-icon="mdi-cellphone-message" outlined v-model.trim="number" label="Número de teléfono que origina el SINPE Móvil*" placeholder="88888888" :rules="numberRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="[
                { text: 'Tipo de Cédula', value: 'type' },
                { text: 'Nombre', value: 'name' }
              ]"
              :items="[{
                type: identificationTypeName,
                name: name
              }]"
              hide-default-footer
              dense
              class="mb-7 ma-0"
            >
              <template v-slot:top>
                <v-subheader>Titular de la cuenta SINPE</v-subheader>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="!hideSubmit">
          <v-col cols="12">
            <v-btn type="submit" color="primary" :disabled="!formIsValid" x-large>Pagar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card v-if="confirm && !loading">
      <v-card-text  align="center" justify="center">
        <p>Para completar tu compra, realizá un SINPE Móvil por un monto de <strong class="title">{{ currencySimbol }}{{ amout }}</strong> al siguiente número de teléfono:</p>
        <h1>7019-6686</h1>
        <p></p>
        <div>A nombre de</div>
        <h4>ONVO Costa Rica Sociedad Anónima</h4>
      </v-card-text>
      <v-card-actions class="px-7">
        <v-btn text @click="confirm = false">Regresar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="dialog = true">Confirmar pago</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Haga el SINPE
        </v-card-title>

        <v-card-text>
          Este es el momento de hacer tu SINPE Móvil. Cuando recibás la confirmación bancaria de que el dinero ya fue enviado, presioná el botón azul para continuar.
        </v-card-text>

        <v-card-actions v-if="isMovil">
          <v-btn
            text
            @click="dialog = false"
          >
            No he recibido la confirmación
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="dialog = false; submitForm()"
          >
            Sí he recibido la confirmación
          </v-btn>
        </v-card-actions>
        <v-container v-else>
          <v-btn
            large
            text
            @click="dialog = false"
            block
          >
            No he recibido la confirmación
          </v-btn>
          <v-btn
            large
            color="primary"
            @click="dialog = false; submitForm()"
            block
          >
            Sí he recibido la confirmación
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'SinpeMovil',
  props: {
    hideSubmit: Boolean,
    receiver: String,
    currencySimbol: String,
    amout: Number
  },
  data () {
    return {
      confirm: false,
      identification: null,
      identificationType: null,
      identificationTypeName: null,
      number: null,
      name: null,
      loading: false,
      existIdentification: false,
      dialog: false
    }
  },
  mounted () {
    this.identification = this.receiver
    this.getById(true)
    this.$refs.form.resetValidation()
  },
  computed: {
    isMovil () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    payload () {
      const identification = this.identificationType === 0
        ? `0${this.identification.substring(0, 1)}-${this.identification.substring(1, 5)}-${this.identification.substring(5, 9)}`
        : this.identificationType
      return {
        identification,
        identificationType: this.identificationType,
        number: '+506' + this.number
      }
    },
    formIsValid () {
      // Validar que todos los campos estén completos
      const full = !!this.identification && (this.identificationType === 0 || this.identificationType === 1) && !!this.number
      if (full) {
        return this.$refs.form.validate()
      }
      return false
    },
    identificationRules () {
      return [
        value => !!value || 'El número de identidad es obligatorio',
        value => (value && (value.length === 12 || value.length === 9)) || 'El número de identidad debe tener 9 o 13 dígitos',
        value => (value && this.existIdentification) || 'El número de identidad no coincide con ninguna persona física o residente'
      ]
    },
    numberRules () {
      return [
        value => !!value || 'El número de teléfono es obligatorio',
        value => (value && value.length === 8) || 'El número de teléfono debe tener 8 dígitos',
        value => (value && /^\d+$/.test(value)) || 'El número de teléfono solo puede contener dígitos, sin letras, ni espacios ni guiones'
      ]
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      const key = this.$route.query.key
      // Construir el objeto de payload con los datos del formulario
      const payload = {
        mobileNumber: { ...this.payload },
        key: this.$route.query.key
      }

      // Enviar la solicitud POST al backend con Axios
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/pay-voucher`, payload)
        .then(response => {
          // Manejar la respuesta del backend
          console.log(response.data)
          // this.$emit('submited', response.data)
          this.$router.push(`/public/successpay?key=${key}&message=${'success'}`)
        })
        .catch(error => {
          // Manejar errores de la solicitud
          if (error.response.status === 400) {
            this.$router.push(`/public/successpay?key=${key}&message=${'awaiting'}`)
          } else {
            this.showError('Error al procesar pago, intentar más tarde')
          }
          this.$emit('submitError', error)
        })
        .finally(() => {
          this.loading = false
        }) /**/
    },
    getById (mounted) {
      const length = this.identification?.length
      if (length === 9 || length === 12) {
        axios
          .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.identification)
          .then(response => {
            const types = {
              '01': 'Física',
              '02': 'Jurídica',
              '03': 'DIMEX',
              '04': 'NITE'
            }
            const typesONVO = {
              '01': 0,
              '03': 1
            }
            this.existIdentification = response.data.tipoIdentificacion === '01' || response.data.tipoIdentificacion === '03'
            this.identificationType = typesONVO[response.data.tipoIdentificacion]
            this.identificationTypeName = types[response.data.tipoIdentificacion]
            this.name = response.data.nombre
          })
          .catch(({ response }) => {
            if (response) {
              console.log(response.status)
              if (response.status === 400 || response.status === 404) {
                this.identificationType = null
                this.identificationTypeName = null
                this.name = null
                this.existIdentification = false
                this.$refs.form.validate()
              }
            }
          })
      }
    },
    ...mapActions('message', [
      'showSuccess',
      'showError'
    ])
  }
}
</script>
