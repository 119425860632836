<template>
  <v-container>
    <div class="headline my-7 ml-2">
      Bancos y efectivo
    </div>
    <Period class="my-2" />
    <BanksAndCashBalances class="my-2" />
    <BanksAndCashTable class="my-2" />
  </v-container>
</template>
<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'
// import { db, fb } from '@/api/firebase.js'
import BanksAndCashTable from '@/components/BanksAndCashTable.vue'
import BanksAndCashBalances from '../components/BanksAndCashBalances.vue'
import Period from '../components/Period.vue'

export default {
  name: 'BanksAndCash',
  components: { BanksAndCashTable, BanksAndCashBalances, Period },
  data: () => ({
  }),

  computed: {
    // ...mapGetters()
  },

  watch: {
  },

  created () {
  },

  methods: {
    // ...mapMutations(),
    // ...mapActions()
  }
}
</script>
<style type="text/css">
</style>
