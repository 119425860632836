<template>
  <v-card>
    <v-card-text>

      <v-simple-table
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr
              v-for="(item, j) in getIncomeStatement.items"
              :key="j"
            >
              <td>
                {{ item.text }}
              </td>
              <td class="text-right"
                :class="{ 'error--text': item.value < 0 }"
              >{{ item.value | currencyFormat }}</td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>

<script>
import { /* mapState, mapActions,/**/ mapGetters } from 'vuex'
// import tools from '@/api/tools.js'
export default {
  name: 'IncomeStatement',
  data () {
    return {}
  },
  methods: {
  },
  computed: {
    getIncomeStatement () {
      // const income = this.getAllLedgers.find(({ code }) => code === '4'))
      // const expenses = this.getAllLedgers.find(({ code }) => code === '5'))
      const revenue = this.getAllLedgers.find(({ code }) => code === '4.01').balances.credit
      const costOfSales = this.getAllLedgers.find(({ code }) => code === '5.01').balances.debit
      const grossProfit = Number(revenue) - Number(costOfSales)
      const otherIncome = this.getAllLedgers.find(({ code }) => code === '4.03').balances.credit
      const distributionCosts = this.getAllLedgers.find(({ code }) => code === '5.02').balances.debit
      const administrativeExpense = this.getAllLedgers.find(({ code }) => code === '5.03').balances.debit
      const otherExpenseByFunction = this.getAllLedgers.find(({ code }) => code === '5.04').balances.debit
      const otherGainsLosses = 0
      const financeIncome = this.getAllLedgers.find(({ code }) => code === '4.02').balances.credit
      const financeCosts = this.getAllLedgers.find(({ code }) => code === '5.05').balances.debit
      const shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod = 0
      const profitLossBeforeTax = grossProfit +
      otherIncome +
      otherGainsLosses -
      distributionCosts -
      administrativeExpense -
      otherExpenseByFunction +
      financeIncome -
      financeCosts
      const incomeTaxExpenseContinuingOperations = 0
      const profitLossFromContinuingOperations = Number(profitLossBeforeTax) -
      Number(incomeTaxExpenseContinuingOperations)
      const profitLossFromDiscontinuedOperations = 0
      const profitLoss = Number(profitLossFromContinuingOperations) +
    Number(profitLossFromDiscontinuedOperations)
      const items = [
        { text: 'Ingresos de actividades ordinarias', value: revenue },
        { text: 'Costo de ventas', value: costOfSales },
        { text: 'Ganancia bruta', value: grossProfit },
        { text: 'Otros ingresos', value: otherIncome },
        { text: 'Costos de distribución', value: distributionCosts },
        { text: 'Gastos de administración', value: administrativeExpense },
        { text: 'Otros gastos, por función', value: otherExpenseByFunction },
        { text: 'Otras ganancias (pérdidas)', value: otherGainsLosses },
        { text: 'Ingresos financieros', value: financeIncome },
        { text: 'Costos financieros', value: financeCosts },
        { text: 'Participación en las ganancias (pérdidas) de asociadas y negocios conjuntos que se contabilicen utilizando el método de la participación', value: shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod },
        { text: 'Ganancia (pérdida), antes de impuestos', value: profitLossBeforeTax },
        { text: 'Gasto (ingreso) por impuestos, operaciones continuadas', value: incomeTaxExpenseContinuingOperations },
        { text: 'Ganancia (pérdida) procedente de operaciones continuadas', value: profitLossFromContinuingOperations },
        { text: 'Ganancia (pérdida) procedente de operaciones discontinuadas', value: profitLossFromDiscontinuedOperations },
        { text: 'Ganancia (pérdida)', value: profitLoss }
      ]
      const incomeStatement = {
        items,
        revenue,
        costOfSales,
        grossProfit,
        otherIncome,
        distributionCosts,
        administrativeExpense,
        otherExpenseByFunction,
        otherGainsLosses,
        financeIncome,
        financeCosts,
        shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod,
        profitLossBeforeTax,
        incomeTaxExpenseContinuingOperations,
        profitLossFromContinuingOperations,
        profitLossFromDiscontinuedOperations,
        profitLoss
      }
      return incomeStatement /* */
    },
    ...mapGetters('accounting', ['getAllLedgers'])
  }
}
</script>
