<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4" class="pa-0">
        <ChatBar :chats="chats" @selectItem="selectChat($event)"></ChatBar>
      </v-col>
      <v-col cols="12" sm="8" style="position: relative;">
        <ConversationPanel :messages="currentMessages"></ConversationPanel>
      </v-col>
    </v-row>
  </v-container>
</template>
<script type="text/javascript">
import { /* mapActions, */ mapGetters } from 'vuex'
import ConversationPanel from '@/components/ConversationPanel.vue'
import ChatBar from '@/components/ChatBar.vue'
import moment from 'moment'
export default {
  name: 'Chats',
  components: {
    ChatBar,
    ConversationPanel
  },
  data: () => ({
    selectedChat: null
  }),
  computed: {
    chats () {
      return this.getChats.map((chat) => {
        const lastMessageTime = moment(chat.lastTimestamp, 'x').calendar(null, {
          sameDay: 'LT',
          lastDay: '[ayer a las] LT',
          lastWeek: 'dddd',
          sameElse: 'L'
        })
        return { ...chat, lastMessageTime }
      })
    },
    currentMessages () {
      return this.selectedChat === null ? null : this.getChats[this.selectedChat].messages
    },
    ...mapGetters('chats', ['getChats'])
  },
  methods: {
    selectChat (chat) {
      this.selectedChat = chat
    }
  }
}
</script>
