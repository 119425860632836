<template>
  <v-card :loading="isLoading" :disabled="isLoading">
    <v-card :loading="loadingEmit" v-if="cashOpen || $route.name === 'IssueVoucher' || issuer" :flat="$route.name !== 'IssueVoucher'">
      <v-card-title class="text-center pb-0" v-if="!documentType">
        <v-row>
          <v-col cols="12" sm="6" class="d-flex align-center justify-left">Punto de Venta</v-col>
          <v-col cols="12" sm="6" class="py-0 my-0"><v-select
          :items="getUserVoucherTypes"
          :value="voucherType"
          @input="setVoucherType"
          label="Tipo de comprobante"
          :disabled="!!documentType"
          required
        ></v-select></v-col></v-row>
      </v-card-title>
      <v-card-text >
      <v-row>
      <v-col cols="12" sm="3" class="py-0 my-0">
        <v-container fluid class="py-0 my-0">
          <v-col cols="12" class="py-0 my-0">
          <v-subheader class="text-left pl-0 ml-0">Datos del {{(voucherType === '08' || voucherType === '12') ? 'Proveedor' : 'Receptor' }}</v-subheader>
          <v-container style="height: 150px;" v-show="!receiver">
              <v-btn fab color="secondary" @click.stop="dialogReceiver = true" v-if="!voucher">
                 <v-icon>mdi-plus</v-icon>
              </v-btn>
          </v-container>
          <v-dialog v-model="dialogReceiver" persistent max-width="600px">
            <v-card >
              <v-card-title>
                <span class="headline">{{(voucherType === '08' || voucherType === '12') ? 'Proveedor' : 'Cliente' }}</span>
              </v-card-title>
              <v-card-text>
                <v-container >
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                      label="Clientes registrados"
                      :items="itemsCustomers"
                      v-model="tmp.customer"
                      @change="setCustomer"
                      return-object
                      item-text="name"
                      ></v-autocomplete>
                    </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                    :items="[
                      { value: '01', text: 'Física' },
                      { value: '02', text: 'Jurídica' },
                      { value: '03', text: 'DIMEX' },
                      { value: '04', text: 'NITE' },
                    ]"
                    label="Tipo*"
                    v-model="tmp.receiver.typeId"
                    required
                    ></v-select>
                  </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field type="number" label="Numero de cédula*" autofocus v-if="dialogReceiver" hint="sin guiones ni espacios" v-model="tmp.receiver.identification" @change="getByIdentification(tmp.receiver.identification)" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Nombre completo*" v-model="tmp.receiver.name" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="email" label="Email*" v-model="tmp.receiver.email" required></v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <v-text-field label="Telefono" v-model="tmp.receiver.phone"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                      :items="getCompany.priceList"
                      label="Tipo de Precio*"
                      v-model="priceList"
                      required
                      ></v-select>
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <v-text-field label="Exoneración" v-model="tmp.receiver.allowanceNumber" :error-messages="tmp.receiver.errorAllowance"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="province" rules="required">-->
                        <v-select label="Provincia*" v-model="tmp.receiver.location.province" @change="getCounty" :items="provinces" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="county" rules="required">-->
                        <v-select label="Cantón*" v-model="tmp.receiver.location.county" :items="counties" @change="getDistrict" :disabled="tmp.receiver.location.province === null" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                    <v-col cols="12"  sm="4">
                      <!--<ValidationProvider v-slot="{ errors }" name="district" rules="required">-->
                        <v-select label="Distrito*" v-model="tmp.receiver.location.district" :items="districts" :disabled="tmp.receiver.location.county === null" required></v-select>
                      <!--</ValidationProvider>-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <!--<ValidationProvider v-slot="{ errors }" name="others" rules="max:250|required">-->
                        <v-text-field label="Otras señas*" :counter="250" v-model="tmp.receiver.location.others" required></v-text-field>
                      <!--</ValidationProvider>-->
                    </v-col>
                  </v-row>
                </v-container>
                <small>*campos obligatorios</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogReceiver = false; if (!(tmp.receiver.editing)){cleanReceiver()}">Cerrar</v-btn>
                <v-btn color="primary" @click="insertReceiver" :loading="loadingReceiver">Agregar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-simple-table v-if="receiver" dense>
            <tbody>
              <tr>
                <th><v-icon>mdi-account-box</v-icon></th>
                <td> {{receiver.identificationDocument.number}} </td>
              </tr>
              <tr>
                <th><v-icon>mdi-account</v-icon></th>
                <td> {{receiver.name}} </td>
              </tr>
              <tr>
                <th><v-icon>mdi-email</v-icon></th>
                <td> {{receiver.email}} </td>
              </tr>
              <tr>
                <th><v-icon>mdi-phone</v-icon></th>
                <td> {{receiver.phone}} </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="d-flex flex-row-reverse pa-0 ma-0" v-if="!voucher">
            <v-btn color="error" @click="deleteReceiver(); cleanReceiver()" v-if="receiver" icon><v-icon>mdi-delete</v-icon></v-btn>
            <v-btn @click.stop="dialogReceiver = true; tmp.receiver.editing = true" v-if="receiver" icon><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
        </v-col>
        </v-container>
        <v-container fluid style="height: 270px;" class="pb-0 my-0" v-show="voucherType !== '13'">
            <v-col cols="12" class="py-0 my-0">
              <v-select
              :items="itemsSalesCondition"
              label="Condición de Venta"
              class="py-0-0 my-0"
              :value="getCondition"
              @input="setCondition"
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <v-text-field v-show="getCondition === '02'"
                label="Plazo Credito"
                type="number"
                :value="getCreditTerm"
                @input="setCreditTerm"
                class="py-0 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12"  class="py-0 my-0">
              <v-select
              :items="currencyItems"
              label="Moneda"
              @change="getExchangeRate"
              class="py-0 my-0"
              :value="getCurrency"
              @input="setCurrency"
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <v-text-field v-show="getCurrency !== 'CRC'"
                label="Tipo de Cambio"
                type="number"
                class="py-0 my-0"
                :value="exchangeRate"
                @input="setExchangeRate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <v-text-field v-show="getCompany.pos ? getCompany.pos.showOrderField : false"
                label="Orden de compra"
                type="text"
                class="py-0 my-0"
                :value="getOrder"
                @input="setOrder"
              ></v-text-field>
            </v-col>
        </v-container>
        <div class="text-left" v-if="isMovil">
          <v-btn class="mx-2" x-large :disabled="loadingEmit" @click.stop="payVoucher" color="primary">Emitir</v-btn>
          <v-btn class="mx-2" x-large  @click="reset()">{{ voucher ? 'Cerrar' : 'Limpiar' }}</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="9">
        <v-container fluid>
          <v-card min-height="550">
          <v-card-text>
          <v-subheader class="py-0 my-0">Detalle de productos o servicios</v-subheader>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-row class="py-0 my-0" v-if="!voucher || proforma">
                <v-col cols="12" sm="4">
                  <v-row class="py-0 my-0">
                    <v-col cols="9" class="xr-0 py-0 my-0">
                      <v-text-field
                        @keyup.enter="insertProductFromCode"
                        v-model="tmp.commandCode"
                        prepend-inner-icon="fa-barcode"
                        autofocus
                        :key="keyCommand"
                        class="xr-0 py-0 my-0"
                      ></v-text-field>
                    </v-col >
                    <v-col cols="3" class="xl-0 py-0 my-0 px-0" >
                      <v-btn fab color="secondary" @click.stop="dialogItems = true" small>
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                </v-col>
                <v-col cols="12" sm="4" class="xr-0 py-0 my-0">
                  <v-spacer></v-spacer>
                  <v-autocomplete
                    :items="sellers"
                    item-value="uid"
                    item-text="name"
                    label="Vendedor"
                    class="xr-0 py-0 my-0"
                    :value="seller"
                    @input="setSeller"
                    v-show="sellers.length > 0"
                    required
                  ></v-autocomplete>
                  <v-select
                  :items="getCompany.activities"
                  item-value="code"
                  item-text="description"
                  label="Actividad económica"
                  class="xr-0 py-0 my-0"
                  :value="activityCode"
                  @input="setActivity"
                  v-show="getCompany.activities.length > 1"
                  required
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersItems"
            :items="itemsDataTable"
            :hide-default-footer="true"
            :disable-sort="true"
            dense
            :items-per-page="itemsDataTable.length"
            height="300"
          >
            <template v-slot:item.index="{ item }">
              <v-btn color="error" icon @click="deleteItem(item.index)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
            <template v-slot:item.hsCode="{ item }">
              <input type="text" :value="getItems[item.index].hsCode" @change="updateHsCode" :id="item.index" style="width: 70px">
            </template>
            <template v-slot:item.quantity="{ item }">
              <input type="number" :value="getItems[item.index].quantity" @change="updateItemQuantity" :id="item.index" style="width: 70px" min="0">
            </template>
            <template v-slot:item.discount="{ item }">
              <input type="number" :value="getItems[item.index].discountRate" @change="updateItemDiscount" :id="item.index" style="width: 70px" min="0">
            </template>
            <template v-slot:body.append v-if="receiver && receiver.allowance">
              <tr>
                <td :colspan="headersItems.length" class="text-right">* Productos exonerados</td>
              </tr>
            </template>
          </v-data-table>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="8" class="pa-0 ma-0">
              <v-textarea
                label="Observaciones"
                v-model="tmp.others.otherText"
                outlined
                clearable
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4"  class="py-0 my-0">
              <v-list dense>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.grossTotal | currencyFormat(getCurrency)}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Venta Neta</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.taxTotal | currencyFormat(getCurrency)}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Impuesto</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="title pa-0 ma-0 text-right">{{summary.netTotal | currencyFormat(getCurrency)}}</v-list-item-title>
                      <v-list-item-subtitle class="pa-0 ma-0 text-right">Total Comprobante</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-col>
          </v-row>

          <v-dialog v-model="dialogItems" persistent max-width="600px">
            <ValidationObserver v-slot="{ invalid }">
              <v-card>
                <v-card-title>
                  <span class="headline">Producto o servicio</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                        label="Productos registrados"
                        v-model="tmp.searchCode"
                        @change="getProductByCode"
                        autofocus
                        v-if="dialogItems"
                        :items="itemsProductsCode"
                        :loading="isLoading"
                        :search-input.sync="search"
                        hide-no-data
                        hide-selected
                        item-text="description"
                        item-value="commercialCode"
                        >
                          <template v-slot:item="{ item }">
                            {{ item.commercialCode }}: {{ item.description }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="CAByS*"
                          v-model="tmp.item.cabys"
                          :items="cabys"
                          item-value="code"
                          item-text="description"
                          @change="insertCabys"
                          clearable
                          required
                        >
                          <template v-slot:prepend>
                            <v-btn color="secondary" fab small><v-icon left>mdi-magnify</v-icon></v-btn>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <!--<v-text-field label="Detalle*" v-model="tmp.item.description" required></v-text-field>-->
                        <ValidationProvider v-slot="{ errors }" name="code" rules="required">
                          <v-text-field :error-messages="errors" label="Codigo*" v-model="tmp.item.code" ref="item" required></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <ValidationProvider v-slot="{ errors }" name="quantity" rules="required">
                          <v-text-field :error-messages="errors" type="number" label="Cantidad*" v-model="tmp.item.quantity" required></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <ValidationProvider v-slot="{ errors }" name="unitPrice" rules="required">
                          <v-text-field :error-messages="errors" type="number" label="Precio unitario*" v-model="tmp.item.price.price" required></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                          <v-text-field :error-messages="errors" label="Detalle*" v-model="tmp.item.description" required></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-checkbox v-model="tmp.item.taxed" :label="`IVA`"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          :items="units"
                          label="Unidad*"
                          v-model="tmp.item.unit"
                          required
                        ></v-select>
                      </v-col>
                      <!--<v-col cols="12" sm="4">
                        <v-select
                        :items="[
                        {value: '01', text: 'Impuesto al Valor Agregado' },
                        {value: '02', text: 'Impuesto Selectivo de Consumo' },
                        {value: '03', text: 'Impuesto Único a los Combustibles' },
                        {value: '04', text: 'Impuesto específico de Bebidas Alcohólicas' },
                        {value: '05', text: 'Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador' },
                        {value: '06', text: 'Impuesto a los Productos de Tabaco' },
                        {value: '07', text: 'IVA (cálculo especial)' },
                        {value: '08', text: 'IVA Régimen de Bienes Usados (Factor)' },
                        {value: '12', text: 'Impuesto Específico al Cemento' },
                        {value: '99', text: 'Otros' }
                        ]"
                        label="Impuesto*"
                        v-model="tmp.taxes.code"
                        required
                        ></v-select>
                      </v-col>-->
                      <v-col cols="12" sm="6" v-if="tmp.item.taxed" >
                        <v-select
                        :items="[
                        {value: '01', text: 'Tarifa 0% (Exento)' },
                        {value: '02', text: 'Tarifa reducida 1%' },
                        {value: '03', text: 'Tarifa reducida 2%' },
                        {value: '04', text: 'Tarifa reducida 4%' },
                        {value: '05', text: 'Transitorio 0%' },
                        {value: '06', text: 'Transitorio 4%' },
                        {value: '07', text: 'Transitorio 8%' },
                        {value: '08', text: 'Tarifa general 13%' }
                        ]"
                        label="Tarifa Impuesto Valor Agregado"
                        v-model="tmp.taxes.rate_code"
                        required
                        @change="setRate"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h3>Total IVA: {{ itemTaxNet }}</h3>
                        <h3>Total Linea: {{ itemNetTotal }}</h3>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*campos obligatorios</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="keyCommand++; dialogItems = false; cleanItem()">Cerrar</v-btn>
                  <v-btn color="primary" @click="tmp.searchCode = tmp.item.code; insertItem()" :disabled="invalid">Agregar</v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
          </v-dialog>
        </v-card-text>
        </v-card>
        </v-container>
      </v-col>
      </v-row>
      <v-dialog v-model="dialogPay" persistent max-width="600px">
        <NewReceipt
          :voucher="voucher"
          :amountOutstanding="summary.netTotal"
          receiptsFrom="01"
          @cancel="dialogPay = false"
          @payVoucher="submitInvoice(true, $event)"
          :pos="true"
        ></NewReceipt>
        <v-card v-if="false">
          <v-card-title>
            <span class="headline">Pagar</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                <p>Condicion de venta</p>
                <v-btn-toggle :value="paymentType" @change="setPaymentType" class="d-none d-sm-flex" mandatory>
                  <v-btn text value="01">
                    Efectivo
                  </v-btn>
                  <v-btn text value="02">
                    Tarjeta
                  </v-btn>
                  <v-btn text value="03">
                    Cheque
                  </v-btn>
                  <v-btn text value="04">
                    Transferencia
                  </v-btn>
                  <v-btn text value="99">
                    Otros
                  </v-btn>
                </v-btn-toggle>
                <v-select
                  :items="[
                  {value: '01', text: 'Efectivo' },
                  {value: '02', text: 'Tarjeta' },
                  {value: '03', text: 'Cheque' },
                  {value: '04', text: 'Transferencia' },
                  {value: '99', text: 'Otros' }
                  ]"
                  @input="setPaymentType"
                  :value="paymentType"
                  class="d-flex d-sm-none"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-text-field label="Paga con" v-model="payWith" outlined v-if="paymentType === '01'" @keyup.enter="submitInvoice" autofocus></v-text-field>
              <v-radio-group v-model="currencyPay" row>
                <v-radio label="CRC" value="CRC"></v-radio>
                <v-radio label="USD" value="USD"></v-radio>
              </v-radio-group>
              <h1 class="text-center">Total</h1>
              <p class="display-2 text-center">{{totalPay}}</p>
            </v-container>
            <!--<v-container>
                <v-btn-toggle v-model="money" class="d-none d-sm-flex" mandatory>
                  <v-btn text value="01">
                    1000
                  </v-btn>
                  <v-btn text value="02">
                    2000
                  </v-btn>
                  <v-btn text value="03">
                    3000
                  </v-btn>
                  <v-btn text value="04">
                    5000
                  </v-btn>
                  <v-btn text value="99">
                    6000
                  </v-btn>
                </v-btn-toggle>
            </v-container>-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogPay = false; ">Cerrar</v-btn>
            <v-btn color="primary" @click="submitInvoice">Pagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-card-text>
      <v-card-actions>
        <div class="text-left" v-if="!isMovil">
          <v-btn class="mx-2" x-large :disabled="loadingEmit" @click.stop="payVoucher" color="primary">Emitir</v-btn>
          <v-btn class="mx-2" x-large  @click="reset()">{{ voucher ? 'Cerrar' : 'Limpiar' }}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <!-- <v-container class="d-flex justify-center"  v-if="!cashOpen && (account.plan === 'pc01' || account.plan === 'pc02')" > -->
    <v-container class="d-flex justify-center"  v-if="!cashOpen && $route.name !== 'IssueVoucher' && !issuer" >
      <v-card max-width="600px" min-width="550px" elevation="24"  :loading="isLoading">
        <v-card-title class="text-center">
          <span class="headline">Apertura de Caja</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            label="Saldo Inicial"
            v-model="initialBalance"
            @keyup.enter="openCashPos()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--<v-btn @click="openCashBox()"  color="secondary">Abrir Cajón</v-btn>-->
          <v-btn @click="openCashPos()"  color="primary">Abrir</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogChange" max-width="600px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <h1 class="display-2 text-center">Vuelto</h1>
          <h1 class="display-2 text-center" v-if="paymentType === '01'" >{{getchange(payWith,totalPay)}}</h1>
          <h1 class="display-1 text-center" v-if="currencyPay === 'USD' && paymentType === '01'" >CRC: {{changeCRC}}</h1>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogChange = false" color="primary">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/javascript">
import { mapActions, mapState, mapGetters } from 'vuex'

import axios from 'axios'
// import api from '@/api/pos.js'
import apiCE from '@/api/apiCE.js'
import printer from '@/api/printer.js'
import tools from '@/api/tools.js'
import { units } from '@/assets/codes.js'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import * as cabys from '@/api/cabys.json'
import firebaseApi from '../api/firebaseApi'
import NewReceipt from '@/components/NewReceipt.vue'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1
    }
  },
  computesRequired: true
})

function decimalAdjust (type, value, exp) {
  // Si el exp no está definido o es cero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // Si el valor no es un número o el exp no es un entero...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}
function round10 (value, exp) {
  return decimalAdjust('round', value, exp)
}

function getDefaultData () {
  return {
    descriptionLimit: 60,
    entries: [],
    isLoading: true,
    model: null,
    search: null,
    listenerProducts: null,
    products: [],
    cabys: cabys.default.data,
    units,
    electronicVoucher: false,
    provinces: [],
    counties: [],
    districts: [],
    initialBalance: 0,
    loadingReceiver: false,
    itemsSalesCondition: [
      { text: 'Contado', value: '01' },
      { text: 'Crédito', value: '02' },
      { text: 'Consignación', value: '03' },
      { text: 'Apartado', value: '04' },
      { text: 'Arrendamiento con Opción de Compra', value: '05' },
      { text: 'Arrendamiento en Función Financiera', value: '06' },
      { text: 'Otros', value: '99' }
    ],
    paymentMethodsItems: [
      { text: 'Efectivo', value: '01' },
      { text: 'Tarjeta', value: '02' },
      { text: 'Cheque', value: '03' },
      { text: 'Transferencia', value: '04' },
      { text: 'Otros', value: '99' }
    ],
    currency: 'CRC',
    currencyItems: [
      { text: 'Colones', value: 'CRC' },
      { text: 'Dolares', value: 'USD' }
    ],
    exchangeRateDollar: null,
    payWith: null,
    currencyPay: 'CRC',
    loadingEmit: false,
    invoice: {
      salesCondition: '01',
      paymentMethods: '01',
      creditTerm: null,
      currency: 'CRC',
      exchangeRate: null,
      observations: null,
      items: []
    },
    barCodes: {},
    tmp: {
      receiver: {
        uid: null,
        typeId: null,
        id: null,
        identification: null,
        name: null,
        email: null,
        phone: null,
        editing: false,
        location: {
          province: null,
          county: null,
          district: null,
          others: null
        },
        customer: {},
        allowanceNumber: null,
        allowance: null
      },
      item: {
        cabys: null,
        code: null,
        quantity: 1,
        description: null,
        price: {
          price: null,
          currency: 'CRC',
          priceList: 'general'
        },
        inventoryable: false,
        taxed: false,
        netTotal: 0,
        unit: 'Unid'
      },
      taxes: {
        code: '01',
        rate_code: '08',
        rate: 13
      },
      others: {
        otherText: null
      },
      searchCode: null,
      commandCode: null
    },
    rateCodeValues: {
      '01': 0,
      '02': 1,
      '03': 2,
      '04': 4,
      '05': 0,
      '06': 4,
      '07': 8,
      '08': 13
    },
    totalPay: 0,
    dialogItems: false,
    dialogReceiver: false,
    dialogPay: false,
    dialogChange: false,
    priceList: 'general',
    keyCommand: 1
  }
}
export default {
  name: 'Pos',
  components: {
    ValidationProvider,
    ValidationObserver,
    NewReceipt
  },
  props: {
    issuer: Boolean,
    moduleName: {
      default: '1'
    },
    voucher: String,
    documentType: String
  },
  created () {
    this.$store.commit(this.moduleName + '/setActivity', this.$store.getters['company/getCompany'].mainActivity)
  },
  data: () => (getDefaultData()),
  beforeDestroy: function () {
  },
  mounted: function () {
    console.log(this.getExchangeRateUSD)
    // this.resetProducts()
    this.isLoading = true
    firebaseApi.db.collection('products')
      .where('company', '==', this.getCompany.identification)
      .get()
      .then(snapshot => {
        const entries = snapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
        this.entries = entries
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.isLoading = false))
    this.getProvince()
    apiCE.getDollarExcachange()
      .then(exchangeRate => {
        this.exchangeRateDollar = exchangeRate.sell
        this.setExchangeRate(exchangeRate.sell)
      })
      .catch(error => {
        console.error(error)
      })
    this.setVoucherType(this.documentType || this.getUserVoucherTypes[0].value)
    if (this.voucher) this.setVoucher(this.voucher)
    const seller = this.sellers.find(({ userUID }) => userUID === this.getUser.uid)
    if (seller && !this.voucher) this.setSeller(seller.uid)
  },
  // watch: {
  //   'invoice.paymentMethods': function (val/*, oldVal/**/) {
  //     this.setPaymentType(val)
  //   },
  //   search (val) {
  //     // Items have already been loaded
  //     if (this.items.length > 0) return
  //     if (val.length < 4) return

  //     // Items have already been requested
  //     if (this.isLoading) return

  //     this.isLoading = true

  //     // Lazily load input items
  //     firebaseApi.db.collection('products')
  //       .where('company', '==', this.getCompany.identification)
  //       .get()
  //       .then(snapshot => {
  //         const entries = snapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
  //         this.entries = entries.filter((item) => {
  //           const textOne = item.description.toLowerCase()
  //           const textTwo = item.commercialCode ? item.commercialCode.toString().toLowerCase() : item.commercialCode.toString().toLowerCase()
  //           const searchText = val.toString().toLowerCase()

  //           return textOne.indexOf(searchText) > -1 ||
  //       textTwo.indexOf(searchText) > -1
  //         })
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //       .finally(() => (this.isLoading = false))
  //   }
  // },
  methods: {
    getAllowance () {
      return new Promise((resolve, reject) => {
        apiCE.getAllowance(this.tmp.receiver.allowanceNumber)
          .then((allowance) => resolve(allowance))
          .catch((e) => reject(e))
      })
    },
    resetProducts () {
      if (this.listenerProducts) this.listenerProducts()
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.products.push({ ...doc.data(), uid: doc.id })
          }
        })
    },
    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.description.toLowerCase()
      const textTwo = item.commercialCode ? item.commercialCode.toString().toLowerCase() : item.commercialCode.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key)
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.tmp.receiver.location.province + '/canton/' + this.tmp.receiver.location.county + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key)
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    openCashBox () {
      printer.cash()
        .catch(error => {
          console.error(error)
        })
    },
    openCashPos () {
      this.openCash(this.initialBalance)
        .then(() => {
          this.initialBalance = 0
        })
    },
    getTotalPay: function () {
      this.totalPay = round10(
        this.invoice.currency === this.currencyPay
          ? this.summary.netTotal
          : this.currencyPay === 'USD'
            ? this.summary.netTotal / this.exchangeRateDollar
            : this.summary.netTotal * this.exchangeRateDollar
        ,
        -5
      )
    },
    getchange: function (payWith, totalPay) {
      return payWith === null || payWith === '' ? 0 : payWith - totalPay
    },
    payVoucher: function () {
      const receiverNoExist = !this.receiver && (this.voucherType === '01' || this.voucherType === '08')
      const itemsNoExist = this.getItems.length < 1
      const creditNoTermExist = this.getCondition === '02' && !this.getCreditTerm
      if (receiverNoExist || itemsNoExist || creditNoTermExist) {
        this.$vuetify.goTo(0)
        if (receiverNoExist) this.showError('Debe agregar un receptor')
        if (itemsNoExist) this.showError('Debe agregar almenos un artículo')
        if (creditNoTermExist) this.showError('Debe agregar plazo del credito')
        return
      }

      const isPOS = this.$route.name === 'Biller'

      if (isPOS) {
        this.dialogPay = true
        this.payWith = null
        this.getTotalPay()
        return
      }
      this.submitInvoice(isPOS)
    },
    keyHandler: function (event) {
      console.log(event)
    },
    insertReceiver: async function () {
      this.loadingReceiver = true
      let allowance = this.tmp.receiver.allowance
      if (this.tmp.receiver.allowanceNumber) {
        try {
          allowance = await this.getAllowance(this.tmp.receiver.allowanceNumber)
        } catch (error) {
          if (!allowance) {
            console.error(error)
            this.tmp.receiver.errorAllowance = 'No se pudo verificar exoneración'
          }
          this.loadingReceiver = false
          throw error
        }
      }
      const newCustomer = {
        uid: this.tmp.receiver.uid,
        id: this.tmp.receiver.id,
        typeId: this.tmp.receiver.typeId,
        identification: this.tmp.receiver.identification,
        name: this.tmp.receiver.name,
        email: this.tmp.receiver.email,
        phone: this.tmp.receiver.phone,
        location: {
          province: this.tmp.receiver.location.province,
          county: this.tmp.receiver.location.county,
          district: this.tmp.receiver.location.district,
          others: this.tmp.receiver.location.others
        },
        priceList: this.priceList,
        allowance
      }
      const cus = await this.insertCustomer(newCustomer)
      const newReceiver = {
        name: this.tmp.receiver.name,
        identificationDocument: {
          documentType: this.tmp.receiver.typeId,
          number: this.tmp.receiver.identification
        },
        phone: this.tmp.receiver.phone,
        email: this.tmp.receiver.email
      }
      if (allowance) newReceiver.allowance = allowance.payload
      if (
        this.tmp.receiver.location.province &&
        this.tmp.receiver.location.county &&
        this.tmp.receiver.location.district &&
        this.tmp.receiver.location.others &&
        this.tmp.receiver.location.province !== null &&
        this.tmp.receiver.location.county !== null &&
        this.tmp.receiver.location.district !== null &&
        this.tmp.receiver.location.others !== null
      ) {
        newReceiver.location = {
          province: this.tmp.receiver.location.province,
          county: tools.zfill(this.tmp.receiver.location.county, 2),
          district: tools.zfill(this.tmp.receiver.location.district, 2),
          others: this.tmp.receiver.location.others
        }
      }
      this.setReceiver(newReceiver)
      this.setReceiverId(cus.uid || cus)
      // this.$set(this.invoice, "receiver", newReceiver)
      this.dialogReceiver = false
      this.cleanReceiver()
    },
    checkStock: function (line) {
      const stock = this.getStock.find((item) => {
        return item.code === line.code
      })
      if (!stock) {
        return true
      }
      if (stock.stock < line.quantity) {
        return false
      }
      return true
    },
    insertItem: function () {
      const product = this.getItems.find((item) => {
        // return this.customFilter(item, this.tmp.searchCode);
        return item.commercialCode === this.tmp.searchCode || item.commercialCode === this.barCodes[this.tmp.searchCode]
      })
      if (!product || !this.tmp.item.inventoryable) {
        const newTaxes = {}
        const newItem = {}
        /* if (!this.checkStock({ code: this.tmp.item.code, quantity: round10(this.tmp.item.quantity, -5) })) {
          const errMsj = 'No hay suficientes productos codigo ' + this.tmp.item.code + ' en bodega.'
          this.showError(errMsj)
          return
        } /**/

        newTaxes.code = this.tmp.taxes.code
        newTaxes.rateCode = this.tmp.taxes.rate_code
        newTaxes.rate = this.tmp.taxes.rate

        newItem.cabys = this.tmp.item.cabys
        newItem.commercialCode = this.tmp.item.code
        newItem.quantity = round10(this.tmp.item.quantity, -5)
        newItem.unit = this.tmp.item.unit
        newItem.description = this.tmp.item.description
        newItem.price = { ...this.tmp.item.price }
        newItem.discountRate = 0
        // unitPrice: Number(this.tmp.item.unitPrice)
        // total: this.itemSubtotal,
        // subtotal: this.itemSubtotal,
        // taxable_base: this.itemSubtotal,
        // tax_net: this.itemTaxNet,
        // netTotal: this.itemNetTotal,

        if (this.tmp.item.taxed) {
          newItem.taxes = [newTaxes]
        }
        // this.invoice.items.push(newItem)
        this.newItem({ ...newItem })
      } else {
        const indexItem = this.getItems.indexOf(product)
        const quantityItem = Number(product.quantity) + Number(this.tmp.item.quantity)
        this.updateQuantity({ index: indexItem, quantity: quantityItem })
      }
      this.dialogItems = false
      this.cleanItem()
    },
    updateItemQuantity (event) {
      const quantityItem = event.target.value
      const indexItem = event.target.id
      const check = true // this.checkStock({ code: this.getItems[indexItem].commercialCode, quantity: quantityItem })
      if (check) {
        this.updateQuantity({ index: indexItem, quantity: quantityItem })
      } else {
        event.target.value = this.getItems[indexItem].quantity
        const errMsj = 'No hay suficientes productos codigo ' + this.getItems[indexItem].commercialCode + ' en bodega.'
        this.showError(errMsj)
      }
    /*
        let quantity = e.target.value
        let invoiceItems = this.invoice.items[index]
        this.tmp.searchCode = invoiceItems.commercialCode
        this.getProductByCode()
        this.tmp.item.quantity = quantity
        this.insertItem()
        this.tmp.commandCode = null */
    },
    updateItemDiscount (event) {
      const discountItem = event.target.value
      const indexItem = event.target.id
      this.updateDiscount({ index: indexItem, discountRate: Number(discountItem) })
    /*
        let quantity = e.target.value
        let invoiceItems = this.invoice.items[index]
        this.tmp.searchCode = invoiceItems.commercialCode
        this.getProductByCode()
        this.tmp.item.quantity = quantity
        this.insertItem()
        this.tmp.commandCode = null */
    },
    insertProductFromCode: async function () {
      const commandCode = this.tmp.commandCode
      if (!/^[*+]-?\d+([.]\d+)?$/.test(commandCode)) {
        this.tmp.searchCode = commandCode
        if (!(await this.getProductByCode())) {
          // alert("El código "+commandCode+" no está registrado")
          this.showError('El código ' + commandCode + ' no está registrado')
        } else {
          this.insertItem()
          this.tmp.commandCode = null
        }
      } else {
        const indexItem = this.getItems.length - 1
        const quantityItem = commandCode[0] === '+'
          ? Number(commandCode.substr(1)) + Number(this.getItems[indexItem].quantity)
          : Number(commandCode.substr(1)) * Number(this.getItems[indexItem].quantity)
        this.updateQuantity({ index: indexItem, quantity: quantityItem })
        this.tmp.commandCode = null
      }
    }, /*
    deleteItem: function(index) {
      this.invoice.items.splice(index, 1)
    }, */
    getExchangeRate: function () {
      if (this.invoice.currency === 'USD') { this.invoice.exchangeRate = round10(this.exchangeRateDollar, -5) } else { this.invoice.exchangeRate = 1 }
    },
    setCustomer: function () {
      const customer = { ...this.tmp.customer }
      if (Object.keys(customer).length > 0) {
        this.tmp.receiver.uid = customer.uid
        this.tmp.receiver.id = customer.id
        this.tmp.receiver.typeId = customer.typeId
        this.tmp.receiver.name = customer.name
        this.tmp.receiver.identification = customer.identification
        this.tmp.receiver.email = customer.email
        this.tmp.receiver.phone = customer.phone
        this.tmp.receiver.allowance = customer.allowance && Object.keys(customer.allowance).length > 0
          ? customer.allowance : null
        this.priceList = customer.priceList
        this.tmp.receiver.allowanceNumber = customer.allowance?.payload.docNumber || null

        if (customer.location) {
          this.tmp.receiver.location.province = customer.location.province
          this.tmp.receiver.location.others = customer.location.others
          this.getCounty()
            .then(() => {
              this.tmp.receiver.location.county = customer.location.county
              return this.getDistrict()
            })
            .then(() => {
              this.tmp.receiver.location.district = customer.location.district
            })
            .catch(error => {
              console.error(error)
            })
        }
      }
    },
    getByIdentification: function () {
      const index = this.getCustomers.findIndex(customer => {
        return customer.identification === this.tmp.receiver.identification
      })
      // console.log(index)
      if (index > -1) {
        this.tmp.receiver.uid = this.getCustomers[index].uid
        this.tmp.receiver.typeId = this.getCustomers[index].typeId
        this.tmp.receiver.name = this.getCustomers[index].name
        this.tmp.receiver.email = this.getCustomers[index].email
        this.tmp.receiver.phone = this.getCustomers[index].phone
        this.priceList = this.getCustomers[index].priceList
        this.tmp.receiver.allowanceNumber = this.getCustomers[index].allowance?.payload.docNumber || null
        this.tmp.receiver.allowance = this.getCustomers[index].allowance && Object.keys(this.getCustomers[index].allowance).length > 0
          ? this.getCustomers[index].allowance : null

        if (this.getCustomers[index].location) {
          this.tmp.receiver.location.province = this.getCustomers[index].location.province
          this.tmp.receiver.location.others = this.getCustomers[index].location.others
          this.getCounty()
            .then(() => {
              this.tmp.receiver.location.county = this.getCustomers[index].location.county
              return this.getDistrict()
            })
            .then(() => {
              this.tmp.receiver.location.district = this.getCustomers[index].location.district
            })
            .catch(error => {
              console.error(error)
            })
        }
      } else {
        axios
          .get('https://api.hacienda.go.cr/fe/ae?identificacion=' + this.tmp.receiver.identification)
          .then(response => {
          // var receiver = {id: id, typeId: response.data.tipoIdentificacion, name: response.data.nombre}
            this.tmp.receiver.typeId = response.data.tipoIdentificacion
            this.tmp.receiver.name = response.data.nombre
          })
      }
    },
    getProductByCode: async function () {
      // let snap = await firebaseApi.db.collection('products')
      //   .where('company', '==', this.getCompany.identification)
      //   .where('commercialCode', '==', this.tmp.searchCode)
      //   .get()
      // if (snap.empty) {
      //   snap = await firebaseApi.db.collection('products')
      //     .where('company', '==', this.getCompany.identification)
      //     .where('barCode', '==', this.tmp.searchCode)
      //     .get()
      //   if (snap.empty) return false
      // }
      // const doc = snap.docs[0]
      // const product = { ...doc.data(), uid: doc.id }
      const product = this.entries
        .find(({ commercialCode, barCode }) => {
          return commercialCode === this.tmp.searchCode ||
            barCode === this.tmp.searchCode ||
            barCode === Number(this.tmp.searchCode)?.toString()
        })
      if (!product) { return false }
      if (product.barCode) this.barCodes[product.barCode] = product.commercialCode
      const price = product.prices.find(({ priceList }) => this.priceList === priceList) ||
        product.prices.find(({ priceList }) => priceList === 'general')
      this.tmp.item = {
        cabys: product.cabys,
        code: product.commercialCode,
        quantity: 1,
        description: product.description,
        price,
        taxed: !!product.taxes[0],
        inventoryable: !!product.inventoryable,
        unit: product.unit
      }
      if (product.taxes[0]) {
        this.tmp.taxes = {
          code: product.taxes[0].code,
          rate_code: product.taxes[0].rateCode,
          rate: product.taxes[0].rate
        }
      }
      return true
    },
    setRate: function () {
      this.tmp.taxes.rate = this.rateCodeValues[this.tmp.taxes.rate_code]
    },
    submitInvoice (isPOS, receipts) {
      this.loadingEmit = true
      this.$vuetify.goTo(0)
      this.issueVoucher({ pos: isPOS, receipts })
        .then(() => {
          this.showSuccess('Comprobante emitido correctamente')
          // this.loadingEmit = false
          // console.log(voucher)
          this.reset()
        // return this.inventarySale(voucher);
        })
        .catch(error => {
          console.error(error)
          this.showError(error)
        })
        .finally(() => {
          this.loadingEmit = false
        })
    },
    reset: function () {
      if (!this.voucher) this.cleanInvoice()
      this.$emit('close')
      /*
      let def = getDefaultData()
      def[keep] = this[keep]
      Object.assign(this.$data, def)
      */
    },
    cleanReceiver: function () {
      this.tmp.receiver = {
        typeId: null,
        id: null,
        name: null,
        email: null,
        phone: null,
        location: {
          province: null,
          county: null,
          district: null,
          others: null
        }
      }
      this.tmp.customer = {}
      this.loadingReceiver = false
    },
    cleanItem: function () {
      this.tmp.item = {
        cabys: null,
        code: null,
        quantity: 1,
        description: null,
        price: {
          priceList: 'general',
          price: null,
          currency: 'CRC'
        },
        taxed: false,
        inventoryable: false,
        netTotal: 0,
        unit: 'Unid'
      }
      this.tmp.taxes = {
        code: '01',
        rate_code: '08',
        rate: 13
      }
      this.tmp.searchCode = null
    },
    navigate ({ name, data }) {
      const routeData = this.$router.resolve({ name: name, query: { data: data } })
      window.open(routeData.href, '_blank')
    },
    deleteItem (payload) {
      this.$store.dispatch(this.moduleName + '/deleteItem', payload)
    },
    newItem (payload) {
      this.$store.dispatch(this.moduleName + '/newItem', payload)
    },
    cleanInvoice (payload) {
      this.$store.dispatch(this.moduleName + '/cleanInvoice', payload)
    },
    updateQuantity (payload) {
      this.$store.dispatch(this.moduleName + '/updateQuantity', payload)
    },
    updateHsCode (payload) {
      this.$store.dispatch(this.moduleName + '/updateHsCode', payload)
    },
    setReceiver (payload) {
      this.$store.dispatch(this.moduleName + '/setReceiver', payload)
    },
    setReceiverId (payload) {
      this.$store.commit(this.moduleName + '/setReceiverId', payload)
    },
    setOrder (payload) {
      this.$store.commit(this.moduleName + '/setOrder', payload)
    },
    updateDiscount (payload) {
      this.$store.commit(this.moduleName + '/updateDiscount', payload)
    },
    setCurrency (payload) {
      this.$store.commit(this.moduleName + '/setCurrency', payload)
    },
    setSeller (payload) {
      this.$store.commit(this.moduleName + '/setSeller', payload)
    },
    setExchangeRate (payload) {
      this.$store.commit(this.moduleName + '/setExchangeRate', payload)
    },
    setCondition (payload) {
      this.$store.commit(this.moduleName + '/setCondition', payload)
    },
    setCreditTerm (payload) {
      this.$store.commit(this.moduleName + '/setCreditTerm', payload)
    },
    deleteReceiver (payload) {
      this.$store.dispatch(this.moduleName + '/deleteReceiver', payload)
    },
    issueVoucher (payload) {
      return new Promise((resolve, reject) => {
        const action = this.voucherType === '13' ? '/submitOrder' : '/issueVoucher'
        this.$store.dispatch(this.moduleName + action, payload)
          .then(voucher => {
            resolve(voucher)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setPaymentType (payload) {
      this.$store.dispatch(this.moduleName + '/setPaymentType', payload)
    },
    setVoucher (payload) {
      this.$store.dispatch(this.moduleName + '/setVoucher', payload)
    },
    setActivity (payload) {
      this.$store.commit(this.moduleName + '/setActivity', payload)
    },
    setVoucherType (payload) {
      this.$store.dispatch(this.moduleName + '/setVoucherType', payload)
    },
    insertCabys () {
      if (!this.tmp.item.cabys) {
        this.tmp.taxes = {
          code: '01',
          rate_code: '08',
          rate: 13
        }
        this.tmp.item.taxed = false
      } else {
        const cabys = this.cabys.find((item) => {
          return item.code === this.tmp.item.cabys
        })
        this.tmp.taxes = {
          code: '01',
          rate_code: cabys.iva === '13' ? '08' : '02',
          rate: Number(cabys.iva)
        }
        this.tmp.item.taxed = true
      }
    },
    ...mapActions('inventories', [
      'inventarySale',
      'insertInventory'
    ]),
    ...mapActions('company', [
      'getElectronicVoucher'
    ]),
    ...mapActions('customers', [
      'insertCustomer'
    ]),
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ]),
    ...mapActions('cashDaily', [
      'openCash'
    ])
    /* ...mapActions(moduleName,[
      this.moduleName+"/deleteItem",
      this.moduleName+"/newItem",
      this.moduleName+"/cleanInvoice",
      this.moduleName+"/updateQuantity",
      this.moduleName+"/setReceiver",
      this.moduleName+"/deleteReceiver",
      this.moduleName+"/issueVoucher",
      this.moduleName+"/setPaymentType",
      this.moduleName+"/setActivity",
      this.moduleName+"/setVoucherType",
    ]) */
  },
  computed: {
    sellers () {
      const sellers = this.getCompany.staff
        .map((staff) => {
          const contact = this.getCustomers.find(({ uid, id }) => uid === staff.contact || id === staff.contact)
          return { ...staff, ...contact }
        })
      return sellers
    },
    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a'
        }
      })
    },
    itemsProductsCode () {
      return this.entries.map(entry => {
        const description = entry.description.length > this.descriptionLimit
          ? entry.description.slice(0, this.descriptionLimit) + '...'
          : entry.description

        return Object.assign({}, entry, { description })
      })
    },
    isPOS () {
      return this.$route.name === 'Biller'
    },
    voucherTypes () {
      const typesBase = [
        // { value: '01', text: 'Factura electrónica' },
        // { value: '02', text: 'Nota de débito electrónica' },
        // { value: '03', text: 'Nota de crédito electrónica' },
        // { value: '04', text: 'Tiquete electrónico' },
        // { value: '08', text: 'Factura electrónica de compras' },
        // { value: '09', text: 'Factura electrónica de Exportación' },
        { value: '10', text: 'Factura de venta' },
        { value: '11', text: 'Factura proforma' },
        { value: '12', text: 'Factura de compra' }
      ]
      const typesEV = [
        { value: '01', text: 'Factura electrónica' },
        { value: '04', text: 'Tiquete electrónico' },
        { value: '08', text: 'Factura electrónica de compras' },
        { value: '09', text: 'Factura electrónica de Exportación' }
      ]
      if (this.electronicVoucher) {
        return typesEV.concat(typesBase)
      } else {
        return typesBase
      }
    },
    headersItems: function () {
      const headers = this.voucherType === '09' ? [
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Partida', value: 'hsCode' },
        { text: 'Detalle', value: 'description' },
        { text: 'Precio Unitario', value: 'unitPrice' },
        { text: 'Desc. %', value: 'discount' },
        { text: 'IVA %', value: 'taxRate' },
        { text: 'Total', value: 'netTotal' },
        { text: 'Remover', value: 'index' }
      ] : [
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Detalle', value: 'description' },
        { text: 'Precio Unitario', value: 'unitPrice' },
        { text: 'Desc. %', value: 'discount' },
        { text: 'IVA %', value: 'taxRate' },
        { text: 'Total', value: 'netTotal' },
        { text: 'Remover', value: 'index' }
      ]
      return headers
    },
    cashOpen: function () {
      return !!this.getCashDaily
    },
    isMovil: function () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    itemSubtotal: function () {
      return round10((this.tmp.item.price.price === null ? 0 : this.tmp.item.price.price) * (this.tmp.item.quantity === null ? 1 : this.tmp.item.quantity), -5)
    },
    itemTaxFactor: function () {
      return round10(this.tmp.taxes.rate / 100, -5)
    },
    itemTaxNet: function () {
      return round10(this.tmp.item.taxed ? this.itemSubtotal * this.itemTaxFactor : 0, -5)
    },
    itemNetTotal: function () {
      return round10(this.itemSubtotal + this.itemTaxNet, -5)
    },
    itemsDataTable: function () {
      const items = []
      for (var i = this.getItems.length - 1; i >= 0; i--) {
        items.push({
          index: i,
          quantity: this.getItems[i].quantity,
          description: this.getItems[i].description,
          subtotal: this.getItems[i].subtotal,
          unitPrice: this.getItems[i].unitPrice,
          discountRate: this.invoiceItems.discountRate,
          taxRate: this.getItems[i].taxes
            ? this.getItems[i].taxes[0].allowance
              ? (this.getItems[i].taxes[0].rate - this.getItems[i].taxes[0].allowance.percentage) + '*' : this.getItems[i].taxes[0].rate
            : 0,
          netTotal: this.getItems[i].netTotal
        })
      }
      return items
    },
    itemsCustomers: function () {
      /* let items = []
      for (var i = this.getCustomers.length - 1; i >= 0; i--) {
        items.push({ text: this.getCustomers[i].name , value: this.getCustomers[i].identification })
      } */
      return this.getCustomers
    },
    /* itemsProductsCode: function () {
      const items = []
      for (var i = this.products.length - 1; i >= 0; i--) {
        items.push({ text: this.products[i].description, value: this.products[i].commercialCode })
      }
      return items
    },
    totalPay: function() {
      return round10(
        this.invoice.currency === this.currencyPay ?
          this.summary.netTotal
        :
          this.currencyPay === "USD" ?
            this.summary.netTotal / this.exchangeRateDollar
          :
            this.summary.netTotal * this.exchangeRateDollar
        ,
        -5
      )
    }, */
    change: function () {
      return this.payWith === null || this.payWith === '' ? 0 : this.payWith - this.totalPay
    },
    changeCRC: function () {
      return round10(this.change * this.exchangeRateDollar)
    },
    receiver () {
      return this.$store.state[this.moduleName].receiver
    },
    items () {
      return this.$store.state[this.moduleName].items
    },
    seller () {
      return this.$store.state[this.moduleName].seller
    },
    proforma () {
      return this.$store.state[this.moduleName].proforma
    },
    paymentType () {
      return this.$store.state[this.moduleName].paymentType
    },
    activityCode () {
      return this.$store.state[this.moduleName].activityCode
    },
    voucherType () {
      return this.$store.state[this.moduleName].voucherType
    },
    getCondition () {
      return this.$store.state[this.moduleName].condition
    },
    getCurrency () {
      return this.$store.state[this.moduleName].currency
    },
    getOrder () {
      return this.$store.state[this.moduleName].getOrder
    },
    exchangeRate () {
      return this.$store.state[this.moduleName].exchangeRate
    },
    getCreditTerm () {
      return this.$store.state[this.moduleName].creditTerm
    },
    getItems () {
      return this.$store.getters[this.moduleName + '/getItems']
    },
    invoiceItems () {
      return this.$store.state[this.moduleName].items
    },
    servicesTaxableTotal () {
      return this.$store.getters[this.moduleName + '/servicesTaxableTotal']
    },
    servicesExentTotal () {
      return this.$store.getters[this.moduleName + '/servicesExentTotal']
    },
    goodsTaxableTotal () {
      return this.$store.getters[this.moduleName + '/goodsTaxableTotal']
    },
    goodsExentTotal () {
      return this.$store.getters[this.moduleName + '/goodsExentTotal']
    },
    taxableTotal () {
      return this.$store.getters[this.moduleName + '/taxableTotal']
    },
    exentTotal () {
      return this.$store.getters[this.moduleName + '/exentTotal']
    },
    subtotal () {
      return this.$store.getters[this.moduleName + '/subtotal']
    },
    taxTotal () {
      return this.$store.getters[this.moduleName + '/taxTotal']
    },
    netTotal () {
      return this.$store.getters[this.moduleName + '/netTotal']
    },
    summary () {
      return this.$store.getters[this.moduleName + '/summary']
    },
    /* ...mapState([
      "account",
    ]), /**/
    ...mapGetters([
      'getExchangeRateUSD'
    ]),
    // ...mapState('products', [
    //   'products'
    // ]),
    ...mapState('cashDaily', [
      'cashDaily'
    ]),
    ...mapGetters('inventories', [
      'getStock'
    ]),
    ...mapGetters('user', [
      'getUser',
      'getVoucherTypes',
      'getUserVoucherTypes'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ]),
    ...mapGetters('customers', [
      'getCustomers'
    ]),
    ...mapGetters('cashDaily', [
      'getCashDaily'
    ])
    /* ...mapState(moduleName,[
      this.moduleName+"receiver",
      this.moduleName+"items",
      this.moduleName+"paymentType",
      this.moduleName+"activityCode",
      this.moduleName+"voucherType",
    ]),
    ...mapGetters(moduleName,[
      "getItems",
      "servicesTaxableTotal",
      "servicesExentTotal",
      "goodsTaxableTotal",
      "goodsExentTotal",
      "taxableTotal",
      "exentTotal",
      "subtotal",
      "taxTotal",
      "netTotal",
      "summary",
    ]) */
  },
  watch: {
    documentType: function (newVal, oldVal) { // watch it
      this.setVoucherType(newVal)
    },
    voucher: function (newVal, oldVal) { // watch it
      this.setVoucher(newVal)
    }
  }
}
</script>
<style type="text/css">

</style>
