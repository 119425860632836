import moment from 'moment'
import tools from './tools.js'
import { rpp } from './redaPosPrinter.js'
// import { rpp } from "./redaPosPrinter.js";

const payemntCodes = {
  '01': 'Efectivo',
  '02': 'Tarjeta',
  '03': 'Cheque',
  '04': 'Transferencia',
  99: 'Otros'
}

const condition = {
  '01': 'Contado',
  '02': 'Crédito',
  '03': 'Consignación',
  '04': 'Apartado',
  '05': 'Arrendamiento con opción de compra',
  '06': 'Arrendamiento en función financiera',
  '07': 'Cobro a favor de un tercero',
  '08': 'Servicios prestados al Estado a crédito',
  '09': 'Pago del servicios prestado al Estado',
  99: 'otros'
}

const paymentTypes = {
  '01': 'Efectivo',
  '02': 'Tarjeta',
  '03': 'Cheque',
  '04': 'Transferencia/depósito bancario',
  '05': 'Recaudado por terceros',
  99: 'Otros (se debe indicar el medio de pago)'
}

const documentType = {
  '01': 'Factura electrónica',
  '02': 'Nota de débito electrónica',
  '03': 'Nota de crédito electrónica',
  '04': 'Tiquete electrónico',
  '05': 'Confirmación de aceptación del comprobante electrónico',
  '06': 'Confirmación de aceptación parcial del comprobante electrónico',
  '07': 'Confirmación de rechazo del comprobante electrónico',
  '08': 'Factura electrónica de compras',
  '09': 'Factura electrónica de exportación',
  10: 'Factura de venta',
  11: 'Factura proforma'
}

const numberFormat = (value, currency = 'CRC') => {
  const val = Number(value)
  if (isNaN(val)) return value
  return val.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
const miStorage = window.localStorage

const redaPrinter = {
  options: {
    preview: false, // Preview in window or print
    pageSize: '80mm',
    margin: '0 0 0 0', // margin of content body
    copies: 1, // Number of copies to print
    // printerName: 'Two Pilots Demo Printer', // 'POS-80', // printerName: string, check it at webContent.getPrinters()
    timeOutPerLine: 400,
    silent: true
  },
  commands: [],
  addHeader (header) {
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: header.title,
      style: {
        'font-weight': '700',
        'font-size': '18px',
        'font-family': 'sans-serif',
        'text-align': 'center'
      }
    })
    this.addText('')
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: header.subtitles.join('<br>'),
      style: {
        'font-size': '12px',
        'font-family': 'sans-serif',
        'text-align': 'center'
      }
    })
    this.addText('')
  },
  addTable ({ header, body }) {
    let headerTable = ''
    let gridTemplateColumns = ''
    let bodyTable = ''
    const line = '--------------------------------------------------------------------------------'
    header.forEach((item) => {
      headerTable += `<div style='text-align: center;'><h3>${item}</h3></div>`
      gridTemplateColumns += 'auto '
    })
    body.forEach((item) => {
      item.forEach((item) => {
        bodyTable += `<div>${item}</div>`
      })
    })
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: headerTable,
      style: {
        display: 'grid',
        'grid-template-columns': gridTemplateColumns.trim()
      }
    })
    this.addText(line)
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: bodyTable,
      style: {
        'font-size': '12px',
        'font-family': 'sans-serif',
        display: 'grid',
        'grid-template-columns': gridTemplateColumns.trim()
      }
    })
    this.addText(line)

    /* this.commands.push({
       type: 'table',
       // style the table
       //style: 'border: none',
       // list of the columns to be rendered in the table header
       tableHeader: table.header,
       // multi dimensional array depicting the rows and columns of the table body
       tableBody: table.body,
       // list of columns to be rendered in the table footer
       tableFooter: table.footer,
       // custom style for the table header
       tableHeaderStyle: '"font-weight": "700";',
       // custom style for the table body
       //tableBodyStyle: 'border: 0.5px solid #ddd',
       // custom style for the table footer
       //tableFooterStyle: 'background-color: #000; color: white;',
    });/**/
  },
  addList (list) {
    list.forEach((item) => {
      if (item.value) {
        if ((item.value.length + item.text.length) > 48) {
          this.commands.push({
            type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
            value: item.text + ':<br>' + item.value + '<br>',
            style: {
              'font-size': '12px',
              'font-family': 'sans-serif'
            }
          })
        } else {
          this.commands.push({
            type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
            value: item.text + ': ' + item.value + '<br>',
            style: {
              'font-size': '12px',
              'font-family': 'sans-serif'
            }
          })
        }
      }
    })
  },
  addListLR (list) {
    const table = []
    list.forEach((item) => {
      table.push([item.text, item.value])
    })
    this.commands.push({
      type: 'table',
      // style the table
      style: 'border-style: none',
      // list of the columns to be rendered in the table header
      // tableHeader: table.header,
      // multi dimensional array depicting the rows and columns of the table body
      tableBody: table,
      // list of columns to be rendered in the table footer
      // tableFooter: table.footer,
      // custom style for the table header
      // tableHeaderStyle: '"font-weight": "700";',
      // custom style for the table body
      tableBodyStyle: 'border-style: none'
      // custom style for the table footer
      // tableFooterStyle: 'background-color: #000; color: white;',
    })
    /* this.commands.push({
      command: 'println',
      value: '',
    }); */
  },
  addText (text, textAlign = 'left') {
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: `${text}<br>`,
      style: {
        'font-size': '12px',
        'font-family': 'Arial, sans-serif',
        'text-align': textAlign
      }
    })
  },
  cash () {
    return new Promise((resolve, reject) => {
      this.commands.push({
        command: 'openCashDrawer',
        value: null
      })
      this.print()
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  print () {
    return new Promise((resolve, reject) => {
      rpp.print({ data: this.commands, options: this.options })
        .then(() => {
          resolve('printed!')
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          this.commands = []
        })
    })
  },
  printVoucher (voucher, receipts) {
    return new Promise((resolve, reject) => {
      tools.getLocation(voucher.issuer.location.province, voucher.issuer.location.county, voucher.issuer.location.district)
        .then(location => {
          const header = {
            title: '',
            subtitles: []
          }
          if (voucher.issuer.tradename) {
            header.title = voucher.issuer.tradename
            if (voucher.documentType !== '10') header.subtitles.push(voucher.issuer.name)
            if (voucher.documentType !== '10') header.subtitles.push(voucher.issuer.id_document ? voucher.issuer.id_document.number : voucher.issuer.identification)
            header.subtitles.push(location.district + ' de ' + location.county + ', ' + location.province)
            header.subtitles.push(voucher.issuer.location.others)
            if (voucher.documentType !== '10') header.subtitles.push('Tel: ' + voucher.issuer.phone)
          } else {
            header.title = voucher.issuer.name
            header.subtitles.push(voucher.issuer.id_document ? voucher.issuer.id_document.number : voucher.issuer.identification)
            header.subtitles.push(location.district + ' de ' + location.county + ', ' + location.province)
            header.subtitles.push(voucher.issuer.location.others)
            header.subtitles.push('Tel: ' + voucher.issuer.phone)
          }

          this.addHeader(header)

          this.addList([
            { text: 'Tipo de documento', value: documentType[voucher.documentType] },
            { text: 'Clave', value: voucher.key },
            { text: 'Consecutivo', value: voucher.sequenceV || voucher.sequence },
            { text: 'Fecha', value: voucher.date },
            { text: 'Nombre del cliente', value: voucher.receiver ? voucher.receiver.name : '' },
            { text: 'Cedula del cliente', value: voucher.receiver ? (voucher.receiver.identification_document ? voucher.receiver.identification_document.number : voucher.receiver.identification) : '' },
            { text: 'Condición de Venta', value: condition[voucher.condition] },
            { text: 'Medios de pago', value: receipts ? Array.from(new Set(receipts.map(({ paymentType }) => paymentTypes[paymentType]))).toString() : paymentTypes[voucher.paymentType] }
          ])

          const tableHeader = [
            'Ct.',
            'Descripción',
            // "IVA",
            'Importe'
          ]

          const tableBody = []

          voucher.items.forEach(item => {
            const row = [
              item.quantity,
              item.description,
              // item.unit_price,
              // item.tax_net,
              numberFormat(item.net_total || item.netTotal)
            ]
            tableBody.push(row)
          })

          this.addText('')

          this.addTable({ header: tableHeader, body: tableBody })

          this.addText('')

          this.addList([
            { text: 'SubTotal', value: numberFormat(voucher.summary.subtotal) },
            { text: 'Descuento', value: numberFormat(voucher.summary.discountTotal || voucher.summary.discount_total) },
            { text: 'IVA', value: numberFormat(voucher.summary.taxTotal || voucher.summary.tax_total) },
            { text: 'TOTAL', value: numberFormat(voucher.summary.netTotal || voucher.summary.net_total) },
            { text: 'Moneda', value: voucher.summary.currency },
            { text: 'Tipo Cambio', value: numberFormat(voucher.summary.exchangeRate) }
          ])

          if (receipts && receipts.length > 0) {
            this.addText('')

            const tableHeader = [
              'Fecha',
              'Método',
              // 'Precio',
              // 'IVA',
              'Importe'
            ]

            const tableBody = []

            receipts.forEach(item => {
              const row = [
                moment(item.date).format('L'),
                paymentTypes[item.paymentType],
                // item.unit_price || item.unitPrice,
                // item.tax_net || item.taxNet,
                numberFormat(item.amount)
              ]
              tableBody.push(row)
            })

            this.addText('')

            this.addTable({ header: tableHeader, body: tableBody, title: 'Pagos realizados:' })

            this.addText('')

            const totalPays = receipts.reduce((acc, cur) => acc + Number(cur.amount), 0)

            this.addList([
              { text: 'Saldo anterior', value: numberFormat(voucher.summary.netTotal) },
              { text: 'Pagado', value: numberFormat(totalPays) },
              { text: 'Saldo actual', value: numberFormat(Number(voucher.summary.netTotal) - totalPays) }
            ])
          }

          this.addText('')

          this.addText('Autorizado mediante el Oficio Número DGT-R-033-2019 del 20/06/2019 de la DGTD', 'center')
          this.addText('Gracias por su preferencia', 'center')

          this.addText('')

          this.addText('')

          this.addText('')

          this.addText('')

          /* this.commands.push({
          command: 'cut',
          value: null
        }); */

          return this.print()
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  printArching (cashDaily) {
    console.log({ ...cashDaily })
    return new Promise((resolve, reject) => {
      const { expectedAmount, user, receiptsAmount, amount, paymentsAmount, closeAmount } = cashDaily

      this.addHeader({ title: 'Balance de caja', subtitles: [] })

      this.addText('<br>')

      this.addList([
        { text: 'Caja diaria n°', value: cashDaily.id },
        { text: 'Fecha', value: moment().format() },
        { text: 'Cajero/a', value: user.name }
      ])

      this.addText('<br>')

      // this.addText('Ventas realizadas')

      // this.addText('<br>')

      // this.addList(Object.entries(sales).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))

      // this.addText('<br>')
      this.addText('Pagos recibidos')

      this.addText('<br>')

      this.addList(Object.entries(receiptsAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))

      this.addText('<br>')
      this.addText('Pagos realizados desde caja diaria')

      this.addText('<br>')

      this.addList([{ text: 'Total Pagos en efectivo', value: paymentsAmount['01'] }])
      this.addText('<br>')
      this.addText('Total Esperado en Caja')

      this.addText('<br>')

      this.addList(Object.entries(expectedAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))
      this.addText('<br>')
      this.addText('Total en Caja')

      this.addText('<br>')

      this.addList(Object.entries(closeAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))
      this.addText('<br>')
      this.addText('Balance de Caja')

      this.addText('<br>')

      this.addList(Object.entries(amount).map((element) => ({ text: payemntCodes[element[0]], value: Number(element[1]) * -1 })))
      this.addText('<br>')
      /// /////////////

      this.print()
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const dividirEnDosLineas = (str) => {
  // Si el string tiene 30 caracteres o menos, devolverlo tal cual
  if (str.length <= 30) {
    return str
  }

  // // Buscar el espacio más cercano al carácter 30
  // const indiceEspacio = str.lastIndexOf(' ', 30)

  // // Si no se encuentra un espacio, devolver el string tal cual (o podría aplicar otra lógica)
  // if (indiceEspacio === -1) {
  //   return str
  // }

  // Dividir el string en dos líneas en el espacio encontrado
  const primeraLinea = str.slice(0, 29)
  const segundaLinea = str.slice(29 + 1)

  // Devolver el string con un salto de línea
  return primeraLinea + '<br>' + segundaLinea
}

const webPrinter = {
  commands: [],
  lineMethods: {
    text: ({ value }) => {
      return value
    },
    title: ({ value }) => {
      return `<h1>${value}</h1>`
    },
    subtitle: ({ value }) => {
      return `<h4>${value}</h4>`
    },
    table: ({ value }) => {
      const hTable = value.header.reduce((acc, cur) => {
        acc += `<th>${cur}</th>`
        return acc
      }, '')
      const body = value.body.reduce((acc, cur) => {
        acc += `<tr>${cur.map((i) => {
          return `<td>${i}</td>`
        }).join()}</tr>`
        return acc
      }, '')
      const table = `
      <div class="items">
        <h3>${value.title}</h3>
        <table>
          <tr>
            ${hTable}
          </tr>
          ${body}
        </table>
      </div>`
      return table
    },
    list: ({ value }) => {
      return value
    },
    listRL: ({ value }) => {
      return value
    },
    println: () => {
      return '<br>'
    }
  },
  addHeader (header) {
    this.commands.push({
      type: 'title', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: header.title,
      css: {
        'font-weight': '700',
        'font-size': '18px',
        'font-family': 'sans-serif',
        'text-align': 'center'
      }
    })
    header.subtitles.forEach((subtitle) => {
      this.commands.push({
        type: 'subtitle', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
        value: subtitle,
        css: {
          'font-size': '12px',
          'font-family': 'sans-serif',
          'text-align': 'center'
        }
      })
    })
  },
  addTable ({ header, body, title }) {
    this.commands.push({
      type: 'table', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: { header, body, title }
    })

    /* this.commands.push({
       type: 'table',
       // style the table
       //style: 'border: none',
       // list of the columns to be rendered in the table header
       tableHeader: table.header,
       // multi dimensional array depicting the rows and columns of the table body
       tableBody: table.body,
       // list of columns to be rendered in the table footer
       tableFooter: table.footer,
       // custom style for the table header
       tableHeaderStyle: '"font-weight": "700";',
       // custom style for the table body
       //tableBodyStyle: 'border: 0.5px solid #ddd',
       // custom style for the table footer
       //tableFooterStyle: 'background-color: #000; color: white;',
    });/**/
  },
  addList (list) {
    list.forEach((item) => {
      if (item.value) {
        if ((item.value.length + item.text.length) > 48) {
          this.commands.push({
            type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
            value: item.text + ':<br>' + item.value + '<br>',
            css: {
              'font-size': '12px',
              'font-family': 'sans-serif'
            }
          })
        } else {
          this.commands.push({
            type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
            value: item.text + ': ' + item.value + '<br>',
            css: {
              'font-size': '12px',
              'font-family': 'sans-serif'
            }
          })
        }
      }
    })
  },
  addListLR (list) {
    const table = []
    list.forEach((item) => {
      table.push([item.text, item.value])
    })
    this.commands.push({
      type: 'table',
      // style the table
      style: 'border-style: none',
      // list of the columns to be rendered in the table header
      // tableHeader: table.header,
      // multi dimensional array depicting the rows and columns of the table body
      tableBody: table,
      // list of columns to be rendered in the table footer
      // tableFooter: table.footer,
      // custom style for the table header
      // tableHeaderStyle: '"font-weight": "700";',
      // custom style for the table body
      tableBodyStyle: 'border-style: none'
      // custom style for the table footer
      // tableFooterStyle: 'background-color: #000; color: white;',
    })
    /* this.commands.push({
      command: 'println',
      value: '',
    }); */
  },
  addText (text, textAlign = 'left') {
    this.commands.push({
      type: 'text', // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
      value: `${text}<br>`,
      css: {
        'font-size': '12px',
        'font-family': 'sans-serif',
        'text-align': textAlign
      }
    })
  },
  printHTML (htmlString, width = 400) {
    const printWindow = window.open('', '', 'resizable,scrollbars,status')
    printWindow.document.open()
    printWindow.document.write(htmlString)
    printWindow.document.close()
    printWindow.print()
    printWindow.close()
    this.commands = []
  },
  async print (copy) {
    let contents = ''
    this.commands.forEach((command) => {
      contents += this.lineMethods[command.type](command)
    })
    // eslint-disable-next-line no-constant-condition
    const ticketString = copy ? `
      <table>
        <tr>
          <td>
            <div  style="margin: 20px;" class="ticket">
            ${contents}
            </div>
          </td>
          <td>
            <div  style="margin: 20px;" class="ticket">
            ${contents}
            </div>
          </td>
        </tr>
      </table>` : `
      <div class="ticket">
      ${contents}
      </div>`
    const html = `<!DOCTYPE html>
    <html>
    <head>
      <title>Ticket de Caja</title>
      <style>
        /* Estilos CSS para el ticket */
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 20px;
        }

        h1 {
          text-align: center;
        }

        h4 {
          text-align: center;
          margin: 0;
        }

        .ticket {
          padding: 0px;
          max-width: 400px;
          margin: 0 auto;
        }

        .ticket .header,
        .ticket .items,
        .ticket .total {
          margin-bottom: 20px;
        }

        .ticket .header h2,
        .ticket .items h3,
        .ticket .total h3 {
          margin: 0;
        }

        .ticket .items table {
          width: 100%;
          border-collapse: collapse;
        }

        .ticket .items table th,
        .ticket .items table td {
          border-bottom: 1px solid #ccc;
          padding: 8px 0;
        }

        .ticket .total table {
          width: 50%;
          margin-left: auto;
        }

        .ticket .total table th,
        .ticket .total table td {
          padding: 5px 0;
        }
      </style>
    </head>
    <body>
      ${ticketString}
    </body>
    </html>`
    return this.printHTML(html)
  },
  printVoucher (voucher, receipts) {
    return new Promise((resolve, reject) => {
      // this.commands.push({
      //  command: 'openCashDrawer',
      //  value: null
      // })

      // const chargeTypes = {
      //   '01': 'Contribución parafiscal',
      //   '02': 'Timbre de la Cruz Roja',
      //   '03': 'Timbre de Benemérito Cuerpo de Bomberos de Costa Rica',
      //   '04': 'Cobro de un tercero',
      //   '05': 'Costos de Exportación',
      //   '06': 'Impuesto de servicio 10%',
      //   '07': 'Timbre de Colegios Profesionales',
      //   99: 'Otros Cargos'
      // }

      tools.getLocation(voucher.issuer.location.province, voucher.issuer.location.county, voucher.issuer.location.district)
        .then(location => {
          const header = {
            title: '',
            subtitles: []
          }
          const id = voucher.issuer.id_document ? voucher.issuer.id_document.number : voucher.issuer.identification
          if (voucher.issuer.tradename) {
            header.title = voucher.issuer.tradename
            header.subtitles.push(voucher.issuer.name)
            header.subtitles.push(voucher.issuer.id_document ? voucher.issuer.id_document.number : voucher.issuer.identification)
            header.subtitles.push(location.district + ' de ' + location.county + ', ' + location.province)
            header.subtitles.push(voucher.issuer.location.others)
            header.subtitles.push('Tel: ' + voucher.issuer.phone)
          } else {
            header.title = voucher.issuer.name
            header.subtitles.push(voucher.issuer.id_document ? voucher.issuer.id_document.number : voucher.issuer.identification)
            header.subtitles.push(location.district + ' de ' + location.county + ', ' + location.province)
            header.subtitles.push(voucher.issuer.location.others)
            header.subtitles.push('Tel: ' + voucher.issuer.phone)
          }

          this.addHeader(header)

          this.addText('')

          this.addList([
            { text: 'Tipo de documento', value: documentType[voucher.documentType] },
            { text: 'Clave', value: dividirEnDosLineas(voucher.key) },
            { text: 'Consecutivo', value: voucher.sequenceV || voucher.sequence },
            { text: 'Fecha', value: voucher.date },
            { text: 'Nombre del cliente', value: voucher.receiver ? voucher.receiver.name : '' },
            { text: 'Cedula del cliente', value: voucher.receiver ? (voucher.receiver.identification_document ? voucher.receiver.identification_document.number : voucher.receiver.identification) : '' },
            { text: 'Condición de Venta', value: condition[voucher.condition] },
            { text: 'Medios de pago', value: Array.from(new Set(receipts.map(({ paymentType }) => paymentTypes[paymentType]))).toString() }
          ])

          const tableHeader = [
            'Ct.',
            'Descripción',
            // 'Precio',
            // 'IVA',
            'Importe'
          ]

          const tableBody = []

          voucher.items.forEach(item => {
            const row = [
              item.quantity,
              item.description,
              // item.unit_price || item.unitPrice,
              // item.tax_net || item.taxNet,
              numberFormat(item.net_total || item.netTotal)
            ]
            tableBody.push(row)
          })

          this.addText('')

          this.addTable({ header: tableHeader, body: tableBody, title: 'Detalles de la compra:' })

          this.addText('')

          this.addList([
            { text: 'Total venta', value: numberFormat(voucher.summary.subtotal) },
            { text: 'Descuento', value: numberFormat(voucher.summary.discountTotal || voucher.summary.discount_total) },
            { text: 'Total venta neta', value: numberFormat(voucher.summary.grossTotal || voucher.summary.gross_total) },
            { text: 'IVA', value: numberFormat(voucher.summary.taxTotal || voucher.summary.tax_total) },
            { text: 'Total del comprobante ', value: numberFormat(voucher.summary.netTotal || voucher.summary.net_total) },
            { text: 'Moneda', value: voucher.summary.currency },
            { text: 'Tipo Cambio', value: numberFormat(voucher.summary.exchangeRate) }
          ])

          if (receipts && receipts.length > 0) {
            const tableHeader = [
              'Fecha',
              'Método',
              // 'Precio',
              // 'IVA',
              'Importe'
            ]

            const tableBody = []

            receipts.forEach(item => {
              const row = [
                moment(item.date).format('L'),
                paymentTypes[item.paymentType],
                // item.unit_price || item.unitPrice,
                // item.tax_net || item.taxNet,
                numberFormat(item.amount)
              ]
              tableBody.push(row)
            })

            this.addText('')

            this.addTable({ header: tableHeader, body: tableBody, title: 'Pagos realizados:' })

            const totalPays = receipts.reduce((acc, cur) => acc + Number(cur.amount), 0)

            this.addList([
              { text: 'Saldo anterior', value: numberFormat(voucher.summary.netTotal) },
              { text: 'Pagado', value: numberFormat(totalPays) },
              { text: 'Saldo actual', value: numberFormat(Number(voucher.summary.netTotal) - totalPays) }
            ])
          }

          this.addText('')

          this.addText('Autorizado mediante el Oficio Número DGT-R-033-2019 del 20/06/2019 de la DGTD', 'center')
          this.addText('Gracias por su preferencia', 'center')

          /* this.commands.push({
          command: 'cut',
          value: null
        }); */

          return this.print(id === '3101785252' || id === '114760094')
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  printArching (cashDaily) {
    return new Promise((resolve, reject) => {
      const { expectedAmount, user, receiptsAmount, amount, paymentsAmount, closeAmount } = cashDaily

      this.addHeader({ title: 'Balance de caja', subtitles: [] })

      this.addText('<br>')

      this.addList([
        { text: 'Caja diaria n°', value: cashDaily.id },
        { text: 'Fecha', value: moment().format() },
        { text: 'Cajero/a', value: user.name }
      ])

      this.commands.push({
        type: 'println',
        value: ''
      })

      // this.addText('Ventas realizadas')

      // this.commands.push({
      //   type: 'println',
      //   value: ''
      // })

      // this.addList(Object.entries(sales).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))

      // this.commands.push({
      //   type: 'println',
      //   value: ''
      // })
      this.addText('Pagos recibidos')

      this.commands.push({
        type: 'println',
        value: ''
      })

      this.addList(Object.entries(receiptsAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))
      this.commands.push({
        type: 'println',
        value: ''
      })
      this.addText('Pagos realizados desde caja diaria')

      this.commands.push({
        type: 'println',
        value: ''
      })

      this.addList([{ text: 'Total Pagos en efectivo', value: paymentsAmount['01'] }])
      this.commands.push({
        type: 'println',
        value: ''
      })
      this.addText('Total Esperado en Caja')

      this.commands.push({
        type: 'println',
        value: ''
      })

      this.addList(Object.entries(expectedAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))
      this.commands.push({
        type: 'println',
        value: ''
      })
      this.addText('Total en Caja')

      this.commands.push({
        type: 'println',
        value: ''
      })

      this.addList(Object.entries(closeAmount).map((element) => ({ text: payemntCodes[element[0]], value: element[1] })))
      this.commands.push({
        type: 'println',
        value: ''
      })
      this.addText('Balance de Caja')

      this.commands.push({
        type: 'println',
        value: ''
      })

      this.addList(Object.entries(amount).map((element) => ({ text: payemntCodes[element[0]], value: Number(element[1]) * -1 })))
      this.commands.push({
        type: 'println',
        value: ''
      })

      this.print()
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const printer = {
  redaPrinter,
  webPrinter,
  async printVoucher (voucher, receipts) {
    const redaPrinterItsRunning = miStorage.getItem('redaPrinterItsRunning')
    const printer = JSON.parse(redaPrinterItsRunning) ? this.redaPrinter : this.webPrinter
    return printer.printVoucher(voucher, receipts)
  },
  async printArching (cashDaily) {
    const redaPrinterItsRunning = miStorage.getItem('redaPrinterItsRunning')
    const printer = JSON.parse(redaPrinterItsRunning) ? this.redaPrinter : this.webPrinter
    return printer.printArching(cashDaily)
  }
}

export default printer
