<template>
  <v-data-table
    :headers="headers"
    :items="getCompaniesItems"
    sort-by="id"
    :search="search"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Compañias creadas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="600px" v-if="getUser.createCompanies">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark v-on="on"><v-icon left>mdi-plus</v-icon>Crear</v-btn>
          </template>
          <NewCompany @close="dialog = false"></NewCompany>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.type="{ item }">
      {{ idsTypes[item.type] }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        :color="getCompany.identification === item.identification ? 'success' : 'grey'"
        @click="changeCompany(item)"
      >
        {{ getCompany.identification === item.identification ? 'mdi-check-circle' : 'mdi-check-circle-outline' }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NewCompany from '@/components/NewCompany.vue'
export default {
  name: 'CompaniesTable',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  components: { NewCompany },
  data () {
    return {
      dialog: false,
      idsTypes: {
        '01': 'Física',
        '02': 'Jurídica',
        '03': 'DIMEX',
        '04': 'NITE'
      },
      search: null
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    headers () {
      const headers = [
        { text: '', value: 'action' },
        {
          text: 'Cédula',
          value: 'identification'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Nombre comercial', value: 'tradename' },
        { text: 'Correo', value: 'email' },
        { text: 'Teléfono', value: 'phone' }
      ]
      return headers.filter((item) => item)
    },
    getCompaniesItems () {
      return this.getCompanies || []
    },
    ...mapGetters('company', [
      'getCompany',
      'getCompanies'
    ]),
    ...mapGetters('user', [
      'getUser'
    ])
    // Aquí es donde se declararían las funciones computadas de tu componente
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    changeCompany (company) {
      this.changeCurrentCompany(company.identification)
        .catch((error) => {
          console.error(error)
          this.showError('Ocurrió un error al cambiar de compañia')
        })
    },
    ...mapActions('user', [
      'changeCurrentCompany'
    ])
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
