<template>
  <v-container fluid class="d-inline-flex justify-end">
    <div>
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="from"
            label="Desde"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="from"
          @input="menuFrom = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="ma-7">
      -
    </div>
    <div>
      <v-menu
        v-model="menuTo"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="to"
            label="Hasta"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="to"
          @input="menuTo = false"
        ></v-date-picker>
      </v-menu>
    </div>
  </v-container>
</template>

<script>
import { /* mapState,/**/ mapActions, mapGetters, mapMutations } from 'vuex'
// import tools from '@/api/tools.js'
import moment from 'moment'
export default {
  name: 'Period',
  data () {
    return {
      menuFrom: false,
      menuTo: false
    }
  },
  methods: {
    ...mapMutations(['setFrom', 'setTo']),
    ...mapActions([])
  },
  computed: {
    from: {
      get () {
        if (this.getFrom === 0) return null
        return moment(this.getFrom).format('YYYY-MM-DD')
      },
      set (value) {
        const newDate = value || 0
        this.setFrom(Number(moment(newDate).format('x')))
      }
    },
    to: {
      get () {
        if (this.getTo === 0) return null
        return moment(this.getTo).format('YYYY-MM-DD')
      },
      set (value) {
        this.setTo(Number(moment(value).hour(23).minute(59).format('x')))
      }
    },
    ...mapGetters(['getFrom', 'getTo'])
  }
}
</script>
