<template>
  <div class="producto-parent">
    <div class="producto">{{ item.product.description | capitalizeWords }}</div>
    <div class="cdigo-de-orden">{{ item.num }}</div>
    <hr />

    <div class="informacin-formulario-de-prod-parent">
      <div class="informacin-formulario-de-prod" :key="i" v-for="(input, i) in item.customization">
        <a class="informacin-formulario-de-prod5" :href="input.value" v-if="isURL(input.value)">
          <img class="octiconupload-16" alt="" :src="require('@/assets/Upload.svg')">

          <div class="accin-subir-archivo">{{ input.text }}</div>
        </a>
        <div class="informacin-formulario-de" v-else>{{ `${input.text}: ${input.value}` }}</div>
      </div>
      <div class="informacin-formulario-de-prod5" @click="download(doc.ref)" style="cursor: pointer;" v-for="(doc, i) in docs" :key="i">
        <v-icon color="#4361EE" class="lsiconmanagement-outline">mdi-tray-arrow-down</v-icon>

          <div class="accin-subir-archivo" style="text-transform: none;">{{ doc.name || 'Descargar Archivo Adjunto' }}</div>
      </div>
      <div class="informacin-formulario-de-prod5" style="cursor: pointer;" @click="triggerFileInput">
        <v-icon color="#4361EE" class="lsiconmanagement-outline">mdi-tray-arrow-up</v-icon>
          <img v-if="false" class="octiconupload-16" alt="" :src="require('@/assets/Upload.svg')">

          <div class="accin-subir-archivo">Subir Archivo Adjunto</div>
      </div>

      <input
        ref="fileInput"
        type="file"
        style="display: none"
        @change="handleFileUpload"
      />
    </div>
    <hr />

    <div class="informacin-formulario-de-prod-parent">
      <div class="informacin-formulario-de-prod7">
          <img class="lsiconmanagement-outline" alt="" :src="require('@/assets/Materiales.svg')">

          <div class="accin-subir-archivo">control de materiales empleados</div>
      </div>
      <v-badge  v-if="item.comments.length > 0">
        <template v-slot:badge>
          {{ item.comments.length }}
        </template>
        <DialogComments :item="item"></DialogComments>
      </v-badge>
      <DialogComments :item="item" v-else></DialogComments>
    </div>
    <hr />

    <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-circular>

    <template v-if="item.status !== '05' && !loading && !orderCard">
      <div class="property-1next" v-if="item.nextStep" @click="save">
        <div class="enviar-a-la">Siguiente estación</div>
      </div>

      <div class="place-order-button" v-else  @click="save">
        <div class="marcar-como-terminado">Marcar como terminado</div>
      </div>
    </template>
  </div>
</template>
<script>
import redaApp from '../api/redaApp'
// import tools from '../api/tools'
import mimeTypes from '../assets/mimeTypes'
import axios from 'axios'
import { auth } from '@/api/firebaseApi'
// import tools from '../api/tools'
import DialogComments from './DialogComments.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'POCard',
  props: {
    item: Object,
    orderCard: Boolean
  },
  data () {
    return {
      errorMessage: null,
      successMessage: null,
      loading: false,
      dataFile: null,
      fileName: null
    }
  },
  components: { DialogComments },
  computed: {
    currStep () {
      if (this.item.currStep) return this.item.currStep
      const task = this.item
      const j = this.item.childs?.findIndex(({ completed }) => Number(completed) === 0)
      if (!j) return task
      const currStep = this.item.childs[j] || task
      return currStep
    },
    docs () {
      return this.item.docs?.filter(({ type }) => type === 'file') || []
    },
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    openUrl (url) {
      window.open(url, '_blank')
    },
    async download (path) {
      const url = await redaApp.getFileUrl({ path })
      this.openUrl(url)
    },
    triggerFileInput () {
      this.$refs.fileInput.click()
    },
    async handleFileUpload (event) {
      this.loading = true
      const dataFile = this.$refs.fileInput.files[0]
      const fileName = this.$refs.fileInput.files[0].name
      const mimeType = this.$refs.fileInput.files[0].type
      const extension = mimeTypes[mimeType]?.extension
      const path = `files/${this.getCompany.identification}/po-${this.item.uid}-${(this.item.docs?.length || 0) + 1}${extension}`
      const token = await auth.currentUser.getIdToken()
      const formData = new FormData()
      formData.append('file', dataFile)
      formData.append('path', path)
      axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/uploadFile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${token}`
          }
        }
      )
        .then(() => {
          const docs = this.item.docs || []
          docs.push({
            type: 'file',
            ref: path,
            name: fileName
          })
          return redaApp.update('tasks', { docs }, this.item.uid)
        })
        .then(() => {
          this.successMessage = 'Archivo enviado con éxito.'
        })
        .catch(error => {
          this.errorMessage = 'Error al enviar el archivo.'
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
      // const file = event.target.files[0]
      // if (file) {
      //   const reader = new FileReader()
      //   reader.onload = async (e) => {
      //     const base64Data = e.target.result
      //     const mime = tools.getMimeTypeFromDataURL(base64Data)
      //     const extension = mimeType[mime]?.extension
      //     const payload = {
      //       path: `files/${this.getCompany.identification}/po-${this.item.uid}-${(this.item.docs?.length || 0) + 1}${extension}`,
      //       data: base64Data
      //     }
      //     try {
      //       // eslint-disable-next-line no-unused-vars
      //       const response = await redaApp.uploadFile(payload)
      //       const docs = this.item.docs || []
      //       docs.push({
      //         type: 'file',
      //         ref: payload.path
      //       })
      //       await redaApp.update('tasks', { docs }, this.item.uid)
      //       this.successMessage = 'Archivo enviado con éxito.'
      //     } catch (error) {
      //       this.errorMessage = 'Error al enviar el archivo.'
      //     }
      //   }
      //   reader.readAsDataURL(file)
      // }
    },
    isURL (str) {
      try {
        const url = new URL(str) // La asignación evita el error de "no-new"
        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch {
        return false
      }
    },
    save () {
      this.loading = true
      const payload = { completed: 1 }
      redaApp.update('tasks', payload, this.currStep?.uid).then(() => {
        this.showSuccess('Orden actualizada')
        this.$emit('updated', this.item)
      }).catch((e) => {
        console.error(e)
        this.showError('Error al actualizar orden')
      }).finally(() => {
        this.loading = false
      })
    },
    ...mapActions('message', ['showError', 'showSuccess'])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.enviar-a-la {
  position: relative;
  font-weight: 500;
}
.property-1next {
  width: 100%;
  position: relative;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: #f72585;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Rubik;
  cursor: pointer;
}
hr {
  border: 1.5px solid #e5eaff;
  width: 100%;
  border-radius: 25px;

}
.producto {
  align-self: stretch;
  position: relative;
  font-size: 24px;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48.2px;
  flex-shrink: 0;
}
.cdigo-de-orden {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48.2px;
  flex-shrink: 0;
}
.frame-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.informacin-formulario-de {
  flex: 1;
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.informacin-formulario-de-prod {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.octiconupload-16 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.accin-subir-archivo {
  position: relative;
  letter-spacing: 0.22px;
  line-height: 35.29px;
  text-transform: capitalize;
}
.informacin-formulario-de-prod5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.informacin-formulario-de-prod-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 8px;
  text-align: left;
}
.lsiconmanagement-outline {
  width: 25px;
  position: relative;
  height: 25px;
  overflow: hidden;
  flex-shrink: 0;
}
.informacin-formulario-de-prod7 {
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 8px;
}
.marcar-como-terminado {
  position: relative;
  font-weight: 500;
}
.place-order-button {
  align-self: stretch;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  background-color: #4361ee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  color: #fff;
  font-family: Rubik;
  cursor: pointer;
}
.producto-parent {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 16px rgba(83, 87, 99, 0.05);
  border-radius: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 12px;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}
</style>
