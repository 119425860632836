<template>
  <div>
    <div class="d-flex mt-5 mb-0">
      <div class="px-2">
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          dense
          solo
          flat
        ></v-text-field>
      </div>

      <div class="px-2">
        <v-select
          :items="statusItems"
          label="Estado"
          v-model="status"
          dense
          solo
          flat
        ></v-select>
      </div>

      <div class="px-2  mr-auto" v-if="false">
        <v-select
          label="Solo field"
          dense
          solo
          flat
        ></v-select>
      </div>

      <div class="px-2" v-if="false">
        <v-select
          label="Solo field"
          dense
          solo
          flat
        ></v-select>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="getOrders"
      :search="search"
      class="elevation-1"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      sort-by="num"
      sort-desc
      hide-default-footer
      :single-expand="true"
      item-key="uid"
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:item.products="{ item }">
        <div v-for="(product, i) in item.items" :key="i">{{product.product.description}}: {{product.quantity}} {{product.product.unit}}</div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | dateFormat(true) }}
      </template>
      <template v-slot:item.deadline="{ item }">
        {{ item.deadline | dateFormat }}
      </template>
      <template v-slot:item.num="{ item }">
        #{{ item.num }}
      </template>
      <template v-slot:item.customer="{ item }">
        {{ (item.customer ? item.customer.name : null) | capitalizeWords }}
      </template>
      <template v-slot:item.completed="{ item }">
        {{ (item.task && item.task instanceof Object ? item.task.completed : 0) | percentage }}
      </template>
      <template v-slot:item.status="{ item }">
        <div
          :class="`ma-1 ${statusObj[item.task.status].color}--text`"
        >
        {{ statusObj[item.task.status].text }}
        </div>
      </template>
      <template v-slot:item.priority="{ item }">
        <v-chip
          class="ma-1"
          v-if="item.priority"
          :color="priorityObj[item.priority].color"
        >
          {{ priorityObj[item.priority].text }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <OrderDialog :item="item" :icon="false" v-if="(item.task && item.task instanceof Object)"></OrderDialog>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Elementos: {{ item.items.length }}</strong>
          </td>
      </template>
      <template v-slot:item.data-table-expand="{ item, isExpanded}">
        {{ item.num }}<v-icon @click="setExapanded(item)">{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </template>
      <template v-slot:footer="{ props }">
        <div class="d-flex justify-space-between pa-6 caption">
          <div class="caption">Mostrando <v-select
            style="display: inline-block; width: 68px;"
              v-model="itemsPerPage"
              outlined
              dense
              class="caption"
              append-icon="mdi-chevron-down"
              :items="[
                { text: '5', value: 5 },
                { text: '10', value: 10 },
                { text: '15', value: 15 },
                { text: 'Todas', value: props.pagination.itemsLength },
              ]"
            >
              <template v-slot:selection="{ item }">{{ item.value }}</template>
            </v-select> de {{ props.pagination.itemsLength }}
          </div>
          <v-pagination
            v-model="page"
            :length="props.pagination.pageCount"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tools from '@/api/tools'
import OrderDialog from '@/components/OrderDialog.vue'

export default {
  name: 'ApprovalOrdersTable',
  props: ['orders', 'loading', 'tasks'],
  components: {
    OrderDialog
  },
  data: () => ({
    search: null,
    page: 1,
    expanded: [],
    itemsPerPage: 10,
    statusItems: [
      { text: 'Atrasada', value: '01' },
      { text: 'En peligro', value: '02' },
      { text: 'Pendiente', value: '03' },
      { text: 'Iniciada', value: '04' },
      { text: 'Lista', value: '05' },
      { text: 'Entregada', value: '06' },
      { text: 'Todas', value: null }
    ],
    status: null,
    headersExcel: [
      { text: 'Nombre', value: 'client.name' },
      { text: 'Contacto', value: 'client.phone' },
      { text: 'Cantón', value: 'address.conty.text' },
      { text: 'Distrito', value: 'address.district.text' },
      { text: 'Dirección', value: 'address.others' },
      { text: 'Masas', value: 'mmn01.quantity' },
      { text: 'Chorreadas', value: 'cpn01.quantity' },
      { text: 'Observaciones', value: 'observations' }
    ],
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      '01': { text: 'Atrasada', color: 'error' },
      '02': { text: 'En peligro', color: 'warning' },
      '03': { text: 'Pendiente', color: 'warning' },
      '04': { text: 'Iniciada', color: 'info' },
      '05': { text: 'Lista', color: 'primary' },
      '06': { text: 'Entregada', color: 'success' }
    }
  }),
  methods: {
    downloadExcel () {
      tools.table2excel(this.getOrders, this.headersExcel, 'Ordenes de trabajo')
    },
    setExapanded (item) {
      this.expanded = [item]
    }
  },
  computed: {
    getOrders () {
      return this.orders.filter(({ task }) => (task?.status === this.status || !this.status))
    },
    headers () {
      return [
        { text: 'ID', value: 'num' },
        { text: '', value: 'data-table-expand' },
        { text: 'Cliente', value: 'customer' },
        { text: 'Fecha de entrega', value: 'created' },
        { text: 'Estado', value: 'status' },
        { text: 'Facturación', value: 'billing' },
        { text: 'Entrega', value: 'delivery' }
      ]
    },
    ...mapGetters('user', [])
  }
}
</script>
