import axios from 'axios'
import { auth } from './firebaseApi'

const submitRedaApp = async (path, data) => {
  const token = await auth.currentUser.getIdToken()
  const headers = { authorization: `Bearer ${token}` }
  return axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/${path}`, data, { headers })
}

const deleteRedaApp = async (path) => {
  const token = await auth.currentUser.getIdToken()
  const headers = { authorization: `Bearer ${token}` }
  return axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/${path}`, { headers })
}

const createCompany = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('create-company', payload)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}

const getRedaApp = async (path, id, params) => {
  if (!params) params = {}
  const token = await auth.currentUser?.getIdToken()
  const headers = { authorization: `Bearer ${token}` }
  const url = id ? `${process.env.VUE_APP_API_BASE_URL}/api/${path}/${id}` : `${process.env.VUE_APP_API_BASE_URL}/api/${path}`
  return axios.get(url, { headers, params })
}

const submitRedaAppPublic = async (path, data) => {
  return axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/${path}`, data)
}

const genCalendar = (textPrompt) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('generate-calendar', { textPrompt })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const sendEmail = (message) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('sendMail', message)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}
const sendEmailVoucher = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('sendVoucher', payload)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}
const newCN = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('newCN', payload)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}
const newDN = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('newDN', payload)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}
const paymentsVoucher = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaAppPublic('payments-voucher', payload)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const getPDF = (id) => {
  return new Promise((resolve, reject) => {
    getRedaApp('pdf', id)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const deleteProduct = (id) => {
  return new Promise((resolve, reject) => {
    getRedaApp('delete-product', id)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const getFile = (payload) => {
  return new Promise((resolve, reject) => {
    getRedaApp('getFile', null, payload)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const getCert = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('cert', payload)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const newStaff = (payload) => {
  return new Promise((resolve, reject) => {
    if (!payload.createUser) payload.role = 'seller'
    submitRedaApp('new-staff', payload)
      .then((res) => {
        console.log(res)
        resolve()
      })
      .catch((error) => reject(error))
  })
}
const getUserByUID = (id) => {
  return new Promise((resolve, reject) => {
    getRedaApp('get-user-by-uid', id)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const create = (collection, payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp(`curd/${collection}`, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const update = (collection, payload, id) => {
  return new Promise((resolve, reject) => {
    submitRedaApp(`curd/${collection}/${id}`, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const deleteItem = (collection, id) => {
  return new Promise((resolve, reject) => {
    deleteRedaApp(`curd/${collection}/${id}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const readItem = (collection, id) => {
  return new Promise((resolve, reject) => {
    getRedaApp(`curd/${collection}/${id}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const readItems = (collection) => {
  return new Promise((resolve, reject) => {
    getRedaApp(`curd/${collection}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const getIndexedItems = (collection) => {
  return new Promise((resolve, reject) => {
    getRedaApp(`get-indexed-items/${collection}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const getTask = (uid) => {
  return new Promise((resolve, reject) => {
    getRedaApp(`get-task/${uid}`)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}
const createItems = async (collection, items, keyId = 'id', logger = false) => {
  let success = 0
  let errors = 0
  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    const itemCreated = await create(collection, item).catch((error) => {
      console.error(error)
      errors++
    })
    if (logger) console.log(`Item id ${itemCreated[keyId]} creado!`)
    success++
  }
  return { success, errors }
}
const importAttachmentsVouchers = async () => {
  // return new Promise((resolve, reject) => {
  //   submitRedaApp('attachments-vouchers')
  //     .then(() => resolve())
  //     .catch((error) => reject(error))
  // })
  return true
}

const runAccounting = ({ to, from }) => {
  return new Promise((resolve, reject) => {
    resolve({
      accounts: [],
      journal: [],
      ledger: [],
      incomeStatement: [],
      statementOfFinancialPosition: [],
      statementOfCashFlows: [],
      statementOfChangesInEquity: []
    })
    // getRedaApp('accounting', null, { to, from })
    //   .then(({ data }) => resolve(data))
    //   .catch((error) => reject(error))
  })
}

const runVouchers = ({ from, to }) => {
  return new Promise((resolve, reject) => {
    resolve({
      vouchers: [],
      vouchersReceived: [],
      vouchersReceivedPending: [],
      vouchersReceivedClassified: [],
      voucherItems: [],
      voucherReceivedItems: [],
      vouchersReceivedPendingItems: [],
      voucherSalesItems: []
    })
    // getRedaApp('vouchers', null, { from, to })
    //   .then(({ data }) => resolve(data))
    //   .catch((error) => reject(error))
  })
}

const submitVoucher = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('voucher', payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error))
  })
}

const searchProduct = (search) => {
  return new Promise((resolve, reject) => {
    getRedaApp('search-product', search)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const createProductionOrder = (newProductionOrders) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('create-po', newProductionOrders)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}
const createTask = (newTask) => {
  return new Promise((resolve, reject) => {
    create('tasks', newTask)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error))
  })
}

const uploadFile = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('upload-file', payload)
      .then(() => resolve())
      .catch((error) => reject(error))
  })
}

const getFileUrl = (payload) => {
  return new Promise((resolve, reject) => {
    submitRedaApp('get-file-url', payload)
      .then((res) => resolve(res.data.url))
      .catch((error) => reject(error))
  })
}

export default { getFileUrl, uploadFile, getTask, deleteProduct, createProductionOrder, getIndexedItems, createTask, getRedaApp, createCompany, searchProduct, submitVoucher, submitRedaApp, runVouchers, runAccounting, create, update, readItem, readItems, getCert, sendEmail, sendEmailVoucher, newCN, newDN, paymentsVoucher, getUserByUID, getPDF, getFile, newStaff, genCalendar, createItems, deleteItem, importAttachmentsVouchers }
