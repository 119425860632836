<template>
  <v-card
    :loading="loading"
  >
    <v-card-title>ONVO Pay</v-card-title>
    <v-card-text>
      <reda-form
        v-model="onvo"
        :options="options"
        @submitForm="save"
      ></reda-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ONVO',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      onvo: {
        publishableAPIKey: null,
        secretAPIKey: null,
        chargeProcessingServiceFee: 0
      },
      default: {
        publishableAPIKey: null,
        secretAPIKey: null,
        chargeProcessingServiceFee: 0
      },
      loading: false
      // Aquí es donde se declararían las variables de tu componente
    }
  },
  computed: {
    // Aquí es donde se declararían las funciones computadas de tu componente
    options () {
      return {
        submitText: 'Guardar',
        showSubmit: true,
        inputs: [
          {
            id: '000',
            label: 'LLAVE PÚBLICA',
            name: 'publishableAPIKey',
            value: 'publishableAPIKey',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '001',
            label: 'LLAVE SECRETA',
            name: 'secretAPIKey',
            value: 'secretAPIKey',
            type: 'text',
            rules: 'required',
            cols: 12
          },
          {
            id: '002',
            label: 'Porcentaje de cargo por servicio de procesamiento a los cobros en linea',
            hint: 'Dejar en blanco o en cero (0) para no cobrar el cargo',
            name: 'chargeProcessingServiceFee',
            value: 'chargeProcessingServiceFee',
            type: 'number',
            rules: 'max_value:100',
            persistentHint: true,
            appendOuterIcon: 'mdi-percent',
            cols: 9
          }
        ]
      }
    },
    ...mapGetters('company', ['getCompany'])
  },
  watch: {
    // Aquí es donde se declararían las funciones de "watch" de tu componente
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
    save () {
      this.loading = true
      this.newONVO({
        apiKeys: {
          publishableAPIKey: this.onvo.publishableAPIKey,
          secretAPIKey: this.onvo.secretAPIKey
        },
        chargeProcessingServiceFee: Number(this.onvo.chargeProcessingServiceFee) / 100
      })
        .then(() => {
          this.showSuccess('Credenciales guardadas correctamente')
          // this.onvo = { ...this.default }
        })
        .catch((error) => {
          console.error(error)
          this.showError('Error al guardar credenciales')
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('company', ['newONVO']),
    ...mapActions('message', [
      'showSuccess',
      'showInfo',
      'showWarning',
      'showError'
    ])
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    if (this.getCompany.onvo) {
      console.log(this.getCompany.onvo.chargeProcessingServiceFee)
      const onvo = {
        ...this.getCompany.onvo.apiKeys,
        chargeProcessingServiceFee: this.getCompany.onvo.chargeProcessingServiceFee
          ? this.getCompany.onvo.chargeProcessingServiceFee * 100
          : 0
      }
      this.onvo = { ...onvo }
    }
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
/* Aquí es donde se escribiría el CSS específico de tu componente */
</style>
