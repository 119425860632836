/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import fbApi from '@/api/firebaseApi'
import views from '@/assets/moduleViews'
import redaApp from '../../api/redaApp'
import icons from '../../assets/redaIcons'

const { auth, fb } = fbApi
// initial state
const initialState = {
  users: [],
  user: {
    logged: false,
    name: null,
    displayName: null,
    email: null,
    emailVerified: null,
    photoURL: null,
    isAnonymous: null,
    uid: null,
    providerData: null,
    active: true,
    currentCompany: null,
    companies: [],
    changePass: false
  },
  listinerUsers: null,
  listinerUser: null
}
const state = () => ({ ...initialState })

// getters
const getters = {
  getChangePass: ({ users }, getters) => {
    return !!getters.getUser?.changePass
  },
  getUsers: ({ users }, getters) => {
    return users
  },
  getUser: ({ user }, getters) => {
    return user
  },
  getLogged: (state, getters) => {
    return state.user.logged
  },
  getAuthorizations: (state, getters, rootState, rootGetters) => {
    const modules = rootGetters['company/getCompany']?.modules
    const authorizations = getters.getCurrentCompany?.authorizations === 'admin' ? modules : getters.getCurrentCompany?.authorizations
    return authorizations
  },
  getFE: (state, getters, rootState, rootGetters) => {
    const fe = rootGetters['company/getApiVoucher'] && rootGetters['company/getApiVoucher'][rootGetters['company/getCompany'].env]
    return fe
  },
  getUserVoucherTypes: (state, getters, rootState, rootGetters) => {
    return getters.getCurrentCompany?.voucherTypes
      ? getters.getCurrentCompany.voucherTypes
      : getters.getVoucherTypes
  },
  getVoucherTypes: (state, getters, rootState, rootGetters) => {
    const fe = getters.getFE
    const typesBase = [
      // { value: '01', text: 'Factura electrónica' },
      // { value: '02', text: 'Nota de débito electrónica' },
      // { value: '03', text: 'Nota de crédito electrónica' },
      // { value: '04', text: 'Tiquete electrónico' },
      // { value: '08', text: 'Factura electrónica de compras' },
      // { value: '09', text: 'Factura electrónica de Exportación' },
      { value: '10', text: 'Factura de venta' },
      { value: '11', text: 'Factura proforma' },
      { value: '12', text: 'Factura de compra' },
      { value: '13', text: 'Orden de trabajo' }
    ]
    const typesEV = [
      { value: '01', text: 'Factura electrónica' },
      { value: '04', text: 'Tiquete electrónico' },
      { value: '08', text: 'Factura electrónica de compras' },
      { value: '09', text: 'Factura electrónica de Exportación' }
    ]
    const typesVouchers = fe ? typesEV.concat(typesBase) : typesBase
    return typesVouchers
  },
  getViews: (state, { getAuthorizations, getLogged }) => {
    if (!getLogged) return []
    const { income, expenses, marketing, eventsAndTasks, products, accounting, config, reports, orvigrafik } = views
    const menu = [
      { title: 'Inicio', icon: icons.Home, to: '/', custom: true },
      {
        title: 'Orvigrafik',
        icon: icons.Orvigrafik,
        subgroup: getAuthorizations
          .map((authorization) => orvigrafik[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Ventas e ingresos',
        icon: icons.VentasIngresos,
        subgroup: getAuthorizations
          .map((authorization) => income[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Compras y gastos',
        icon: icons.ComprasGastos,
        subgroup: getAuthorizations
          .map((authorization) => expenses[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Contactos',
        icon: icons.Contactos,
        to: getAuthorizations.includes('Customers') ? '/customers' : false,
        custom: true
      },
      {
        title: 'Productos y servicios',
        icon: icons.Productos,
        subgroup: getAuthorizations
          .map((authorization) => products[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Eventos y tareas',
        icon: 'mdi-calendar-multiple-check',
        subgroup: getAuthorizations
          .map((authorization) => eventsAndTasks[authorization])
          .filter((item) => Boolean(item))
      },
      {
        title: 'Marketing',
        icon: 'mdi-strategy',
        subgroup: getAuthorizations
          .map((authorization) => marketing[authorization])
          .filter((item) => Boolean(item))
      },
      {
        title: 'Contabilidad',
        icon: icons.Contabilidad,
        subgroup: getAuthorizations
          .map((authorization) => accounting[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Reportes',
        icon: icons.Reportes,
        subgroup: getAuthorizations
          .map((authorization) => reports[authorization])
          .filter((item) => Boolean(item)),
        custom: true
      },
      {
        title: 'Punto de venta',
        icon: 'mdi-cash-register',
        to: getAuthorizations.includes('Biller') ? '/biller' : false
      },
      {
        title: 'Compañias',
        icon: 'mdi-domain',
        to: getAuthorizations.includes('Companies') ? '/companies' : false
      },
      {
        title: 'Configuración',
        icon: 'mdi-cog',
        subgroup: getAuthorizations
          .map((authorization) => config[authorization])
          .filter((item) => Boolean(item))
      }
    ]
    return menu.filter((item) => (item.subgroup && item.subgroup.length > 0) || Boolean(item.to))
  },
  getCurrentCompany: (state, getters) => {
    const index = state.user.companies.findIndex(item => {
      return item.id === state.user.currentCompany
    })
    return index > -1 ? state.user.companies[index] : state.user.currentCompany
  },
  getUserUid: (state) => async (uid) => {
    console.log(uid)
    const user = await fbApi.api.getItem('users', uid, 'userUID')
    const newUser = { ...user, uid: user.userUID }
    return newUser
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters, rootState }, userObj) {
    try {
      redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => {})
      const userQuerySnapshot = await fbApi.db.collection('users')
        .where('userUID', '==', userObj.uid)
        .get()
      let user = {}
      userQuerySnapshot.forEach((doc) => {
        user = { id: doc.id, ...doc.data() }
      })
      console.log(user.photoURL)
      const newUser = {}
      newUser.logged = true
      newUser.name = user.name
      newUser.displayName = userObj.displayName
      newUser.email = userObj.email
      newUser.emailVerified = userObj.emailVerified
      newUser.photoURL = user.photoURL || userObj.photoURL
      newUser.isAnonymous = userObj.isAnonymous
      newUser.uid = userObj.uid
      newUser.providerData = userObj.providerData
      newUser.authorizations = user.authorizations
      newUser.active = user.active
      newUser.currentCompany = user.currentCompany
      newUser.createCompanies = user.createCompanies
      newUser.companies = user.companies
      newUser.changePass = user.changePass
      const company = user.companies.find((item) => item.id === user.currentCompany)
      const companies = user.companies.map((item) => item.id)
      await dispatch('setUser', newUser)
      await dispatch('company/init', companies, { root: true })
      await dispatch('company/updateCompany', company, { root: true })
      const listinerUser = userQuerySnapshot.query.onSnapshot(async (querySnapshot) => {
        let docUser = {}
        querySnapshot.forEach((doc) => {
          docUser = { id: doc.id, ...doc.data() }
        })
        const newUserObj = {}
        newUserObj.logged = true
        newUserObj.name = docUser.name
        newUserObj.displayName = userObj.displayName
        newUserObj.email = userObj.email
        newUserObj.emailVerified = userObj.emailVerified
        newUserObj.photoURL = docUser.photoURL || userObj.photoURL
        newUserObj.isAnonymous = userObj.isAnonymous
        newUserObj.uid = userObj.uid
        newUserObj.providerData = userObj.providerData
        newUserObj.authorizations = docUser.authorizations
        newUserObj.active = docUser.active
        newUserObj.currentCompany = docUser.currentCompany
        newUserObj.createCompanies = docUser.createCompanies
        newUserObj.companies = docUser.companies
        newUserObj.changePass = docUser.changePass
        commit('setUser', newUserObj)
        const currentUser = newUserObj.companies.find(({ id }) => id === newUserObj.currentCompany)
        if (currentUser.id !== rootGetters['company/getCompany'].identification) {
          commit('setLoadingApp', true, { root: true })
          await dispatch('company/updateCompany', currentUser, { root: true })
          commit('setLoadingApp', false, { root: true })
        }
      })
      commit('setListinerUser', listinerUser)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar usuario')
    }
  },
  async initUsers ({ state, commit, dispatch, getters, rootGetters }, userUIDs) {
    console.log(userUIDs)
    try {
      const usersQuerySnapshot = await fbApi.db.collection('users')
        .where('userUID', 'in', userUIDs)
        .get()
      const users = []
      usersQuerySnapshot.forEach((doc) => users.push({ id: doc.id, ...doc.data() }))
      commit('setUsers', users)
      const listenerUsers = usersQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newUsers = []
        querySnapshot.forEach((doc) => newUsers.push({ id: doc.id, ...doc.data() }))
        commit('setUsers', newUsers)
      })
      commit('setListenerUsers', listenerUsers)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar caja diaria')
    }
  },
  changeCurrentCompany: ({ commit, getters }, company) => {
    return new Promise((resolve, reject) => {
      fbApi.api.updateItemValue(
        'users',
        getters.getUser.uid,
        'userUID',
        { text: 'currentCompany', value: company }
      )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUser: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      if (user) {
        // User is signed in.
        commit('setUser', user)
        resolve()
        // account: {},
      } else {
        // User is signed out.
        commit('setUser', false)
        // router.push('Login')
        resolve()
      }
    })
  },
  updatePassword: ({ commit, getters }, { oldPassword, newPassword }) => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser
      if (oldPassword) {
        const credential = fb.auth.EmailAuthProvider.credential(
          currentUser.email,
          oldPassword
        )
        currentUser.reauthenticateWithCredential(credential)
          .then(({ user }) => {
            return user.updatePassword(newPassword)
          })
          .then(() => {
            resolve('Contraseña actualizada correctamente')
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        currentUser.updatePassword(newPassword)
          .then(() => {
            return fbApi.api.updateItemValue(
              'users',
              getters.getUser.uid,
              'userUID',
              { text: 'changePass', value: false }
            )
          })
          .then(() => {
            resolve('Contraseña actualizada correctamente')
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  sendPasswordResetEmail: ({ commit }, { email }) => {
    return new Promise((resolve, reject) => {
      auth.sendPasswordResetEmail(email)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  signIn: ({ commit }, { email, password }) => {
    return new Promise((resolve, reject) => {
      auth.signInWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  signOut: ({ commit }) => {
    return new Promise((resolve, reject) => {
      auth.signOut()
        .then(() => resolve())
        .catch((error) => {
          reject(error)
        })
    })
  },
  reset: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch('company/reset', null, { root: true })
        .then(() => {
          console.log('Reset user!')
          commit('reset')
          resolve()
        })
        .catch((error) => reject(error))
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerUsers) {
      state.listinerUsers()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListenerUsers (state, listener) {
    state.listinerUsers = listener
  },
  setListinerUser (state, listener) {
    state.listinerUser = listener
  },
  setUsers: (state, users) => {
    if (!users) state.listinerUsers()
    state.users = users
  },
  login: (state) => {
    console.log('Logged!')
    state.user.logged = true
  },
  setUser: (state, user) => {
    if (user) {
      // User is signed in.
      state.user.logged = true
      state.user.name = user.name
      state.user.displayName = user.displayName
      state.user.email = user.email
      state.user.emailVerified = user.emailVerified
      state.user.photoURL = user.photoURL
      state.user.isAnonymous = user.isAnonymous
      state.user.uid = user.uid
      state.user.providerData = user.providerData
      state.user.active = user.active
      state.user.currentCompany = user.currentCompany
      state.user.createCompanies = user.createCompanies
      state.user.companies = user.companies
      state.user.changePass = user.changePass
    } else {
      // User is signed out.
      state.user.logged = false
      state.user.name = null
      state.user.displayName = null
      state.user.email = null
      state.user.emailVerified = null
      state.user.photoURL = null
      state.user.isAnonymous = null
      state.user.uid = null
      state.user.providerData = null
      state.user.active = true
      state.user.currentCompany = null
      state.user.createCompanies = false
      state.user.companies = []
      state.user.changePass = false
      if (state.listinerUsers) {
        state.listinerUsers()
        state.listinerUsers = null
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
