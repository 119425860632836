<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>Post #{{ post.id }}</v-card-title>
      <v-btn @click="checkLoginState">Comprobar FB</v-btn>
      <RedaForm
      :options="form"
      v-model="tmp.post"
      @submitForm="savePost"
      ></RedaForm>
    </v-card>
  </v-container>
</template>

<script>
import { /* mapState, /* */ mapActions, mapGetters } from 'vuex'
import redaApp from '../api/redaApp'
import fb from '../api/fb'

export default {
  name: 'Post',
  props: ['uid'],
  components: {},
  data: () => ({
    loading: false,
    tmp: {
      post: {}
    }
  }),
  mounted () {
    this.tmp.post = { ...this.post }
  },
  methods: {
    checkLoginState () {
      fb.checkLoginState()
    },
    savePost () {
      this.loading = true
      redaApp.update('posts', this.tmp.post, this.post.uid)
        .then(() => this.showSuccess('Cambios guardados'))
        .catch((error) => {
          console.error(error)
          this.showError('Error al guardar cambios')
        })
        .finally(() => { this.loading = false })
    },
    publishPost () {
    },
    mounted () {
      this.tmp.post = { ...this.post }
    },
    ...mapActions('posts', ['insertPost']),
    ...mapActions('message', ['showSuccess', 'showError'])
  },
  computed: {
    form () {
      return {
        submitText: 'Guardar',
        showSubmit: true,
        inputs: [
          {
            id: 'de01',
            label: 'Fecha',
            name: 'date',
            value: 'date',
            type: 'date',
            rules: 'required',
            cols: 12
          },
          {
            id: 'de01',
            label: 'Contenido',
            name: 'text',
            value: 'text',
            type: 'textarea',
            rules: 'required',
            cols: 12
          },
          {
            id: 'de02',
            label: 'Busqueda de imagen propuesta',
            name: 'search_phrase',
            value: 'search_phrase',
            rules: 'required',
            type: 'text',
            cols: 6
          },
          {
            id: 'de04',
            label: 'Frase atractiva',
            name: 'catchy_phrase',
            value: 'catchy_phrase',
            rules: 'required',
            type: 'text',
            cols: 6
          },
          {
            id: 'de05',
            label: 'Llamado a la acción',
            name: 'cta',
            value: 'cta',
            type: 'number',
            rules: 'required',
            cols: 12
          },
          {
            id: 'de06',
            label: 'Link de acción',
            name: 'action_link',
            value: 'action_link',
            type: 'text',
            cols: 12
          },
          {
            id: 'de07',
            label: 'Hashtags',
            name: 'hashtags',
            value: 'hashtags',
            type: 'text',
            cols: 12
          }
        ]
      }
    },
    post () {
      return this.getPosts.find((post) => post.uid === this.uid)
    },
    ...mapGetters('posts', ['getPosts']),
    ...mapGetters('company', ['getCompany'])
  }
}
</script>
