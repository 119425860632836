<template>
  <v-data-table
    :headers="headers"
    :items="pendingVouchers"
    class="elevation-1"
    sort-by="date"
    sort-desc
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Documentos pendientes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px"><!--
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              class="mb-2"
              v-on="on"
            ><v-icon>mdi-plus</v-icon></v-btn>
          </template>-->
          <v-card v-if="false">
            <v-card-title>
              <span class="headline">Nuevo movimiento</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :value="dateParsed"
                      label="Fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      @click.stop="menuDate = true"
                    ></v-text-field>
                    <div>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        absolute
                        min-width="auto"
                      >
                        <v-date-picker
                          :value="dateParsed"
                          @change="setDate($event)"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Producto"
                      :items="itemsProductsCode"
                      v-model="editedItem.product"
                      :filter="customFilter"
                      autofocus
                      item-text="name"
                      item-value="code"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="details"
                      item-text="name"
                      item-value="name"
                      label="Detalle"
                      v-model="editedItem.detail"
                      @input="inputDetail"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="cellarItems"
                      label="Entra a"
                      v-model="editedItem.entry"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="cellarItems"
                      label="Sale de"
                      v-model="editedItem.exit"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field type="number" v-model="editedItem.quantity" label="Cantidad"></v-text-field>
                  </v-col>
                  <v-col cols="12"  sm="6">
                    <v-text-field type="number" v-model="editedItem.cost" label="Costo unitario"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Registrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!--<template v-slot:item.entry="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>
    <template v-slot:item.exit="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>-->
    <template v-slot:item.date="{ item }">
      {{getDateFormat(item.date)}}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import tools from '@/api/tools.js'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'PendingEntries',
  components: {},
  props: {
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Clave',
        align: 'start',
        sortable: false,
        value: 'key'
      },
      { text: 'Fecha', value: 'date' }
    ],
    editedIndex: -1,
    editedItem: {
      date: null,
      product: '',
      detail: 'Compra',
      entry: null,
      exit: null,
      quantity: 1,
      cost: 0
    },
    defaultItem: {
      date: null,
      product: '',
      detail: 'Compra',
      entry: null,
      exit: null,
      quantity: 1,
      cost: 0
    }
  }),

  mounted () {
  },

  computed: {
    cellarItems () {
      const cellars = this.getCellars.map((cellar) => ({ text: cellar.name, value: cellar.code }))
      cellars.unshift({ text: 'Ninguna', value: null })
      return cellars
    },
    pendingVouchers () {
      const movs = this.getInventories.filter(({ pending }) => pending)
      return movs.reduce((acc, curr) => {
        const index = acc.findIndex(({ key }) => curr.voucher === key)
        if (index === -1) {
          acc.push({ key: curr.voucher, date: curr.date, movs: [{ ...curr }] })
          return acc
        }
        acc[index].mov.push({ ...curr })
      }, [])
    },

    getProductTmp () {
      const product = this.getProducts.find(item => {
        return item.commercial_code === this.editedItem.product
      })
      return product
    },

    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    itemsProductsCode () {
      const items = []
      for (var i = this.getProducts.length - 1; i >= 0; i--) {
        if (this.getProducts[i].inventoryable) {
          items.push({ name: this.getProducts[i].description, code: this.getProducts[i].commercialCode })
        }
      }
      return items
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    ...mapGetters('products', [
      'getProducts'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ]),
    ...mapGetters('inventories', [
      'getInventories',
      'getStock',
      'getCellars'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  methods: {
    getDateFormat (date) {
      return moment(date, 'x').format('LLL')
    },

    round (number) {
      return tools.round10(number, -2)
    },

    inputDetail () {
      const movement = this.details.find(item => {
        return item.name === this.editedItem.detail
      })
      this.editedItem.movement = movement.movement
      /* if (
          this.editedItem.detail === 'Venta'
          || this.editedItem.detail === 'Perdida'
        ) {
          this.editedItem.movement = 'exit';
        } else {
          this.editedItem.movement = 'entry';
        }/**/
    },

    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      const index = this.desserts.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      const inventory = {}
      // inventory.id = this.inventories.length + 1
      inventory.date = this.editedItem.date
      inventory.company = this.getCompany.identification
      inventory.product = this.editedItem.product
      inventory.productFull = this.getProductTmp
      inventory.detail = this.editedItem.detail
      inventory.entry = this.editedItem.entry
      inventory.exit = this.editedItem.exit
      inventory.quantity = this.editedItem.quantity
      inventory.cost = this.editedItem.cost
      inventory.docs = []
      inventory.user = this.account
      this.insertInventory(inventory)
        .then(() => {
          this.close()
        })
    },
    ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
