<template>
    <div>
      <div class="chat">
        <v-card v-for="message in messages" :key="message.id" class="message" :class="{'right': message.role === currentUser}">
          <v-card-text>{{ message.body }}</v-card-text>
          <v-card-actions class="text-right">{{ moment(message.date, 'x').format('LT') }}</v-card-actions>
        </v-card>
      </div>
      <div class="typer">
        <emoji-picker :show="emojiPanel" @close="toggleEmojiPanel" @click="addEmojiToMessage"></emoji-picker>
        <v-text-field
          v-model="newMessage"
          :append-outer-icon="'mdi-send'"
          :prepend-icon="emojiPanel ? 'mdi-close-circle' : 'mdi-emoticon-outline'"
          clear-icon="mdi-close-circle"
          clearable
          type="text"
          @click:append-outer="sendMessage"
          @click:prepend="toggleEmojiPanel"
          @keyup.enter="sendMessage"
          class="typer-input"
        ></v-text-field>
      </div>
    </div>
  </template>

<script>
import EmojiPicker from '@/components/EmojiPicker.vue'
import moment from 'moment'
export default {
  name: 'ConversationPanel',
  data () {
    return {
      currentUser: 'assistant',
      emojiPanel: false,
      moment,
      /* messages: [
        { id: 1, sender: 'John', text: 'Hey, how are you?', time: '10:00 AM' },
        { id: 2, sender: 'Me', text: 'I\'m good, thanks for asking!', time: '10:05 AM' },
        { id: 3, sender: 'John', text: 'That\'s great!', time: '10:10 AM' }
      ], */
      newMessage: ''
    }
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  components: { EmojiPicker },
  methods: {
    sendMessage () {
      if (this.newMessage !== '') {
        const message = {
          id: this.messages.length + 1,
          sender: this.currentUser,
          text: this.newMessage,
          time: new Date().toLocaleTimeString()
        }
        this.messages.push(message)
        this.newMessage = ''
      }
    },
    addEmojiToMessage (emoji) {
      this.newMessage += emoji.value
    },
    toggleEmojiPanel () {
      this.emojiPanel = !this.emojiPanel
    }
  }
}
</script>

  <style scoped>
  .chat {
    height: calc(100vh - 13rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .message {
    max-width: 60%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .message.right {
    align-self: flex-end;
    background-color: #dcf8c6;
  }
  .input {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .typer{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    bottom: 0;
    height: 4.9rem;
    background-color: #fff;
    box-shadow: 0 -5px 10px -5px rgba(0,0,0,.2);
  }
  .typer-input{
    position: absolute;
    padding: 1rem;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.25rem;
  }
  </style>
