<template>
  <v-card>
    <v-card-text>
      <v-data-iterator
        :items="journal"
        sort-by="num"
        sort-desc
      >
        <template v-slot:header>
          <v-row>
            <v-col
              cols="12"
              md="9"
              class="d-flex align-center"
            >
              <div class="title">Libro Diario</div>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="d-flex align-center justify-end"
              v-if="!entries"
            >
              <v-dialog
                v-model="dialog"
                max-width="600px"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo asiento
                  </v-btn>
                </template>
                <v-card :loading="loading" >
                  <v-card-title>
                    <span class="headline">Nuevo asiento diario</span>
                  </v-card-title>

                  <v-card-text>
                    <!--<v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.date"
                            label="Fecha"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.description"
                            label="Calories"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-data-table
                        dense
                        :headers="[
                          { text: 'Cuenta', value: 'code', },
                          { text: 'Nombre de la cuenta', value: 'name', },
                          { text: 'Debe', value: 'debit', },
                          { text: 'Haber', value: 'credit', },
                        ]"
                        :items="editedItem.accounts"
                        item-key="name"
                        class="elevation-1"
                      ></v-data-table>
                    </v-container>-->
                    <RedaForm
                      :options="formEntry"
                      v-model="editedItem"
                      @cancel="close"
                      @submitForm="save"
                    ></RedaForm>
                    {{ editItem.docs }}
                  </v-card-text>

                  <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              lg="6"
              xl="4"
            >
              <Entry :item="item" @editEntry="editItem(item)">
              </Entry>
            </v-col>
          </v-row>
        </template>

        <!-- <template v-slot:footer>
          <v-toolbar
            class="mt-2"
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title class="subheading">
              This is a footer
            </v-toolbar-title>
          </v-toolbar>
        </template> -->
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>
<script>
import Entry from '@/components/Entry.vue'
import DialogAccounts from '@/components/DialogAccounts.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
import doubleEntry from '@/api/doubleEntry'

moment.locale('es-mx')

export default {
  name: 'Entries',
  components: {
    Entry
  },
  props: [
    'entries'
  ],
  data: () => ({
    // formEntry: doubleEntry,
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    loading: false,
    editedItem: {
      id: null,
      date: Number(moment().format('x')),
      accounts: [],
      description: '',
      docs: [],
      adjustmentEntry: false
    },
    defaultItem: {
      id: null,
      date: Number(moment().format('x')),
      accounts: [],
      description: '',
      docs: null,
      adjustmentEntry: false
    },
    tmpTransaction: {
      code: '',
      name: '',
      debit: 0,
      credit: 0
    },
    defaultTransaction: {
      code: '',
      name: '',
      debit: 0,
      credit: 0
    }
  }),
  methods: {

    editItem (item) {
      this.editedIndex = this.journal.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    /* deleteItem(item) {
    },

    deleteItemConfirm() {
    }, /* */

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
      })
    },

    save () {
      this.loading = true
      const item = { ...this.editedItem }
      this.editedItem.accounts.forEach((account, i) => {
        item.accounts[i].debit = Number(account.debit)
        item.accounts[i].credit = Number(account.credit)
      })
      console.log({ ...item })
      this.newEntry(item)
        .then((entry) => {
          this.close()
          console.log(entry)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    ...mapActions([]),
    ...mapActions('accounting', [
      'newEntry'
    ])
  },
  computed: {
    formEntry () {
      const form = { ...doubleEntry }
      form.inputs[1].options.form.inputs[0].items = this.getAccountsDetails
      form.inputs[1].options.insert.items = this.getAccountsDetails
      form.inputs[1].options.customInsert = {
        component: DialogAccounts,
        inserts: [
          { inputKey: 'account', objKey: 'id' },
          { inputKey: 'name', objKey: 'name' }
        ]
      }
      return form
    },
    journal () {
      // return this.getJournal;
      const from = Number.isNaN(Number(this.from)) ? moment(this.from).format('x') : Number(this.from)
      const to = Number.isNaN(Number(this.to)) ? moment(this.to).format('x') : Number(this.to)
      const journal = []
      const getJournal = this.entries || this.getJournal
      const entries = getJournal.filter((entry) => entry.date >= from && entry.date <= to)
      entries.forEach((entry) => {
        const { accounts } = entry
        const debits = accounts.reduce((acc, cur) => Number(acc) + Number(cur.debit), 0)
        const credits = accounts.reduce((acc, cur) => Number(acc) + Number(cur.credit), 0)
        journal.push({
          dateString: moment(entry.date, 'x').format('lll'),
          debits,
          credits,
          ...entry,
          balanceEntry: debits - credits
        })
      })
      return journal /* */
    },
    ...mapState(['from', 'to']),
    ...mapState('accounting', []),
    ...mapGetters([]),
    ...mapGetters('accounting', [
      'getJournal',
      'getAccountsCatalog',
      'getAccountsDetails'
    ])
  }
}
</script>
