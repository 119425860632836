<template>
  <v-data-table
    :headers="headers"
    :items="tasks"
    class="elevation-1"
    sort-by="priority"
    :search="search"
    dense
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
      </v-toolbar>
    </template>
    <!--<template v-slot:item.entry="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>
    <template v-slot:item.exit="{ item }">
      <v-container>
        <v-icon :color="item.movement === 'entry'
        ? 'success'
        : 'error'">
          {{(item.movement === 'entry')
            ? 'mdi-arrow-right-bold-box'
            : 'mdi-arrow-left-bold-box'}}
        </v-icon>
          {{(item.movement === 'entry')
            ? 'Entrada'
            : 'Salida'}}
      </v-container>
    </template>-->
    <template v-slot:item.start="{ item }">
      {{ item.start | dateFormat }}
    </template>
    <template v-slot:item.end="{ item }">
      {{ item.end | dateFormat }}
    </template>
    <template v-slot:item.deadline="{ item }">
      {{ item.deadline | dateFormat }}
    </template>
    <template v-slot:item.priority="{ item }">
      <v-chip
        class="ma-1"
        v-if="item.priority"
        :color="priorityObj[item.priority].color"
      >
        {{ priorityObj[item.priority].text }}
      </v-chip>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        class="ma-1"
        v-if="item.status"
        :color="statusObj[item.status].color"
      >
      {{ statusObj[item.status].text }}
      </v-chip>
    </template>
    <template v-slot:item.assignee="{ item }">
      {{ item.assignee ? item.assignee.name : null }}
    </template>
    <template v-slot:item.check="{ item }">
      <TaskActions :tasksIndexed="tasksIndexed" :item="item" @updated="emitUpdated"></TaskActions>
    </template>
    <template v-slot:item.actions="{ item }">
      <TaskDialog :item="item" :tasksIndexed="tasksIndexed" :tasks="tasks" :staff="staff" v-if="false"></TaskDialog>
      <TaskEdit :item="item" :tasks="tasks" :staff="staff" @refresh="emitUpdated"></TaskEdit>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import TaskActions from '@/components/TaskActions.vue'
import TaskDialog from '@/components/TaskDialog.vue'
import TaskEdit from '@/components/TaskEdit.vue'
// import firebaseApi from '../api/firebaseApi'
moment.locale('es')
export default {
  name: 'TasksTable',
  components: {
    TaskActions,
    TaskDialog,
    TaskEdit
  },
  props: {
    tasks: Array,
    tasksIndexed: Object,
    staff: Array
  },
  data: () => ({
    search: null,
    loading: false,
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      '01': { text: 'Atrasada', color: 'error' },
      '02': { text: 'En peligro', color: 'warning' },
      '03': { text: 'Pendiente', color: 'grey' },
      '04': { text: 'Iniciada', color: 'info' },
      '05': { text: 'Lista', color: 'success' }
    }
  }),

  mounted () {
  },

  computed: {
    headers () {
      return [
        { text: '', value: 'check', sortable: false },
        {
          text: 'Tarea',
          align: 'start',
          value: 'name'
        },
        { text: 'Inicio', value: 'start' },
        { text: 'Termina', value: 'end' },
        { text: 'Límite', value: 'deadline' },
        { text: 'Responsable', value: 'assignee' },
        { text: 'Prioridad', value: 'priority' },
        { text: 'Estado', value: 'status' },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    ...mapGetters('user', [
      'getUser'
    ]),
    // ...mapGetters('products', [
    //   'getProducts'
    // ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },

  watch: {
  },

  methods: {
    emitUpdated () {
      this.$emit('updated')
    },

    getDateFormat (date) {
      return moment(date, 'x').format('L')
    },

    inputDetail () {
      const movement = this.details.find(item => {
        return item.name === this.editedItem.detail?.name
      })
      this.editedItem.movement = movement.movement
      /* if (
          this.editedItem.detail === 'Venta'
          || this.editedItem.detail === 'Perdida'
        ) {
          this.editedItem.movement = 'exit';
        } else {
          this.editedItem.movement = 'entry';
        }/**/
    },

    customFilter (item, queryText /* itemText /**/) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code.toString().toLowerCase()
      const searchText = queryText.toString().toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },

    editItem (item) {
    },

    deleteItem (item) {
    },

    save () {
    },
    ...mapActions('inventories', ['insertInventory'])

  }
}
</script>
