<template>
  <div>
    <div :class="{ tggle: !internalValue, 'tggle-active': internalValue}" @click="internalValue = !internalValue">
        <div class="button">
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RedaSwitch',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lazyValue: this.value
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', this.lazyValue)
      }
    }
  },
  watch: {
    value (val) {
      this.lazyValue = val
    }
  },
  methods: {
    // Aquí es donde se declararían los métodos de tu componente
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    // Acciones al montar el componente
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.tggle-active .button {
    position: absolute;
    top: 1.37px;
    left: 15.03px;
    box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
    border-radius: 68.34px;
    background-color: #fff;
    width: 13.7px;
    height: 13.7px;
    overflow: hidden;
}
.tggle-active {
    width: 30.1px;
    position: relative;
    border-radius: 68.34px;
    background-color: #3f37c9;
    height: 16.4px;
    cursor: pointer;
}
.button {
    position: absolute;
    top: 1.37px;
    left: 1.37px;
    box-shadow: 0px 1.3667755126953125px 2.73px rgba(39, 39, 39, 0.1);
    border-radius: 68.34px;
    background-color: #fff;
    width: 13.7px;
    height: 13.7px;
    overflow: hidden;
}
.tggle {
    width: 30.1px;
    position: relative;
    border-radius: 68.34px;
    background-color: #535763;
    height: 16.4px;
    cursor: pointer;
}
</style>
