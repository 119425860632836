/* eslint no-shadow: ["error", { "allow": ["state", "getters", "product"] }] */
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import moment from 'moment'
import fbApi from '@/api/firebaseApi'
import redaApp from '../../api/redaApp'
// import tools from '@/api/tools';

const { api } = fbApi

// initial state
const initialState = {
  inventories: [],
  inventoryAliases: [],
  cellars: [],
  cellar: null,
  listinerInventories: null,
  listinerInventoryAliases: null,
  listinerCellars: null
}

const state = () => ({ ...initialState })

// getters
const getters = {
  getInventoryPurchases: (state, getters, { to, from }, rootGetters) => {
    const inventories = getters.getInventories.filter(({ entry }) => entry)
    return inventories
  },
  getCost (state, getters, { to, from }, rootGetters) {
    const costs = getters.getInventoryPurchases.reduce((acc, curr) => {
      if (acc[curr.product]) {
        if (curr.detail !== 'DEVOLUCIÓN DE VENTA') {
          acc[curr.product].quantity += Number(curr.quantity)
          acc[curr.product].totalCost += Number(curr.cost)
          acc[curr.product].unitCost += acc[curr.product].totalCost / acc[curr.product].quantity
        }
      } else {
        acc[curr.product] = {}
        acc[curr.product].quantity = Number(curr.quantity)
        acc[curr.product].totalCost = Number(curr.cost)
        acc[curr.product].unitCost = Number(curr.cost) / Number(curr.quantity)
      }
      return acc
    }, {})
    return costs
  },
  // getInventoryAliases: ({ inventoryAliases }) => {
  //   return inventoryAliases
  // },
  getCellars: ({ cellars }) => {
    return cellars
  },
  getCellar: ({ cellars }, getters, rootState, rootGetters) => {
    const cellar = rootGetters['company/getBranch']?.posCellar
    return cellars.find(({ code }) => code === cellar)
  },
  getCellarsEntry: ({ cellars, cellar }) => {
    return cellars.filter(({ type }) => type === '01' || type === '03')
  },
  getInventoryAliasesIndexed: ({ inventoryAliases }, getters, { to, from, products }, rootGetters) => {
    return inventoryAliases.reduce((acc, curr) => {
      if (!acc[curr.code]) acc[curr.code] = curr
      return acc
    }, {})
  },
  getInventories: (state, getters, { to, from, products }, rootGetters) => {
    return state.inventories.filter(({ date }) => from < date || date < to).map((mov) => {
      const newMov = { ...mov }
      if (mov.entry) newMov.entry = state.cellars.find(({ code }) => mov.entry)
      if (mov.exit) newMov.exit = state.cellars.find(({ code }) => mov.exit)
      newMov.commercialCode = mov.product
      // newMov.product = products.products.find(({ commercialCode }) => commercialCode === mov.product)
      newMov.unitCost = mov.cost / mov.quantity
      return newMov
    })
  },
  getStock: (state, getters, rootState, rootGetters) => {
    const stocks = getters.getInventories.reduce((acc, curr) => {
      const i = acc.findIndex(({ code }) => code === curr.commercialCode)
      if (i !== -1) {
        acc[i].entries += Number(curr.entry ? Number(curr.quantity) : 0)
        acc[i].exits += Number(curr.exit ? Number(curr.quantity) : 0)
        acc[i].stock = acc[i].entries - acc[i].exits
        acc[i].value += (curr.entry ? Number(curr.cost) : 0)
        acc[i].value -= (curr.exit ? Number(curr.cost) : 0)
        acc[i].currentvalue += (curr.entry ? Number(curr.cost) : 0)
        acc[i].currentvalue -= (curr.exit ? Number(curr.cost) : 0)
      } else {
        const entries = curr.entry ? Number(curr.quantity) : 0
        const exits = curr.exit ? Number(curr.quantity) : 0
        const stock = entries - exits
        const cost = Number(curr.cost)
        acc.push({
          code: curr.commercialCode,
          entries,
          exits,
          cost,
          value: cost,
          currentvalue: cost,
          stock
        })
      }
      return acc
    }, [])
    return stocks
  }
}

// actions
const actions = {
  async init ({ state, commit, dispatch, getters, rootGetters }, company) {
    try {
      const inventoriesQuerySnapshot = await fbApi.db.collection('inventories')
        .where('company', '==', company)
        .get()
      const inventories = []
      inventoriesQuerySnapshot.forEach((doc) => inventories.push({ ...doc.data(), uid: doc.id }))
      commit('setInventories', inventories)
      const listinerInventories = inventoriesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newInventories = []
        querySnapshot.forEach((doc) => newInventories.push({ ...doc.data(), uid: doc.id }))
        commit('setInventories', newInventories)
      })
      commit('setListinerInventories', listinerInventories)
      const inventoryAliasesQuerySnapshot = await fbApi.db.collection('inventoryAliases')
        .where('company', '==', company)
        .get()
      const inventoryAliases = []
      inventoryAliasesQuerySnapshot.forEach((doc) => inventoryAliases.push({ ...doc.data(), uid: doc.id }))
      commit('setInventoryAliases', inventoryAliases)
      const listinerInventoryAliases = inventoryAliasesQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newInventoryAliases = []
        querySnapshot.forEach((doc) => newInventoryAliases.push({ ...doc.data(), uid: doc.id }))
        commit('setInventoryAliases', newInventoryAliases)
      })
      commit('setListinerInventoryAliases', listinerInventoryAliases)
      const cellarsQuerySnapshot = await fbApi.db.collection('cellars')
        .where('company', '==', company)
        .get()
      const cellars = []
      cellarsQuerySnapshot.forEach((doc) => cellars.push({ ...doc.data(), uid: doc.id }))
      commit('setCellars', cellars)
      const listinerCellars = cellarsQuerySnapshot.query.onSnapshot((querySnapshot) => {
        const newCellars = []
        querySnapshot.forEach((doc) => newCellars.push({ ...doc.data(), uid: doc.id }))
        commit('setCellars', newCellars)
      })
      commit('setListinerCellars', listinerCellars)
      return
    } catch (error) {
      console.error(error)
      throw new Error('Error al iniciar inventario')
    }
  },
  checkStock: function ({ getters }, line) {
    return true
    // const stock = getters.getStock.find((item) => {
    //   return item.code === line.code
    // })
    // if (!stock) {
    //   return true
    // }
    // if (stock.stock < line.quantity) {
    //   return false
    // }
    // return true
  },
  inventarySale ({ state, getters, dispatch, rootGetters }, voucher) {
    return new Promise((resolve) => {
      let j = 0
      voucher.data.items.forEach((item) => {
        const prod = rootGetters['products/getProducts'].find(product => {
          return product.commercialCode === item.commercialCode
        })
        if (prod?.inventoryable) {
          j++
          const stock = prod.stocks.find(cellarStock => {
            return cellarStock.code === getters.getCellar.code
          })
          dispatch('insertInventory', {
            id: getters.getInventories.length + j,
            date: moment().format('x'),
            company: rootGetters['company/getCompany'].identification,
            product: item.commercialCode,
            detail: 'VENTA',
            exit: getters.getCellar.code,
            entry: null,
            quantity: Number(item.quantity),
            cost: stock.unitCost,
            docs: [],
            voucher: voucher.key,
            user: rootGetters['user/getUser'].uid
          })
            .catch(error => {
              console.error(error)
            })
        }
      })
      resolve()
    })
  },
  insertInventory: function ({ state, rootGetters }, inventory) {
    return new Promise((resolve, reject) => {
      inventory.user = rootGetters['user/getUser'].uid
      if (!inventory.uid) {
        redaApp.create('inventories', inventory)
          .then((newMov) => resolve(newMov))
          .catch((e) => reject(e))
      } else {
        redaApp.update('inventories', inventory, inventory.uid)
          .then((mov) => resolve(mov))
          .catch((e) => reject(e))
      }
    })
  },
  insertInventoryAliases ({ state, rootGetters }, alias) {
    console.log({ ...alias })
    return new Promise((resolve, reject) => {
      alias.user = rootGetters['user/getUser'].uid
      if (!alias.uid) {
        redaApp.create('inventoryAliases', alias)
          .then((newAlias) => resolve(newAlias))
          .catch((e) => reject(e))
      } else {
        redaApp.update('inventoryAliases', alias, alias.uid)
          .then((newAlias) => resolve(newAlias))
          .catch((e) => reject(e))
      }
    })
  },
  insertCellar ({ state, commit, dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      api.insertItem('cellars', { ...payload, company: rootGetters['company/getCompany'].identification }, 'code', true)
        .then((cellar) => resolve(cellar))
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  reset (state) {
    if (state.listinerInventories) {
      state.listinerInventories()
    }
    if (state.setListinerInventoryAliases) {
      state.setListinerInventoryAliases()
    }
    if (state.listinerCellars) {
      state.listinerCellars()
    }
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = typeof value === 'object' && value !== null ? Array.isArray(value) ? [...value] : { ...value } : value
    }
  },
  setListinerInventories (state, listener) {
    state.listinerInventories = listener
  },
  setListinerInventoryAliases (state, listener) {
    state.listinerInventoryAliases = listener
  },
  setListinerCellars (state, listener) {
    state.listinerCellars = listener
  },
  setInventories (state, payload) {
    if (!payload) state.listinerInventories()
    state.inventories = payload
  },
  setInventoryAliases (state, payload) {
    if (!payload) state.listinerInventoryAliases()
    state.inventoryAliases = payload
  },
  setCellars (state, payload) {
    if (!payload) state.listinerCellars()
    state.cellars = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
