<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="getProducts"
      sort-by="name"
      :search="search"
      :loading="loading"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Productos inventariables</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="mdi-magnify"
            class="mt-3 py-2 mr-2"
          ></v-text-field>
          <PFNewDialog :products="getProducts"></PFNewDialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex flex-row align-center justify-center">
          <PFNewDialog v-if="item && item.uid" :icon="'edit'" :item="item"></PFNewDialog>
          <reda-icon
            iconName="delete"
            @click="deleteItem(item)"
            :button="true"
          ></reda-icon>
        </div>
      </template>
      <template v-slot:item.taxes="{ item }">
        {{item.taxes && item.taxes.length ? Number(item.taxes[0].rate) : 0}}
      </template>
      <template v-slot:item.price="{ item }">
        {{((item.prices.findIndex( element => {return element.priceList == priceList}) > -1)
          ? Number(
              item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].price
            )
            +
            (
              item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].price
              * (item.taxes && item.taxes.length > 0 ? item.taxes[0].rate/100 : 0)
            )
          : "No definido") | currencyFormat((item.prices.findIndex( element => {return element.priceList == priceList}) > -1) ? item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].currency : null)}}
      </template>
      <template v-slot:item.currency="{ item }">
        {{(item.prices.findIndex( element => {return element.priceList == priceList}) > -1) ? item.prices[item.prices.findIndex( element => {return element.priceList == priceList})].currency : "No definida"}}
      </template>
    </v-data-table>
  </v-container>
  </template>
<script>
import PFNewDialog from '@/components/PFNewDialog.vue'
import { mapGetters, mapActions } from 'vuex'
import tools from '@/api/tools.js'
import firebaseApi from '../api/firebaseApi'
import RedaIcon from '../components/RedaIcon.vue'
export default {
  name: 'OrviProducts',
  components: { PFNewDialog, RedaIcon },
  data: () => ({
    listenerProducts: null,
    products: [],
    search: '',
    loading: true,
    priceList: 'general',
    headers: [
      { text: 'Código', value: 'commercialCode' },
      { text: 'Descripción', value: 'description' },
      { text: 'Unidad', value: 'unit' },
      { text: 'Moneda', value: 'currency' },
      { text: 'Precio', value: 'price', align: 'end' },
      { text: 'IVA %', value: 'taxes', align: 'end' },
      { text: 'Acciones', value: 'action', sortable: false, align: 'center' }
    ],
    items: [],
    editedIndex: -1
  }),
  mounted: function () {
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerProducts()
  },
  computed: {
    getProducts () {
      return this.products
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editar Producto'
    },
    ...mapGetters('priceList', [
      'getPrices'
    ]),
    ...mapGetters('company', [
      'getCompany'
    ])
  },
  filters: {
    round10 (value) {
      if (!value) return 0
      tools.round10(value, -5)
    }
  },
  methods: {
    customFilter (item, queryText, itemTitle) {
      try {
        const textOne = item.description.toLowerCase()
        const textTwo = item.code.toString()
        const searchText = queryText.toString().toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
      } catch (error) {
        console.error(error)
        console.log({ ...item })
      }
    },
    reset () {
      if (this.listenerProducts) this.listenerProducts()
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerProducts = firebaseApi.db.collection('products')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.loading = true
          this.products = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const data = doc.data()
            if (data.inventoryable) this.products.push({ ...data, uid: doc.id })
          }
          this.loading = false
        })
    },

    deleteItem (item) {
      this.deleteProduct(item)
        .then((msj) => {
          console.log(msj)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    ...mapActions('priceList', ['cleanPrices', 'setPrices']),
    ...mapActions('message', ['showError']),
    ...mapActions('products', ['deleteProduct'])
  }
}
</script>
  <style type="text/css">

  </style>
