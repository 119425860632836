<template>
  <v-container>
    <Gantt :data="series" :columns="columns"></Gantt>
  </v-container>
</template>
<script type="text/javascript">
import Gantt from '@/components/Gantt.vue'
import { mapGetters } from 'vuex'
// import redaApp from '../api/redaApp'
export default {
  name: 'TasksGantt',
  components: { Gantt },
  props: [
    'tasks'
  ],
  data: () => ({
  }),
  mounted () {
    // redaApp.submitRedaApp('get-tasks')
    //   .then(({ data }) => {
    //     this.movs = data.inventories
    //     this.products = data.products
    //   })
    //   .catch((e) => console.error(e))
    //   .finally(() => {
    //     this.loading = false
    //   })
  },
  computed: {
    series () {
      const series = []
      const seriesIndexed = this.tasks
        .filter(({ start, end }) => start && end)
        .sort((a, b) => a.start - b.start)
        .map((task, i) => ({ ...task, y: i + 1 }))
        .reduce((acc, curr) => {
          const company = curr.company
          if (acc[company]) {
            acc[company].push(curr)
            return acc
          }
          acc[company] = [curr]
          return acc
        }, {})
      for (const [key, value] of Object.entries(seriesIndexed)) {
        const company = this.getCompanies.find(({ identification }) => identification === key)
        if (company) {
          series.push({
            name: company.tradename || company.name,
            data: value
          })
        }
      }
      return series
    },
    columns () {
      return [{
        title: {
          text: 'Tarea'
        },
        labels: {
          format: '{point.name}'
        }
      }, {
        title: {
          text: 'Responsable'
        },
        labels: {
          format: '{point.assignee.name}'
        }
      }]
    },
    ...mapGetters('company', ['getCompany', 'getCompanies'])
  }
}
</script>
<style type="text/css">

</style>
