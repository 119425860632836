<template>
<div class="otros-datos">
  <div class="titulo-seccion">
      <div class="datos-del-cliente">Otros Datos</div>
  </div>
  <div class="frame-parent">
      <div class="frame-wrapper">
          <div class="frame-container">
              <div class="seleccione-el-tipo-de-entrega-wrapper">
                  <div class="seleccione-el-tipo">Seleccione el tipo de entrega:</div>
              </div>
          </div>
      </div>
      <div class="frame-group">
          <div class="rectangle-parent" v-for="(item, i) in deliveryMethodItems" :key="i">
            <input type="radio" :value="item.value" v-model="deliveryMethodValue"  class="frame-child">
              <div class="entrega-en-sucursal-wrapper">
                  <div class="entrega-en-sucursal">{{ item.text }}</div>
              </div>
          </div>
      </div>
  </div>
  <img class="otros-datos-child" alt="" src="Line 10.svg">
  <div class="frame-div"  v-if="deliveryMethodValue !== '001'">
      <div class="frame-wrapper">
          <div class="frame-container">
              <div class="seleccione-el-tipo-de-entrega-wrapper">
                  <div class="seleccione-el-tipo">Datos de entrega:</div>
              </div>
          </div>
      </div>
      <div class="caja-de-texto-select">
        <OdSelect label="Provincia" :items="provinces" v-model="address.province"></OdSelect>
        <OdSelect label="Cantón" :items="counties" v-model="address.county"></OdSelect>
        <OdSelect label="Distrito" :items="districts" v-model="address.district"></OdSelect>
      </div>
      <div class="caja-de-texto-parent">
          <div class="caja-de-texto">
            <input type="text" v-model="address.others" class="entrega-en-sucursal" placeholder="Dirección de entrega">
          </div>
          <div class="caja-de-texto" v-if="false">
            <input type="text" class="entrega-en-sucursal" placeholder="Horario">
          </div>
          <div class="caja-de-texto">
            <input type="text" class="entrega-en-sucursal" v-model="addressee" placeholder="Nombre de la persona que recibe">
          </div>
      </div>
  </div>
  <div class="frame-parent1" v-if="deliveryMethod.prices !== 'free'">
      <div class="seleccione-el-tipo-de-entrega-wrapper">
          <div class="seleccione-el-tipo">Costo de envio:</div>
      </div>
      <div class="wrapper">
          <input type="number" class="div" v-model="deliveryCost">
      </div>
  </div>
  <img class="otros-datos-child" alt="" src="Line 11.svg">

  <div class="frame-div">
      <div class="frame-wrapper">
          <div class="frame-container">
              <div class="seleccione-el-tipo-de-entrega-wrapper">
                  <div class="seleccione-el-tipo">Observaciones:</div>
              </div>
          </div>
      </div>
      <div class="caja-de-texto-wrapper">
          <div class="caja-de-texto">
            <input type="text" v-model="others" class="entrega-en-sucursal" placeholder="Agregue sus observaciones aqui">
          </div>
      </div>
  </div>
  <div class="boton-prinicipal" @click="save(); $vuetify.goTo(0)">
    <div class="btn-text">Finalizar</div>
  </div>
</div>
</template>
<script>
import OdSelect from '@/components/odInputs/OdSelect.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'OtherDataOrder',
  props: {
    // Aquí es donde se declararían las propiedades de tu componente
  },
  data () {
    return {
      deliveryMethodValue: '001',
      deliveryCost: 0,
      addressee: null,
      address: {
        province: null,
        county: null,
        district: null,
        others: null
      },
      provinces: [],
      counties: [],
      districts: [],
      others: null
    }
  },
  computed: {
    payload () {
      const payload = {
        address: this.deliveryMethodValue !== '001'
          ? this.address : {
            province: null,
            county: null,
            district: null,
            others: null
          },
        method: this.deliveryMethodValue,
        phones: [],
        emails: [],
        addressee: this.addressee,
        docs: [],
        movs: [{
          action: 'En preparación',
          staff: this.getUser.uid
        }],
        others: this.others
      }
      return payload
    },
    deliveryMethod () {
      return this.deliveryMethodIndexed[this.deliveryMethodValue]
    },
    deliveryMethodItems () {
      return this.getCompany.shippingMethods?.map(({ id, name }) => ({
        value: id, text: name
      })) || []
    },
    deliveryMethodIndexed () {
      return this.getCompany.shippingMethods?.reduce((acc, cur) => {
        acc[cur.id] = cur
        return acc
      }, {})
    },
    location () {
      return this.$store.state['1'].receiver?.location
    },
    ...mapGetters('company', ['getCompany']),
    ...mapGetters('user', ['getUser'])
  },
  components: { OdSelect },
  watch: {
    'address.province' (val) {
      this.getCounty()
    },
    'address.county' (val) {
      this.getDistrict()
    },
    deliveryMethodValue (val) {
      if (val === '001') return
      this.address.province = this.location?.province || null
      this.address.county = this.location?.county || null
      this.address.district = this.location?.district || null
      this.address.others = this.location?.others || null
    }
  },
  methods: {
    getProvince () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincias.json')
          .then(response => {
            const provinces = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              provinces.push({
                text: value,
                value: String(key)
              })
            }
            this.provinces = provinces
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCounty () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.address.province + '/cantones.json')
          .then(response => {
            const counties = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              counties.push({
                text: value,
                value: String(key).padStart(2, '0')
              })
            }
            this.counties = counties
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict () {
      return new Promise((resolve, reject) => {
        axios.get('https://ubicaciones.paginasweb.cr/provincia/' + this.address.province + '/canton/' + Number(this.address.county) + '/distritos.json')
          .then(response => {
            const districts = []
            const entries = Object.entries(response.data)
            for (const [key, value] of entries) {
              districts.push({
                text: value,
                value: String(key).padStart(2, '0')
              })
            }
            this.districts = districts
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOtherCharge () {
      this.$store.commit('1/addOtherCharge', {
        detail: 'Costos de entrega',
        amount: Number(this.deliveryCost || 0)
      })
    },
    setDelivery () {
      this.$store.commit('1/setDelivery', {
        detail: 'Costos de entrega',
        amount: Number(this.deliveryCost || 0)
      })
    },
    save () {
      this.setDelivery(JSON.parse(JSON.stringify(this.payload)))
      this.addOtherCharge()
      this.clean()
    },
    clean () {
      this.deliveryMethodValue = '001'
      this.deliveryCost = 0
      this.addressee = null
      this.address.province = null
      this.address.county = null
      this.address.district = null
      this.address.others = null
      this.counties = []
      this.districts = []
      this.others = null
    }
  },
  beforeCreate () {
    // Acciones antes de crear el componente
  },
  created () {
    // Acciones al crear el componente
  },
  beforeMount () {
    // Acciones antes de montar el componente
  },
  mounted () {
    this.getProvince()
  },
  beforeUpdate () {
    // Acciones antes de actualizar el componente
  },
  updated () {
    // Acciones al actualizar el componente
  },
  beforeDestroy () {
    // Acciones antes de destruir el componente
  },
  destroyed () {
    // Acciones al destruir el componente
  }
}
</script>
<style scoped>
.datos-del-cliente {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.titulo-seccion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  font-size: 24px;
  color: #480ca8;
  font-family: Rubik;
}
.seleccione-el-tipo {
  position: relative;
  font-weight: 500;
}
.seleccione-el-tipo-de-entrega-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frame-child {
  width: 18.8px;
  position: relative;
  border-radius: 50%;
  border: 2.1px solid #4361ee;
  box-sizing: border-box;
  height: 18.8px;
}
input[type="radio"].frame-child {
  appearance: none; /* Oculta el radio button original */
  cursor: pointer;
}
input[type="radio"].frame-child:checked {
  background-color: #4361ee;
}
.entrega-en-sucursal {
  flex: 1;
  position: relative;
}
input.entrega-en-sucursal {
  border: none;
  background: transparent;
  outline: none;
}
.entrega-en-sucursal-wrapper {
  width: 243.3px;
  border-radius: 8.37px;
  height: 23.7px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px;
  box-sizing: border-box;
}
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.3px;
  font-size: 11.51px;
}
.frame-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.otros-datos-child {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.caja-de-texto {
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #4361ee;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
}
.caja-de-texto-select {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  font-size: 11px;
  color: #979797;
}
.caja-de-texto-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 11px;
  color: #979797;
}
.frame-div {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.div {
  width: 76.2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
input.div {
  border: none;
  background: transparent;
  outline: none;
}
.wrapper {
  border-bottom: 1.5px solid #4361ee;
  box-sizing: border-box;
  height: 22.7px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  text-align: center;
  color: #8b909a;
}
.frame-parent1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 12px;
}
.caja-de-texto-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 11px;
  color: #979797;
}
.btn-text {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 12.66px;
  font-weight: 500;
}
.boton-prinicipal {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(203, 27, 107, 0.05) inset;
  border-radius: 30px;
  background-color: #f72585;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-family: Rubik;
  cursor: pointer;
}
.otros-datos {
  width: 100%;
  position: relative;
  border-radius: 20.92px;
  background-color: #fff;
  border: 1.5px solid #4361ee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25.1px 24px;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #535763;
  font-family: Roboto;
}

</style>
